import React from "react";
//import { Link } from "react-router-dom"
// reactstrap components
import {
  Card,
  CardBody,
  Table,
} from "reactstrap";

const TableEducation = ({item}) => {
    return (
      <>
      <Card>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Studienfach</th>
                        <th>Hochschule </th>
                        <th>Anfangsdatum</th>
                        <th>Endtermin</th>
                        <th>Abschlussnote</th>
                        <th>Beschreibung</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                        Innere Medizin
                        </td>
                        <td>
                        Uni Saarland
                        </td>
                        <td>
                        01/09/2005 
                        </td>
                        <td>
                        01/09/2009
                        </td>
                        <td>
                        Keiner
                        </td>
                        <td>
                        Ich Innerestudiere Medizin
                        </td>
                        {/* {item.resume.map((w) => {
                          return (
                            <>
                            {w.educations.map((edu) => {
                              return (
                                <>
<td>
                              {edu.institutionName} <br/>
                              <small className="text-italic">
                                {edu.schoolLevel}
                              </small>
                              </td>
                        <td>
                        {edu.city} <br/>
                              <small className="text-italic">
                                {edu.country}
                              </small>
                        </td>
                        <td>
                        {edu.detail}

                        </td>
                        <td className="">
                          {edu.startMonth}.{edu.startYear} - {edu.endMonth}.{edu.endYear}
                          </td>
                                </>
                              )
                            })}
                            </>
                          )
                        })}
                         */}
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
      </>
    );
}

export default TableEducation;
