import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import classnames from "classnames";
import Button_loading from "components/Button_loading";
import SunEditor from "suneditor-react";
import Moment from "moment";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "react-toastify/dist/ReactToastify.css";

const ContractEditor = () => {
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [contractCompanyId, setContractCompanyId] = useState(null);
  const [contractCandidateId, setContractCandidateId] = useState(null);
  const userId = localStorage.getItem("user_id");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const fetchData = async () => {
    setLoading(true);
    setDisabled(true);
    try {
      const companyResponse = await axios.post("/api2/GetEditorContractCompany");
      const candidateResponse = await axios.post("/api2/GetEditorContractCandidate");
      const allCandidatesResponse = await axios.post("/api2/GetEditorContractCandidateAll");
      const allCompaniesResponse = await axios.post("/api2/GetEditorContractCompanyAll");

      if (companyResponse.data.result[0]) {
        setValue(companyResponse.data.result[0].EditorDesc);
        setContractCompanyId(companyResponse.data.result[0].Contract_Company_id);
      }

      if (candidateResponse.data.result[0]) {
        setValue1(candidateResponse.data.result[0].EditorDesc);
        setContractCandidateId(candidateResponse.data.result[0].Contract_Candidate_id);
      }

      setCandidateList(allCandidatesResponse.data.result);
      setCompanyList(allCompaniesResponse.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const submitHandler = async () => {
    setLoading(true);
    setDisabled(true);
    try {
      await axios.post("/api2/EditorContractCompany", {
        value,
        Contract_Company_id: contractCompanyId,
        user_id: userId,
      });
      toast.success("Company contract saved successfully!");
      await fetchData();
    } catch (error) {
      console.error("Error saving company contract:", error);
      toast.error("Failed to save company contract.");
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const submitHandler1 = async () => {
    setLoading(true);
    setDisabled(true);
    try {
      await axios.post("/api2/EditorContractCandidate", {
        value1: value1,
        Contract_Candidate_id: contractCandidateId,
        user_id: userId,
      });
      toast.success("Candidate contract saved successfully!");
      await fetchData();
    } catch (error) {
      console.error("Error saving candidate contract:", error);
      toast.error("Failed to save candidate contract.");
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content">
      <ToastContainer />
      <Nav pills style={{ justifyContent: "center" }}>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Vertrag Kandidaten
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Vertrag Unternehmen
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            Vertrag Archived
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div style={{ background: "white" }}>
            <Row>
              <Col md="8">
                <h4 style={{ textAlign: "center" }}>Vertrag Kandidaten</h4>
              </Col>
              <Col md="4">
                <Link to="/admin/ContractEditor_New_Version_Kandidaten">
                  <Button style={{ marginTop: "25px" }} color="primary">
                    Add New (Kandidaten)
                  </Button>
                </Link>
              </Col>
            </Row>
            <div>
              <SunEditor
                setContents={value1}
                onChange={setValue1}
                lang="en"
                width="100%"
                height="100%"
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle", "blockquote"],
                    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                    ["fontColor", "hiliteColor", "textStyle"],
                    ["removeFormat"],
                    "/", // Line break
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link"],
                    ["fullScreen", "showBlocks", "codeView"],
                    ["preview", "print"],
                    ["save", "template"],
                  ],
                }}
              />
              <Button_loading
                style={{ float: "right" }}
                color="primary"
                onClick={submitHandler1}
                loading={loading}
                disabled={disabled}
              >
                Save
              </Button_loading>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div style={{ background: "white" }}>
            <Row>
              <Col md="8">
                <h4 style={{ textAlign: "center" }}>Vertrag Unternehmen</h4>
              </Col>
              <Col md="4">
                <Link to="/admin/ContractEditor_New_Version_Unternehmen">
                  <Button style={{ marginTop: "25px" }} color="primary">
                    Add New (Unternehmen)
                  </Button>
                </Link>
              </Col>
            </Row>
            <div>
              <SunEditor
                setContents={value}
                onChange={setValue}
                lang="en"
                width="100%"
                height="100%"
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["paragraphStyle", "blockquote"],
                    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                    ["fontColor", "hiliteColor", "textStyle"],
                    ["removeFormat"],
                    "/", // Line break
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table", "link"],
                    ["fullScreen", "showBlocks", "codeView"],
                    ["preview", "print"],
                    ["save", "template"],
                  ],
                }}
              />
              <Button_loading
                style={{ float: "right" }}
                color="primary"
                onClick={submitHandler}
                loading={loading}
                disabled={disabled}
              >
                Save
              </Button_loading>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div style={{ background: "white" }}>
            <Card>
              <CardHeader>
                <h4>Kandidaten Vertrag List</h4>
              </CardHeader>
              <CardBody>
                {candidateList.map((user) => (
                  <div key={user.Contract_Candidate_id}>
                    <Link to={`candidate_vertrag_edit/${user.Contract_Candidate_id}`}>
                      <li>{Moment(user.Date).format("DD.MM.YYYY")}</li>
                    </Link>
                  </div>
                ))}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h4>Unternehmen Vertrag List</h4>
              </CardHeader>
              <CardBody>
                {companyList.map((user) => (
                  <div key={user.Contract_Company_id}>
                    <Link to={`company_vertrag_edit/${user.Contract_Company_id}`}>
                      <li>{Moment(user.Date).format("DD.MM.YYYY")}</li>
                    </Link>
                  </div>
                ))}
              </CardBody>
            </Card>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ContractEditor;
