import React from "react";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  //CardTitle,
  CardFooter,
  //Collapse,
  Form,
  FormGroup,
  Input,
  // NavItem,
  // NavLink,
  // Nav,
  // TabContent,
  // TabPane,
  Row,
  Col,
  //Container,
  Table,
  Button
} from "reactstrap";

import {contactPersons} from "variables/general.jsx"

//import TableCandidateList from "building_block/table_candidate_list"
import TableContactList from "building_block/table_contact_list"
//import TableCandidateDatabase from "building_block/table_candidate_job_details_database"
//import Message from "building_block/message"

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalTabs: "HelloMatch",
      jobTypeSingleSelect: null,
      statusSingleSelect: null,
      qualificationSingleSelect: null,
      specializationMultipleSelect: null,
      workingTimeMultipleSelect: null,
      workingTernureMultipleSelect: null,
      qualificationMultiSelect: null,
      specializationMultiSelect: null,
      countrySingleSelect: null,
      citySingleSelect: null,
      radiusSingleSelect: null,
      decisionSingleSelect: null,
      openedCollapses: ["collapseOne", "collapse1"]
    };
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                    <Row>
                        <Col md="6">
                        <h2>
                        Ansprechpartner
                        </h2>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
            <Col md="12">
              <Row className="justify-content-center ml-auto mr-auto">
                <Col md="8">
                  <Card>
                <CardBody>
                  <Form action="#" method="#">
                          <Row>
                            <Col md="4">
                            <label>Name</label>
                    <FormGroup>
                      <Input placeholder="Name" type="text" />
                    </FormGroup>
                            </Col>
                            <Col md="4">
                            <label>Email</label>
                    <FormGroup>
                      <Input
                        placeholder="email"
                        type="email"
                        autoComplete="off"
                      />
                    </FormGroup>
                            </Col>
                            <Col md="4">
                            <label>Status</label>
                            <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="statusSingleSelect"
                            value={this.state.statusSingleSelect}
                            onChange={value =>
                              this.setState({ statusSingleSelect: value })
                            }
                            options={[
                              {
                              value:"1",
                              label:"Registrierung bestätigt"
                            },
                          {
                            value:"2",
                            label:"Registrierung nicht bestätigt"
                          },
                        ]}
                            placeholder="Bitte auswählen"
                          />
                            </Col>
                          </Row>
                          </Form>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col className="text-center">
                    <Button 
                          className="btn-round center"
                          outline
                          size="md"
                          color="primary">
Suchen
                          </Button>
                    </Col>
                  </Row>
                          
                </CardFooter>
              </Card>
                </Col>
              </Row>
              <hr/>
              <Card>
                <CardHeader>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">#</th>
                        <th className="text-left">Name</th>
                        <th className="text-left">Kontakt</th>
                        <th className="text-left">Status</th>
                        <th className="text-right">Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>
                    {contactPersons.map((item) => {
                          return (
                              <tr>
                                <TableContactList item={item} />
                              </tr>
                          )
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Message /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ContactList;
