import React from "react";
import axios from "axios";
import "./style1.css";
import {Redirect, Link} from "react-router-dom";
import ReactPaginate from "react-paginate";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      cand: [],
      offset: 0,
      perPage: 10,
      currentPage: 0,
      state1: false,
    };
  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  componentDidMount() {
    axios
      .get("/api2/candidatefeedback")
      .then((response) => {
        console.log(response);
        const cand = response.data;
        //const back_feedback_id = response.data.back_feedback_id;
        //this.setState({back_feedback_id})
        const slice = cand.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const postData = slice.map((item) => (
          <React.Fragment>
            <tr>
              <td>
                <label className="text-ubold">{item.feedbackdate}</label>
              </td>
              <td className="text-left">
                <label className="text-ubold">
                  {/* {item.title === 'Kein' ? null : item.title}  */}
                  {item.f_name} {item.l_name} <br></br>
                  {/* <small><b>{item.telephone}</b></small> */}
                </label>
              </td>

              <td className="text-left">
                <label className="text-ubold p_wrap">{item.text}</label>
              </td>
              <td className="text-left">
                <label className="text-ubold p_wrap">
                  {item.feedback_status}
                </label>
              </td>

              <td className="text-right">
                {item.feedback_status === "accept" ? null : (
                  <span>
                    <Button
                      className="btn-icon"
                      color="success"
                      id="tooltip366246651"
                      size="sm"
                      type="button"
                      onClick={this.acceptHandle.bind(this, item.app_id)}>
                      <i className="fa fa-check-circle" />
                    </Button>{" "}
                    <UncontrolledTooltip delay={0} target="tooltip366246651">
                      Akzeptieren
                    </UncontrolledTooltip>
                  </span>
                )}
                <Button
                  className="btn-icon"
                  color="danger"
                  id="tooltip366246652"
                  size="sm"
                  type="button"
                  onClick={this.deleteHandle.bind(this, item.app_id)}>
                  <i className="fa fa-trash" />
                </Button>{" "}
                <UncontrolledTooltip delay={0} target="tooltip366246652">
                  Löschen
                </UncontrolledTooltip>
                {/* <Link to={`/admin/candidateFeedbackEdit/${item.item.app_id}`}> */}
                <Button
                  className="btn-icon"
                  color="primary"
                  id="tooltip366246652"
                  size="sm"
                  type="button"
                  style={{marginLeft: "0px"}}
                  onClick={this.editHandle.bind(this, item.app_id)}>
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* </Link> */}
                <UncontrolledTooltip delay={0} target="tooltip366246652">
                  Bearbeiten
                </UncontrolledTooltip>
              </td>
            </tr>
          </React.Fragment>
        ));
        localStorage.setItem("cand", JSON.stringify(this.state.cand));
        console.log(cand);
        this.setState({state1: true});
        this.setState({
          pageCount: Math.ceil(cand.length / this.state.perPage),
          postData,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  deleteHandle(appid) {
    console.log(this.state);
    console.log(appid);
    axios
      .post("/api2/delcandidfeedback", {...this.state, appid})
      .then((response) => {
        console.log(response);
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        //window.location.reload(true);
        // const WorkExp1 = response.data;
        // this.setState({ WorkExp1 });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  editHandle(appid) {
    console.log(appid);
    this.setState({appid});
    this.setState({redirect: true});
  }
  acceptHandle(appid) {
    console.log(this.state);
    console.log(appid);
    axios
      .post("/api2/acceptcandidfeedback", {...this.state, appid})
      .then((response) => {
        console.log(response);
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        //window.location.reload(true);
        // const WorkExp1 = response.data;
        // this.setState({ WorkExp1 });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.componentDidMount();
      }
    );
  };
  render() {
    const {redirect} = this.state;
    if (redirect) {
      return (
        <Redirect to={`/admin/candidateFeedbackEdit/${this.state.appid}`} />
      );
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend">
                    <b>Kandidaten Bewertungen</b>
                  </h5>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Vom</th>
                        <th className="text-left">KANDIDATEN</th>
                        <th className="text-left">Bewertung</th>
                        <th className="text-left">Status</th>
                        <th className="text-right">Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>{this.state.postData}</tbody>
                    <br></br>
                    <br></br>
                    {this.state.state1 ? (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Imprint;
