// src/config/axiosConfig.js
import axios from "axios";

// Base URL and withCredentials setting (adjust based on your needs)
axios.defaults.baseURL = "";
axios.defaults.withCredentials = true;

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Pass through successful responses
    return response;
  },
  async (error) => {
    // Handle expired token errors
    if (error.response?.status === 401 && error.response?.data?.Message === "Token expired. Please refresh.") {
      try {
        // Refresh the token
        const refreshResponse = await axios.post("/api2/token-refresh");
        const newToken = refreshResponse.data.token;

        // Update the token in the original request
        error.config.headers["Authorization"] = `Bearer ${newToken}`;

        // Retry the original request with the new token
        return axios.request(error.config);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);

        // Redirect to login or handle logout
        window.location.href = "/login";
      }
    }

    // Pass all other errors down the chain
    return Promise.reject(error);
  }
);
