import React from "react";
import axios from 'axios';
import "./style1.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      cand : []
    };
  }
  deleteHandle(jobid, e){
    console.log(jobid);
    axios
      .post('/api2/deletelogo',{...this.state,jobid})
      .then(response =>{
        console.log(response)
        var resmsg = response.data.msg;
        this.setState({ resmsg });
        window.location.reload();
      })
      .catch(error =>{
        console.log(error)
        var reserrmsg = error.response.data.msg;
        this.setState({ reserrmsg });
      })
}
  componentDidMount(){
    console.log("logo start")
    axios
			.get('/api2/alllogo')
			.then(response => {
        console.log(response)
        const cand = response.data;
        this.setState({ cand });
        console.log(cand);
  })
			.catch(error => {
        console.log(error);
    	})
  }


  render() {
    return (
      <>
     <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend"><b>Unternehmen Logo</b></h5>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Vom</th>
                        <th className="text-left">Company</th>
                        <th className="text-left">Logo</th>
                        <th className="text-right">Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.cand.map((item) => {
                          return (
                            <>
                    <tr>
                             <td>
                             <label className="text-ubold">
                           {item.selected_date}
                          
                                    </label>
                            </td> 
                            <td className="text-left">
                           
                            <label className="text-ubold">
                         
                         {item.company_name}
                                     </label>
                        </td>

                        <td className="text-left">
                           
                           <label className="text-ubold">
                        
                        {item.logo} 
                                    </label>
                       </td>
                        <td className="text-right">
                      
                          <Button
                            className="btn-icon"
                            color="danger"
                            id="tooltip366246652"
                            size="sm"
                            type="button"
                            onClick={this.deleteHandle.bind(this, item.job_id)}
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246652"
                          >
                            Löschen
                          </UncontrolledTooltip>
                        </td> 
                        </tr>
                        </>
                          )
                        })} 
                    </tbody>
         
                  </Table>
                </CardBody>
                </Card>  
            </Col>
          </Row> 
        </div>
      </>
    );
  }
}

export default Imprint;
