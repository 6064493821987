import React from 'react'

//import { Link } from "react-router-dom";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,

  } from "reactstrap";

import CardJob from "building_block/job_card_list"

const JobBooked = ({item}) => {
    return (
          <Col md="6">
              <Row className="">
                <Col md="12">
                <CardJob item={item} />
                </Col>
              </Row>
            <Card>
              <CardBody>
                <Row>
                  <Col md="7">
                    <b>
                  Vertragsbeginn:
                    </b>
                  </Col>
                  <Col md="5">
                  {item.startDate}
                  </Col>
                  <Col md="7">
                    <b>
                  Frühestmögliches Vertragsende:
                    </b>
                  </Col>
                  <Col md="5">
                  {item.earliestDateEndContract}
                  </Col>
                  <Col md="7">
                    <b>
                    Letztmöglicher Kündigungseingang bis:
                    </b>
                  </Col>
                  <Col md="5">
                  {item.deadlineTermination}
                  </Col>
                </Row>
                 
              </CardBody>
            </Card>
            <Row>
                    <Col className="">
                    <Button href='src/assets/img/invoice-example.png' download color="primary">
                          Rechnung drucken
                        </Button>
                    </Col>
                  </Row>
          </Col>
    )
}

export default JobBooked