import React from "react";
import axios from 'axios';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import Icons from "views/components/Icons.jsx";
//import { FaBeer } from 'react-icons/fa';
class Contact extends React.Component {

 
    componentWillMount(){
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    this.setState({user_id})
  }
  render() {
    return (
      <>
        <div>
        <div className="content">
              <Card className="card-stats">
                    {/* Hero Start */}
              <section className="bg-home bg-light d-flex align-items-center" style={{
                height: '70vh',
                marginTop: '100px'
              }}>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="text-center">
                        <div
                          className="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                          style={{ height: 90, width: 90 }}
                        >
                         <i style={{fontSize: '42px',
                            color: '#2eca8b'}} class="fas fa-check"></i>
                        </div>
                        <h1 className="my-4 fw-bold">Vielen Dank für Deine Empfehlung!</h1>
                        <p className="text-muted para-desc mx-auto">
                        Du hast von uns eine E-Mail mit allen weiteren Informationen erhalten, die für eine Registrierung notwendig sind. Leite diese jetzt an Deinen Freund weiter. Deine Prämie erhältst Du, sobald Dein Freund die Teilnahmebedingungen erfüllt hat.
                        </p>
                       
                      </div>
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                </div>{" "}
                {/*end container*/}
              </section>
              {/*end section*/}
              </Card>
              </div>
        </div>
      </>
    );
  }
}
export default Contact;
