import React from "react";
import Select from "react-select";
import axios from 'axios';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";
import Icons from "views/components/Icons.jsx";
//import { FaBeer } from 'react-icons/fa';
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      roleSelect: null,
      email: '',
      password: '',
      alladmin : []
    };
    this.toggle = this.toggle.bind(this);
  }
   toggle() {
  this.setState({
    modal: !this.state.modal
  });
  }
  	changeHandler = e => {
		this.setState({ [e.target.name]: e.target.value })
  }
  	submitHandler = e => {
      e.preventDefault();
      var email_err;
      var pass_err;
      var role_err;
    console.log("states",this.state)
      if (this.state.email === '') {
        email_err = "please enter email"
        this.setState({email_err})
      } else {
        this.setState({email_err:undefined})
      }
      if (this.state.password === '') {
        pass_err = "please enter password"
        this.setState({pass_err})
      } else {
        this.setState({pass_err:undefined})
      }
      if (this.state.roleSelect === null) {
        role_err = "please select role"
        this.setState({role_err})
      } else {
        this.setState({role_err:undefined})
      }
      if (email_err === undefined && pass_err === undefined && role_err === undefined) {
        		axios
			.post('/api2/addadminusers', this.state)
			.then(response => {
        console.log(response)
        this.setState({adduser_err:undefined})
        var adduser_succ = response.data.msg;
        this.setState({ adduser_succ })
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
			})
			.catch(error => {
        console.log(error.response);
        this.setState({adduser_succ:undefined})
        var adduser_err = error.response.data.msg
        this.setState({ adduser_err })
    	})
      }

	}
  componentDidMount() {
    		axios
			.get('/api2/getadminusers')
			.then(response => {
        console.log(response)
        const alladmin = response.data;
        this.setState({alladmin})
			})
			.catch(error => {
        console.log(error.response);
        
    	})
  }
  acceptHandle(user, e) {
    console.log(user)
    axios
			.post('/api2/activeadminusers', {...this.state,user})
			.then(response => {
        console.log(response)
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
			})
			.catch(error => {
        console.log(error.response);
    	})
  }
  rejectHandle(user, e) {
    console.log(user)
    axios
			.post('/api2/blockadminusers', {...this.state,user})
			.then(response => {
        console.log(response)
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
			})
			.catch(error => {
        console.log(error.response);
    	})

  }
  deleteHandle = e => {
    console.log(this.state.username)
    axios
			.post('/api2/deleteadminusers', this.state)
			.then(response => {
        console.log(response)
        //this.setState(toggle)
        window.location.reload()
        //this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
			})
			.catch(error => {
        console.log(error.response);
    	})
  }
  render() {
    return (
      <>
        <div className="content">
              <Card >
            <CardBody>
              <Badge color="warning" style={{ fontSize: '58%' }}>{this.state.adduser_err}</Badge>
              <Badge color="success" style={{fontSize:'58%'}}>{this.state.adduser_succ}</Badge>
                   <Row>
               <Col className="" md="4">
                        <FormGroup>
                        <label className="form-label"><b>Email </b><span className="text-danger">*</span></label>
                              <Badge color="warning" style={{fontSize:'58%'}}>{this.state.email_err}</Badge>
                              <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="nc-icon nc-single-02" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="E-mail" type="email" name="email" 
                                 onChange={this.changeHandler}
                                  style={{fontSize: '15px', height: '41px'}}
                                  />
                                </InputGroup>
                        </FormGroup>
                      </Col>
                     <Col className="" md="3">
                        <FormGroup>
                        <label className="form-label"><b>Password </b><span className="text-danger">*</span></label>
                              <Badge color="warning" style={{fontSize:'58%'}}>{this.state.pass_err}</Badge>
                              <InputGroup>
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                       <i className="nc-icon nc-key-25" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input placeholder="Password" type="password" name="password" 
                                 onChange={this.changeHandler}
                                  style={{fontSize: '15px', height: '41px'}}
                                  />
                                </InputGroup>
                        </FormGroup>
                      </Col>
                        <Col className="" md="3">
                          <FormGroup>
                    <label><b>Role</b><span className="text-danger">*</span></label>
                    <Badge color="warning" style={{fontSize:'58%'}}>{this.state.role_err}</Badge>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="titleSelect"
                              value={this.state.roleSelect}
                              onChange={value =>
                                this.setState({roleSelect: value})
                              }
                              options={[
                                {
                                  value: "1",
                                  label: "admin"
                                },
                                {
                                  value: "2",
                                  label: "admin user"
                                },
                                {
                                  value: "3",
                                  label: "school"
                                }
                          ]}
                          placeholder = 'Bitte auswählen'></Select>
                          </FormGroup>
                        </Col>
                      
                        <Col className="" md="2">
                          <FormGroup>
                          
                    <Button color='primary'
                     style = {{marginTop:'25px'}}
                      onClick={this.submitHandler}
                    >Add User</Button>
                          </FormGroup>
                        </Col>
                    
                      
                    </Row>  
                    {this.state.alladmin.map((item) => {    
                          return (
                            <>
                   <Card style = {{backgroundColor: '#f5f5f5', padding:'10px',margin:'13px'}} >
          <Row>
                                  <Col md='4' className="text-left">
                                    {/* {item.f_name === null ? null: <h7 className="text-left" style={{fontSize:'13px'}}><i class="fa fa-user"></i>&nbsp; {item.andre} {item.f_name} {item.l_name}</h7>}<br/> */}
                                    <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp; {item.username}</h7><br/>
                                    {/* {item.telephone === null ? null : <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fa fa-mobile"></i>&nbsp;{item.telephone}</h7>}<br /> */}
                                    {/* {item.telephone === null ? null: <h7 className="text-left" style={{fontSize:'13px'}}><i class="fas fa-envelope-open-text"></i>&nbsp;{item.telephone}</h7>}<br/> */}
                                  </Col>
                            <Col md = '2' className="text-left"><h7 className="text-left" style={{fontSize:'13px'}}>&nbsp;<Badge color = 'info'>{item.account_type}</Badge> </h7>    </Col>
                            <Col md = '2' className="text-left">&nbsp;<Badge color = 'success'>{item.status}</Badge>
                           </Col>
                            
                    
         
                 <Col md = '4' className="text-right" style = {{marginTop:'-10px',marginBottom:'-10px'}}>
                 <Button
                          style = {{marginRight:'-3px'}}
                            className="btn-icon"
                            color="primary"
                            id={"tooltip366246639"+item.user_id}
                            size="sm"
                            type="button"
                              onClick={this.acceptHandle.bind(this, item.username)}
                            
                          >
                            <i class="fa fa-unlock" aria-hidden="true"></i>
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246639"+item.user_id}
                            
                          >
                            Aktivieren
                          </UncontrolledTooltip>

                          <Button
                            className="btn-icon"
                            color="dark"
                            id={"tooltip366246640"+item.user_id}
                            size="sm"
                            type="button"
                              onClick={this.rejectHandle.bind(this, item.username)}
                          >
                            <i className="fa fa-toggle-off" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246640"+item.user_id}
                          >
                            Deaktivieren
                          </UncontrolledTooltip> 

                          <Button
                           
                            className="btn-icon"
                            color="danger"
                            id={"tooltip366246642"+item.user_id}
                            size="sm"
                            type="button"
                             onClick={() => this.setState({ username: item.username},this.toggle)}
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246642"+item.user_id}
                          >
                            Löschen 
                          </UncontrolledTooltip>
                <Modal isOpen={this.state.modal} toggle={this.toggle}  className={this.props.className}
>
<ModalHeader toggle={this.toggle}></ModalHeader> 
<ModalBody>
 <h4 ><b>Admin dauerhaft löschen</b></h4>
<br></br><Badge color="warning">{this.state.reply}</Badge><br/>
Sind Sie sicher, dass Sie diesen Admin löschen möchten?<br></br>
<br></br>
<center><Button  
       onClick={this.toggle}  
       style={{width: 'auto',
       height: '32px',
       fontSize: '9px',
       borderRadius: '32px',
       boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
       fontWeight: '400',
       border: 'none !important',
       padding: '9px 27px',
       // background: '#dc3545',
        marginBottom: '1rem' }}>Abbrechen</Button>
 <Button 
       color="primary"
       style={{width: 'auto',
       height: '32px',
       fontSize: '9px',
       borderRadius: '32px',
       boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
       fontWeight: '400',
       border: 'none !important',
       padding: '10px 27px',
       background: '#dc3545'
     }}
      onClick={this.deleteHandle} 
     >
       
       Bestätigen </Button></center>

</ModalBody>
</Modal>

       
                  </Col>
                         
                </Row> 
         
             
             </Card> 
             

                        </>
                          )
                        })} 
          
                   </CardBody> 
          </Card>
         

         
        </div>
      </>
    );
  }
}
export default Contact;
