import React from "react";
import axios from 'axios';

// react plugin used to create DropdownMenu for selecting items
import { Link } from "react-router-dom";

// reactstrap components
import {
  Row,
  Col,
  //CardHeader,  
  Button
} from "reactstrap";
//import Card from "../../items/Card/Card.jsx";
//import CardBody from "../../items/Card/CardBody.jsx";
////import Ternure from "@material-ui/icons/BusinessCenter";
// import Time from "@material-ui/icons/Timer";
//import TypeMedical from "@material-ui/icons/LocationCity";
//import City from "@material-ui/icons/MapOutlined";
// import Ternure from "@material-ui/icons/BusinessCenter";
//import Time from "@material-ui/icons/Timer";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // levelSelect: null,
      records : [],
      offset: 0,
      perPage: 10,
      currentPage: 0,
      visible1:true
    };
  }
  componentWillMount(){
const job_id = JSON.parse(localStorage.getItem('job_id'))
this.setState({ job_id })
console.log(job_id)
axios.post("/api2/matchcandid", {...this.state,job_id})
                      .then(response => { 
                       console.log(response)
                      //  const job_id = response.data[0].job_id;
                      //       localStorage.setItem('job_id',JSON.stringify(job_id));
                      //       console.log(job_id)
                       // const msg1 = response.data.message;
                       // this.setState({msg1})
                       this.setState({redirect : true})
                    })
                    .catch(error => {
                     console.log(error);
                   })
}

  componentWillUnmount(){
    localStorage.removeItem('job_id');
}
 




  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md = "3"></Col>
            <Col md="6">
              {/* <Card>
              <CardHeader>
              <div style={{textAlign:'center'}}>
                <label className = "title" style={{fontSize: '2em'}}>STELLENANZEIGE</label> 
                  <h5 className="cstm-heading-backend1" ><b>Gratuliere!</b></h5><br></br> 
                <h7 style={{ fontSize: '14px'}}>Sie haben die Stellenanzeige erfolgreich eingestellt. Sie wird in Kürze online sein.</h7> <br></br>
                <Link to = {`/admin/apply-job/${this.state.job_id}`} style={{fontSize: '16px'}}>
                  <Link to = {"/admin/jobs"} style={{fontSize: '16px'}}>
                  <Button color = "primary">
                  Ihr Inserat
                  </Button>
                </Link>//
                </div>
                </CardHeader>
              </Card> */}
              <Link to = {"/admin/jobs"} style={{fontSize: '16px'}}>
                  <Button color = "primary">
                  Ihr Inserat
                  </Button>
                </Link>
            </Col>
            <Col md = "3"></Col>
          </Row> 
        </div>
      </>
    );
  }
}
export default Documents;
    
