import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

//import data
import {dateLogoBooked, showProfileBooked, jobsBooked, klinikBooked} from "variables/general.jsx"

import LogoBooked from "building_block/upgrades_booked/logo"
import JobBooked from "building_block/upgrades_booked/job"
import KlinikBooked from "building_block/upgrades_booked/klinik/more_profile"
import ShowKlinikProfileBooked from "building_block/upgrades_booked/klinik/show_profil"


class MyOptionalContract extends React.Component {
  render() {
    return (
      <div className="content">
            <Row className="">
            <Col md="12">
              <Card className="">
               
                <CardBody>
                  <div className="section-bottom-110">
                  <h3 className="text-center">
Mein Logo
</h3>
                  {dateLogoBooked.map((item) => {
                    return (

<LogoBooked item={item}/>
                    )
                  })}
                  </div>
                
<hr/>
<div className="section-bottom-110">
<h3 className="text-center">
Top Jobs
</h3>
<Row className="justify-content-center">
  
{jobsBooked.map((item) => {
  return (
<JobBooked item={item}/>

  )
})}

</Row>
</div>
<hr/>
<div className="section-bottom-110">
<h3 className="text-center">
              Sie haben {klinikBooked.length} Einrichtungen bei uns angemeldet:
            </h3>
{klinikBooked.map((item) => {
  return (
<KlinikBooked item={item}/>

  )
})}
</div>

<hr/>
<div className="section-bottom-110">
<h3 className="text-center">
Anbieterprofil
            </h3>
{showProfileBooked.map((item) => {
  return (
<ShowKlinikProfileBooked item={item}/>
  )
})}
</div>

<br/>


                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    );
  }
}

export default MyOptionalContract;
