import React from 'react'

//import { Link } from "react-router-dom";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Container
  } from "reactstrap";


const KlinikBooked = ({item}) => {
    return (
        <Container>
        <Row>
          <Col md="12">
            <h5>
              <b>
                
              {item.institutionName}
              </b>
            </h5>
            <Card>
              <CardBody>
                <Container>
                <Row>
                  <Col md="7">
                    <b>
                  Vertragsbeginn:
                    </b>
                  </Col>
                  <Col md="5">
                  {item.startDate}
                  </Col>
                  <br/>
                  <Col md="7">
                    <b>
                  Frühestmögliches Vertragsende:
                    </b>
                  </Col>
                  <Col md="5">
                  {item.earliestDateEndContract}
                  </Col>
                  <br/>
                  <Col md="7">
                    <b>
                    Letztmöglicher Kündigungseingang bis:
                    </b>
                  </Col>
                  <Col md="5">
                  {item.deadlineTermination}
                  </Col>
                </Row>
                </Container>
                
                 
              </CardBody>
            </Card>
            <Row>
                    <Col className="">
                    <Button href='src/assets/img/invoice-example.png' download color="primary">
                          Rechnung drucken
                        </Button>
                    </Col>
                  </Row>
          </Col>
        </Row>
      </Container>
    )
}

export default KlinikBooked