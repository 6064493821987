
import React from "react";
// react component used to create sweet alerts
//import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {Card, CardBody, CardTitle, Row, Col} from "reactstrap";

class ButtonDeleteList extends React.Component {
 
  render() {
    return (
      <>
  <Card className="card-pricing">
                        <CardBody>
                          <h6 className="card-category text-warning">
                          FLATRATE
                          </h6>
                          <CardTitle tag="h2">
                            1999,- €
                <small className="style-Monat-onPricing"> pro Monat</small>
                            </CardTitle>
                            <small className="mwst-pricing">zzgl. MwSt. </small>
                          <ul>
                          <li>
              Einmalig Bereitstellungskosten 999,- € zzgl. MwSt.
                </li>
              <li>
                  <b>unbegrenzt</b> Stellenazeigen
                </li>
                <li>
                  <b>1</b> Nutzer (Arbeitgeber) inkl. 
                </li>

                <li>
                jeder weitere Nutzer 999,- € mtl. zzgl. MwSt.
                </li>
                <li>
                  <b>1 Jahr</b> Mindestlaufzeit
                </li>
                <li>
                <p className="text-left text-bold">Vorteile:</p> 
                  <Row>
                    <Col
                    md="1"
                    sm="1"
                    xs="1"
                    >
                      <i className="nc-icon nc-check-2" />
                    </Col>
                    <Col
                    md="10"
                    sm="10"
                    xs="10"
                    >
                        <p className="text-left">
                  
                  <span className="">
                  30 Tage mobil optimierte Stellenanzeige auf hello-medics.de
                  </span>
                  
                  </p>
                    </Col>
                  </Row>
                  <Row>
                  <Col
                    md="1"
                    sm="1"
                    xs="1"
                    >
                      <i className="nc-icon nc-check-2" />
                    </Col>
                    <Col
                    md="10"
                    sm="10"
                    xs="10"
                    >
                        <p className="text-left">
                  <span className="">
                  Datumsaktualisierung nach 14 Tage für Aktualität in der Suchergebnisliste
                  </span>
                  
                  </p>
                    </Col>
                  </Row>
                  <Row>
                  <Col
                    md="1"
                    sm="1"
                    xs="1"
                    >
                      <i className="nc-icon nc-check-2" />
                    </Col>
                    <Col
                    md="10"
                    sm="10"
                    xs="10"
                    >
                        <p className="text-left">
                  
                  <span className="">
                  Logo in der Suchergebnisliste der Bewerber für mehr Aufmerksamkeit
                  </span>
                  
                  </p>
                    </Col>
                  </Row>
                  <p className="text-left text-bold">Inklusive Flatrate-Vorteile:</p> 
                <Row>
                <Col
                    md="1"
                    sm="1"
                    xs="1"
                    >
                      <i className="nc-icon nc-check-2" />
                    </Col>
                    <Col
                    md="10"
                    sm="10"
                    xs="10"
                    >
                        <p className="text-left">
                  
                  <span className="">
                  Unternehmensprofile, Fotos, Videos
                  </span>
                  
                  </p>
                    </Col>
                  </Row>
                </li>
              </ul>
                          
                        </CardBody>
                      </Card>
  <br />
      </>
    );
  }
}

export default ButtonDeleteList;
