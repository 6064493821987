import React from "react";
import axios from "axios";
import "./style1.css";
import ReactPaginate from "react-paginate";
import {Redirect, Link} from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Input,
} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      cand: [],
      offset: 0,
      perPage: 10,
      currentPage: 0,
      state1: false,
      feedback_text: "",
    };
  }
  changeHandler = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };
  // componentWillUnmount(){
  //   clearTimeout(this.intervalID);
  // }
  componentDidMount() {
    console.log(this.props.match.params);
    const {appid} = this.props.match.params;
    console.log("test", appid);
    axios
      .post("/api2/candidatefeedback1", {...this.state, appid})
      .then((response) => {
        console.log(response);
        const f_date = response.data[0].c_f_date;
        const f_name = response.data[0].f_name;
        const l_name = response.data[0].l_name;
        const telephone = response.data[0].telephone;
        const feedback_status = response.data[0].feedback_status;
        const feedback_text = response.data[0].text;
        this.setState({
          f_date,
          f_name,
          l_name,
          telephone,
          feedback_status,
          feedback_text,
        });
        //const cand = response.data[0];
        //const back_feedback_id = response.data.back_feedback_id;
        //this.setState({back_feedback_id})
        //const slice = cand.slice(this.state.offset, this.state.offset + this.state.perPage)
        //     const postData = slice.map(item => <React.Fragment>
        //     <tr>
        //                            <td>
        //                            <label className="text-ubold">
        //                          {item.feedbackdate}

        //                                   </label>
        //                           </td>
        //                           <td className="text-left">

        //                           <label className="text-ubold">

        //
        //                        {/* {item.title === 'Kein' ? null : item.title}  */}
        //                        {item.f_name} {item.l_name} <br></br>
        //                        <small><b>{item.telephone}</b></small>
        //                                    </label>
        //                       </td>

        //                       <td className="text-left">

        //                          <label className="text-ubold p_wrap">

        //                       {item.text}
        //                                   </label>
        //         </td>
        //         <td className="text-left">

        //                          <label className="text-ubold p_wrap">

        //                       {item.feedback_status}
        //                                   </label>
        //         </td>

        //         <td className="text-right">
        //           {item.feedback_status === "accept" ? null :
        //           <span><Button
        //                           className="btn-icon"
        //                           color="success"
        //                           id="tooltip366246651"
        //                           size="sm"
        //                           type="button"
        //                            onClick={this.acceptHandle.bind(this, item.app_id)}
        //                         >
        //                           <i className="fa fa-check-circle" />
        //                         </Button>{" "}
        //                         <UncontrolledTooltip
        //                           delay={0}
        //                           target="tooltip366246651"
        //                         >
        //                           Akzeptieren
        //                         </UncontrolledTooltip>
        //           </span>}
        //                         <Button
        //                           className="btn-icon"
        //                           color="danger"
        //                           id="tooltip366246652"
        //                           size="sm"
        //                           type="button"
        //                            onClick={this.deleteHandle.bind(this, item.app_id)}
        //                         >
        //                           <i className="fa fa-trash" />
        //                         </Button>{" "}
        //                         <UncontrolledTooltip
        //                           delay={0}
        //                           target="tooltip366246652"
        //                         >
        //                           Ablehnen
        //           </UncontrolledTooltip>
        //           {/* <Link to={`/admin/candidateFeedbackEdit/${item.item.app_id}`}> */}
        //             <Button
        //                           className="btn-icon"
        //                           color="primary"
        //                           id="tooltip366246652"
        //                           size="sm"
        //             type="button"
        //             style = {{marginLeft:"0px"}}
        //                            onClick={this.editHandle.bind(this, item.app_id)}
        //                         >
        //                           <i className="fa fa-edit" />
        //           </Button>{" "}
        //         {/* </Link> */}
        //                         <UncontrolledTooltip
        //                           delay={0}
        //                           target="tooltip366246652"
        //                         >
        //                           Edit
        //                         </UncontrolledTooltip>
        //                       </td>
        //                       </tr>
        //     </React.Fragment>)
        // localStorage.setItem('cand', JSON.stringify(this.state.cand));
        // console.log(cand);
        // this.setState({state1:true})
        // this.setState({
        // pageCount: Math.ceil(cand.length / this.state.perPage),
        // postData
        //})
      })
      .catch((error) => {
        console.log(error);
      });
  }
  submitHandler = (e) => {
    console.log(this.state);
    console.log(this.props.match.params);
    const {appid} = this.props.match.params;
    console.log("test", appid);
    axios
      .post("/api2/editcandidatefeedback", {...this.state, appid})
      .then((response) => {
        console.log(response);
        this.setState({redirect: true});
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const {redirect} = this.state;
    if (redirect) {
      return <Redirect to={`/admin/candidateFeedback`} />;
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend">
                    <b>Bearbeiten Kandidaten Bewertungen</b>
                  </h5>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">KANDIDATEN</th>
                        <th className="text-left">STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {this.state.f_name} {this.state.l_name}
                        </td>
                        <td> {this.state.feedback_status}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr></hr>
                  <p style={{color: "#51cbce"}}>BEWERTUNG</p>
                  <Input
                    //  style={{backgroundColor: '#f1f1f1'}}
                    style={{fontSize: "15px", backgroundColor: "#f1f1f1"}}
                    type="textarea"
                    rows="15"
                    cols="5"
                    name="feedback_text"
                    value={this.state.feedback_text}
                    //placeholder="Beschreiben Sie kurz Ihre Erfahrungen, Motivation und Ziele. Es muss nicht perfekt sein, Sie können diese später jederzeit bearbeiten."
                    onChange={this.changeHandler}
                  />
                  {/* <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Vom</th>
                        <th className="text-left">KANDIDATEN</th>
                        <th className="text-left">Bewertung</th>
                        <th className="text-left">Status</th>
                        <th className="text-right">Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>
                   
                    
                   {this.state.postData} 
          </tbody>
          <br></br>
          <br></br>
        {this.state.state1?  <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.state.pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}/>: null} 
                  </Table> */}
                </CardBody>
                <CardFooter>
                  <div className="text-center">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={this.submitHandler}>
                      Speichern
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Imprint;
