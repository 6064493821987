import React from "react";
import "./style1.css";
import {Link} from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
Container,
CardBody,
CardFooter} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      hosp : [],
      offset: 0,
      perPage: 1,
      currentPage: 0,
      state1 : false
    };
  }
  render() {
    
    return (
      <>
        <div className="content">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="6" md="8">
            
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center"><b>Abgeschlossen</b></h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <center><b><u><h6>Vertrag erfolgreich senden</h6></u></b></center>
                    <br />
                    <center><b>Gehe zu Übersicht</b></center>
                  </CardBody>
                  <CardFooter>
                    <Link to = {'/admin/dashboard'}>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="primary"
                      type = "submit"
                    >
                      Übersicht
                    </Button>
               </Link>
                  </CardFooter>
                </Card>
              
            </Col>
          </Row>
          
        </Container>
      </div>
      </>
    );
  }
}

export default Imprint;
