import React from "react";
import Select from 'react-select'
import defaultImage from "assets/img/default-avatar.png";
import defaultAvatar from "assets/img/placeholder.jpg";
import axios from 'axios'
import {Redirect } from "react-router-dom";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Badge
} from "reactstrap";

//react-select css
const customStyles = {
  control: base => ({
    ...base,
    height: 55,
    width: '103%',

    padding: '0px 0px',
    fontSize: '13px',
    top:'42%',
    
  }),

  
};
const states = [
  {value: 'Baden-Württemberg', label: 'Baden-Württemberg', id: "1"},
  {value: 'Bayern', label: 'Bayern', id: "2"},
  {value: 'Berlin', label: 'Berlin', id: "3"},
  {value: 'Brandenburg', label: 'Brandenburg', id: "4"},
  {value: 'Bremen', label: 'Bremen', id: "5"},
  {value: 'Hamburg', label: 'Hamburg', id: "6"},
  {value: 'Hessen', label: 'Hessen', id: "7"},
  {value: 'Mecklenburg-Vorpommern', label: 'Mecklenburg-Vorpommern', id: "8"},
  {value: 'Niedersachsen', label: 'Niedersachsen', id: "9"},
  {value: 'Nordrhein-Westfalen', label: 'Nordrhein-Westfalen', id: "10"},
  {value: 'Rhineland-Pfalz', label: 'Rhineland-Pfalz', id: "11"},
  {value: 'Saarland', label: 'Saarland', id: "12"},
  {value: 'Sachsen', label: 'Sachsen', id: "13"},
  {value: 'Sachsen-Anhalt', label: 'Sachsen-Anhalt', id: "14"},
  {value: 'Schleswig-Holstein', label: 'Schleswig-Holstein', id: "15"},
  {value: 'Thüringen', label: 'Thüringen', id: "16"},
  // {value: 'Deutschlandweit', label: 'Deutschlandweit', id: "17"}
      ]
const opt = [
  // {
  //   id: "1",
  //   label: "Arzt",
  //   value: "A"
  // },
  {
    id: '2',
    label: 'Altenpflege',
    value: 'alt'
  },
  {
    id: '3',
    label: 'Krankenpflege',
    value: 'krank'
  },
  {
    id: "4",
    label: "Therapeut",
    value: "tera"
  },
  {
    id: '5',
    label: 'Medizintechnik',
    value: 'mediz'
  },
  {
    id: '6',
    label: 'Rettungsdienst',
    value: 'ret'
  },
  {
    id: '7',
    label: 'Praxispersonal',
    value: 'prax'
  },
  {
    id: '8',
    label: 'Pharmazie',
    value: 'phar'
  }
]
const opt2 = [
  // { value: 'doctor0', label: 'Bitte auswählen', link: 'A' },
  { value: 'doctor1', label: 'Assistenzarzt', link: 'A' },
  { value: 'doctor2', label: 'Facharzt', link: 'A' },
  { value: 'doctor3', label: 'Oberarzt', link: 'A' },
  { value: 'doctor4', label: 'Ltd, Oberarzt', link: 'A' },
  { value: 'doctor5', label: 'Chefarzt', link: 'A' },

  // { value: 'krank0', label: 'Bitte auswählen', link: 'krank' },
  { value: 'krank1', label: 'Anästhesieschwester', link: 'krank' },
  { value: 'krank3', label: 'Anästhesietechnische Assistentin (ATA)', link: 'krank' },
  { value: 'krank4', label: 'Fachkrankenschwester', link: 'krank' },
  { value: 'krank5', label: 'Gesundheitspflegehelfer/Krankenpflegehelfer', link: 'krank' },
  { value: 'krank6', label: 'Gesundheitspfleger/Krankenpfleger', link: 'krank' },
  { value: 'krank7', label: 'Hebamme', link: 'krank' },
  { value: 'krank8', label: 'Heilerziehungspfleger', link: 'krank' },
  { value: 'krank9', label: 'Kinderkrankenschwester', link: 'krank' },
  { value: 'krank10', label: 'OP Schwester', link: 'krank' },
  { value: 'krank11', label: 'Operationstechnische Assistentin (OTA)', link: 'krank' },
  { value: 'krank12', label: 'Pflegeassistent', link: 'krank' },
  { value: 'krank13', label: 'Pflegedienstleitung', link: 'krank' },
  { value: 'krank14', label: 'Stationsleitung', link: 'krank' },
  { value: 'krank15', label: 'Study Nurse', link: 'krank' },

  // { value: 'ret1', label: 'Bitte auswählen', link: 'ret' },
  { value: 'ret2', label: 'Rettungsassistentin', link: 'ret' },
  { value: 'ret3', label: 'Rettungshelfer', link: 'ret' },
  { value: 'ret4', label: 'Rettungssanitäter', link: 'ret' },

  // { value: 'mediz1', label: 'Bitte auswählen', link: 'mediz' },
  { value: 'mediz2', label: 'Medizininformatiker', link: 'mediz' },
  { value: 'mediz3', label: 'Medizinisch-technische Laboratoriumsassistentin (MTLA)', link: 'mediz' },
  { value: 'mediz4', label: 'Medizintechnik-Ingenieur', link: 'mediz' },
  { value: 'mediz5', label: 'Medizintechniker', link: 'mediz' },
  { value: 'mediz6', label: 'MTA Funktionsdiagnostik (MTAF', link: 'mediz' },
  { value: 'mediz7', label: 'MTA Radiologie (MTRA)', link: 'mediz' },
  { value: 'mediz8', label: 'Zahntechniker', link: 'mediz' },

  // { value: 'phar1', label: 'Bitte auswählen', link: 'phar' },
  { value: 'phar2', label: 'Apotheker', link: 'phar' },
  { value: 'phar3', label: 'Pharmakant', link: 'phar' },
  { value: 'phar4', label: 'Pharmareferent', link: 'phar' },
  { value: 'phar5', label: 'Pharmazeutisch-kaufmännische Angestellte (PKA)', link: 'phar' },
  { value: 'phar6', label: 'Pharmazeutisch-technische Assistentin (PTA)', link: 'phar' },

  // { value: 'prax1', label: 'Bitte auswählen', link: 'prax' },
  { value: 'prax2', label: 'Medizinische Fachangestellte', link: 'prax' },
  { value: 'prax3', label: 'Praxismanagerin', link: 'prax' },
  { value: 'prax4', label: 'Zahnmedizinische Fachangestellte', link: 'prax' },
  { value: 'prax5', label: 'Tiermedizinische Fachangestellte', link: 'prax' },

  // { value: 'alt1', label: 'Bitte auswählen', link: 'alt' },
  { value: 'alt2', label: 'Altenpflegehelfer', link: 'alt' },
  { value: 'alt3', label: 'Altenpfleger', link: 'alt' },
  { value: 'alt4', label: 'Betreuungskraft', link: 'alt' },
  { value: 'alt5', label: 'Fachaltenpfleger', link: 'alt' },
  { value: 'alt6', label: 'Hauswirtschafterin', link: 'alt' },
  { value: 'alt7', label: 'Pflegedienstleitung', link: 'alt' },

  // { value: 'tera1', label: 'Bitte auswählen', link: 'tera' },
  { value: 'tera2', label: 'Ergotherapeut', link: 'tera' },
  { value: 'tera3', label: 'Erzieher', link: 'tera' },
  { value: 'tera4', label: 'Heilpraktiker', link: 'tera' },
  { value: 'tera5', label: 'Kinderpfleger', link: 'tera' },
  { value: 'tera6', label: 'Kunsttherapeut', link: 'tera' },
  { value: 'tera7', label: 'Logopäde', link: 'tera' },
  { value: 'tera8', label: 'Masseur/medizinischer Bademeister', link: 'tera' },
  { value: 'tera9', label: 'Motopäde', link: 'tera' },
  { value: 'tera10', label: 'Musiktherapeut', link: 'tera' },
  { value: 'tera11', label: 'Orthoptist', link: 'tera' },
  { value: 'tera12', label: 'Podologe', link: 'tera' },
  { value: 'tera13', label: 'Physiotherapeut', link: 'tera' },
  { value: 'tera15', label: 'Psychotherapeut', link: 'tera' },
  { value: 'tera16', label: 'Psychologe', link: 'tera' },
  { value: 'tera17', label: 'Sozialpädagoge', link: 'tera' },
];
const opt3 = [
 
  ////////////////////////////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank1' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank1' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank1' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank1' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank1' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank1' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank1' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank1' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank1' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank1' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank1' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank1' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank1' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank1' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank1' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank1' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank1' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank1' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank1' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank1' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank1' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank1' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank1' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank1' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank1' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank1' },
  { value: 'Fach27', label: 'Urologie', link: 'krank1' },
  //////////////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank3' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank3' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank3' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank3' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank3' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank3' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank3' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank3' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank3' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank3' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank3' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank3' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank3' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank3' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank3' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank3' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank3' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank3' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank3' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank3' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank3' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank3' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank3' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank3' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank3' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank3' },
  { value: 'Fach27', label: 'Urologie', link: 'krank3' },
  ///////////////////////////////////////////////////////////////////////
  
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank4' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank4' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank4' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank4' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank4' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank4' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank4' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank4' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank4' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank4' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank4' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank4' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank4' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank4' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank4' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank4' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank4' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank4' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank4' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank4' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank4' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank4' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank4' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank4' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank4' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank4' },
  { value: 'Fach27', label: 'Urologie', link: 'krank4' },
  //////////////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank5' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank5' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank5' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank5' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank5' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank5' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank5' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank5' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank5' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank5' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank5' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank5' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank5' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank5' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank5' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank5' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank5' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank5' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank5' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank5' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank5' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank5' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank5' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank5' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank5' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank5' },
  { value: 'Fach27', label: 'Urologie', link: 'krank5' },
  ///////////////////////////////////////////////////////////////////////
  
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank6' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank6' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank6' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank6' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank6' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank6' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank6' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank6' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank6' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank6' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank6' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank6' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank6' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank6' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank6' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank6' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank6' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank6' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank6' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank6' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank6' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank6' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank6' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank6' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank6' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank6' },
  { value: 'Fach27', label: 'Urologie', link: 'krank6' },
  //////////////////////////////////////////////////////////////
 
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank7' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank7' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank7' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank7' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank7' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank7' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank7' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank7' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank7' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank7' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank7' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank7' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank7' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank7' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank7' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank7' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank7' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank7' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank7' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank7' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank7' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank7' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank7' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank7' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank7' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank7' },
  { value: 'Fach27', label: 'Urologie', link: 'krank7' },
  /////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank8' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank8' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank8' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank8' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank8' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank8' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank8' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank8' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank8' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank8' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank8' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank8' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank8' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank8' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank8' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank8' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank8' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank8' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank8' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank8' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank8' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank8' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank8' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank8' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank8' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank8' },
  { value: 'Fach27', label: 'Urologie', link: 'krank8' },
  ///////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank9' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank9' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank9' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank9' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank9' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank9' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank9' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank9' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank9' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank9' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank9' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank9' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank9' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank9' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank9' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank9' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank9' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank9' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank9' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank9' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank9' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank9' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank9' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank9' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank9' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank9' },
  { value: 'Fach27', label: 'Urologie', link: 'krank9' },
  ////////////////////////////////////////////////////////////////
  
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank10' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank10' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank10' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank10' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank10' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank10' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank10' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank10' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank10' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank10' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank10' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank10' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank10' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank10' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank10' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank10' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank10' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank10' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank10' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank10' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank10' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank10' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank10' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank10' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank10' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank10' },
  { value: 'Fach27', label: 'Urologie', link: 'krank10' },
  ////////////////////////////////////////////////////////////////////
 
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank11' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank11' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank11' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank11' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank11' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank11' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank11' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank11' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank11' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank11' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank11' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank11' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank11' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank11' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank11' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank11' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank11' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank11' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank11' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank11' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank11' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank11' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank11' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank11' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank11' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank11' },
  { value: 'Fach27', label: 'Urologie', link: 'krank11' },
  //////////////////////////////////////////////////////////////////////////////
  
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank12' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank12' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank12' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank12' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank12' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank12' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank12' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank12' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank12' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank12' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank12' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank12' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank12' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank12' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank12' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank12' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank12' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank12' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank12' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank12' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank12' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank12' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank12' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank12' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank12' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank12' },
  { value: 'Fach27', label: 'Urologie', link: 'krank12' },
  ////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank13' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank13' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank13' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank13' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank13' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank13' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank13' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank13' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank13' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank13' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank13' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank13' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank13' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank13' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank13' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank13' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank13' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank13' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank13' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank13' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank13' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank13' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank13' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank13' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank13' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank13' },
  { value: 'Fach27', label: 'Urologie', link: 'krank13' },
  /////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank14' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank14' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank14' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank14' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank14' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank14' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank14' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank14' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank14' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank14' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank14' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank14' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank14' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank14' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank14' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank14' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank14' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank14' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank14' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank14' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank14' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank14' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank14' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank14' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank14' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank14' },
  { value: 'Fach27', label: 'Urologie', link: 'krank14' },
  ///////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank15' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank15' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank15' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank15' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank15' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank15' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank15' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank15' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank15' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank15' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank15' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank15' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank15' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank15' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank15' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank15' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank15' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank15' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank15' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank15' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank15' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank15' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank15' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank15' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank15' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank15' },
  { value: 'Fach27', label: 'Urologie', link: 'krank15' },
]
const zm = [
  { value: 'Festanstellung', label: 'Festanstellung', id: "3" },
  { value: 'Befristet ', label: 'Befristet ', id: "4" },
];

const tv = [
  { value: 'Vollzeit', label: 'Vollzeit', id: "1" },
  { value: 'Teilzeit', label: 'Teilzeit', id: "2" },
];

class CreateJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      optSelect: {},
      opt2Select: {},
      opt3Select: {},
      zmSelect: null,
      tvSelect: null,
      file: null,
      company_name: '',
      comp_name: '',
      discription: '',
      address: '',
      zip_code: '',
      city: '',
      benefits: '',
      haus_no : '',
      PLZ:'',
      street:'',
      haus_no1:'',
      posst_code:'',
      city1:'',
      bill_comp_name:'',
      bill_email:'',
      bill_address:'',
      bill_haus_no:'',
      bill_PLZ:'',
      bills_VAT:'',
      about:'',
      statusSelect: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      visible: false,
      visible1: false,
      packageSelect:null,
      Rechnungsadresse:null,
      Unternehmensadresse:null,
      visible2: false,
      visible3: false,
      area:'',
      area1:'',
      visible4 : true,
      visible6 : false,
      visible7 : true
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.changeHandler2 = this.changeHandler2.bind(this);
    this.changeHandler1 = this.changeHandler1.bind(this);
    this.changeHandler3 = this.changeHandler3.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit(e) {
    e.preventDefault();
    var bundesland1;
    var zmSelect1;
    var tvSelect1;
    var optt3;
    if(this.state.file === null){
    if(this.state.optSelect.label === undefined){
      const bgrp_err = "Bitte wählen Sie Berufsgruppe";
      this.setState({bgrp_err})
    }
  if(this.state.area !== ''){
      var somestring = this.state.area;
    var index = somestring.indexOf(" ");
    var postcode = somestring.substr(0,index);
          console.log(postcode)
          // if (postcode.includes("1") || postcode.includes("2") || postcode.includes("3") || postcode.includes("4") || postcode.includes("5") || postcode.includes("6") || postcode.includes("7") || postcode.includes("8") || postcode.includes("9") || postcode.includes("0")) {
            if (postcode.includes(1) || postcode.includes(2) || postcode.includes(3) || postcode.includes(4) || postcode.includes(5)|| postcode.includes(6) || postcode.includes(7) || postcode.includes(8) || postcode.includes(9) || postcode.includes(0)) {
            console.log("number found", postcode)
           var city = somestring.substr(index+1);
          console.log("Second part city from number",city); 
          } else {
            console.log("string found", postcode)
            var city = this.state.area;
            console.log("city from string",city)
          }
       }
     if (this.state.opt2Select.label === "Bitte auswählen") {
     const func_err = "Bitte wählen Sie Funktion";
     this.setState({func_err})}
      if (this.state.statusSelect === null) {
      const unter_err = "Bitte wählen Sie Unternehmenstatigkeit";
      this.setState({unter_err})}
       if (this.state.zmSelect === null) {
        const Anstellungsart_err = "Bitte wählen Sie Anstellungsart";
        this.setState({Anstellungsart_err})}
         if (this.state.tvSelect === null) {
          const zeit_err = "Bitte wählen Sie Zeitmodell";
          this.setState({zeit_err})}
           if (this.state.discription === '' || this.state.discription === null) {
                  const disc_err = "Bitte geben Sie Aufgabenbeschreibung ein";
                  this.setState({disc_err})}
           if (this.state.benefits === '' || this.state.benefits === null) {
                    const benefits_err = "Bitte geben Sie Ihre Benefits ein";
                    this.setState({benefits_err})}
          
                    if(this.state.optSelect.label !== undefined && this.state.opt2Select.label !== "Bitte auswählen" && this.state.statusSelect !== null && this.state.zmSelect !== null && this.state.tvSelect !== null && this.state.discription !== '' && this.state.benefits !== '')
                    {
                      if(this.state.area === ''){
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                        console.log("area is empty", this.state.city)
                        if(this.state.optSelect.label === 'Krankenpflege'){
                          optt3 = this.state.opt3Select.label 
                      }
                      else {
                      optt3 = null
                      }
                             axios.post("/api2/addjobs1", {...this.state,bundesland1,optt3})
                      .then(response => { 
                       console.log(response)
                       const job_id = response.data[0].job_id;
                       localStorage.setItem('job_id',JSON.stringify(job_id));
                       console.log(job_id)
                  this.setState({redirect : true})
                    })
                    .catch(error => {
                     console.log(error);
                   })
                      }else{
                        console.log("area is not empty",city)
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                        if(this.state.optSelect.label === 'Krankenpflege'){
                          optt3 = this.state.opt3Select.label 
                      }
                      else {
                      optt3 = null
                      }
                             axios.post("/api2/addjobs1", {...this.state,city,bundesland1,optt3})
                      .then(response => { 
                       console.log(response)
                       // const msg1 = response.data.message;
                       // this.setState({msg1})
                       const job_id = response.data[0].job_id;
                       localStorage.setItem('job_id',JSON.stringify(job_id));
                       console.log(job_id)
                  this.setState({redirect : true})
                    })
                    .catch(error => {
                     console.log(error);
                   })
                     }
                    }
                  }
                
    ////////////////////////////////////////////////////////////////////////////////////
    else {
      if(this.state.optSelect.label === undefined){
        const bgrp_err = "Bitte wählen Sie Berufsgruppe";
        this.setState({bgrp_err})
      }
     if(this.state.area !== ''){
      var somestring = this.state.area;
    var index = somestring.indexOf(" ");
    var postcode = somestring.substr(0,index);
          console.log(postcode)
          // if (postcode.includes("1") || postcode.includes("2") || postcode.includes("3") || postcode.includes("4") || postcode.includes("5") || postcode.includes("6") || postcode.includes("7") || postcode.includes("8") || postcode.includes("9") || postcode.includes("0")) {
            if (postcode.includes(1) || postcode.includes(2) || postcode.includes(3) || postcode.includes(4) || postcode.includes(5)|| postcode.includes(6) || postcode.includes(7) || postcode.includes(8) || postcode.includes(9) || postcode.includes(0)) {
            console.log("number found", postcode)
           var city = somestring.substr(index+1);
          console.log("Second part city from number",city); 
          } else {
            console.log("string found", postcode)
            var city = this.state.area;
            console.log("city from string",city)
          }
       }
       if (this.state.opt2Select.label === "Bitte auswählen") {
       const func_err = "Bitte wählen Sie Funktion";
       this.setState({func_err})}
        if (this.state.statusSelect === null) {
        const unter_err = "Bitte wählen Sie Unternehmenstatigkeit";
        this.setState({unter_err})}
         if (this.state.zmSelect === null) {
          const Anstellungsart_err = "Bitte wählen Sie Anstellungsart";
          this.setState({Anstellungsart_err})}
           if (this.state.tvSelect === null) {
            const zeit_err = "Bitte wählen Sie Zeitmodell";
            this.setState({zeit_err})}
             if (this.state.discription === '' || this.state.discription === null) {
                    const disc_err = "Bitte geben Sie Aufgabenbeschreibung ein";
                    this.setState({disc_err})}
             if (this.state.benefits === '' || this.state.benefits === null) {
                      const benefits_err = "Bitte geben Sie Ihre Benefits ein";
                      this.setState({benefits_err})}
                      if(this.state.optSelect.label !== undefined && this.state.opt2Select.label !== "Bitte auswählen" && this.state.statusSelect !== null && this.state.zmSelect !== null && this.state.tvSelect !== null && this.state.discription !== '' && this.state.benefits !== '')
                    
                    {
                      if(this.state.zmSelect[1]=== undefined){
                        zmSelect1 = null;
                        console.log(zmSelect1)
                      }else{
                        zmSelect1 = this.state.zmSelect[1].label
                        console.log(zmSelect1)
                      }
                      if(this.state.tvSelect[1]=== undefined){
                        tvSelect1 = null;
                        console.log(tvSelect1)
                      }else{
                        tvSelect1 = this.state.tvSelect[1].label
                        console.log(tvSelect1)
                      }
                      if(this.state.area === ''){
                        console.log("area is empty",this.state.city)
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                        console.log(this.state.zmSelect)
                        console.log(this.state.zmSelect)
                        if(this.state.optSelect.label === 'Krankenpflege'){
                          optt3 = this.state.opt3Select.label 
                      }
                      else {
                      optt3 = null
                      }
                        const data = new FormData()
                      data.set('hosp_name',this.state.hosp_name)
                      data.set('adress',this.state.adress)
                      data.set('haus_number',this.state.haus_number)
                      data.set('zip_code',this.state.zip_code)
                      data.set('city',this.state.city)
                      data.set('bundesland1',bundesland1)
                      data.set('hosp_id', this.state.hosp_id)
                      data.set('job_id', this.state.job_id)
                      data.set('optSelect', this.state.optSelect.label)
                      data.set('opt2Select', this.state.opt2Select.label)
                      data.set('opt3Select', this.state.optt3)
                      data.set('discription', this.state.discription)
                      data.set('benefits', this.state.benefits)
                      data.set('about', this.state.about)
                      data.set('statusSelect', this.state.statusSelect.label)
                      data.set('zmSelect', this.state.zmSelect[0].label)
                      data.set('tvSelect',this.state.tvSelect[0].label)
                      data.set('zmSelect1',zmSelect1)
                      data.set('tvSelect1',tvSelect1)
                      data.append('file', this.state.file)
                      axios.post("/api2/addjobs", data)
                        .then(response => { 
                          console.log(response)
                          const job_id = response.data[0].job_id;
                            localStorage.setItem('job_id',JSON.stringify(job_id));
                            console.log(job_id)
                            this.setState({redirect : true})
                          
                      })
                      .catch(error => {
                        console.log(error);
                      })
                      }else{
                        console.log("area is not empty",city)
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                        if(this.state.optSelect.label === 'Krankenpflege'){
                          optt3 = this.state.opt3Select.label 
                      }
                      else {
                      optt3 = null
                      }
                        const data = new FormData()
                      data.set('hosp_name',this.state.hosp_name)
                      data.set('adress',this.state.adress)
                      data.set('haus_number',this.state.haus_number)
                      data.set('zip_code',this.state.zip_code)
                      data.set('city',city)
                      data.set('bundesland1',bundesland1)
                      data.set('hosp_id', this.state.hosp_id)
                      data.set('job_id', this.state.job_id)
                      data.set('optSelect', this.state.optSelect.label)
                      data.set('opt2Select', this.state.opt2Select.label)
                      data.set('opt3Select', this.state.optt3)
                      data.set('discription', this.state.discription)
                      data.set('benefits', this.state.benefits)
                      data.set('about', this.state.about)
                      data.set('statusSelect', this.state.statusSelect.label)
                      data.set('zmSelect', this.state.zmSelect[0].label)
                      data.set('tvSelect',this.state.tvSelect[0].label)
                      data.set('zmSelect1',zmSelect1)
                      data.set('tvSelect1',tvSelect1)
                      data.append('file', this.state.file)
                      axios.post("/api2/addjobs", data)
                        .then(response => { 
                          console.log(response)
                          const job_id = response.data[0].job_id;
                            localStorage.setItem('job_id',JSON.stringify(job_id));
                            console.log(job_id)
                            this.setState({redirect : true})
                          
                      })
                      .catch(error => {
                        console.log(error);
                      })
                      }
                    }
     }
  }
  // changeHandler5 = Unternehmensadresse => {
  //   this.setState(pv => {
  //     return { ...pv, Unternehmensadresse: Unternehmensadresse };
  //   }, () => {
  //     if (this.state.Unternehmensadresse.label === "Unternehmensadresse ändern"){
  //       this.setState(pv => ({ ...pv, visible3: true , visible4 : false }));
  //     } else {
  //       this.setState({ visible3: false, visible4 : true });
  //     }
  //   });

  // }

  changeHandler6 = UberUns => {
    this.setState(pv => {
      return { ...pv, UberUns: UberUns };
    }, () => {
      if (this.state.UberUns.label === "Über uns ändern"){
        this.setState(pv => ({ ...pv, visible6: true, visible7 : false }));
      } else {
        this.setState({ visible6: false , visible7 : true });
      }
    });

  }
  componentWillMount(){
    const {hosp_id} =  this.props.match.params;
    console.log(hosp_id)
    this.setState({hosp_id})
  }
  componentDidMount() {
   console.log(this.state)
  //  console.log(this.props.match.params);
  //  const { hosp_id } = this.props.match.params;
  //  console.log(hosp_id);
    axios.post("/api2/getcomadress", this.state)
         .then(response => { 
          console.log(response)
          const hosp_name = response.data[0].hosp_name;
          this.setState({hosp_name})
          const adress = response.data[0].adress;
          this.setState({adress})
          const haus_number = response.data[0].haus_number;
          this.setState({haus_number})
          const zip_code = response.data[0].zip_code;
          this.setState({zip_code})
          const city = response.data[0].city;
          this.setState({city})
          const bundesland = response.data[0].state;
          this.setState({bundesland})
          const about = response.data[0].hosp_disc;
          this.setState({about})
          const hosp_logo = response.data[0].hosp_logo;
          this.setState({hosp_logo})
          const imagePreviewUrl = `https://unternehmen.hello-sister.de/uploads/${hosp_logo}`
          this.setState({imagePreviewUrl})
          
       })
       .catch(error => {
        console.log(error);
        
      })
      ///////////////////////////////////
      // axios.post("/api1/getbankadress", this.state)
      //    .then(response => { 
      //     console.log(response)
      //     const email = response.data[0].bill_email;
      //     this.setState({email})
      //     const company_name = response.data[0].company_name;
      //     this.setState({company_name})
      //     const street = response.data[0].street;
      //     this.setState({street})
      //     const haus_no1 = response.data[0].haus_no;
      //     this.setState({haus_no1})
      //     const post_code = response.data[0].post_code;
      //     this.setState({post_code})
      //     const city1 = response.data[0].city;
      //     this.setState({city1})
      //     const VAT = response.data[0].VAT;
      //     this.setState({VAT})
      //  })
      //  .catch(error => {
      //   console.log(error);
        
      // })
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : `https://unternehmen.hello-sister.de/uploads/${this.state.hosp_logo}`
    });
    this.refs.fileInput.value = null;
  }
  changeHandler2 = optSelect => {
    this.setState(pv => {
      return { ...pv, optSelect: optSelect };
    }, () => {
      if (this.state.optSelect.label === "Arzt" ||
        this.state.optSelect.label === "Altenpflege" ||
        this.state.optSelect.label === "Krankenpflege" ||
        this.state.optSelect.label === "Therapeut" ||
        this.state.optSelect.label === "Medizintechnik" ||
        this.state.optSelect.label === "Rettungsdienst" ||
        this.state.optSelect.label === "Praxispersonal" ||
        this.state.optSelect.label === "Pharmazie") {
        let nextVal = opt2.filter((o) => o.link === this.state.optSelect.value)[0];
        this.changeHandler1(nextVal);
        this.setState(pv => ({ ...pv, visible: true }));
      } else {
        this.setState({ visible: false });
      }
    });

  }

  changeHandler1 = optSelect => {
    this.setState(() => ({ opt2Select: optSelect }), () => {
      if (this.state.opt2Select.label === "Assistenzarzt" ||
        this.state.opt2Select.label === "Facharzt" ||
        this.state.opt2Select.label === "Oberarzt" ||
        this.state.opt2Select.label === "Ltd, Oberarzt" ||
        this.state.opt2Select.label === "Chefarzt" ||
        this.state.opt2Select.label === "Anästhesieschwester" ||
        this.state.opt2Select.label === "Anästhesietechnische Assistentin (ATA)" ||
        this.state.opt2Select.label === "Fachkrankenschwester" ||
        this.state.opt2Select.label === "Gesundheitspflegehelfer/Krankenpflegehelfer" ||
        this.state.opt2Select.label === "Gesundheitspfleger/Krankenpfleger" ||
        this.state.opt2Select.label === "Hebamme" ||
        this.state.opt2Select.label === "Heilerziehungspfleger" ||
        this.state.opt2Select.label === "Kinderkrankenschwester" ||
        this.state.opt2Select.label === "OP Schwester" ||
        this.state.opt2Select.label === "Operationstechnische Assistentin (OTA)" ||
        this.state.opt2Select.label === "Pflegeassistent" ||
        this.state.opt2Select.label === "Pflegedienstleitung" ||
        this.state.opt2Select.label === "Stationsleitung" ||
        this.state.opt2Select.label === "Study Nurse") {
          let nextVal1 = opt3.filter((p) => p.link === this.state.opt2Select.value)[0];
          this.changeHandler3(nextVal1);
        this.setState({ visible1: true })
        console.log("if works", this.state);
      } else {
        this.setState({ visible1: false });
        console.log("else works", this.state);
      }

    })
  }

  changeHandler3 = opt2Select => {
    this.setState(pv => {
      return { ...pv, opt3Select: opt2Select };
    } );
  }

  // changeHandler4 = Rechnungsadresse => {
  //   this.setState(pv => {
  //     return { ...pv, Rechnungsadresse: Rechnungsadresse };
  //   }, () => {
  //     if (this.state.Rechnungsadresse.label === "Rechnungsadresse ändern"){
  //       this.setState(pv => ({ ...pv, visible2: true }));
  //     } else {
  //       this.setState({ visible2: false });
  //     }
  //   });

  // }

  changeHandler5 = Unternehmensadresse => {
    this.setState(pv => {
      return { ...pv, Unternehmensadresse: Unternehmensadresse };
    }, () => {
      if (this.state.Unternehmensadresse.label === "Unternehmensadresse ändern"){
        this.setState(pv => ({ ...pv, visible3: true , visible4 : false }));
      } else {
        this.setState({ visible3: false , visible4 : true });
      }
    });

  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to='/admin/create-job-last'/>;
    }
    const filteredOpt = opt2.filter((o) => o.link === this.state.optSelect.value)
    const filteredOpt1 = opt3.filter((p) => p.link === this.state.opt2Select.value)
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              {this.state.err1} {this.state.err2}
              <Form onSubmit={this.handleSubmit}>
                <Card>
                  <CardHeader>
                    <h4> <u> <b> Unternehmenslogo </b>  </u> </h4>
                    <Col md="4">
{/*               
                      <Badge color="warning">{this.state.file_err}</Badge>
               */}
                      <Badge color="success">{this.state.msg1}</Badge>
                      <hr></hr>
                      {/* <Row>
                        <Col md='2'>
                          <div className="fileinput text-center">
                            <input type="file" onChange={this.handleImageChange} ref="fileInput" name="file" />
                            <div style={{width: '150px'}} className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
                              <img src={this.state.imagePreviewUrl} alt="..." />
                            </div>
                            
                            <div>
                              {this.state.file === null ? (
                             
                               <Button color="primary"
                               style={{width: '130px',
                               height: '58px',
                               fontSize: '11px',
                               padding: '13px'
                              }}
                               onClick={() => this.handleClick()}>
                                  {this.props.avatar ? "Ihr Photo" : "Logo hochladen (Optional)"}
                                </Button>
                               
                              
                              
                               
                              ) : (
                                  <span>
                                    <Button 
                                    color="primary"
                                    style={{width: '130px',
                                    height: '42px',
                                    fontSize: '11px',
                                    padding: '13px'
                                   }}
                                    onClick={() => this.handleClick()}>
                                      Ändern
              </Button>
                                    {this.props.avatar ? <br /> : null}
                                    <Button
                                     color="danger"
                                     style={{width: '130px',
                                     height: '42px',
                                     fontSize: '9px',
                                     borderRadius: '32px',
                                     boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                     fontWeight: '400',
                                     border: 'none !important',
                                     padding: '13px 27px',
                                     background: '#dc3545'
                                   }}
                                      onClick={() => this.handleRemove()}
                                    >
                                      <i className="fa fa-times" />
                Löschen
              </Button>
                                  </span>
                                )}
                            </div>
                          </div>
                        </Col>

                      </Row> */}
                       <div className="fileinput text-center">
                    <h4 className = 'title'> <u>  Logo hochladen </u> </h4>
        <input type="file" onChange={this.handleImageChange} ref="fileInput"  name = "file" />
        {/* <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")} style={{width: '180px'}}> */}
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")} style={{width: '180px'}}>
          <img src={this.state.imagePreviewUrl} alt="..." />
          {/* <img src={`../uploads/${this.state.hosp_logo}`} alt="..." /> */}
        </div>
        
          <Row>
            <Col>
            <div>
          {this.state.file === null ? (
            <Button className="btn-round" onClick={() => this.handleClick()}>
              {this.props.avatar ? "Ihr Photo" : "Logo auswählen (Optional)"}
            </Button>
  
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Ändern
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button
                color="danger"
                style={{width: '112px',
                height: '25px',
                fontSize: '9px',
                boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                fontWeight: '400',
                border: 'none !important',
                padding: '0',
                background: '#dc3545'
              }}
                onClick={() => this.handleRemove()}
              >
                <i className="fa fa-times" />
                Löschen
              </Button>
            </span>
          )}
        </div>
            </Col>
           
          </Row>
          
      </div>
                    </Col>
                    
                    <hr></hr>
                    <br></br>
                 
                    <Row>
                      <Col>
                      <br></br>
                        <h4 className='title'><u>Unternehmensadresse</u></h4>
                        <br></br>
                        
                    <FormGroup>
                    <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="statusSelect"
                            value={this.state.Unternehmensadresse}
                            onChange={this.changeHandler5
                            }
                            options={[
                            {
                                value: "1",
                                label: "Unternehmensadresse ändern"
                              }, 
                               {
                                value: "2",
                                label: "Vorherige Unternehmensadresse"
                              },
                              
                        ]}
                            placeholder="Unternehmensadresse ändern"
                          />
                    </FormGroup>
                   
                    <br></br> 
                        {this.state.visible4 ?  <span>    <Row>
                      <Col md ='12'>
                      <b>{this.state.hosp_name}</b>
                      <p>{this.state.adress} {this.state.haus_number}</p>
                     <p> {this.state.zip_code} {this.state.city},{this.state.bundesland}</p>
                        </Col></Row>
                      </span> : null}
                

                    <br></br>
                    <hr></hr>
                   
                    
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>Name des Unternehmens / Firma</label> </Col>
                    </Row>
                    : null }
                    {this.state.visible3 ?
                    <Row>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value = {this.state.hosp_name}
                          name="hosp_name"
                          onChange={this.changeHandler}
                          required="Name des Unternehmens / Firma"
                        />
                         <Badge color="warning">{this.state.address_err}</Badge> 
                      </Col>
                    </Row>
                    : null}
                    {this.state.visible3 ?
                    <br></br>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>Straße</label> </Col>
                      <Col><label>Hausnummer</label> </Col>
                    </Row>
                    : null }
                    {this.state.visible3 ?
                    <Row>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value={this.state.adress}
                          name="adress"
                          onChange={this.changeHandler}
                          required
                          />
                         <Badge color="warning">{this.state.address_err}</Badge> 
                      </Col>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value={this.state.haus_number}
                          name="haus_number"
                          onChange={this.changeHandler}
                          required
                        />
                         <Badge color="warning">{this.state.haus_err}</Badge> 
                      </Col>
                    </Row>
                    : null}
                    {this.state.visible3 ?
                    <br></br>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>PLZ</label> </Col>
                      <Col><label>Ort</label> </Col>
                    </Row>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value={this.state.zip_code}
                          name="zip_code"
                          onChange={this.changeHandler}
                          required
                        />
                         <Badge color="warning">{this.state.post_err}</Badge> 
                      </Col>
                      <Col>
                       <GooglePlacesAutocomplete
                      onChange={this.changeHandler}
                      onSelect={({description})=>(this.setState({area:description}))}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: 'de'
                        },
                        types: ["(regions)"],
                        terms:[
                              'cities'
                        ]
                      }}
                      placeholder={this.state.city}
                      inputClassName='form-control'
                      inputStyle={{height: '38px'}}
                      required
                      //initialValue="description"
                      required
                     
                    /> 
                         <Badge color="warning">{this.state.city_err}</Badge> 
                      </Col>
                    </Row>
                    :null}
                   {this.state.visible3 ?
                    <br></br>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>Bundesland</label></Col>
                      <Col>
                      <label>Land</label>
                      </Col>
                     
                      </Row>
                      :null}
                      {this.state.visible3 ?
                     <span> <Row>
                       <Col>
                      <Select
                   className="react-select primary"
                   classNamePrefix="react-select"
                   name="bundesland"
                   placeholder = {this.state.bundesland}
                   value={this.state.bundesland}
                   onChange={value =>
                     this.setState({ bundesland: value })
                   }
                   options={states} 
                  //  styles={customStyles}
                 />
                      </Col>
                        <Col>
                      <Input
                        type="Text"
                        name = "Input"  
                        value="Deutschland"
                        readOnly
                      />
                      </Col>
                      
                    </Row>
                    <br></br>
                    <hr></hr> </span>
                    :null}   

                    
                    <br></br>
                        <h4 className='title'> <u> Unternehmenstätigkeit </u> </h4>
                        <br></br>
                        <label style={{fontSize: '12px'}}>Wählen Sie bitte mindestens eine Tätigkeit aus, der Ihr Unternehmen zugeordnet werden kann.</label>
                        <Badge color="warning">{this.state.unter_err}</Badge>
             
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="statusSelect"
                          value={this.state.statusSelect}
                          onChange={value =>
                            this.setState({ statusSelect: value })
                          }
                          options={[{
                            value: "1",
                            label: "Altenheim / Pflegeheim "
                          },
                          {
                            value: "2",
                            label: "Krankenhaus"
                          },
                          {
                            value: "3",
                            label: "MVZ"
                          },
                          {
                            value: "4",
                            label: "Pflegedienst"
                          },
                          {
                            value: "5",
                            label: "Arztpraxis"
                          },
                          {
                            value: "5",
                            label: "Reha-Klinik"
                          },
                          ]}
                          placeholder="Bitte auswählen"
                          styles={customStyles}
                        />
                        <br></br>
                        <br></br>
                        <h4 className='title'> <u> Über uns </u> </h4>
                        <br></br>
                        <br></br>
                       
                    <FormGroup>
                    <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="statusSelect"
                            value={this.state.UberUns}
                            onChange={this.changeHandler6
                            }
                            options={[
                            {
                                value: "1",
                                label: "Über uns ändern"
                              }, 
                               {
                                value: "2",
                                label: "Vorherige Über uns"
                              },
                              
                        ]}
                            placeholder="Über uns ändern"
                          />
                    </FormGroup>
                    
                    {this.state.visible7 ? <span><br></br> 
                       <br></br>
                       {this.state.about}</span>: null}
                       {this.state.visible6 ? <span> <br></br>
                        <label style={{fontSize: '12px'}}>Beschreiben Sie Ihr Unternehmen.</label>
                        <br></br>
                        <Input 
                    style={{fontSize: '15px'}}
                    type="textarea"
                        rows = "5"
                        cols = "5"
                        value={this.state.about}
                        name='about'
                      //name='benefits'
                      onChange={this.changeHandler}
                    />
                       </span>: null}
                      </Col>
                    </Row>
                    <hr></hr>
                  </CardHeader>
                  <br></br>
                  <br></br>
                  <CardBody>
                    <h4 className='title'><u>Stellendetails</u></h4>
                    <br></br>
                    <Row>
                      <Col className="" md="4">
                        <FormGroup>
                          <label className="title"  style={{fontSize: '12px'}}>Berufsgruppe</label>
                          <Badge color="warning">{this.state.bgrp_err}</Badge>
                 
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="titleSelect"
                            value={this.state.optSelect}
                            onChange={this.changeHandler2}
                            options={opt}
                            styles={customStyles}
                          />
                          {/* <Badge color="warning">{this.state.grop_err}</Badge>
                 */}
                        </FormGroup>
                
                      </Col>
                      {this.state.visible ?
                        <Col className="" md="4">
                          <FormGroup>
                            <label className="title"  style={{fontSize: '12px'}}>Funktion, Position</label>
                            <Badge color="warning">{this.state.func_err}</Badge>
                   
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="titleSelect"
                              value={this.state.opt2Select}
                              onChange={this.changeHandler1}
                              options={filteredOpt}
                              styles={customStyles}
                            />
                            <Badge color="warning">{this.state.func_err}</Badge>
                          </FormGroup>
                        </Col>
                        : null}
                      {this.state.visible1 ?
                        <Col className="" md="4">
                          <FormGroup>
                            <label className="title"  style={{fontSize: '12px'}}>Fachgebiet, Fachabteilungen</label>
                            <Select
                            styles={customStyles}
                              //isMulti
                              //closeMenuOnSelect={false}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="titleSelect"
                              value={this.state.opt3Select}
                              onChange={this.changeHandler3}
                              options={filteredOpt1}
                            />
                          </FormGroup>
                        </Col>
                        : null}
                    </Row>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <h5 className='title'><u>Aufgabenbeschreibung</u></h5>
                    <br></br>
                    <label  style={{fontSize: '12px'}}>Beschreiben Sie die Aufgabe hier</label>
                    <br></br>
                    <Badge color="warning">{this.state.disc_err}</Badge>
               
                    <Input 
                    style={{fontSize: '15px'}}
                    type="textarea"
                        rows = "5"
                        cols = "5"
                      name="discription"
                      onChange={this.changeHandler} />
                    <br></br>
                      <hr></hr>
                   <br></br>
                    <h4 className='title'><u>Rahmenbedingungen</u></h4>
                    <br></br>
                    <Row>
                      <Col md="6">
                        <label className='title'  style={{fontSize: '12px'}}>Anstellungsart</label>
                        <br></br>
                        <label  style={{fontSize: '12px'}}>Bitte geben Sie die möglichen Arten der Anstellung an.</label>
                        <br></br>
                        <Badge color="warning">{this.state.Anstellungsart_err}</Badge>
                 
                        <Select
                        styles={customStyles}
                           isMulti
                        //  closeMenuOnSelect={false}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="titleSelect"
                          placeholder = 'Bitte auswählen'
                          value={this.state.zmSelect}
                          onChange={value =>
                            this.setState({ zmSelect: value })
                          }
                          options={zm}
                        />
                        {/* <Badge color="warning">{this.state.zm_err}</Badge>
                      */}
                      </Col>

                      <Col md="6">
                        <label className='title'  style={{fontSize: '12px'}}>Zeitmodell</label>
                        <br></br>
                        <label  style={{fontSize: '12px'}}>Handelt es sich um eine Vollzeit- oder Teilzeitstelle?</label>
                        <br></br>
                        <Badge color="warning">{this.state.zeit_err}</Badge>
                     
                        <Select
                          styles={customStyles}
                          isMulti
                         // closeMenuOnSelect={false}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="titleSelect"
                          placeholder = 'Bitte auswählen'
                          value={this.state.tvSelect}
                          onChange={value =>
                            this.setState({ tvSelect: value })
                          }
                          options={tv}
                        />
                        {/* <Badge color="warning">{this.state.tv_err}</Badge>
                      */}
                      </Col>
                    </Row>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <Row>
                      <Col>
                      <h4 className='title'><u>Benefits</u></h4>
                    <br></br>
                    <label  style={{fontSize: '12px'}}>Welche Benefits bietet Ihr Unternehmen an?</label>
                    <br></br>
                    <Badge color="warning">{this.state.benefits_err}</Badge>
                    <Input 
                    style={{fontSize: '15px'}}
                    type="textarea"
                        rows = "10"
                        cols = "15"

                      name='benefits'
                      onChange={this.changeHandler}
                    />
                      </Col>
                    
                    </Row>
                    <hr></hr>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md='10'>
                      {/* <label className = "title" style = {{fontSize: '15px'}}> <b>Ihre Angaben können von Ihnen NICHT mehr bearbeitet werden, nachdem die Stellenanzeige veröffentlicht wurde. Bitte überprüfen Sie Ihre Eingaben daher sorgfältig. Sollte Sie im Nachgang einen Änderungsbedarf an Ihrer Stellenanzeige feststellen, können Sie uns gern <Link to = {"/admin/contact"} target="_blank" rel="noopener noreferrer">kontaktieren </Link>.</b></label> */}
                      </Col>
                      <Col>
                      {/* <Link to = {'/admin/job-print'}> */}
                        <Button color="primary"
                         onClick={this.handleSubmit} 
                        >
                           WEITER</Button>
                        {/* </Link> */}
                      </Col>
                    </Row>
                    <br></br>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default CreateJob;

