import React, {useState, useEffect} from "react";
import axios from "axios";
import Button_loading from "components/Button_loading";
import SunEditor from "suneditor-react";
import {ToastContainer, toast} from "react-toastify";

const ImpressumEditor = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("user_id"); // Get user ID from localStorage

  const submitHandler = () => {
    setLoading(true);
    axios
      .post("/api2/EditorImpressum", {value, user_id: userId})
      .then((response) => {
        toast.success("Impressum saved successfully!");
        fetchImpressum();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error saving Impressum:", error);
        toast.error("Failed to save Impressum.");
        setLoading(false);
      });
  };

  const fetchImpressum = () => {
    setLoading(true);
    axios
      .post("/api2/GetEditorImpressum")
      .then((response) => {
        const editorValue = response.data.result[0]?.EditorDesc || "";
        setValue(editorValue);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Impressum:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchImpressum();
  }, []);

  return (
    <div className="content">
      <ToastContainer />
      <div style={{background: "white"}}>
        <h4 style={{textAlign: "center"}}>Impressum</h4>
        <SunEditor
          setContents={value}
          onChange={setValue}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle", "blockquote"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
              ],
              ["fontColor", "hiliteColor", "textStyle"],
              ["removeFormat"],
              "/",
              ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],
              ["table", "link"],
              ["fullScreen", "showBlocks", "codeView"],
              ["preview", "print"],
              ["save", "template"],
            ],
          }}
        />
        <Button_loading
          style={{float: "right"}}
          color="primary"
          onClick={submitHandler}
          loading={loading}>
          Save
        </Button_loading>
      </div>
    </div>
  );
};

export default ImpressumEditor;
