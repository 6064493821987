import React from "react";
//import { Link } from "react-router-dom"
// reactstrap components
import {
  Card,
  CardBody,
  Table,
} from "reactstrap";

const TableExperience = ({item}) => {
    return (
      <>
      <Card>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Position</th>
                        <th>Unternehmen</th>
                        <th>Website</th>
                        <th>Anfangsdatum</th>
                        <th>Endtermin</th>
                        <th>Beschreibung</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Assistenzarzt
                        </td>
                        <td>
                        Knappschaftsklinikum Saar GmbH
                        </td>
                        <td>
                        http://www.kksaar.de/
                        </td>
                        <td>
                          01/01/2011
                        </td>
                        <td>
                          30/10/2017
                        </td>
                        <td>
                       Ich bin Ein Assistenzarzt
                        </td>
                        {/* {item.resume.map((w) => {
                          return (
                            <>
                            {w.experiences.map((ex) => {
                              return (
                                <>
<td>
                              {ex.companyName}
                              </td>
                        <td>
                        {ex.city} <br/>
                              <small className="text-italic">
                                {ex.country}
                              </small>
                        </td>
                        <td>
                        {ex.position} <br/>
                              <small className="text-italic">
                                {ex.skill}
                              </small>

                        </td>
                        <td className="">
                          {ex.startMonth}.{ex.startYear} - {ex.endMonth}.{ex.endYear}
                          </td>
                                </>
                              )
                            })}
                            </>
                          )
                        })}
                         */}
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
      </>
    );
}

export default TableExperience;
