import React from "react";
import axios from 'axios';
import Select from "react-select";
import { Link } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  UncontrolledCollapse,
  FormGroup,
  Input
} from "reactstrap";
import Time from "@material-ui/icons/Timer";
import ReactPaginate from 'react-paginate';
import "./style1.css";

const optt = [
  {
    value: "1",
    label: "Bewerbung verworfen",
    fontSize: '166px'
  },
  {
    value: "2",
    label: "Bewerbung angenommen",
    fontSize: '166px'
  },
  {
    value: "3",
    label: "Beworben",
    fontSize: '166px'
  },
  {
    value: "4",
    label: "Bewerbung im Prüfung",
    fontSize: '166px'
  },
  {
    value: "5",
    label: "Einstellungszusage",
    fontSize: '166px'
  },
  {
    value: "6",
    label: "Bewerbung abgelehnt",
    fontSize: '166px'
  },
  {
    value: "7",
    label: "closed",
    fontSize: '166px'
  },
  {
    value: "8",
    label: "nicht Beworben",
    fontSize: '166px'
  },
]
const option = [
  {
    value: "1",
    label: "Bewerbung verworfen",
    fontSize: '166px'
  },
  {
    value: "2",
    label: "Bewerbung angenommen",
    fontSize: '166px'
  },
  {
    value: "3",
    label: "Beworben",
    fontSize: '166px'
  },
  {
    value: "4",
    label: "Bewerbung im Prüfung",
    fontSize: '166px'
  },
  {
    value: "5",
    label: "Einstellungszusage",
    fontSize: '166px'
  },
  {
    value: "6",
    label: "Bewerbung abgelehnt",
    fontSize: '166px'
  },
  {
    value: "7",
    label: "closed",
    fontSize: '166px'
  },
  {
    value: "8",
    label: "nicht Beworben",
    fontSize: '166px'
  },
  {
    value: "9",
    label: "deactive",
    fontSize: '166px'
  }
]
class CandidateByJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countJobs: 0,
      data: [],
      data1: [],
      data2: [],
      cand: [],
      filter: [],
      msg: '',
      visible1: false,
      state2: false,
      job_value: null,
      offset1: 0,
      perPage1: 20,
      currentPage1: 0,
      offset: 0,
      perPage: 20,
      currentPage: 0,
      searchByName: "",
      searchError:""
    };
  }
  changeHandler = (job_value) => {
    //console.log(this.state)
    this.setState(pv => {
      return { ...pv, job_value: job_value };
    }
    );
    console.log(job_value)
    if (job_value.label === "nicht Beworben") {
      console.log("job_value from if", job_value)
      axios.get('/api2/candidatebystellen')
        .then(response => {
          console.log(response)
          const filter = response.data;
          this.setState({ filter });
          this.setState({ visible1: true })
          this.setState({ state2: false })
          this.setState(pv => {
            return { ...pv, isShow: false };
          }
          );
          localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
          console.log(filter);
          this.makePostData(this.state.filter);
          this.setState({ reserr: undefined })

        })

        .catch(error => {
          console.log(error.response);
          const reserr = error.response.data.msg;
          this.setState({ reserr })
          this.setState({ state2: true })
        })
    } else {
      console.log("job_value from else", job_value)
      axios.post("/api2/candidsearchbyvalue", { ...this.state, job_value })
        .then(response => {
          console.log(response)
          const filter = response.data;
          this.setState({ filter });
          this.setState({ visible1: true })
          this.setState({ state2: false })
          this.setState(pv => {
            return { ...pv, isShow: true };
          }
          );
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData(this.state.filter);
          this.setState({ reserr: undefined })

        })
        .catch(error => {
          console.log(error);
          const reserr = error.response.data.msg;
          this.setState({ reserr })
          this.setState({ state2: true })
        })
    }
  }
  changeHandlerInput = e => {
    //console.log(e.target.value) 
    this.setState({ searchByName: e.target.value })
    //console.log(this.state.searchByName)
  }
  CandidateSearchByName = () => {
    if(this.state.searchByName != ""){
      axios.post("/api2/candidsearchName", this.state)
      .then(response => {
        console.log(response)
        const filter = response.data;
        this.setState({ filter });
        this.setState({ visible1: true })
        this.setState({ state2: false })
        this.setState(pv => {
          return { ...pv, isShow: true };
        }
        );
        localStorage.setItem('filter', JSON.stringify(this.state.filter));
        console.log(filter);
        this.makePostData(this.state.filter);
        this.setState({ reserr: undefined })
        this.setState({searchError:""})
      })
      .catch(error => {
        console.log(error);
        const reserr = error.response.data.msg;
        this.setState({ reserr })
        this.setState({ state2: true })
      })
    }
    else{
          this.setState({searchError:"bitte schreiben"})
    }
    //console.log(this.state.searchByName)
   
  }
  //   componentWillUnmount(){
  //   clearTimeout(this.intervalID);
  // }
  submitHandler(jobid, appid, hospid, e) {
    var role_err;
    console.log("ids", jobid, appid, hospid, this.state.roleSelect)
    if (this.state.roleSelect === undefined) {
      console.log("error")
      role_err = "please select"
      this.setState({ role_err })
    } else {
      this.setState({ role_err: undefined })
      axios.post('/api2/changejobstatus', { ...this.state, jobid, appid, hospid })
        .then(response => {
          console.log(response)
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          // const data1 = response.data;
          // this.setState({ data1 });
          // console.log(data1);
        })
        .catch(error => {
          console.log(error.response);
        })
    }

  }
  componentDidMount() {
    console.log(this.state);
    axios.get('/api2/getnotes1')
      .then(response => {
        console.log(response)
        const data1 = response.data;
        this.setState({ data1 });
        console.log(data1);
      })
      .catch(error => {
        console.log(error.response);
      })
    /////////////////////////////////////////
    // axios.get('/api2/getnotes1')
    // .then(response => {
    //   console.log(response)
    //    const data2 = response.data;
    //    this.setState({ data2 });
    //    console.log(data2);
    // })
    // .catch(error => {
    //   console.log(error.response);
    // })
    ///////////////////////////////////////
    axios.get('/api2/candidatebyjob')
      .then(response => {
        console.log(response)
        const data = response.data;
        this.setState({ data });
        const slice1 = data.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
        const postData1 = slice1.map(item => <React.Fragment>
          <Card style={{ backgroundColor: '#f5f5f5' }} key={item.job_id}>
            <CardBody>
              <Row>
                <Col md='2'>
                  {item.picture === null || item.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
                    alt="..."
                    className="avatar border-gray"
                    src={`https://hello-sister.de/uploads/${item.picture}`}


                  />}

                </Col>

                <Col md='4'><h7 className="text-left"   >

                  <Link to={`/admin/detail-kandidate/${item.app_id}`} target="_blank" rel="noopener noreferrer"> 
                  {/* {item.salutation} {" "} */}
                    {/* {item.title === 'Kein' ? null : item.title} */}
                    {item.f_name} {item.l_name}
                  </Link>
                  <br></br>
                  <i class="fas fa-map-marker-alt">

                  </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.applicant_house} , {item.post_code} {item.applicant_city}, {item.country}</h7>
                </h7>
                  <br></br>
                  <h7 className="text-left" >
                    <Badge className="mr-1" color="info">
                      {item.candidate_status} am {item.action_date}
                    </Badge>
                  </h7>
                  <br></br>
                  <Link to={`/admin/notes/${item.applyjob_id}`} target="_blank" rel="noopener noreferrer"> <Badge className="mr-1" color="primary" >Notizen hinzufügen</Badge></Link>

                  {this.state.data1.map(item1 => {
                    return (
                      <>
                        {item.applyjob_id === item1.applyjob_id ?
                          <Badge className="mr-1" color="success" pill>
                            {item1.cnt} Notizen
                          </Badge>
                          :
                          null
                        }
                      </>
                    )
                  })}
                  <Badge className="mr-1" color="success" pill>
                    {this.state.role_err}
                  </Badge>
                  <Select
                    //  styles={customStyles1}
                    // className="react-select primary"
                    classNamePrefix="react-select"
                    name="roleSelect"
                    //value={this.state.roleSelect}
                    onChange={value =>
                      this.setState({ roleSelect: value })
                    }
                    // styles={customStyles}
                    options={[
                      {
                        value: "1",
                        label: "Bewerbung verworfen",
                        fontSize: '166px'
                      },
                      {
                        value: "2",
                        label: "Bewerbung angenommen",
                        fontSize: '166px'
                      },
                      {
                        value: "3",
                        label: "Beworben",
                        fontSize: '166px'
                      },
                      {
                        value: "4",
                        label: "Bewerbung im Prüfung",
                        fontSize: '166px'
                      },
                      {
                        value: "5",
                        label: "Einstellungszusage",
                        fontSize: '166px'
                      },
                      {
                        value: "6",
                        label: "Bewerbung abgelehnt",
                        fontSize: '166px'
                      },
                      {
                        value: "7",
                        label: "closed",
                        fontSize: '166px'
                      },
                      // {
                      // value: "8",
                      // label: "nicht Beworben",
                      // fontSize: '166px'
                      // }
                    ]}
                    placeholder='Bitte auswählen'
                  />
                  <Button color='primary'
                    style={{ marginTop: '25px' }}
                    onClick={this.submitHandler.bind(this, item.job_id, item.app_id, item.hosp_id)}
                  >Status ändern</Button>
                </Col>
                <Col md='4'>
                  <h7 className="text-left">{item.app_contract} {item.contract_date_candid}<br /><br />
                    {item.hosp_contract} {item.hosp_contract_date}</h7>    </Col>
                <Col md='2'>
                  <td className="text-left" style={{ fontSize: '12px' }}>
                    <b>Beworben am <br></br><center><Badge className="mr-1" color="success">{item.applied_date} </Badge></center> </b>


                    <center> <Link><i class="fas fa-chevron-circle-down" style={{ fontSize: '26px', color: '#4d72d0' }} id={`toggler${item.job_id}` + `${item.app_id}`}

                    ></i></Link>  </center> </td>
                </Col>
              </Row>

              <div>




                <UncontrolledCollapse toggler={`#toggler${item.job_id}` + `${item.app_id}`}
                >

                  <br></br>
                  <h7><b>{item.f_name}</b> hat sich auf diese Stellenanzeige beworben.</h7>
                  <Card style={{ marginTop: '15px' }}>
                    <CardBody><Row>
                      <Col md='2'>
                        {item.logo === null || item.logo === " " ? null :
                          <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`}
                            alt='' height='80' width='auto' />}
                      </Col>

                      <Col md='8'><h7 className="text-left"     >
                        <h7 style={{ color: '#4287f5', fontSize: '13px' }}><Link to={`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">{item.company_name}</Link></h7>
                        <br></br>
                        <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer">{item.job_function} (m/w/d) {item.profession == 'undefined' ? null : item.profession}

                        </Link>
                        <br></br>
                        <i class="fas fa-map-marker-alt">

                        </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                          {item.zip_code}  {item.city}, {item.state}</h7>
                      </h7>
                        <h5 className="text-left" >

                        </h5></Col>
                      <Col md='2' className="text-right" >

                      </Col>
                    </Row>
                      <br></br>
                      <Row style={{ backgroundColor: '#f5f5f5' }}>

                        <Col md="3">
                          <Time />&nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                        </Col>
                        <Col md="3">
                          <i class="fas fa-hourglass-half"></i>
                          &nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} {item.job_ternure2 !== null ? ',' + item.job_ternure2 : null} </h7>
                        </Col>
                        <Col md="3">
                          <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}> Online seit: {item.created_date}</h7>
                        </Col>
                        <Col md="3">
                          <i class="far fa-calendar-alt"></i>

                          &nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                        </Col>
                      </Row>

                    </CardBody>

                  </Card>





                </UncontrolledCollapse>
              </div>
            </CardBody>
          </Card>

        </React.Fragment>)
        localStorage.setItem('data', JSON.stringify(this.state.data));
        console.log(data);
        this.setState({
          state2: true
        })
        this.setState({
          pageCount1: Math.ceil(data.length / this.state.perPage1),

          postData1
        })
        this.setState({
          state2: false
        })
      })

      .catch(error => {
        console.log(error.response);


      })



  }
  handlePageClick1 = (e) => {
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;

    this.setState({
      currentPage1: selectedPage1,
      offset1: offset1
    }, () => {
      this.componentDidMount()
    });

  };

  makePostData(filter) {
    const slice = filter.slice(this.state.offset, this.state.offset + this.state.perPage)
    const postData = slice.map(item => <React.Fragment>
      {this.state.isShow ?
        <Card style={{ backgroundColor: '#f5f5f5' }} key={item.job_id}>

          <CardBody>
            <Row>
              <Col md='2'>
                {item.picture === null || item.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
                  alt="..."
                  className="avatar border-gray"
                  src={`https://hello-sister.de/uploads/${item.picture}`}


                />}

              </Col>

              <Col md='4'><h7 className="text-left"   >

                <Link to={`/admin/detail-kandidate/${item.app_id}`} target="_blank" rel="noopener noreferrer"> 
                {/* {item.salutation} {" "} */}
                  {/* {item.title === 'Kein' ? null : item.title} */}
                  {item.f_name} {item.l_name}
                </Link>
                <br></br>
                <i class="fas fa-map-marker-alt">

                </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.applicant_house} , {item.post_code} {item.applicant_city}, &nbsp; {item.country}</h7>
              </h7>
                <br></br>
                <h7 className="text-left" >
                  <Badge className="mr-1" color="info">
                    {item.candidate_status} am {item.action_date}
                  </Badge>
                </h7>
                <br></br>
                <Link to={`/admin/notes/${item.applyjob_id}`} target="_blank" rel="noopener noreferrer"> <Badge className="mr-1" color="primary" >Notizen hinzufügen</Badge></Link>

                {this.state.data1.map(item1 => {
                  return (
                    <>
                      {item.applyjob_id === item1.applyjob_id ?
                        <Badge className="mr-1" color="success" pill>
                          {item1.cnt} Notizen
                        </Badge>
                        :
                        null
                      }
                    </>
                  )
                })}
                <Badge className="mr-1" color="success" pill>
                  {this.state.role_err}
                </Badge>
                <Select
                  //  styles={customStyles1}
                  // className="react-select primary"
                  classNamePrefix="react-select"
                  name="roleSelect"
                  value={this.state.roleSelect}
                  onChange={value =>
                    this.setState({ roleSelect: value })
                  }
                  // styles={customStyles}
                  options={[
                    {
                      value: "1",
                      label: "Bewerbung verworfen",
                      fontSize: '166px'
                    },
                    {
                      value: "2",
                      label: "Bewerbung angenommen",
                      fontSize: '166px'
                    },
                    {
                      value: "3",
                      label: "Beworben",
                      fontSize: '166px'
                    },
                    {
                      value: "4",
                      label: "Bewerbung im Prüfung",
                      fontSize: '166px'
                    },
                    {
                      value: "5",
                      label: "Einstellungszusage",
                      fontSize: '166px'
                    },
                    {
                      value: "6",
                      label: "Bewerbung abgelehnt",
                      fontSize: '166px'
                    },
                    {
                      value: "7",
                      label: "closed",
                      fontSize: '166px'
                    },
                    {
                      value: "8",
                      label: "deactive",
                      fontSize: '166px'
                    }
                    // {
                    // value: "8",
                    // label: "nicht Beworben",
                    // fontSize: '166px'
                    // }
                  ]}
                  placeholder='Bitte auswählen'
                />
                <Button color='primary'
                  style={{ marginTop: '25px' }}
                  onClick={this.submitHandler.bind(this, item.job_id, item.app_id, item.hosp_id)}
                >Status ändern</Button>
              </Col>
              <Col md='4'>
                <h7 className="text-left">{item.app_contract} {item.contract_date_candid}<br /><br />
                  {item.hosp_contract} {item.hosp_contract_date}</h7>    </Col>
              <Col md='2'>
                <td className="text-left" style={{ fontSize: '12px' }}>
                  <b>Beworben am <br></br><center><Badge className="mr-1" color="success">{item.applied_date} </Badge></center> </b>


                  <center> <Link><i class="fas fa-chevron-circle-down" style={{ fontSize: '26px', color: '#4d72d0' }} id={`toggler${item.job_id}` + `${item.app_id}`}

                  ></i></Link>  </center> </td>
              </Col>
            </Row>

            <div>




              <UncontrolledCollapse toggler={`#toggler${item.job_id}` + `${item.app_id}`}
              >

                <br></br>
                <h7><b>{item.f_name}</b> hat sich auf diese Stellenanzeige beworben.</h7>
                <Card style={{ marginTop: '15px' }}>
                  <CardBody><Row>
                    <Col md='2'>
                      {item.logo === null || item.logo === " " ? null :
                        <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height='80' width='auto' />}
                    </Col>

                    <Col md='8'><h7 className="text-left"     >
                      <h7 style={{ color: '#4287f5', fontSize: '13px' }}><Link to={`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">{item.company_name}</Link></h7>
                      <br></br>
                      <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer">{item.job_function} (m/w/d) {item.profession == 'undefined' ? null : item.profession}

                      </Link>
                      <br></br>
                      <i class="fas fa-map-marker-alt">

                      </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                        {item.zip_code}  {item.city}, {item.state}</h7>
                    </h7>
                      <h5 className="text-left" >

                      </h5></Col>
                    <Col md='2' className="text-right" >

                    </Col>
                  </Row>
                    <br></br>
                    <Row style={{ backgroundColor: '#f5f5f5' }}>

                      <Col md="3">
                        <Time />&nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                      </Col>
                      <Col md="3">
                        <i class="fas fa-hourglass-half"></i>
                        &nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} {item.job_ternure2 !== null ? ',' + item.job_ternure2 : null} </h7>
                      </Col>
                      <Col md="3">
                        <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px' }}> Online seit: {item.created_date}</h7>
                      </Col>
                      <Col md="3">
                        <i class="far fa-calendar-alt"></i>

                        &nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                      </Col>
                    </Row>

                  </CardBody>

                </Card>





              </UncontrolledCollapse>
            </div>
          </CardBody>
        </Card>

        :
        <Card style={{ backgroundColor: '#f5f5f5' }} key={item.job_id}>

          <CardBody><Row>
            <Col md='2'>
              {item.picture === null || item.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
                alt="..."
                className="avatar border-gray"
                src={`https://hello-sister.de/uploads/${item.picture}`}


              />}
            </Col>

            <Col md='5'><h7 className="text-left"   >
              <Link to={`/admin/detail-kandidate/${item.app_id}`}>
                {/* {item.salutation} {" "} */}
                {/* {item.title === 'Kein' ? null : item.title} */}
                {item.f_name} {item.l_name}
              </Link>
              <br></br>
              {item.telephone === null ? null : <><h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-mobile-alt"></i> &nbsp;{item.telephone}</h7><br></br></>}
              <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp;{item.username}</h7><br></br>
              <i class="fas fa-map-marker-alt"></i>&nbsp;
              <h7 className="text-left" style={{ fontSize: '13px' }}>{item.haus_no}, {item.post_code} {item.city}, &nbsp;{item.country}</h7>
              <br></br>
            </h7>
            </Col>
          </Row>

          </CardBody>

        </Card>
      }



    </React.Fragment>)
    this.setState({
      pageCount: Math.ceil(filter.length / this.state.perPage),
      postData
    });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.makePostData(this.state.filter);
    });

  };

  render() {
    return (
      <>
        <div className="content">

          <Card>
            <CardBody>
              <h7 className="cstm-heading-backend"><b>Bewerbungen</b></h7>
              <hr></hr>
              <FormGroup>
                <Input placeholder="Suche nach Vorname oder Nachname" type="text" name="searchByName" onChange={this.changeHandlerInput.bind(this)} />
              </FormGroup>
              <Button onClick={this.CandidateSearchByName}>Suchen</Button>
              <Badge color="danger">{this.state.searchError}</Badge>
              <Select
                className="react-select primary"
                name="job_value"
                value={this.state.job_value}
                options={option}
                onChange={this.changeHandler.bind(this)}
                placeholder="Bitte wählen Sie zunächst den Anzeigenstatus aus"
                type="text"
              />

              <Badge color="danger">{this.state.errvalue}</Badge>

              <br></br>
              {this.state.state2 ?
                <center><Badge color="danger">{this.state.reserr}</Badge></center>
                :
                <>
                  {this.state.visible1 ?
                    <>
                      {this.state.postData}
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                    </>
                    :
                    <>
                      {this.state.postData1}
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick1}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                    </>
                  }
                </>

              }

            </CardBody>
          </Card>

        </div>
      </>
    );
  }
}

export default CandidateByJob;