import React, {Component} from "react";
import axios from "axios";
import Button from "reactstrap/lib/Button";
import {Row, Col} from "reactstrap";
import {Redirect} from "react-router-dom";
import SunEditor from "suneditor-react";

export default class AGBEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }
  modules = {
    toolbar: [
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
      ["link", "image", "color"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  submitHandler = (e) => {
    axios
      .post("/api2/EditorAGBCandidateNew", this.state)
      .then((response) => {
        //   console.log(response)
        //   this.setState({adduser_err:undefined})
        //   var adduser_succ = response.data.msg;
        //   this.setState({ adduser_succ })
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        this.setState({redirect: true});
      })
      .catch((error) => {
        console.log(error.response);
        //   this.setState({adduser_succ:undefined})
        //   var adduser_err = error.response.data.msg
        //   this.setState({ adduser_err })
      });
    //}
  };

  componentDidMount() {
    axios
      .post("/api2/GetEditorAGBCandidate", this.state)
      .then((response) => {
        //console.log(response)
        const value = response.data.result[0].EditorDesc;
        //  console.log(value)
        this.setState({value});
        //this.setState({adduser_err:undefined})
        //   var adduser_succ = response.data.msg;
        //   this.setState({ adduser_succ })
      })
      .catch((error) => {
        console.log(error.response);
        //   this.setState({adduser_succ:undefined})
        //   var adduser_err = error.response.data.msg
        //   this.setState({ adduser_err })
      });
  }

  componentWillMount() {
    const user_id = localStorage.getItem("user_id");
    //  console.log(user_id)
    this.setState({user_id});
  }

  onChange = (value) => {
    // console.log(value)
    this.setState({value: value});
  };

  render() {
    const {redirect} = this.state;
    if (redirect) {
      return <Redirect to="/admin/AGBEditor" />;
    }
    return (
      <div className="content">
        <div style={{background: "white"}}>
          <Row>
            <Col md="8">
              <h4>AGB Kandidaten (New Version)</h4>
            </Col>
          </Row>

          <SunEditor
            setContents={this.state.value}
            onChange={this.onChange}
            // onClick={this.submitHandler1}
            lang="en"
            width="100%"
            height="100%"
            setOptions={{
              buttonList: [
                ["undo", "redo"],
                ["font", "fontSize", "formatBlock"],
                ["paragraphStyle", "blockquote"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                ],
                ["fontColor", "hiliteColor", "textStyle"],
                ["removeFormat"],
                "/", // Line break
                ["outdent", "indent"],
                ["align", "horizontalRule", "list", "lineHeight"],
                ["table", "link" /** 'image', 'video', 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                ["fullScreen", "showBlocks", "codeView"],
                ["preview", "print"],
                ["save", "template"],
                /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
              ],
            }}
          />
          <div style={{marginBottom: "70px"}}>
            <Button
              style={{float: "right"}}
              color="primary"
              onClick={this.submitHandler}>
              Save{" "}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
