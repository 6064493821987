import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  //Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  //Table
} from "reactstrap";

//import Upgrades from "./upgrades/Upgrades.jsx"
import MyContract from "./my_contract/MyContract.jsx"
import MyOptionalContract from "./my_optional_contract/MyOptionalContract.jsx"
import {mainInstitution} from "variables/general.jsx"
import TableInvoice from "./component_v2"

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTabs: "myContractPage",
      mainInstitutionSingleSelect:  
        {
        value: "1",
    id: "1",
    label: "DRK Krankenhaus Nordlingen",
    contactInformation: [
      {
        id: "M-1",
        name: "DRK Krankenhaus Nordlingen",
        institutionsType: "Krankenhaus",
        email: "info@nordlingen.de",
        telefon: "09898777",
        website: "www.nordlingen.de",
        dateRegistration: "29.08.2019",
        timeRegistration: "12:18",
        adresse: [
          {
          street: "test-1 str 40",
          city: "saarlouis",
          zipCode: "66117",
          region: "bayern",
          country: "deutschland"
        }
      ],
      positionNumber: "KN-01920",
      dateRegistrtaion: "29.06.2017"
      }
    ],
    dateFirstBookedInstitut: [
      {
        startDate: "01.01.2019",
        earliestDateEndContract: "10.01.2020",
        deadlineTermination: "01.10.2019"
      }
    ],
    dataInvoice: [
      {
        contractType: "Basic",
        customerNumber: "KJAVKSC90",
        invoiceFile: [
          {
            id: "1",
            invoiceDate: "15.10.2019",
            dueDate: "22.10.2019",
            total: "1089"
          },
          {
            id: "2",
            invoiceDate: "15.09.2019",
            dueDate: "22.09.2019",
            total: "1089"
          },
          {
            id: "3",
            invoiceDate: "15.08.2019",
            dueDate: "22.08.2019",
            total: "1089"
          },
          {
            id: "4",
            invoiceDate: "15.07.2019",
            dueDate: "22.07.2019",
            total: "1089"
          },
          {
            id: "5",
            invoiceDate: "15.06.2019",
            dueDate: "22.06.2019",
            total: "1089"
          },
          {
            id: "6",
            invoiceDate: "15.05.2019",
            dueDate: "22.05.2019",
            total: "1089"
          }
        ]
      }
    ]
  }
      ,
    };
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  render() {
    const firstInstitution = mainInstitution[0]
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-plain card-subcategories">
              {mainInstitution.length !== 1 ? 
                    <>
                    <CardHeader>
                  <CardTitle className="text-center" tag="h1">
                    Rechnungen
                  </CardTitle>
                  <br />
                  <Row className="justify-content-center">
            <Col md="4" className="text-center">
            <label>Rechnungsanschrift</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="mainInstitutionSingleSelect"
                            value={this.state.mainInstitutionSingleSelect}
                            onChange={value =>
                              this.setState({ mainInstitutionSingleSelect: value})
                            }
                            options={mainInstitution}
                            placeholder="Bitte auswählen"
                          />
            </Col>
          </Row>
          <br/>
                </CardHeader>
                    {this.state.mainInstitutionSingleSelect !== "" && this.state.mainInstitutionSingleSelect.value !== "0" ? 
                    <>
                      <CardBody>
                      {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}
                      <Nav
                        className="nav-pills-primary nav-pills-icons justify-content-center"
                        pills
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            href="#pablo"
                            role="tablist"
                            className={
                              this.state.pageTabs === "myContractPage" ? "active" : ""
                            }
                            onClick={() => this.setState({ pageTabs: "myContractPage" })}
                          >
                            <i className="now-ui-icons objects_umbrella-13" />
                            Vertrag
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            href="#pablo"
                            role="tablist"
                            className={
                              this.state.pageTabs === "upgradePage"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ pageTabs: "upgradePage" })
                            }
                          >
                            <i className="now-ui-icons shopping_shop" />
                            Gebuchte Optionen
                          </NavLink>
                        </NavItem>
                        
                      </Nav>
                      <TabContent
                        className="tab-space tab-subcategories"
                        activeTab={this.state.pageTabs}
                      >
                        <TabPane tabId="myContractPage">
                        
                         <MyContract item={this.state.mainInstitutionSingleSelect}/>
                        </TabPane>
                        <TabPane tabId="upgradePage">
                        <MyOptionalContract/>
                        </TabPane>
                        
                      </TabContent>
                    </CardBody>
                    <TableInvoice item={this.state.mainInstitutionSingleSelect} />
                    </>
                      :
                      ""
                      }
                    </>
                    : 
                    <>
                    <CardHeader>
                  <CardTitle className="text-center" tag="h1">
                    Rechnungen
                  </CardTitle>
                  <br />
                </CardHeader>
                <CardBody>
                  {/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}
                  <Nav
                    className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        role="tablist"
                        className={
                          this.state.pageTabs === "myContractPage" ? "active" : ""
                        }
                        onClick={() => this.setState({ pageTabs: "myContractPage" })}
                      >
                        <i className="now-ui-icons objects_umbrella-13" />
                        Vertrag
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        href="#pablo"
                        role="tablist"
                        className={
                          this.state.pageTabs === "upgradePage"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          this.setState({ pageTabs: "upgradePage" })
                        }
                      >
                        <i className="now-ui-icons shopping_shop" />
                        Gebuchte Optionen
                      </NavLink>
                    </NavItem>
                    
                  </Nav>
                  <TabContent
                    className="tab-space tab-subcategories"
                    activeTab={this.state.pageTabs}
                  >
                    <TabPane tabId="myContractPage">
                    
                     <MyContract item={firstInstitution}/>
                    </TabPane>
                    <TabPane tabId="upgradePage">
                    <MyOptionalContract/>
                    </TabPane>
                    
                  </TabContent>
                </CardBody>
                <TableInvoice item={firstInstitution} />
                    </>
                    }
                
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Panels;
