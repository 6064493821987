import React from "react";
import { Link } from "react-router-dom"
// reactstrap components
import {
  Button,
  UncontrolledTooltip,
  Badge
} from "reactstrap";

import ButtonDeleteList from "building_block/button_delete_table"

const TableCandidateJobDetails = ({item}) => {
    return (
      <>
                        <td className="text-right">
                            <div className="avatar text-right">
                            <img
                              alt="..."
                              className="img-circle img-no-padding img-responsive"
                              src={require("assets/img/faces/joe-gardner-2.jpg")}
                            />
                            </div>
                        </td>
                        <td className="text-left">{item.title} {item.firstName} {item.lastName}, {item.jobType}
                        <br/>
                        {item.qualificationExperienced} Jahre Berufserfahrung
                        <br/>
                        {item.lastContacted.map((contact) => {
                          return (
                            <>
                            {contact !== "" ? 
                            <small className="text-italic">
                            Letzter Kontakt war am {contact.date} um {contact.time}
                            </small> 
                            : ""}
                           </> 
                          )
                        })}
                        </td>
                        <td className="text-left">
                        <Badge className="mr-1" color="primary" pill>
                        {item.qualification}
            </Badge>
            <br/>
            {item.specialities.map((speciality) => {
                return (
<Badge className="mr-1" color="primary" pill>
                        {speciality.name}
            </Badge>
                )
            })}
                        </td>
                        <td className="text-right">
                          
                          <Link to={"/admin-message/message"}>
                          <Button
                            className="btn-icon"
                            color="success"
                            id="tooltip366246651"
                            size="sm"
                            type="button"
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246651"
                          >
                            Nachricht
                          </UncontrolledTooltip>
                          </Link>
                          <Link to={"/admin/candidate-profile"}>
                          <Button
                            className="btn-icon"
                            color="info"
                            id="tooltip476609794"
                            size="sm"
                            type="button"
                          >
                            <i className="fa fa-search" />
                          </Button>{" "}
                          </Link>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip476609794"
                          >
                            Details
                          </UncontrolledTooltip>
                          {/* <ButtonDeleteList/> */}
                        </td>
      </>
    );
}

export default TableCandidateJobDetails;
