import React from 'react'

//import { Link } from "react-router-dom";
// reactstrap components
import {
    Row,
    Col,
    Card,
    CardBody,

  } from "reactstrap";
const DetailCardList = ({item}) => {
    return (
      //  <Row>
      //  <Col lg="4" md="4" sm="4">
         <Card className="card-stats">
           <CardBody>
             <Row>
              {/* <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                   <i className="fa fa-stethoscope text-warning" />
                 </div>
               </Col>
              
                 <div className="stats text-right">
                   <p className="card-category">Funktion</p>
                   {item.qualifications.map((q) => {
                       return (
                           <>
                           <h6>
                        {q.name} 
                        </h6>
                        <p>
                        mit {q.experience} Berufserfahrung 
                        </p> 
                        <br/> 
                           </>
                        
                       )
                   })}
                 </div> */}
                  <Col >
                 <div className="stats text-left">
                 
                 <label className = 'title'>Geburtsdatum</label>
                 <br></br>
                      <label>14/10/1975</label>
                      <br></br>
                      <br></br>
                      <br></br>
                      <label className = 'title'>Sichtbarkeit des Profils</label>
                 <br></br>
                      <label>Mein Lebenslauf ist anonym</label>
                      <br></br>
                      
                      </div>
</Col>
<Col>
                      <div className="stats text-center">

                      <label className = 'title'>Straße und Hausnummer</label>
                 <br></br>
                      <label>An der trift 42 </label>
                      <br></br>
                      <br></br>
                      <br></br>
                 
                      <label className = 'title'>Postleitzahl</label>
                       <br></br>
                      <label>12345</label>
                      <br></br>
                      <br></br>
                      <br></br>
                      <label className = 'title'>Stadt</label>
                 <br></br>
                      <label>Berlin</label>
                      <br></br>
                    
                 </div>
</Col>
<Col>
                 <div className="stats text-right">
                 
                      <label className = 'title'>Land</label>
                 <br></br>
                      <label>Deutschland</label>
                      <br></br>
                      <br></br>
                      <br></br>
                      <label className = 'title'>Telefonnummer</label>
                 <br></br>
                      <label>+1234567890</label>
                      <br></br>
                     
                      </div>
               </Col>
             </Row>
           </CardBody>
            {/*<CardFooter>
             <hr />
             <div className="stats text-left">
             <p className="card-category">Fachgebiet</p>
                   {item.specializations.map((s) => {
                       return (
                        <CardTitle >
                            <b>{s.name}</b> {" "}
                         mit {s.experience} Berufserfahrung<br/> 
                    </CardTitle>
                       )
                   })}
             </div> 
           </CardFooter>*/}
         </Card>
      //  </Col>
      //  <Col lg="4" md="4" sm="4">
      //    <Card className="card-stats">
      //    <CardBody>
      //                  <Row>
                         /* <Col md="4" xs="5">
                           <div className="icon-big text-center icon-warning">
                             <i className="nc-icon nc-badge text-warning" />
                           </div>
                         </Col>
                         <Col md="8" xs="7">
                           <div className="numbers">
                           {item.adresse.map((a) => {
                       return (
                           <>
                      <p className="card-category">Adresse
                      <br/>
                      <b>{a.street} {a.zipCode}, {a.city}, {a.country}</b>
                      </p>
                           </>
                       )
                   })}
                   <br/>
                             <p className="card-category">Geburtsdatum
                             <br/>
                             <b>
                              {item.dateBirth}
                             </b>
                             </p>
                             <br/>
                                
                            </div>
                      </Col> */
                  //     <label className = 'title'>Anrede</label>
                  //     <label>Frau</label>
                  //   </Row>
                  // </CardBody>
                  // <CardFooter>
                    /* <hr/>
                    <div className="stats text-left">
                    {item.status.map((s) => {
                      return (
                        <>
                        <p className="card-category">Registrierung
                           <br/>
                           <b className="">
                                am {item.dateRegistration} um {item.timeRegistration}
                             </b>
                             <br/>
                             
                                 {s.registration === "aktiv" ? <b>Registrierung bestätigt</b> : <b>Registrierung nicht bestätigt</b>}
                           </p> <br/>
                           <p className="card-category">Status
                             <br/>
                             <b>
                             {s.status}
                             </b>
                             </p>
                             <br/>
                             <p className="card-category">Berufliche Wünsche Benachrichtigung
                             <br/>
                             <b>
                             {s.jobWishNotification}
                             </b>
                             </p>
                        </>
                           )
                          })}
                    </div> */
      //             </CardFooter>
                   
                
      //    </Card>
      //  </Col>
      //  <Col lg="4" md="4" sm="4">
      //    <Card className="card-stats">
      //                   <CardBody>
                       /* <Row>
                         <Col md="4" xs="5">
                           <div className="icon-big text-center icon-warning">
                             <i className="nc-icon nc-paper text-warning" />
                           </div>
                         </Col>
                         <Col md="8" xs="7">
                           <div className="numbers">
                             {item.approbation.map((a) => {
                               return (
                                 <>
                                 {a.available === "Ja" ? 
                                 <>
                                 <p className="card-category">Approbation
                             <br/>
                             <b>
                             {a.available}, seit {a.dateCreated}
                             </b>
                             </p>
                                 </>
                                 : 
                                 <>
                                 <p className="card-category">Approbation
                             <br/>
                             <b>
                             {a.available}
                             </b>
                             </p> <br/>
                             {item.occupationPermit.map((be) => {
                               return (
                                 <>
                                 {be.available === "Ja" ? 
                                 <p className="card-category">Berufserlaubnis
                                 <br/>
                                 <b>
                                 {be.available}, seit {be.dateCreated}
                                 </b> <br/>
                                 <b>
                                   in {be.location}
                                 </b>
                                 </p>
                                 : 
                                 <p className="card-category">Berufserlaubnis
                                 <br/>
                                 <b>
                                 {be.available}
                                 </b>
                                 </p>
                                }
                                 </>
                               )
                             })} <br/>
                             {item.languagesExamination.map((fsp) => {
                               return (
                                 <>
                                 {fsp.available === "Ja" ? 
                                 <p className="card-category">Fachsprachenprüfung (FSP)
                                 <br/>
                                 <b>
                                 {fsp.available}, seit {fsp.dateCreated}
                                 </b> <br/>
                                 <b>
                                   in {fsp.location}
                                 </b>
                                 </p>
                                 : 
                                 <p className="card-category">Fachsprachenprüfung (FSP)
                                 <br/>
                                 <b>
                                 {fsp.available}
                                 </b>
                                 </p>
                                }
                                 </>
                               )
                             })}
                                 </>
                                }
                                 </>
                               )
                             })}
                      </div>
                      </Col>
                    </Row> */
    //               </CardBody>
    //               <CardFooter>
    //               </CardFooter>
                
    //      </Card>
    //    </Col>
    //  </Row>
    )
}

export default DetailCardList