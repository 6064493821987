import React, { useState, useEffect } from 'react';
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Button_loading from 'components/Button_loading';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Editor = () => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const user_id = localStorage.getItem("user_id");

  const submitHandler = () => {
    setLoading(true);
    setDisabled(true);

    axios
      .post('/api2/EditorConditions', { value, user_id })
      .then(response => {
        toast.success("Conditions updated successfully!", { position: toast.POSITION.TOP_RIGHT });
        setLoading(false);
        setDisabled(false);
      })
      .catch(error => {
        toast.error("Error updating conditions. Please try again.", { position: toast.POSITION.TOP_RIGHT });
        setLoading(false);
        setDisabled(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post('/api2/GetEditorConditions', { user_id })
      .then(response => {
        const fetchedValue = response.data.result[0]?.EditorDesc || '';
        setValue(fetchedValue);
        setLoading(false);
      })
      .catch(error => {
        toast.error("Error fetching conditions data.", { position: toast.POSITION.TOP_RIGHT });
        setLoading(false);
      });
  }, [user_id]);

  const onChange = (content) => {
    setValue(content);
  };

  return (
    <div className="content">
      <ToastContainer />
      <div style={{ background: 'white' }}>
        <h4 style={{ textAlign: "center" }}>Konditionen</h4>
        <SunEditor
          setContents={value}
          onChange={onChange}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['paragraphStyle', 'blockquote'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['removeFormat'],
              '/', 
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['table', 'link'], 
              ['fullScreen', 'showBlocks', 'codeView'],
              ['preview', 'print'],
              ['save', 'template']
            ]
          }}
        />
        <Button_loading
          className="m-1"
          onClick={submitHandler}
          loading={loading}
          disabled={disabled}
          color={"primary"}
        >
          Save
        </Button_loading>
      </div>
    </div>
  );
};

export default Editor;
