import React from "react";
import axios from 'axios';
// react plugin used to create charts
// import { Line, Bar, Doughnut } from "react-chartjs-2";
// // react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";
// import { Link } from "react-router-dom"
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";
import ReactPaginate from 'react-paginate';
// import {
//   chartExample1,
//   chartExample2,
//   chartExample3,
//   chartExample4,
//   chartExample5,
//   chartExample6,
//   chartExample7,
//   chartExample8
// } from "variables/charts.jsx";
// import {chartProvision, chartJobs, chartInstitutions} from "variables/data_charts_hm.jsx"
//import { monthNames} from "variables/general.jsx"

// import TableTop from "building_block/table_top"


// import FixedMessage from "components/FixedMessage";
// import { isTemplateMiddle } from "typescript";

// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countJobs: 0,
      hosp : [],
      cand : [],
      msg : '',
      offset: 0,
      perPage: 10,
      currentPage: 0,
      offset1: 0,
      perPage1: 10,
      currentPage1: 0,
      visible1: false,
      visible2: false
    };
    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }



  toggle() {
  this.setState({
    modal: !this.state.modal
  });
  }
  toggle1() {
  this.setState({
    modal1: !this.state.modal1
  });
}



  acceptHandle(hospid, e){
    e.preventDefault();
    //const { company_name, person_name} = this.state
      console.log(hospid)
      axios
			.post('/api2/accepthospital', {...this.state,hospid})
			.then(response => {
        console.log(response)
        const msg = "Sie akzeptieren die Anfrage";
        this.setState({msg})
        window.location.reload();
			})
			.catch(error => {
        console.log(error.response);
    	})
}
deleteHandle (hospid, e){
  e.preventDefault();
  //const { company_name, person_name} = this.state
    console.log(hospid)
    axios
    .post('/api2/rejecthospital', {...this.state,hospid})
    .then(response => {
      console.log(response);
      const msg = "Sie lehnen diese Anfrage ab";
        this.setState({msg})
      window.location.reload();
    })
    .catch(error => {
      console.log(error.response);
    })
}
deletecandidHandle = e =>{
  e.preventDefault();
  //const { company_name, person_name} = this.state
    console.log(this.state.user_id)
    axios
    .post('/api2/deletecandid', this.state)
    .then(response => {
      console.log(response);
      // const msg = "Sie lehnen diese Anfrage ab";
      //   this.setState({msg})
      window.location.reload();
    })
    .catch(error => {
      console.log(error.response);
    })
}
registeremailHandler (userid, e){
  e.preventDefault();
  //const { company_name, person_name} = this.state
    console.log("register email",userid)
    axios
    .post('/api2/sentregistermail', {...this.state,userid})
    .then(response => {
      console.log(response);
      const msg = "Sie lehnen diese Anfrage ab";
        this.setState({msg})
      window.location.reload();
    })
    .catch(error => {
      console.log(error.response);
    })
 }
 updateemailHandler (userid, e){
  e.preventDefault();
  //const { company_name, person_name} = this.state
    console.log("update email",userid)
    axios
    .post('/api2/sendupdatemail', {...this.state,userid})
    .then(response => {
      console.log(response);
      const msg = "Sie lehnen diese Anfrage ab";
        this.setState({msg})
      window.location.reload();
    })
    .catch(error => {
      console.log(error.response);
    })
 }
  componentDidMount(){
    const roles = localStorage.getItem('roles')
    this.setState({roles})
    console.log(roles);
    console.log(this.state);
    axios.post('/api2/hospitalsrequests', this.state)
    .then(response => {
      console.log(response)
      const hosp = response.data;
      this.setState({ hosp });
      localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      console.log(hosp);
      const slice = hosp.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
      const postData1 = slice.map(item => <React.Fragment>
          
          <tr>
                             <td>
                             <label className="text-ubold">
                           {item.hosp_name}<br></br>
                           {item.adress}  {item.haus_number}, <br></br>
                           {item.zip_code}  {item.city}
                                    </label>
                            </td> 
                            <td className="text-left">
                           
                            <label className="text-ubold">
                         
                         {item.andre}  {item.f_name} {item.l_name} <br></br>
                           
                            {item.telephone === null ? null :<>   <i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${item.telephone}`} target="_blank" rel="noopener noreferrer">{item.telephone}</a></> }
 
                                     </label>
                        </td>
                        <td className="text-left">
                        <label className="text-ubold">
                        {item.register_date}
                                   </label>
                                    </td>
                                    <td className="text-left">
                                    <Badge className="mr-1" color="info" pill>
                                    {item.status}
                                   </Badge>
                        </td>
                        <td className="text-right">
                        <Button
                            className="btn-icon"
                            color="success"
                            id={"tooltip366246651"+item.hosp_id}
                            size="sm"
                            type="button"
                            onClick={this.acceptHandle.bind(this, item.hosp_id)}
                          >
                            <i className="fa fa-check-circle" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246651"+item.hosp_id}
                          >
                            Akzeptieren
                          </UncontrolledTooltip>
                          <Button
                            className="btn-icon"
                            color="danger"
                            id={"tooltip366246652"+item.hosp_id}
                            size="sm"
                            type="button"
                            onClick={this.deleteHandle.bind(this, item.hosp_id)}
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246652"+item.hosp_id}
                          >
                            Ablehnen
                          </UncontrolledTooltip>
                        </td> 
                        </tr>
      </React.Fragment>)
  localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
  console.log(hosp);
  this.setState({
  pageCount1: Math.ceil(hosp.length / this.state.perPage1),
  
  postData1
  
  })
  this.setState({ visible1 : true });})
    .catch(error => {
      this.setState({ visible1 : false });
      console.log(error.response);

      
    })
    /////////////////////////////////////////// newly register candidates
    axios.get('/api2/newcandidates', this.state)
    .then(response => {
      console.log(response)
      const cand = response.data;
      this.setState({ cand });
      localStorage.setItem('hosp', JSON.stringify(this.state.cand));
      console.log(cand);
      const slice = cand.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = slice.map(user => <React.Fragment>
             <Card style = {{backgroundColor: '#f5f5f5', padding:'10px',margin:'13px'}} >
          <Row>
                            <Col md = '4' className="text-left">
                            <h7 className="text-left" style={{fontSize:'13px'}}><i class="fas fa-envelope-open-text"></i>&nbsp;{user.user_mail_id}</h7><br></br>
                     {user.telephone === null ? null :<><h7 className="text-left" style={{fontSize:'13px'}}><i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${user.telephone}`} target="_blank" rel="noopener noreferrer">{user.telephone}</a></h7><br></br></>}

                           </Col>
                            
                     <Col md = '4'><h7 className="text-left"   >
                     
                     <h7 className="text-left" style={{fontSize:'11px'}}><b>Registrierung:</b> {user.created_date}</h7><br></br>
                <h7 className="text-left" style={{fontSize:'11px'}}><b>Email Sent:</b> {user.email_sent}</h7><br></br>
               
                {user.status === 'aktiv' ? <h7 className="text-left" style={{fontSize: '11px', color : '#28a745'}}><b>Konto Status:</b> {user.status} </h7>  
                :<h7 className="text-left" style={{fontSize: '11px', color : '#ff0800'}}><b>Konto Status:</b> {user.status} </h7>}
                     
                     

                     </h7> 
                </Col>
                <Col md = '4' className="text-right">
                <Button
                            className="btn-icon"
                            color="danger"
                            id={"tooltip36624665200"+user.users_user_id}
                            size="sm"
                            type="button"
                           onClick={this.registeremailHandler.bind(this, user.users_user_id)}
                          >
                            <i className="fa fa-envelope-square" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip36624665200"+user.users_user_id}
                          >
                            Registration E-mail
                          </UncontrolledTooltip>
                          <Button
                            className="btn-icon"
                            color="primary"
                            id={"tooltip3662466520"+user.users_user_id}
                            size="sm"
                            type="button"
                           onClick={this.updateemailHandler.bind(this, user.users_user_id)}
                          >
                            <i className="fa fa-envelope-square" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip3662466520"+user.users_user_id}
                          >
                            Profile update E-mail	
                          </UncontrolledTooltip>
                          {this.state.roles === 'admin' ?
                          <>
                          <Button
                            className="btn-icon"
                            color="danger"
                            id={"tooltip366246642"+user.users_user_id}
                            size="sm"
                            type="button"
                            //onClick={this.deletecandidHandle.bind(this, item1.users_user_id)}
                            onClick={() => this.setState({ user_id: user.users_user_id},this.toggle)} 
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246642"+user.users_user_id}
                          >
              Löschen 
                          </UncontrolledTooltip></>
                          :
                          null
                          }
                          
       
                  </Col>
                        
                </Row> 
         
             
             </Card> 
              
          {/* <tr>
                             <td>
                             <label className="text-ubold">
                           {item1.user_mail_id}
                                    </label> <br />
                                    <small>
                              <i className="nc-icon nc-mobile" /> :{" "} {item1.telephone}
                              </small>
                            </td> 
                        <td className="text-left">
                        <label className="text-ubold">
                        {item1.created_date}
                                   </label>
                                    </td>
                                    <td className="text-left">
                                    <Badge className="mr-1" color="info" pill>
                                    {item1.status}
                                   </Badge><br/>
                                   {item1.email_sent}
                        </td>
                        <td className="text-right">
                        
                          <Button
                            className="btn-icon"
                            color="danger"
                            id={"tooltip36624665200"+item1.users_user_id}
                            size="sm"
                            type="button"
                           onClick={this.registeremailHandler.bind(this, item1.users_user_id)}
                          >
                            <i className="fa fa-envelope-square" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip36624665200"+item1.users_user_id}
                          >
                            Registration E-mail
                          </UncontrolledTooltip>
                          <Button
                            className="btn-icon"
                            color="primary"
                            id={"tooltip3662466520"+item1.users_user_id}
                            size="sm"
                            type="button"
                           onClick={this.updateemailHandler.bind(this, item1.users_user_id)}
                          >
                            <i className="fa fa-envelope-square" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip3662466520"+item1.users_user_id}
                          >
                            Profile update E-mail	
                          </UncontrolledTooltip>
                          <Button
                            className="btn-icon"
                            color="danger"
                            id={"tooltip366246642"+item1.users_user_id}
                            size="sm"
                            type="button"
                            //onClick={this.deletecandidHandle.bind(this, item1.users_user_id)}
                            onClick={() => this.setState({ user_id: item1.users_user_id},this.toggle)} 
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246642"+item1.users_user_id}
                          >
              Löschen 
                          </UncontrolledTooltip>
       
            
                        </td> 
                        </tr> */}
      </React.Fragment>)
  localStorage.setItem('cand', JSON.stringify(this.state.cand));
  console.log(cand);
  this.setState({
  pageCount: Math.ceil(cand.length / this.state.perPage),
  postData
  })
  this.setState({ visible2 : true });
      console.log(cand);     
    })
    .catch(error => {
      this.setState({ visible2 : false });
      console.log(error.response);
    })
/////////////////////////////////////////////////////////// axios
    axios.post('/api2/matchedstellen', this.state)
    .then(response => {
      console.log(response);
      const msg = "Sie lehnen diese Anfrage ab";
        this.setState({msg})
      window.location.reload();
    })
    .catch(error => {
      console.log(error.response);
    })
 //}
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
  
    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.componentDidMount()
    });
  
  }; 
  handlePageClick1 = (e) => {
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;
  
    this.setState({
        currentPage1: selectedPage1,
        offset1: offset1
    }, () => {
        this.componentDidMount()
    });
  
  }; 
  render() {
    //var d = new Date()
    //var actualMonth = monthNames[d.getMonth()]
    return (
      <>
        <div className="content">
        {this.state.roles === 'admin' ? 
               <>
        
        <Card>
                <CardHeader>
                <h4 className = 'title'>Neue Unternehmensregistrierungen:</h4>
          <br></br>
          <Badge color="success">{this.state.msg}</Badge>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Unternehmen</th>
                        <th className="text-left">Kontakt</th>
                        <th className="text-left">Registrierung</th>
                        <th className="text-left">Status</th>
                        <th className="text-right">Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.postData1}    
                    </tbody>
                  {this.state.visible1 ? <span><center> <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick1}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/></center></span> : null}  
                  </Table>
                </CardBody>
                <hr />
              </Card> 
             </>: null }
             
          {this.state.roles === 'admin' || this.state.roles === 'admin user' ?
            <>
                <Card>
                <CardHeader>
                <h4 className = 'title'>Neue Kandidatenregistrierungen:</h4>
          <br></br>
          <Badge color="success">{this.state.msg}</Badge>
                </CardHeader>
                <CardBody>
                
                    {this.state.postData}
                    <br></br>
                    {this.state.visible2 ? <span><center> <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/></center></span> : null} 
                <Modal isOpen={this.state.modal} toggle={this.toggle}  className={this.props.className}
>
<ModalHeader toggle={this.toggle}></ModalHeader> 
<ModalBody>
 <h4 ><b>Kandidaten dauerhaft löschen</b></h4>
<br></br><Badge color="warning">{this.state.reply}</Badge><br/>
Sind Sie sicher, dass Sie diesen Kandidaten löschen möchten?<br></br>
<br></br>
<center><Button  
       onClick={this.toggle}  
       style={{width: 'auto',
       height: '32px',
       fontSize: '9px',
       borderRadius: '32px',
       boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
       fontWeight: '400',
       border: 'none !important',
       padding: '9px 27px',
       // background: '#dc3545',
        marginBottom: '1rem' }}>Abbrechen</Button>
 <Button 
       color="primary"
       style={{width: 'auto',
       height: '32px',
       fontSize: '9px',
       borderRadius: '32px',
       boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
       fontWeight: '400',
       border: 'none !important',
       padding: '10px 27px',
       background: '#dc3545'
     }}
      onClick={this.deletecandidHandle} 
     >
       
       Bestätigen </Button></center>

</ModalBody>
                  </Modal>
                  
                  {/* </Table> */}
                </CardBody>
                <hr />
          </Card>
          
            </>
            :
            null}
          {this.state.roles === 'school' ?
            
            <Card><CardBody 
            // style = {{color : '#00FFFF'}}
            ><center><h5 ><b><u>Welcome to admin panel of partners</u></b></h5></center></CardBody></Card>
            : null}
          
        </div>
      </>
    );
  }
}

export default Dashboard;