import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Row, Col } from "reactstrap";
import { Redirect, useParams } from "react-router-dom";
import Moment from "moment";
import SunEditor from "suneditor-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AGBEditor = () => {
  const { Contract_Candidate_id } = useParams();
  const [value1, setValue1] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [archivedDate, setArchivedDate] = useState("");

  const user_id = localStorage.getItem("user_id");

  const submitHandler = async () => {
    try {
      await axios.post("/api2/EditorContractCandidate", {
        value1,
        Contract_Candidate_id,
      });
      toast.success("Content saved successfully!");
      setRedirect(true);
    } catch (error) {
      console.error("Error saving content:", error);
      toast.error("Failed to save content.");
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post("/api2/GetEditorContractCandidate_single", {
        Contract_Candidate_id,
      });
      const fetchedValue = response.data.result[0]?.EditorDesc || "";
      const fetchedDate = response.data.result[0]?.Date || "";
      setValue1(fetchedValue);
      setArchivedDate(fetchedDate);
    } catch (error) {
      console.error("Error fetching content:", error);
      toast.error("Failed to fetch content.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (redirect) {
    return <Redirect to="/admin/ContractEditor" />;
  }

  return (
    <div className="content">
      <ToastContainer />
      <div style={{ background: "white" }}>
        <Row style={{ padding: "12px" }}>
          <Col md="12">
            <h4>
              Kandidaten Vertrag (Archived Date: {Moment(archivedDate).format("DD.MM.YYYY")})
            </h4>
          </Col>
        </Row>
        <SunEditor
          setContents={value1}
          onChange={setValue1}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle", "blockquote"],
              ["bold", "underline", "italic", "strike", "subscript", "superscript"],
              ["fontColor", "hiliteColor", "textStyle"],
              ["removeFormat"],
              "/", // Line break
              ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],
              ["table", "link"],
              ["fullScreen", "showBlocks", "codeView"],
              ["preview", "print"],
              ["save", "template"],
            ],
          }}
        />
        <div style={{ marginBottom: "70px" }}>
          <Button style={{ float: "right" }} color="primary" onClick={submitHandler}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AGBEditor;
