import React from "react";
//import { Link } from "react-router-dom"
// reactstrap components
import {
  Card,
  CardBody,
  Table,
} from "reactstrap";

const TableExperience = ({item}) => {
    return (
      <>
      <Card>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Sprache</th>
                        <th>Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                        Deutsch
                        <br></br>
                        <br></br>
                        English
                        </td>
                        <td>
                        Muttersprache
                        <br></br>
                        <br></br>
                        Verhandlungssicher
                        </td>
                        {/* {item.resume.map((w) => {
                          return (
                            <>
                            {w.languages.map((la) => {
                              return (
                                <>
<td>
                              {la.name}
                              </td>
                        <td>
                        {la.level}
                        </td>
                                </>
                              )
                            })}
                            </>
                          )
                        })} */}
                        
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
      </>
    );
}

export default TableExperience;
