import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  // CardTitle,
  // CardHeader,
  // Collapse,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
  Badge,
  UncontrolledTooltip,
  //CardText,
  Table,
  Input
} from "reactstrap";
import Ternure from "@material-ui/icons/BusinessCenter";
import Time from "@material-ui/icons/Timer";
import TypeMedical from "@material-ui/icons/LocationCity";
import City from "@material-ui/icons/MapOutlined";



class CandidateProfile1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notedata: [],
      note: '',
      candid_email: '',
      records: []
    };
  }
  handleMoreClick = () => {
    this.setState({
      buttonToRender: (this.state.buttonToRender + 2)
    });
  };


  componentDidMount() {
   // console.log("props", this.props)
    const { job_id } = this.props.match.params;
    console.log(job_id)
    this.setState({ job_id })
    // const user = JSON.parse(localStorage.getItem('user'))
    // this.setState({user})
    // console.log(user)
    axios.post('/api2/getjobdetails', { ...this.state, job_id })
      .then(response => {
        console.log(response)
         let records = response.data;
        // let job = null;
        // records.forEach(element => {
        //   if (element.job_id === job_id) {
        //     job = element;
        //   }
        // });
        // records = [job];
         this.setState({ records });
        // console.log("RECORDS", job, records);
      })
      .catch(error => {
        console.log(error.response);

        const msg = error.response.data.Message;
        this.setState({ msg });
      })
    //////////////////
    axios.post('/api2/getadminsentjobs', { ...this.state, job_id })
      .then(response => {
        console.log(response)
        //   const note = response.data[0].admin_notes;
        //   this.setState({ note });
        //   // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        //   console.log(note);
        const notedata = response.data
        this.setState({ notedata });
        //   //state : {user : this.state.user}

        //   //this.setState({redirect : true})
      })
      .catch(error => {
        console.log(error.response);

        const msg = error.response.data.Message;
        this.setState({ msg });
      })
  }


  submitHandler = () => {
    console.log(this.state)
    if (this.state.candid_email === "") {
      const note_err = "Bitte fügen Sie hier eine E-Mail hinzu"
      this.setState({ note_err })
    } else {
      this.setState({ note_err: undefined })
      axios
        .post('/api2/sendadminjob', this.state)
        .then(response => {
          console.log(response)
          const note_msg = response.data.msg;
          this.setState({ note_msg })
          window.location.reload();
          // const WorkExp1 = response.data;
          // this.setState({ WorkExp1 });
        })
        .catch(error => {
          console.log(error)
          const err_msg = error.response.data.Message
          this.setState({ err_msg })
        })
    }
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({ note_err: undefined })
  }
  deletenoteHandle(noteid) {
    console.log("note id", noteid)
    axios
      .post('/api2/deljob', { ...this.state, noteid })
      .then(response => {
        console.log(response)
        const note_msg = response.data.msg;
        this.setState({ note_msg })
        window.location.reload();
        // const WorkExp1 = response.data;
        // this.setState({ WorkExp1 });
      })
      .catch(error => {
        console.log(error)
      })
  }
  render() {

    return (
      <div className="content">
        <Badge className="mr-1" color="danger" pill>
          {this.state.err_msg}
        </Badge>
        <Badge className="mr-1" color="success" pill>
          {this.state.note_msg}
        </Badge>
        <Card>
          <CardBody>
            {this.state.records.map(job => {
              return (
                <>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody color>
                          <div>
                            <Row>
                              <Col md="1.5">
                                <img src={`https://unternehmen.hello-sister.de/uploads/${job.logo}`} alt='' width='150' height='100' />
                                <br></br>

                                {/* <img src ={'../agenda.png'}  alt=''  height = '90'></img> */}
                              </Col>
                              <Col>
                                <h5 className="text-ubold">
                                  {/* { this.state.jobdata.map(jobs1 => <b> {jobs1.company_name}</b>)}  */}
                                  {job.company_name}
                                </h5>
                                <h5 style={{ fontSize: '20px' }}>
                                  {job.job_function} (m/w/d) {job.profession === 'undefined' ? null : job.profession}
                                </h5>


                              </Col>
                              <span className="category-social pull-right color-white text-bold">
                                <Link to={`/admin/job-profile/${job.job_id}/${job.app_id}`} target="_blank" rel="noopener noreferrer">
                                  <Button color="primary"
                                  >
                                    Inserats-Nr. {job.job_id} </Button>
                                </Link>
                              </span>
                              <br></br>
                              {/* <span className="category-social pull-right color-white text-bold">
               <Button color="primary" 
                       onClick={this.logoSelect.bind(this, job.logo,job.job_id,job.company_name)}
                      >
                        Select this Logo
                      </Button>
                  </span> */}
                            </Row>
                            <br></br>
                            <Row>

                              <Col md="2">
                                Status
                                <br />
                                <h5 style={{ fontSize: '16px' }}>
                                  <Badge className="mr-1" color="success" pill>
                                    {job.job_status}
                                  </Badge>
                                </h5>


                              </Col>

                              <Col md="3">
                                <Ternure />
                                <br />
                                <h5 style={{ fontSize: '16px' }}>
                                  {job.job_ternure} <br></br>
                                  {job.job_ternure1}
                                </h5>

                              </Col>

                              <Col md="2">
                                <Time />
                                <br />
                                <h5 style={{ fontSize: '16px' }}>
                                  {job.job_worktime} <br></br>
                                  {job.job_worktime1}
                                </h5>


                              </Col>

                              <Col md="2">
                                <TypeMedical />
                                <br />
                                <h5 style={{ fontSize: '16px' }}>
                                  {job.sector}
                                </h5>


                              </Col>


                              <Col md="3">
                                <City />
                                <br />
                                <h5 style={{ fontSize: '16px' }}>
                                  Laufzeit bis: {job.valid_till}



                                </h5>

                              </Col>


                            </Row>
                            <Row>


                            </Row>
                          </div>
                        </CardBody>
                        <CardFooter>

                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                  {/* <Card>
                            <CardHeader>
                            <h6 className = "title"><b>Einleitung:</b></h6>
                            </CardHeader>
                            <hr></hr>
                            <CardBody>
                              <CardText>
                              <p className="p_wrap">
                                {job.about_us}
                                </p>
                              </CardText>
                            </CardBody>
                          </Card> */}
                  {/* <br></br> */}
                  {/* <Card>
                            <CardHeader>
                            <h6 className = "title"><b>Ihr Profil:</b></h6>
                            </CardHeader>
                            <hr></hr>
                            <CardBody>
                              <CardText>
                                {job.job_function} (m/w/d) {job.profession == 'undefined' ? null : job.profession} 
                              </CardText>
                            </CardBody>
                          </Card> */}
                  {/* <br></br> */}
                  {/* <Card>
                            <CardHeader>
                            <h6 className = "title"><b>Ihre Aufgaben:</b></h6>
                            </CardHeader>
                            <hr></hr>
                            <CardBody>
                            <CardText>
                            <p className="p_wrap">
                  {job.discription}
                  </p>
                  </CardText> 
                            </CardBody>
                          </Card> */}
                  {/* <br></br> */}
                  {/* <Card>
                            <CardHeader>
                            <h6 className = "title"><b>Benefits:</b></h6>
                            </CardHeader>
                            <hr></hr>
                            <CardBody>
                            <CardText>
                            <p className="p_wrap"> 
                  {job.benefits}
                  </p>
                  </CardText>
                            </CardBody>
                          </Card> */}
                  {/* <br></br> */}
                  {/* <Card>
                            <CardHeader>
                            <h6 className = "title"><b>Standort:</b></h6>
                            </CardHeader>
                            <hr></hr>
                            <CardBody>
                            
                                <b>
                                
                                {job.company_name}
                                </b>
                              <br></br>
                              <br></br>
                              <CardText>
                              {job.address} {job.haus_no}<br></br>
                              {job.zip_code}  {job.city},{job.state}
                              </CardText>
                            </CardBody>
                           
                          </Card> */}
                  {/* <br></br> */}
                </>
              );
            })}
            <br></br>
            <br></br>
            <Table responsive>
              <thead>
                <tr>
                  <th>kandidate</th>
                  <th>GESENDET AM</th>
                  {/* <th>Note</th> */}
                  <th>Löschen</th>
                </tr>
              </thead>
              <tbody>
                {this.state.notedata.map(item => {
                  return (
                    <>
                      <tr>
                        <td><b> <Link to={`/admin/detail-kandidate/${item.app_id}`} target="_blank" rel="noopener noreferrer">
                          {item.andre} {item.f_name} {item.l_name} <br></br>
                          {item.haus_no}<br />
                          {item.post_code + ' '}{item.city}. <br></br>
                          {item.username}<br></br>
                          {item.telephone}</Link></b>  </td>
                        <td>{item.sent_date}</td>
                        {/* <td>{user.admin_notes}</td> */}
                        <td> <Button
                          className="btn-icon"
                          color="danger"
                          id="tooltip3662466421"
                          size="sm"
                          type="button"
                          onClick={this.deletenoteHandle.bind(this, item.sendjob_id)}
                        >
                          <i className="fa fa-trash" />
                        </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip3662466421"
                          >
                            Löschen
                          </UncontrolledTooltip></td>



                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
            <br></br>
            <br></br>
            <Badge className="mr-1" color="danger" pill>
              {this.state.note_err}
            </Badge>
            <center>
              <label>Kandidaten-E-Mail, um den Job zu senden</label>
              <Input type='email' name='candid_email' onChange={this.changeHandler}></Input>
              <br />
              <Button color='primary'
                onClick={this.submitHandler}
              >Submit</Button></center>

          </CardBody>
        </Card>



      </div>
    );
  }
}

export default CandidateProfile1;
