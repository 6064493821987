import React from "react";
// react plugin used to create DropdownMenu for selecting items
//import Select from "react-select";
// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";

//import { dataInvoice, mainInstitution } from "variables/general.jsx"
import TableInvoice from "building_block/table_invoice"
//import MyContract from "../../explore/my_contract/MyContract.jsx"


const InvoiceTable = ({item}) => {
    return (
      <>
        <div className="content">
            <Row>
            <Col md="12">
              <Card>
                <CardBody>
                    {item.dataInvoice.map((di) => {
                        return (
                            <>
                            <h4 className="text-center">
                                (Kundennummer: {di.customerNumber})
                            </h4>
                            <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center">#</th>
                                <th>Rechnungsdatum</th>
                                <th>PDF-Dokumente</th>
                                <th className="text-center">Zahlbar zum</th>
                                <th className="text-center">Rechnungsbetrag</th>
                              </tr>
                            </thead>
                            <tbody>
                            {di.invoiceFile.map((item, index) => {
                                  return (
                                      <tr>
                                        <TableInvoice item={item} index={index + 1}/>
                                      </tr>
                                  )
                              })}
                            </tbody>
                          </Table>

                            </>
                        )
                    })}
                </CardBody>
              </Card>
            </Col>
          </Row>
         
        </div>
      </>
    );
  }


export default InvoiceTable;