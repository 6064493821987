import React from "react";
import axios from 'axios';
import "./style1.css";
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom"
import { jsPDF } from "jspdf";
import Time from "@material-ui/icons/Timer";
import html2pdf from "html2pdf.js";
import Moment from 'moment';
import DOMPurify from "dompurify";
import * as ReactDOMServer from 'react-dom/server';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Input,
  UncontrolledCollapse
} from "reactstrap";
//import Input from "react-select/src/components/input";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      cand: [],
      offset: 0,
      perPage: 20,
      currentPage: 0,
      state1: false,
      app_sign: null,
      app_sign_city: null,
      app_sign_date: null,
      cdate: null,
      kjdate: null,
      value: ''
    };
    this.toggle = this.toggle.bind(this);
  }
  JsPdfGenerator = (U1, U2, U3, U4, U5, K2, K3, K4, K5, K6, S1, S2, S3, C1) => {
    var doc = new jsPDF();
    doc.setFont("times new roman", "normal");
    doc.setFontSize(10);
    doc.text("Gül GmbH, An der Trift 40, 66123 Saarbrücken, Deutschland", 20, 15);
    doc.text("AG Saarbrücken, HRB 100749, USt.IdNr: DE286297909, GF: Deniz Gül", 20, 20);
    doc.text("T: +49 (681) 68 63 53 74, E: kunden@guel.gmbh,  W: www.hello-sister.de ", 20, 25);
    doc.addImage("/images/logo_color.png", "JPEG", 140, 5, 50, 20);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(15);
    doc.text("VERMITTLUNGSVEREINBARUNG", 105, 40, null, null, "center");
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Zwischen der Gül GmbH, vertreten durch den Geschäftsführer Deniz Gül, An der Trift 40, ", 20, 55);
    doc.text("66123 Saarbrücken", 20, 60);
    doc.text("- nachfolgend: (Vermittlungsagentur) bzw. (VA) -", 20, 70);
    doc.text("und", 20, 80);
    doc.text(`${K2} ${K3}, ${K4}, ${K5} ${K6}`, 20, 90);
    doc.text("- nachfolgend: (Vermittlungssuchender) bzw. (VS) -", 20, 100);
    doc.text("wird nachfolgende Vereinbarung zum Zwecke der Vermittlung einer Anstellung mit der ", 20, 115);
    doc.text(`Einrichtung ${U1}, ${U2} ${U3}, ${U4} ${U5}`, 20, 120);
    doc.text("getroffen: ", 20, 125);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("Präambel ", 20, 140);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("I) Die Vermittlungsagentur (VA) agiert auf dem Gebiet der Zusammenführung vom Personal im ", 20, 145);
    doc.text("Gesundheitswesen bzw. Vermittlungssuchenden (VS) und Interessenten bzw. ", 20, 150);
    doc.text("Vermittlungsnehmern (VN) in Deutschland. Die  Vereinbarung gilt sowohl für einzelne ", 20, 155);
    doc.text("natürliche Personen, als auch auf Personenmehrheiten und deren Mitglieder sowie auf ", 20, 160);
    doc.text("juristische Personen und deren Leitungsorgane gleichermaßen.", 20, 165);
    // doc.text("in Vollzeit oder Teilzeit oder auf sonstige Weise erfolgen soll. ", 20, 170);
    doc.text("Die VA bemüht sich nach Erteilung eines entsprechenden Auftrags um die Vermittlung ", 20, 175);
    doc.text("von Anstellungen, gleich welcher fachlichen Ausrichtung sowie unabhängig davon, ob die ", 20, 180);
    doc.text("zu vermittelnde Tätigkeit befristet, unbefristet, in Vollzeit oder Teilzeit oder auf sonstige ", 20, 185);
    doc.text("Weise erfolgen soll.", 20, 190);

    doc.text("II) Vermittlungssuchende sind: ", 20, 200);
    doc.text("-      Krankenpflege (Anästhesieschwester, Anästhesietechnische Assistentin (ATA), ", 20, 205);
    doc.text("       Fachkrankenschwester, Gesundheitspflegehelfer Krankenpflegehelfer, ", 20, 210);
    doc.text("       Gesundheitspfleger und Krankenpfleger, Hebamme, Heilerziehungspfleger,  ", 20, 215);
    doc.text("       Kinderkrankenschwester, OP-Schwester, Operationstechnische Assistentin (OTA), ", 20, 220);
    doc.text("       Pflegeassistent, Pflegedienstleitung, Stationsleitung, Study Nurse)", 20, 225);
    doc.text("-      Altenpflege (Altenpflegehelfer, Altenpfleger, Betreuungskraft, Fachaltenpfleger , ", 20, 235);
    doc.text("       Hauswirtschafterin, Pflegedienstleitung)", 20, 240);
    doc.text("-      Medizintechnik (Medizininformatiker, Medizinisch-technische ", 20, 250);
    doc.text("       Laboratoriumsassistentin (MTLA), Medizintechnik-Ingenieur, Medizintechniker, ", 20, 255);
    doc.text("       MTA Funktionsdiagnostik (MTAF), MTA Radiologie (MTRA), Zahntechniker)", 20, 260);
    doc.text("-      Praxispersonal (Medizinische Fachangestellte, Praxismanager, Zahnmedizinische ", 20, 270);
    doc.text("       Fachangestellte, Tiermedizinische Fachangestellte)", 20, 275);
    doc.text("-      Rettungsdienst (Rettungsassistent, Rettungshelfer, Rettungssanitäter)", 20, 285);

    doc.addPage("l");
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("-      Therapeut (Ergotherapeut, Erzieher, Heilpraktiker, Kinderpfleger, Kunsttherapeut, ", 20, 20);
    doc.text("       Logopäde, Masseur/medizinischer Bademeister, Motopäde, Musiktherapeut, ", 20, 25);
    doc.text("       Orthoptist, Podologe, Physiotherapeut, Psychotherapeut, Psychologe, Sozialpädagoge)", 20, 30);
    doc.text("-      Pharmazie (Apotheker, Pharmakant, Pharmareferent, Pharmazeutisch-kaufmännische ", 20, 40);
    doc.text("       Angestellte (PKA), Pharmazeutisch-technische Assistentin (PTA) )", 20, 45);
    doc.text("-      u.ä. medizinische Stellungen und Tätigkeiten.", 20, 55);
    doc.text("III) Vermittlungsnehmer sind öffentliche und private: ", 20, 65);
    doc.text("Krankenhäuser, Kliniken, Medizinische Versorgungszentren, Kur- und", 20, 70);
    doc.text("Rehabilitationsanstalten, Sanatorien, Heilanstalten, Pflegestätten, Praxen, ärztliche ", 20, 75);
    doc.text("Niederlassungen und ähnliche medizinische Einrichtungen.", 20, 80);
    // doc.text("IV) Als Festanstellungen gelten grundsätzlich alle sozialversicherungspflichtigen ", 20, 90);
    // doc.text("Beschäftigungsverhältnisse, unabhängig von deren zeitlichen Ausgestaltung und Umfang, ", 20, 95);
    // doc.text("auch Aushilfstätigkeiten und/oder geringfügige Tätigkeiten.", 20, 100);
    // doc.text("V) Die für Vermittlungssuchende und Vermittlungsnehmer verwandten Kurzformen „VS“ und ", 20, 110);
    // doc.text("„VN“ erstrecken sich im Rahmen der vorliegenden Vereinbarung sowie innerhalb der gemäß ", 20, 115);
    // doc.text("Ziffer 9 einbezogenen Allgemeinen Geschäftsbedingungen sowohl auf einzelne natürliche ", 20, 120);
    // doc.text("Personen, als auch auf Personenmehrheiten und deren Mitglieder sowie auf juristische ", 20, 125);
    // doc.text("Personen und deren Leitungsorgane gleichermaßen.", 20, 130);
    doc.setFontSize(12);
    doc.setFont("times bew roman", "bold");
    doc.text("1. Vertragsgegenstand", 20, 90);
    doc.setFont("times new roman", "normal");
    doc.text("Der VS erteilt der VA den Auftrag, im Sinne der zur Kenntnis genommenen Allgemeinen ", 20, 95);
    doc.text("Geschäftsbedingungen der VA, insoweit tätig zu werden, als sich die VA auftrags- bzw.  ", 20, 100);
    doc.text("bestimmungsgemäß um die Suche entsprechender VN bemüht und dem VS in Frage ", 20, 105);
    doc.text("kommende Angebote übermittelt.", 20, 110);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("2. Dienstleistung  ", 20, 120);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Der VS erklärt sich ausdrücklich damit einverstanden, dass es sich bei der Tätigkeit der ", 20, 125);
    doc.text("VA um eine Dienstleistung handelt, d.h., dass die VA ausdrücklich keinen ", 20, 130);
    doc.text("Vermittlungserfolg schuldet, sondern sich lediglich um die Unterbreitung von ", 20, 135);
    doc.text("Vermittlungsvorschlägen auf Basis bestmöglicher Übereinstimmung von Angebot des ", 20, 140);
    doc.text("VN und Gesuch des VS bemüht. Ein Anspruch auf die Unterbreitung von ", 20, 145);
    doc.text("Vermittlungsvorschlägen besteht nicht.", 20, 150);

    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("3. Vergütung", 20, 160);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Die beauftragte Tätigkeit der VA ist im Verhältnis zum VS kostenlos.", 20, 165);

    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("4. Eignung des VS / Haftung", 20, 175);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("4.1 Der VS ist für seine individuelle Eignung zur Ausübung der zu vermittelnden Tätigkeit in ", 20, 180);
    doc.text("allen Belangen selbst verantwortlich. Die VA überprüft insoweit nicht die persönlichen und ", 20, 185);
    doc.text("beruflichen (insbesondere behördlichen, strafrechtlichen, versicherungsrechtlichen, ", 20, 190);
    doc.text("steuerrechtlichen) Voraussetzungen des VS.", 20, 195);
    doc.text("4.2 Die VA übernimmt nicht die Haftung für Echtheit und Inhalt von Dokumenten und", 20, 205);
    doc.text("sonstigen Unterlagen des VS.", 20, 210);
    doc.text("4.3 Der VS bestätigt im Rahmen der vorliegenden Vereinbarung ausdrücklich, dass eine ", 20, 220);
    doc.text("entsprechende Überprüfung seiner persönlichen Eignung keinesfalls Gegenstand des ", 20, 225);
    doc.text("vorliegenden Vertrages ist. Insofern besteht auch keinerlei Auskunftspflicht auf Seiten ", 20, 230);
    doc.text("des VS gegenüber der VA.", 20, 235);

    //doc.addPage("l");
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("5. Eignung des VN / Haftung", 20, 245);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("5.1 Die VA hat gegenüber dem VS keinerlei Überprüfungs- und Nachforschungsverpflichtung  ", 20, 250);
    doc.text("hinsichtlich der jeweiligen Angaben des VN. Gleiches gilt für das Vorliegen jedweder ", 20, 255);
    doc.text("Voraussetzungen (insbesondere versicherungsrechtlicher Art und hinsichtlich behördlicher", 20, 260);
    doc.text("Genehmigungen) zur Beschäftigung vom Personal im Gesundheitswesen auf Seiten des", 20, 265);
    doc.text("VN sowie für die konkrete Art und Ausgestaltung einer vertraglichen Beziehung zwischen dem ", 20, 270);
    doc.text("VS und dem VN und den sich daraus ergebenden rechtlichen Folgen.", 20, 275);
    doc.addPage("l");

    doc.text("5.2 Die VA übernimmt keine Haftung für die Richtigkeit jeglicher Angaben des VN.", 20, 20);
    doc.text("5.3 Der VS bestätigt im Rahmen der vorliegenden Vereinbarung ausdrücklich, dass eine ", 20, 30);
    doc.text("Überprüfung etwaig erforderlicher Voraussetzungen auf Seiten des VN nicht Gegenstand ", 20, 35);
    doc.text("der Beauftragung der VA ist.", 20, 40);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("6. Vertrags- und Vermittlungsabschluss", 20, 50);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Der VS verpflichtet sich ausdrücklich, die VA unverzüglich und vollständig über den ", 20, 55);
    doc.text("Abschluss eines Vertrages mit einem von der VA vorgeschlagenen bzw. vermittelten VN ", 20, 60);
    doc.text("in Kenntnis zu setzen. Dies gilt auch, wenn und soweit eine vertragliche Beziehung ", 20, 65);
    doc.text("zwischen dem VS und dem durch die VA erstmals vermittelten VN innerhalb eines ", 20, 70);
    doc.text("Zeitraumes von 24 Monaten nach Kündigung des Vertrages i.S.d. Ziffer 8 zustande ", 20, 75);
    doc.text("kommen sollte.", 20, 80);
    // doc.text("eines Zeitraumes von 24 Monaten nach Kündigung des Vertrages i.S.d. Ziffer 8 zustande ", 20, 125);
    // doc.text("kommen sollte.", 20, 130);

    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("7. Störung / Beendigung / Nichtzustandekommen des Vermittlungserfolges", 20, 90);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("7.1 Für eine Störung oder Verletzung vertraglicher Pflichten in dem Vertragsverhältnis", 20, 95);
    doc.text("zwischen VS und VN übernimmt die VA keinerlei Haftung, ebenso wenig für eine ", 20, 100);
    doc.text("Auflösung, Kündigung oder sonstige Beendigung der vermittelten Vertragsbeziehung. ", 20, 105);
    doc.text("Dieser Haftungsausschluss gilt auch für eventuelle Schäden und Aufwendungen, die dem ", 20, 110);
    doc.text("VS und/oder dem VN im Zusammenhang mit Vertragsstörungen oder der ", 20, 115);
    doc.text("Vertragsbeendigung entstehen.", 20, 120);
    doc.text("7.2 Ebenso wenig übernimmt die VA die Haftung für ein Nichtzustandekommen eines", 20, 130);
    doc.text("Vermittlungserfolges und der sich daraus eventuell zu Lasten des VS oder des VN ", 20, 135);
    doc.text("ergebenden Schäden und/oder nutzlosen Aufwendungen, es sei denn, das ", 20, 140);
    doc.text("Nichtzustandekommen des Vermittlungserfolges ist auf ein vorsätzliches oder grob", 20, 145);
    doc.text("fahrlässiges Verhalten der VA zurückzuführen.", 20, 150);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("8. Beendigung des Vermittlungsvertrages", 20, 160);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("8.1 Der Vermittlungsvertrag endet spätestens mit dem Zustandekommen eines ", 20, 165);
    doc.text("Vermittlungserfolges in Form einer vertraglichen Vereinbarung zwischen VS und VN ", 20, 170);
    doc.text("und zwar unabhängig davon, wie diese Vereinbarung inhaltlich oder zeitlich ausgestalt", 20, 175);
    doc.text("ist und auch unabhängig davon, ob die Vereinbarung einer aufschiebenden oder ", 20, 180);
    doc.text("auflösenden Bedingung oder Befristung unterworfen ist.", 20, 185);
    doc.text("8.2 Zuvor können der VS wie auch die VA den vorliegenden Vermittlungsvertrag ", 20, 195);
    doc.text("jederzeit unter Einhaltung einer Frist von einem Monat zum Monatsende ordentlich ", 20, 200);
    doc.text("kündigen. Die beiderseitige Möglichkeit zur außerordentlichen Kündigung unter den ", 20, 205);
    doc.text("entsprechenden gesetzlichen Voraussetzungen bleibt unberührt. Eine Kündigung bedarf ", 20, 210);
    doc.text("in jedem Fall der Textform.", 20, 215);

    //doc.addPage("l");
    doc.text("8.3 Die Vertragsbeendigung durch Eintreten des Vermittlungserfolges oder Kündigung ", 20, 225);
    doc.text("des Vermittlungsvertrages hat keinerlei Einfluss auf einen in diesem Zusammenhang ", 20, 230);
    doc.text("entstandenen oder noch entstehenden Vergütungsanspruch der VA gegenüber dem VN. ", 20, 235);
    doc.text("Dies gilt auch im Falle einer wirksamen Schuld- bzw. Verpflichtungsübernahme durch", 20, 240);
    doc.text("den VS. Hiermit erklärt sich der VS ausdrücklich einverstanden.", 20, 245);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.addPage("l");
    doc.text("9. Einbeziehung der AGB", 20, 20);

    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("In die vorliegende Vermittlungsvereinbarung werden ergänzend die Allgemeinen ", 20, 25);
    doc.text("Geschäftsbedingungen der VA ausdrücklich einbezogen. Diese sind dem VS zur ", 20, 30);
    doc.text("Kenntnisnahme in ihrer aktuellen Fassung zur Verfügung gestellt worden und der VS ", 20, 35);
    doc.text("bestätigt sein Einverständnis mit deren Geltung durch seine Unterschrift unter diese ", 20, 40);
    doc.text("Vereinbarung.", 20, 45);
    doc.text(`Saarbrücken, ${C1}                                                                ${S1}, ${S2}`, 20, 55);
    doc.text("Ort, Datum                                                                                      Ort, Datum", 20, 60);
    doc.addImage("/sign.png", "JPEG", 20, 65, 40, 20);
    doc.addImage(`https://hello-sister.de/uploads/${S3}`, "JPEG", 140, 65, 40, 20);
    doc.addImage("/stamp.png", "JPEG", 20, 85, 40, 20);

    doc.text("Unterschrift VA                                                                               Unterschrift VS", 20, 110);
    doc.text("                                                                                                         Stand: 24.10.2022", 20, 285);
    doc.save("Vertrag.pdf");

  }
  componentWillMount() {
    const user_id = localStorage.getItem("user_id")
    //  console.log(user_id)
    this.setState({ user_id })
  }

  PDFGenerate = async(job_company, job_address, job_haus, job_post_code, job_city, andre, f_name, l_name, haus_no, post_code, applicant_city,
    app_sign_city, app_sign_date, app_sign, contract_date_candid, contract_id) => {

      const toDataUrl = async function (url, callback) {
        //Convert to base64
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.send();
        });
      };

      let urlBase64 = await toDataUrl(app_sign);
     // console.log(urlBase64)

   // console.log(contract_id)
    axios
      .post('/api2/GetEditorContractCandidate_single_download', { ...this.state, contract_id })
      .then(response => {
        //console.log(response)
        const value = response.data.result[0].EditorDesc;
        //console.log(value)
        this.setState({ value })
        //this.setState({adduser_err:undefined})
        //   var adduser_succ = response.data.msg;
        //   this.setState({ adduser_succ })



        //  console.log(this.state.value)
        //console.log("second")
        //console.log(app_sign)
        const Contract = ReactDOMServer.renderToString(<div style={{ fontFamily: 'Arial', color: "black" }}>
          <Row>
            <Col md="9">   <p style={{ fontFamily: 'Arial', fontSize: '12px', color: 'black', marginBottom: '30px' }}>
              Gül GmbH, An der Trift 40, 66123 Saarbrücken, Deutschland<br></br>
              AG Saarbrücken, HRB 100749, USt.IdNr: DE286297909, GF: Deniz Gül<br></br>
              T: +49 (681) 68 63 53 74, E: kunden@guel.gmbh, W: www.hello-sister.de<br></br>
            </p></Col>
            <Col md="3">
              <img src="/img2/logo_color.png" width="150" style={{ marginTop: '10px' }} />
            </Col>
          </Row>
          <b><center>VERMITTLUNGSVEREINBARUNG</center></b>
          <br></br>
          Zwischen der Gül GmbH, vertreten durch den Geschäftsführer Deniz Gül, An der Trift 40, 66123 Saarbrücken<br></br>
          <br></br>
          - nachfolgend: „Vermittlungsagentur“ bzw. „VA“ -
          <br></br>
          <br></br>
          und
          <br></br>
          <br></br>
          <b>{andre} {f_name} {l_name}, {haus_no}, {post_code} {applicant_city}</b>
          <br></br>
          <br></br>
          - nachfolgend: „Vermittlungssuchender“ bzw. „VS“ -
          <br></br>
          <br></br>
          <br></br>
          wird nachfolgende Vereinbarung zum Zwecke der Vermittlung einer Anstellung mit der Einrichtung <b>{job_company}, {job_address} {job_haus}, {job_post_code} {job_city}.</b> getroffen:
          <br></br>
          <div
            style={{ fontFamily: 'times new roman', color: "black" }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(value),
            }}
          >
          </div>
          <Row>
            <Col md='8'><b style={{ fontSize: '14px' }}>Saarbrücken, {contract_date_candid}</b>
            </Col>
            <Col md='4'><b style={{ fontSize: '14px' }}>{app_sign_city}, {app_sign_date}</b>
            </Col>
          </Row>
          <Row>
            <Col md='8'> <u style={{ fontSize: '14px' }}>Ort, Datum</u>	</Col>
            <Col md='4'> <u style={{ fontSize: '14px' }}>Ort, Datum</u>	</Col>

          </Row>
          <Row>
            <Col md="8"> <img src="/sign.png" width="150" /></Col>
            <Col md="4"> <img src={urlBase64} width="150" /></Col>
          </Row>
          <Row>
            <Col md="8"><img src="/stamp.png" width="150" /></Col>
          </Row>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <img src="/sign.png" width="150" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <img src="/stamp.png" width="150" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src="/sign.png" width="150" />
      </div> */}
          <Row>
            <Col md='8'> <u style={{ fontSize: '14px' }}>Unterschrift VA</u>	</Col>
            <Col md='4'> <u style={{ fontSize: '14px' }}>Unterschrift VS</u>	</Col>

          </Row>
        </div>)


        var opt = {
          margin: 0.5,
          filename: f_name + '_vertrag.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
        //html2pdf(element);
        html2pdf().set(opt).from(Contract).save();

        // var doc = new jsPDF("p", "pt", "a4");
        // doc.html(document.querySelector("#print_element"), {
        //   callback: function (pdf) {
        //     pdf.save("mypdf.pdf");
        //   }
        // })
      })
      .catch(error => {
        console.log(error.response);
        //   this.setState({adduser_succ:undefined})
        //   var adduser_err = error.response.data.msg
        //   this.setState({ adduser_err })
      })
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.componentDidMount()
    });

  };
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  saveCommision = e => {
    console.log(this.state.applyjob_id)
    console.log(this.state.kjdate)
    console.log(this.state.cdate)
    var cdate_err;
    var kjdate_err;
    if (this.state.cdate === null) {
      cdate_err = "please select"
      this.setState({ cdate_err })
    } else {
      this.setState({ cdate_err: undefined })
    }
    if (this.state.kjdate === null) {
      kjdate_err = "please select"
      this.setState({ kjdate_err })
    } else {
      this.setState({ kjdate_err: undefined })
    }
    if (kjdate_err === undefined && cdate_err === undefined) {
      axios
        .post('/api2/savecommision', this.state)
        .then(response => {
          console.log(response)
          const msg = "Sie akzeptieren die Anfrage";
          this.setState({ msg })
          window.location.reload();
        })
        .catch(error => {
          console.log(error.response);
        })
    }

  }
  componentDidMount() {
    axios
      .get('/api2/candidatecontract')
      .then(response => {
        console.log(response)
        const cand = response.data;
        const slice = cand.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = slice.map(item => <React.Fragment>
          <Card style={{ backgroundColor: '#f5f5f5' }} key={item.job_id}>

            <CardBody>
              <Row>
                <Col md='2'>
                  {item.picture === null || item.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
                    alt="..."
                    className="avatar border-gray"
                    src={`https://hello-sister.de/uploads/${item.picture}`}


                  />}

                </Col>

                <Col md='4'><h7 className="text-left"   >

                  <Link to={`/admin/detail-kandidate/${item.app_id}`} target="_blank" rel="noopener noreferrer">
                    {/* {item.salutation} {" "} */}
                    {/* {item.title === 'Kein' ? null : item.title} */}
                    {item.f_name} {item.l_name}
                  </Link>
                  <br></br>
                  <i class="fas fa-map-marker-alt">

                  </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.applicant_house} , {item.post_code} {item.applicant_city}, &nbsp; {item.country}</h7>
                  <br></br>
                  {item.app_sign !== null ? <>
                    {item.joining_date === null && item.commision_date === null ?
                      <>
                        <Button
                          onClick={() => this.setState({
                            applyjob_id: item.applyjob_id, andre:
                              item.andre, f_name: item.f_name, l_name: item.l_name
                          }, this.toggle)}
                        >
                          Commision settings
                        </Button>
                      </>
                      : null}</>
                    :
                    null

                  }
                </h7>



                </Col>
                <Col md='4'>
                  <h7 className="text-left">{item.app_contract}<br />
                    {item.contract_date_candid}</h7>    </Col>
                <Col md='2'>
                  <td className="text-left" style={{ fontSize: '12px' }}>
                    {item.app_contract === 'Vertrag erstellt für Kandidaten' ?
                      <Button size="md" disabled >
                        Vertrag Erstellen
                      </Button>
                      :
                      <span>
                        {item.app_sign === null ? <Link to={`/admin/KandidateContractPage/${item.applyjob_id}`} >
                          <Button
                            color="success"
                            size="md"
                          >
                            Vertrag Erstellen
                          </Button>
                        {/* </Link> : <Button color="primary" onClick={() => this.JsPdfGenerator(
                          item.company_name, item.address, item.haus_no, item.zip_code, item.city,
                          item.f_name, item.l_name, item.applicant_house, item.post_code, item.applicant_city,
                          item.app_sign_city, item.app_sign_date, item.app_sign, item.contract_date_candid
                        )}>Vertrag Herunterladen</Button>} */}

                      </Link> : <Button color="primary" onClick={() => this.PDFGenerate(
                        item.company_name, item.address, item.haus_no, item.zip_code, item.city,
                        item.andre,item.f_name, item.l_name, item.applicant_house, item.post_code, item.applicant_city,
                        item.app_sign_city, item.app_sign_date, `https://hello-sister.de/uploads/${item.app_sign}`, item.contract_date_candid,item.contract_id
                      )}>Vertrag Herunterladen</Button>}
                  </span>
                    }

                  <center> <Link><i class="fas fa-chevron-circle-down" style={{ fontSize: '26px', color: '#4d72d0' }} id={`toggler${item.job_id}` + `${item.app_id}`}

                  ></i></Link>  </center> </td>
              </Col>
            </Row>
            <Row style={{ backgroundColor: '#ffffff' }}>



              <Col md="6">

                {item.commision_date === null ? null :
                  <> <i class="far fa-calendar-check"></i> &nbsp;&nbsp;   <h7 style={{ fontSize: '13px', color: '#336699' }}> Provision erhalten : {item.commision_date} </h7> </>}
              </Col>
              <Col md="6" className="text-right">
                {item.joining_date === null ? null : <>  {item.remaining_months === 6 || item.remaining_months === 5 || item.remaining_months === 4 ?
                  <h7 style={{ fontSize: '13px', color: '#28a745' }}> <i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7>
                  : item.remaining_months === 3 ? <h7 style={{ fontSize: '13px', color: '#FF6600' }}> <i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7>
                    : item.remaining_months === 2 ? <h7 style={{ fontSize: '13px', color: '#FFCC00' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7> :
                      item.remaining_months === 1 ? <h7 style={{ fontSize: '13px', color: '#336699' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7> :
                        item.remaining_months === 0 ? <h7 style={{ fontSize: '13px', color: '#336699' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7> :
                          <h7 style={{ fontSize: '13px', color: '#28a745' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7>
                } </>}
              </Col>
            </Row>

            <div>




              <UncontrolledCollapse toggler={`#toggler${item.job_id}` + `${item.app_id}`}
              >

                <br></br>
                <h7><b>{item.f_name}</b> hat sich auf diese Stellenanzeige beworben.</h7>
                <Card style={{ marginTop: '15px' }}>
                  <CardBody><Row>
                    <Col md='2'>
                      {item.logo === null || item.logo === " " ? null :
                        <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height='80' width='auto' />}
                    </Col>

                    <Col md='8'><h7 className="text-left"     >
                      <h7 style={{ color: '#4287f5', fontSize: '13px' }}><Link to={`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">{item.company_name}</Link></h7>
                      <br></br>
                      <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer">{item.job_function} (m/w/d) {item.profession == 'undefined' ? null : item.profession}

                      </Link>
                      <br></br>
                      <i class="fas fa-map-marker-alt">

                      </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                        {item.zip_code}  {item.city}, {item.state}</h7>
                    </h7>
                      <h5 className="text-left" >

                      </h5></Col>
                    <Col md='2' className="text-right" >
                      <label className="text-ubold">
                        <Badge className="mr-1" color="success">{item.hospital_status}</Badge><br></br>
                        <center><b>{item.action_date}</b></center>
                      </label>
                    </Col>
                  </Row>
                    <br></br>
                    <Row style={{ backgroundColor: '#f5f5f5' }}>

                      <Col md="3">
                        <Time />&nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                      </Col>
                      <Col md="3">
                        <i class="fas fa-hourglass-half"></i>
                        &nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} </h7>
                      </Col>
                      <Col md="3">
                        <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px' }}> Online seit: {item.created_date}</h7>
                      </Col>
                      <Col md="3">
                        <i class="far fa-calendar-alt"></i>

                        &nbsp;&nbsp;
                        <h7 style={{ fontSize: '13px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                      </Col>
                    </Row>

                  </CardBody>

                </Card>





              </UncontrolledCollapse>
            </div>
          </CardBody>
        </Card>

          {/* <tr>
   <td>
                             <label className="text-ubold">
                           {item.hospital_status}<br></br>
                           {item.action_date}
                                    </label>
                            </td> 
                            <td className="text-left">
                           
                            <label className="text-ubold">
                         
                          <Link to = {`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">           
                            <b>{item.company_name}  </b> <br></br>
                        {item.address} {item.haus_no}, {item.zip_code}<br></br>
                        {item.city}  
                        </Link>
 
                                     </label>
                        </td>
                        <td className="text-left">
                        <label className="text-ubold">
                        <Link to = {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer">    {item.job_field} <br></br> 
                       {item.job_field} {item.job_function} <br></br> {item.profession} </Link>   
                                   </label>
                                    </td>

                                    <td className="text-left">
                                    <label className="text-ubold">
                                    <Link to = {`/admin/detail-kandidate/${item.app_id}`} target="_blank" rel="noopener noreferrer">  {item.andre} {item.f_name} {item.l_name} <br></br>
                                    {item.applicant_house}, {item.post_code}<br></br>
                                    {item.applicant_city} <br></br>
                                    {item.applicant_contact}</Link>     
                                  </label>
                        </td>
                        <td>{item.app_contract}<br />
                        {item.contract_date_candid}</td>
                        
                        <td className="text-right">
                        {item.app_contract === 'Vertrag erstellt für Kandidaten' ?
                        <Button  size = "md" disabled >
                          Vertrag Erstellen
                        </Button>
                        :
                        <span>
                        {item.app_sign === null ? <Link to = {`/admin/KandidateContractPage/${item.applyjob_id}`} >
                        <Button  
                            color="success"
                            size = "md"
                          >   
                          Vertrag Erstellen
                          </Button>
                         </Link> : <Button color = "primary"  onClick={ () => this.JsPdfGenerator(
                             item.company_name,item.address,item.haus_no,item.zip_code,item.city,
                             item.andre,item.f_name,item.l_name,item.applicant_house,item.post_code,item.city,
                             item.app_sign_city,item.app_sign_date,item.app_sign,item.contract_date_candid
                         )}>Vertrag Herunterladen</Button>}
                         </span>
    }
          </td>
          <td>{item.app_sign !== null ? <>{item.joining_date === null && item.commision_date === null ? <><Button
                          style = {{marginRight:'-3px'}}
                            className="btn-icon"
                            color="primary"
                            id={"tooltip366246639"+item.applyjob_id}
                            size="sm"
                            type="button"
                            onClick={() => this.setState({ applyjob_id: item.applyjob_id,andre : item.andre,f_name:item.f_name,l_name:item.l_name},this.toggle)}
                            
                          >
                            <i class="far fa-check-square"></i>
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246639"+item.applyjob_id}
                            
                          >
                Commision settings
                          </UncontrolledTooltip></>
              :item.joining_date === null || item.commision_date === null ?<><Button
                          style = {{marginRight:'-3px'}}
                            className="btn-icon"
                            color="primary"
                            id={"tooltip366246639"+item.applyjob_id}
                            size="sm"
                            type="button"
                            onClick={() => this.setState({ applyjob_id: item.applyjob_id,andre : item.andre,f_name:item.f_name,l_name:item.l_name},this.toggle)}
                            
                          >
                            <i class="far fa-check-square"></i>
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246639"+item.applyjob_id}
                            
                          >
                  Commision settings
                          </UncontrolledTooltip><br />
                {item.joining_date !== null ? <p>{item.joining_date}<br /></p> : null}
                {item.commision_date !== null ? <p>{item.commision_date}</p>:null}
              </>
              : <p><small>Commision date : {item.commision_date}</small><br></br>
                {item.remaining_months === 6 || item.remaining_months === 5 || item.remaining_months === 4 ? <p style={{fontSize: '13px', color : '#ff0000'}}>Arbeitsbeginn : {item.joining_date}</p>
                 :item.remaining_months === 3 ? <p style={{fontSize: '13px', color : '#FF6600'}}>Arbeitsbeginn : {item.joining_date}</p>
                    : item.remaining_months === 2 ? <p style={{ fontSize: '13px', color: '#FFCC00' }}>Arbeitsbeginn : {item.joining_date}</p> :
                      item.remaining_months === 1 ? <p style={{ fontSize: '13px', color: '#336699' }}>Arbeitsbeginn : {item.joining_date}</p> :
                        item.remaining_months === 0 ? <p style={{ fontSize: '13px', color: '#336699' }}>Arbeitsbeginn : {item.joining_date}</p> :
                   <p style={{fontSize: '13px', color : '#28a745'}}>Arbeitsbeginn : {item.joining_date}</p>
              }
           
              </p>

          }</> : null
                      }
            
            
          </td>
        </tr>
         */}
        </React.Fragment >)

    localStorage.setItem('cand', JSON.stringify(this.state.cand));
    console.log(cand);
    this.setState({ state1: true })
    this.setState({
      pageCount: Math.ceil(cand.length / this.state.perPage),
      postData
    })
  })
      .catch(error => {
    console.log(error);
      })
  }

render() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
               
                <h5 className="cstm-heading-backend"><b>Kandidaten Vertrag</b></h5>
                <hr></hr>
              </CardHeader>
              <CardBody>


                {this.state.postData}

                <br></br>
                {this.state.state1 ? <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} /> : null}

                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
                >
                  <ModalHeader toggle={this.toggle}>Commision Settings</ModalHeader>
                  <ModalBody>

                    <b> {this.state.andre} {this.state.f_name} {this.state.l_name}</b><br></br><br></br>
                    {/* <Input
                          style={{fontSize: '15px', height: '35px', backgroundColor: '#f1f1f1'}}
                          type="Text"
                          autoComplete="off"
                          value = {this.state.hosp_name}
                          name="hosp_name"
                          onChange={this.changeHandler}
                          required="Name des Unternehmens / Firma"
                        /> */}
                    Kandidate Arbeitsbeginn:<Badge color="warning">{this.state.kjdate_err}</Badge> <Input type="date" onChange={this.changeHandler} autoComplete="off" name="kjdate"></Input><br />
                    Provision erhalten:<Badge color="warning">{this.state.cdate_err}</Badge><Input type="date" onChange={this.changeHandler} autoComplete="off" name="cdate"></Input><br />
                    <br></br>
                    <center><Button
                      onClick={this.toggle}
                      style={{
                        width: 'auto',
                        height: '32px',
                        fontSize: '9px',
                        borderRadius: '32px',
                        boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                        fontWeight: '400',
                        border: 'none !important',
                        padding: '9px 27px',
                        // background: '#dc3545',
                        marginBottom: '1rem'
                      }}>Abbrechen</Button>
                      <Button
                        color="primary"
                        style={{
                          width: 'auto',
                          height: '32px',
                          fontSize: '9px',
                          borderRadius: '32px',
                          boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                          fontWeight: '400',
                          border: 'none !important',
                          padding: '10px 27px',
                          background: '#dc3545'
                        }}
                        onClick={this.saveCommision}
                      >

                        Bestätigen </Button></center>

                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>


          </Col>
        </Row>
      </div>
    </>
  );
}
}

export default Imprint;
