import React from "react";
import axios from 'axios';
import { Redirect, Link } from "react-router-dom";
import Time from "@material-ui/icons/Timer";
import ReactPaginate from 'react-paginate';
import "./style1.css";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Badge,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Input,
  CardHeader,
  Progress
} from "reactstrap";

//import data
import { ToastContainer, toast } from 'react-toastify';



class CandidateProfile1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [""],
      buttonToRender: 4,
      horizontalTabs: "details",
      data1: [],
      lang: [],
      doc: [],
      exp: [],
      edu: [],
      skills: [],
      t_skills: [],
      notedata: [],
      visible1: true,
      visible6: true,
      visible7: true,
      visible2: true,
      visible3: true,
      visible4: true,
      visible5: true,
      visible8: true,
      visible9: true,
      visible10: true,
      visible11: true,
      visible12: true,
      visible13: true,
      note: '',
      data: [],
      data4: [],
      data2: [],
      data3: [],
      data5: [],
      data6: [],
      offset1: 0,
      perPage1: 10,
      currentPage1: 0,
      offset: 0,
      perPage: 20,
      currentPage: 0,
      postData1Show: true,
      jobSearch: true,
      levelSelect: null,
      selectedFile: null,
      doc_title: '',
      recordsDoc: [],
      error: '',
      loaded: 0,
      tableShowDocs: true,
      theInputKey: "",
    };
  }
  handleMoreClick = () => {
    this.setState({
      buttonToRender: (this.state.buttonToRender + 2)
    });
  };
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  closeHandler(jobid, appid, e) {
    e.preventDefault();
    //console.log("close called",jobid,appid)
    axios.post('/api2/closematchjob', { ...this.state, jobid, appid })
      .then(response => {
        //console.log(response)
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
      })
      .catch(error => {
        //console.log(error.response);
      })
  }
  deleteHandler(noteid, e) {
    //  // e.preventDefault();
    //console.log("delete called",noteid)
    axios.post('/api2/deljob', { ...this.state, noteid })
      .then(response => {
        //console.log(response)
        window.location.reload()
      })
      .catch(error => {
        //console.log(error.response);
      })
  }


  checkFileSize = (e) => {
    let files = e.target.files
    let size = 10 * 1024 * 1024
    let err = "";
    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err += files[x].type + ' ist zu groß, bitte wählen Sie eine kleinere Datei. Maximal 10 MB\n';
      }
    };
    if (err !== '') {
      e.target.value = null
      //console.log(err)
      toast.error(err)
      return false
    }

    return true;

  }

  checkMimeType = (e) => {
    //getting file object
    let files = e.target.files
    //define message container
    let err = ''
    // list allow mime type
    const types = ['image/gif', "image/png", "image/jpg", "image/jpeg", "image/bmp", "application/vnd.ms-powerpoint", "image/svg+xml", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.oasis.opendocument.presentation", "application/vnd.oasis.opendocument.text", "application/rtf"]
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every(type => files[x].type !== type)) {
        // create error message and assign to container   
        err += files[x].type + ' ist kein unterstütztes Format. Sie können nur Fotos oder Dokumente in folgenden Formaten hochladen: .jpeg, .png, .gif, .pdf, .docx';
      }
    };

    if (err !== '') { // if message not same old that mean has error 
      e.target.value = null // discard selected file
      console.log(err)
      toast.error(err)
      return false;
    }
    return true;

  }

  changeHandler = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  fileHandlerDoc = e => {
    e.preventDefault();
    var files = e.target.files[0]

    if (this.checkMimeType(e) && this.checkFileSize(e)) {
      this.setState({
        file: files,
        loaded: 0,
        //[e.target.name]: e.target.value 
      })
    }
  }

  submitHandlerDoc = e => {
    e.preventDefault();
    const { selectedFile, doc_title } = this.state
    //console.log("asgdgasd",this.state.file,this.state.doc_title)
    if (this.state.file === null || this.state.doc_title === '') {
      const msg = "BITTE WÄHLEN SIE EINE DATEI AUS UND VERGEBEN SIE EINEN DOKUMENTENTITEL";
      this.setState({ msg })
    } else {
      const data = new FormData()
      data.set('doc_title', this.state.doc_title)
      data.set('user_id', this.state.candidate_user_id)
      data.append('file', this.state.file)
      console.log(data)
      //console.log(this.state)
      axios.post("/api2/documents", data, {
        onDownloadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total * 100)
          })
        }
      }

      )
        .then(response => { // then print response status
          toast.success('erfolgreich hochgeladen')
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 1000);
          // const msg = res.data.Message;
          this.setState({ msg: undefined });
          this.setState({ doc_title: '' })
          let randomString = Math.random().toString(36);
          this.setState({
            theInputKey: randomString
          });
          // e.target.value = null
          //console.log(response)
          const docsucc = response.data.message;
          this.setState({ docsucc })
          if (this.state.horizontalTabs === "Docs") {

          }
          //window.location.reload();
        })
        .catch(error => {
          //console.log(error);
          const msg = error.response.data.msg;
          this.setState({ msg })
        })
    }
  }

  deleteHandleDoc(docid, e) {
    //alert(id);
    //console.log(docid);
    this.setState({ docid });
    //console.log(this.state);
    axios
      .post('/api2/deldoc', { ...this.state, docid })
      .then(response => {
        //console.log(response)
        const docsucc = response.data.Message;
        this.setState({ docsucc })
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 1000);
        //window.location.reload();
        // const WorkExp1 = response.data;
        // this.setState({ WorkExp1 });
      })
      .catch(error => {
        //console.log(error)
      })
  }

  componentDidMount() {
    const roles = localStorage.getItem('roles')
    this.setState({ roles })
    console.log(roles);
    //console.log(this.props.match.params)
    const { app_id } = this.props.match.params;
    //console.log(app_id)
    this.setState({ app_id })
    axios.post("/api2/appliedcandid", { ...this.state, app_id })
      .then(response => {
        console.log(response);
        const data1 = response.data.result1;
        const candidate_user_id = data1[0].user_id;

        this.setState({ candidate_user_id })
        //console.log(candidate_user_id);
        this.setState({ data1 });
        const notedata = response.data.note;
        //console.log(notedata);
        this.setState({ notedata });
        localStorage.setItem('data1', JSON.stringify(this.state.data1));
        //console.log({data1});

        const jobField = data1[0].job_field;
        this.setState({ jobField })
        //console.log({jobField})

        if (jobField === 'Altenpflege') {
          this.setState({ visible1: false });
        }
        else if (jobField === 'Therapeut') {
          this.setState({ visible1: false });
        }
        else if (jobField === 'Medizintechnik') {
          this.setState({ visible1: false });
        }
        else if (jobField === 'Rettungsdienst') {
          this.setState({ visible1: false });
        }
        else if (jobField === 'Praxispersonal') {
          this.setState({ visible1: false });
        }
        else if (jobField === 'Pharmazie') {
          this.setState({ visible1: false });
        }
        else if (jobField === 'Krankenpflege') {
          this.setState({ visible1: true });
        }
        else if (jobField === 'Arzt') {
          this.setState({ visible1: true });
        }
        if (jobField === 'Altenpflege') {
          this.setState({ visible6: false, visible7: true });
        }
        else if (jobField === 'Therapeut') {
          this.setState({ visible6: false, visible7: true });
        }
        else if (jobField === 'Medizintechnik') {
          this.setState({ visible6: false, visible7: true });
        }
        else if (jobField === 'Rettungsdienst') {
          this.setState({ visible6: false, visible7: true });
        }
        else if (jobField === 'Praxispersonal') {
          this.setState({ visible6: false, visible7: true });
        }
        else if (jobField === 'Pharmazie') {
          this.setState({ visible6: false, visible7: true });
        }
        else if (jobField === 'Krankenpflege') {
          this.setState({ visible6: false, visible7: true });
        }
        else if (jobField === 'Arzt') {
          this.setState({ visible6: true, visible7: false });
        }

        if (this.state.data1[0].job_worktime === 'none') {
          this.setState({ visible9: false });
        }
        else {
          this.setState({ visible9: true });
        }

        if (this.state.data1[0].job_worktime2 === 'none') {
          this.setState({ visible10: false });
        }
        else {
          this.setState({ visible10: true });
        }

        if (this.state.data1[0].job_ternure === 'none') {
          this.setState({ visible2: false });
        }
        else {
          this.setState({ visible2: true });
        }

        if (this.state.data1[0].job_ternure1 === 'none') {
          this.setState({ visible3: false });
        }
        else {
          this.setState({ visible3: true });
        }

        if (this.state.data1[0].sector === 'none') {
          this.setState({ visible4: false });
        }
        else {
          this.setState({ visible4: true });
        }

        if (this.state.data1[0].sector1 === 'none') {
          this.setState({ visible5: false });
        }
        else {
          this.setState({ visible5: true });
        }

        if (this.state.data1[0].sector2 === 'none') {
          this.setState({ visible8: false });
        }
        else {
          this.setState({ visible8: true });
        }

        const profileStatus = data1[0].profile_status;
        this.setState({ profileStatus })
        //console.log({profileStatus})
        if (profileStatus === 2) {
          this.setState({ visible11: false, visible12: true })
        }
        else {
          this.setState({ visible11: true, visible12: false })
        }
        const skills = response.data.skills;
        //console.log("skills",skills);
        this.setState({ skills });
        localStorage.setItem('skills', JSON.stringify(this.state.skills));
        //console.log(this.state.skills)
        const lang = response.data.languages;
        //console.log(lang);
        this.setState({ lang });
        localStorage.setItem('lang', JSON.stringify(this.state.lang));
        //console.log(this.state.lang)
        const doc = response.data.documents;
       // console.log(doc);
        this.setState({ doc });
        localStorage.setItem('doc', JSON.stringify(this.state.doc));
        //console.log(this.state.doc)
        const edu = response.data.education;
        //console.log(edu);
        this.setState({ edu });
        localStorage.setItem('edu', JSON.stringify(this.state.edu));
        //console.log(this.state.edu)
        const exp = response.data.experience;
        //console.log(exp);
        this.setState({ exp });
        localStorage.setItem('exp', JSON.stringify(this.state.exp));
        //console.log(this.state.exp)
        const t_skills = response.data.t_skills;
        //console.log(exp);
        this.setState({ exp });
      })
      .catch(error => {
        //console.log(error);
      })   //////////////////////////////////////////////////
    axios.post('/api2/jobsbysearch', { ...this.state, app_id })
      .then(response => {
        //console.log(response)
        const data2 = response.data
        // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        this.setState({ data2 });
        this.setState({
          jobSearch: true
        });
        //console.log(data2)
      })
      .catch(error => {
        //console.log(error.response);
        this.setState({ jobSearch: false });

      })
    //////////////////////////////////
    axios.post('/api2/jobsbyadmin', { ...this.state, app_id })
      .then(response => {
        //console.log(response)
        const data5 = response.data
        // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        this.setState({ data5 });
        //console.log(data5)
      })
      .catch(error => {
        //console.log(error.response);
      })
    ///////////////////////////
    axios.get('/api2/getnotes2')
      .then(response => {
        //console.log(response)
        const data3 = response.data
        // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        this.setState({ data3 });
        //console.log(data3)
      })
      .catch(error => {
        //console.log(error.response);
      })
    ///////////////////////////////////////////////
    axios.post('/api2/jobsbystellen', { ...this.state, app_id })
      .then(response => {
        //console.log(response)
        const data = response.data.jobs;
        this.setState({ data });
        //console.log(data)
        ////////////
        const data4 = response.data.appliedjobs;
        // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        this.setState({ data4 });
        //console.log(data4) 
        const slice1 = data.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
        const postData1 = slice1.map(item => <React.Fragment>
          <Card style={{ backgroundColor: '#f5f5f5', padding: '10px' }} >

            <Row>
              <Col md='2'>
                <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height='100' width='auto' />
              </Col>

              <Col md='5'>
                {this.state.roles === 'admin' ?
                  <> <h7 style={{ color: '#4287f5', fontSize: '10px' }}><Link to={`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">{item.company_name}</Link></h7><br></br></>
                  :
                  null}


                <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`}>
                  {item.job_field} {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}
                </Link>

                <br></br>
                {this.state.data4.map(item1 => {
                  return (
                    <>
                      {item.job_id === item1.job_id ?
                        <span>
                          <Badge className="mr-1" color="success" pill>
                            {item1.candidate_status} am {item1.action_date}
                          </Badge>
                        </span>
                        :
                        null
                      }
                    </>
                  )
                })} <br></br>

                <Link to={`/admin/notes1/${item.job_id}/${this.state.app_id}`}
                  target="_blank" rel="noopener noreferrer"> <Badge className="mr-1" color="primary" >Notizen hinzufügen</Badge></Link>

                {this.state.data3.map(item1 => {
                  return (
                    <>
                      {item.job_id === item1.job_id ?
                        <span>
                          <Badge className="mr-1" color="success" pill>
                            {item1.cnt} Notizen
                          </Badge><br />
                        </span>
                        :
                        null
                      }
                    </>
                  )
                })}

              </Col>

              <Col md='3' className="text-left">
                {this.state.data4.map(item1 => {
                  return (
                    <>
                      {item.job_id === item1.job_id ?
                        <h7>
                          <br />
                          {item1.hosp_contract} {item1.contract_date_hosp}<br /><br />
                          {item1.app_contract} {item1.contract_date_candid}
                        </h7>
                        :
                        null
                      }
                    </>
                  )
                })}

              </Col>
              <Col md='2' className="text-right">

                <b>Gesendet am <br></br><Badge className="mr-1" color="success">{item.sent_date} </Badge> </b>

                <h7>{item.match_status}</h7>
                {this.state.roles === 'admin' ?
                  <>
                    <br></br>
                    <Button
                      className="btn-icon"
                      color="dark"
                      id={"tooltip366246640" + item.job_id}
                      size="sm"
                      type="button"
                      onClick={this.closeHandler.bind(this, item.job_id, this.state.app_id)}
                    >
                      <i className="fa fa-toggle-off" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246640" + item.job_id}
                    >
                      Close
                    </UncontrolledTooltip>
                  </>
                  :
                  null}
              </Col>
            </Row>
            <br></br>
            <Row style={{ backgroundColor: '#ffffff', marginTop: '-10px' }}>

              <Col md="3">
                <i class="far fa-clock"></i>
                &nbsp;&nbsp;
                <h7 style={{ fontSize: '11px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
              </Col>
              <Col md="3">
                <i class="fas fa-hourglass-half"></i>
                &nbsp;&nbsp;
                <h7 style={{ fontSize: '11px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null}
                  {/* {item.job_ternure2 !== null ? ',' + item.job_ternure2 : null} */}
                </h7>
              </Col>
              <Col md="3">
                <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                <h7 style={{ fontSize: '11px' }}> Online seit: {item.created_date}</h7>
              </Col>
              <Col md="3">
                <i class="far fa-calendar-alt"></i>

                &nbsp;&nbsp;
                <h7 style={{ fontSize: '11px' }}>Online bis: {item.valid_till} </h7>



              </Col>
            </Row>
            <div>
            </div>

          </Card>

        </React.Fragment>)
        localStorage.setItem('data', JSON.stringify(this.state.data));
        //console.log(data);
        this.setState({
          state2: true
        })
        this.setState({
          pageCount1: Math.ceil(data.length / this.state.perPage1),

          postData1
        })
        this.setState({
          state2: false,
          postData1Show: true
        })
      })
      .catch(error => {
        //console.log(error.response);
        this.setState({
          postData1Show: false
        })
      })
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    axios.post('/api2/userrefrals', { ...this.state, app_id })
      .then(response => {
        console.log(response)
        const data6 = response.data
        //localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        this.setState({ data6 });
        //console.log(data5)
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  componentWillMount() {
    const applicant = JSON.parse(localStorage.getItem('applicant'))
    this.setState({ applicant })
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    this.setState({ user_id })
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ user })
  }
  componentWillUnmount() {
    localStorage.removeItem("applicant")
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  closeHandler1(jobid, appid, e) {
    //  // e.preventDefault();
    //console.log("close called",appid,jobid)
    axios.post('/api2/closematchjob1', { ...this.state, appid, jobid })
      .then(response => {
        //console.log(response)
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        //window.location.reload();
      })
      .catch(error => {
        //console.log(error.response);
      })
  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  submitHandler = e => {
    //console.log(this.state)
    if (this.state.note === "") {
      const note_err = "Hinweis ist leer"
      this.setState({ note_err })
    } else {
      this.setState({ note_err: undefined })
      axios
        .post('/api2/addnote', this.state)
        .then(response => {
          //console.log(response)
          const note_msg = response.data.msg;
          this.setState({ note_msg })
          window.location.reload();
        })
        .catch(error => {
          //console.log(error)
        })
    }
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  deletenoteHandle(noteid, e) {
    //console.log("note id",noteid)
    axios
      .post('/api2/delnote', { ...this.state, noteid })
      .then(response => {
        //console.log(response)
        const note_msg = response.data.msg;
        this.setState({ note_msg })
        window.location.reload();
        // const WorkExp1 = response.data;
        // this.setState({ WorkExp1 });
      })
      .catch(error => {
        //console.log(error)
      })
  }
  handlePageClick1 = (e) => {
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;

    this.setState({
      currentPage1: selectedPage1,
      offset1: offset1
    }, () => {
      this.componentDidMount()
    });

  };
  payRef(refCode, e) {
    console.log(refCode)
    axios
      .post('/api2/payRefBonus', { ...this.state, refCode })
      .then(response => {
        console.log(response)
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
      })
      .catch(error => {
        //console.log(error)
      })
  }
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to='/admin/Dashboard' />;
    }
    return (
      <div className="content">
        <Row className="ml-auto mr-auto justify-content-center">
          <Col md="12">
            <Card className="card-user">

              {this.state.data1.map(user => {
                return (
                  <>
                    <CardBody>
                      <Row>
                        <Col md="12">

                          <Row>
                            <Col md="2"
                              style={{
                                padding: '8px',
                                marginLeft: '29px',
                                marginTop: '10px',
                              }}
                            >
                              {user.picture === null ? null : <span>  <img style={{ width: '100px', height: '100px' }}
                                alt="..."
                                className="avatar border-gray"
                                src={`https://hello-sister.de/uploads/${user.picture}`}

                              //src={require("assets/img/bg/5.jpg")}
                              />
                              </span>}
                            </Col>
                            <Col md="6"
                            >
                              <h5 cl assName="text-primary"><b> 
                                {/* {user.salutation} {" "} */}
                                {/* {user.title === 'Kein' ? null : user.title}  */}
                                {user.f_name} {user.l_name}</b> </h5>
                              {user.telephone === null ? null : <><p style={{ fontSize: '14px' }}><i class="fas fa-mobile-alt"></i>&nbsp;<a href={`tel:${user.telephone}`}>{user.telephone}</a> </p></>}
                              {user.telephone === null ? null : <><p style={{ fontSize: '14px' }}><i class="fab fa-whatsapp"></i>&nbsp;<a href={`https://wa.me/${user.telephone}`} target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">{user.telephone}</a> </p></>}


                              <p style={{ fontSize: '14px' }}><i class="fas fa-envelope-open-text"></i><a href={`mailto:${user.username}`}>&nbsp;{user.username}</a></p>
                              <p style={{ fontSize: '14px' }}><i class="fas fa-map-marker-alt"></i>&nbsp;	{user.haus_no}, {user.post_code} {user.applicant_city}, {user.country}</p>

                            </Col>
                          </Row>



                          <Row  >
                            <Col md="12">

                              <label className='p_wrap'><b>{user.about_me}</b></label>

                            </Col>
                          </Row>

                          <Row>
                            <Col md="5" style={{ margin: '15px' }}>
                              <h4>Kompetenzen</h4>
                              <hr></hr>
                              {this.state.skills.map(user => {
                                return (
                                  <>
                                    <Row>
                                      <Col md="8">
                                        <p style={{ fontSize: '14px' }}>{user.s_job_function} {user.s_job_field === 'Krankenpflege' ? <> , {user.s_profession}</> : null}  </p>
                                      </Col>
                                      <Col md="4">
                                        <span style={{ fontSize: '14px' }}>{user.s_level}</span>
                                      </Col>
                                    </Row>


                                  </>
                                );
                              })}
                            </Col>

                            <Col md="6" style={{ margin: '15px' }}>
                              <h4>Arbeitsgenehmigung</h4>
                              <hr></hr>
                              {this.state.data1.map(user => {
                                return (
                                  <>

                                    <Row>
                                      <Col md="7">
                                        <p style={{ fontSize: '14px' }}>Arbeitsgenehmigung vorhanden? </p>
                                      </Col>
                                      <Col md="5">
                                        <p style={{ fontSize: '14px' }}>
                                          {user.wp_approval === "Ich bin berechtigt in die Europäische Union zu arbeiten und benötige kein Visum." ? 'Ja' : 'Nein'}

                                        </p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="7">
                                        <p style={{ fontSize: '14px' }}>Berufsbezeichnung </p>
                                      </Col>
                                      <Col md="5">
                                        <p style={{ fontSize: '14px' }}>{user.Approbation === "Nein" || user.Approbation === null ? "Nein" : "Ja"}</p>
                                      </Col>
                                    </Row>

                                  </>
                                );
                              })}


                            </Col>
                          </Row>

                          <Row>
                            <Col md="5" style={{ margin: '15px' }}>
                              <h4>Arbeitserfahrung</h4>
                              <hr></hr>



                              {this.state.exp.map(user => {
                                return (
                                  <>


                                    <p style={{ fontSize: '14px' }}>Position:&nbsp;&nbsp;   {user.position}<i class="fas fa-chevron-circle-down" id={`toggler${user.exp_id}`} style={{ color: 'rgb(77, 114, 208)', position: 'absolute', left: '84%', }}></i></p>
                                    <p style={{ fontSize: '14px' }}>Unternehmen:&nbsp;&nbsp;  {user.company_name}</p>
                                    <UncontrolledCollapse toggler={`#toggler${user.exp_id}`}
                                    >

                                      <p style={{ fontSize: '14px' }}>Webseite:&nbsp;&nbsp;  {user.website}</p>
                                      <p style={{ fontSize: '14px' }}>Anfangsdatum:&nbsp;&nbsp;  {user.start_date}</p>
                                      <p style={{ fontSize: '14px' }}>Enddatum:&nbsp;&nbsp;  {user.End_date}</p>
                                      <p style={{ fontSize: '14px' }}>Beschreibung:&nbsp;&nbsp;  {user.description}</p>
                                    </UncontrolledCollapse>

                                    <hr></hr>


                                  </>
                                );
                              })}


                            </Col>

                            <Col md="6" style={{ margin: '15px' }}>
                              <h4>Ausbildung</h4>
                              <hr></hr>
                              {this.state.edu.map(user => {
                                return (
                                  <>

                                    <p style={{ fontSize: '14px' }}>Studienfach:&nbsp;&nbsp; {user.Subject}
                                      <i class="fas fa-chevron-circle-down"
                                        style={{
                                          color: 'rgb(77, 114, 208)',
                                          position: 'absolute',
                                          left: '84%'
                                        }}
                                        id={`toggler${user.edu_id}`}

                                      ></i></p>
                                    <p style={{ fontSize: '14px' }}>Hochschule:&nbsp;&nbsp; {user.university_name}</p>
                                    <UncontrolledCollapse toggler={`#toggler${user.edu_id}`}
                                    >

                                      <p style={{ fontSize: '14px' }}>Abschlussnote:&nbsp;&nbsp; {user.grades}</p>
                                      <p style={{ fontSize: '14px' }}>Anfangsdatum:&nbsp;&nbsp; {user.start_date}</p>
                                      <p style={{ fontSize: '14px' }}>Enddatum:&nbsp;&nbsp; {user.End_date}</p>
                                      <p style={{ fontSize: '14px' }}>Beschreibung:&nbsp;&nbsp; {user.description}</p>
                                    </UncontrolledCollapse>

                                    <hr></hr>

                                  </>
                                );
                              })}



                            </Col>

                          </Row>

                          <Row>
                            <Col md="5" style={{ margin: '15px' }}>
                              <h4>Sprachen</h4>
                              <hr></hr>


                              {this.state.lang.map(user => {
                                return (
                                  <>
                                    <Row>
                                      <Col md="6">
                                        <p style={{ fontSize: '14px' }}>  {user.language}</p>
                                      </Col>
                                      <Col md="6">
                                        <span style={{ marginLeft: '30%', fontSize: '14px' }}> {user.level}</span>
                                      </Col>
                                    </Row>
                                  </>
                                );
                              })}

                            </Col>

                            <Col md="6" style={{ margin: '15px' }}>
                              <h4>Dokumente</h4>
                              <hr></hr>
                              {this.state.doc.map(user => {
                                return (
                                  <>
                                    <Row>
                                      <Col md="4">
                                        <p style={{ fontSize: '14px' }}>   {user.doc_title}</p>
                                      </Col>
                                      <Col md="6">
                                        <span style={{ marginLeft: '30%', fontSize: '14px' }}> <a href={`https://hello-sister.de/uploads/${user.doc_name}`} download target="_blank" rel="noopener noreferrer">
                                          {/* <a href={`/images/8._Theoretical_Sheet.pdf`} download> */}
                                          Download
                                          {/* </a> */}
                                        </a></span>
                                      </Col>
                                      <Col md='2'>
                                      <Badge
                                       // style={{ background: 'red', marginTop: '-2px' }}
                                        className="btn-icon"
                                        color="danger"
                                        id={"tooltip4766097832" + user.doc_id}
                                        size="sm"
                                        type="button"
                                        onClick={this.deleteHandleDoc.bind(this, user.doc_id)}
                                      >
                                        <span>X</span>
                                      </Badge>{" "}
                                      <UncontrolledTooltip
                                        delay={0}
                                        target={"tooltip4766097832" + user.doc_id}
                                      >
                                        Löschen
                                      </UncontrolledTooltip>
                                    </Col>
                                    </Row>
                                   
                                  </>
                                );
                              })}

                            </Col>

                          </Row>
                          <Row>
                            <Col md="12" style={{ margin: '15px' }}>
                              <h4>Präferenzen</h4>
                              <hr></hr>
                              {this.state.data1.map(user => {
                                return (
                                  <>
                                    <Row>

                                      <Col> <p style={{ fontSize: '14px' }}>Berufsgruppe:&nbsp;&nbsp; {user.job_field}{user.job_field1 === null ? null : <>,&nbsp;{user.job_field1}</>}{user.job_field2 === null ? null : <>,&nbsp;{user.job_field2}</>}  </p></Col>  </Row>
                                    <Row>

                                      <Col> <p style={{ fontSize: '14px' }}>Funktion:&nbsp;&nbsp; {user.job_function}{user.job_function1 === null ? null : <>,&nbsp;{user.job_function1}</>}{user.job_function2 === null ? null : <>,&nbsp;{user.job_function2}</>} </p></Col>  </Row>
                                    {user.job_field === 'Krankenpflege' || user.job_field1 === 'Krankenpflege' || user.job_field2 === 'Krankenpflege' ? <> <Row>
                                      <Col> <p style={{ fontSize: '14px' }}>Fachgebiet:&nbsp;&nbsp;{user.profession}

                                        {user.profession1 === null ? null : <>&nbsp;{user.profession1}</>}
                                        {user.profession2 === null ? null : <>&nbsp;{user.profession2}</>}
                                        {/* {user.profession === null ? null : <>&nbsp;{user.profession}</> } */}
                                      </p></Col>

                                    </Row> </> : null}
                                    <Row>   <Col> <p style={{ fontSize: '14px' }}>Einrichtung:&nbsp;&nbsp;  {this.state.visible4 ?
                                      <>{user.sector}</>
                                      : null}

                                      {user.sector1 !== null ?
                                        <>,&nbsp;{user.sector1}</>
                                        : null}

                                      {user.sector2 !== null ?
                                        <>,&nbsp;{user.sector2}</>
                                        : null}
                                    </p>
                                    </Col></Row>

                                    <Row>

                                      <Col> <p style={{ fontSize: '14px' }}>Anstellungsart:&nbsp;&nbsp;   {this.state.visible2 ?
                                        <> {user.job_ternure}</>
                                        : null}

                                        {this.state.visible3 ?
                                          <>,&nbsp;{user.job_ternure1} </>
                                          : null}
                                        {user.job_ternure2 !== null ? ',' + user.job_ternure2 : null}
                                      </p></Col>
                                    </Row>
                                    <Row>
                                      <Col> <p style={{ fontSize: '14px' }}>Zeitmodell:&nbsp;&nbsp;  {this.state.visible9 ?
                                        <> {user.job_worktime}</>
                                        : null}

                                        {this.state.visible10 ?
                                          <>,&nbsp; {user.job_worktime2} </>
                                          : null}</p></Col></Row>
                                    <Row>
                                      <Col> <p style={{ fontSize: '14px' }}>Stadt:&nbsp;&nbsp;

                                        {user.city}
                                        {user.city1 === null ? null : <>,&nbsp;{user.city1}</>}
                                        {user.city2 === null ? null : <>,&nbsp;{user.city2}</>}
                                      </p></Col></Row>
                                    <Row>
                                      <Col> <p style={{ fontSize: '14px' }}>Umkreis:&nbsp;&nbsp; {user.radius}</p></Col>
                                    </Row>
                                  </>
                                );
                              })}


                            </Col>
                          </Row>
                          <Row>

                            <Col md="12" >
                              <Card style={{
                                background: '#e3e5e6',
                              }}>
                                <h4 style={{ textAlign: 'center' }}>Notizen</h4>
                              </Card>

                              <CardHeader style={{
                                background: '#e3e5e6',
                                padding: '10px',
                                marginBottom: '15px'
                              }}>
                                <Row >
                                  <Col md="3">User</Col>
                                  <Col md="3">Datum</Col>
                                  <Col md="4">Notizen</Col>
                                  <Col md="2">Action</Col>
                                </Row>
                              </CardHeader>

                              {this.state.notedata.map(user => {
                                return (
                                  <>
                                   
                                    <Row>
                                      <Col md='10'>
                                        <Row>
                                          <Col><p style={{ fontSize: '14px' }}>{user.admin_id}</p>
                                          </Col>
                                          <Col> <p style={{ fontSize: '14px' }}>{user.note_date}</p>
                                          </Col>
                                          <Col>
                                            <p style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>{user.note}</p>
                                          </Col>
                                        </Row>


                                      </Col>
                                      <Col md='2'>  <Button
                                        className="btn-icon"
                                        color="danger"
                                        id="tooltip3662466421"
                                        size="sm"
                                        type="button"
                                        onClick={this.deletenoteHandle.bind(this, user.note_id_app)}
                                      >
                                        <i className="fa fa-trash" />
                                      </Button>{" "}
                                        <UncontrolledTooltip
                                          delay={0}
                                          target="tooltip3662466421"
                                        >
                                          Löschen
                                        </UncontrolledTooltip>

                                      </Col>
                                    </Row>

                                    <hr></hr>




                                  </>
                                );
                              })}
                              <Input

                                type='textarea'
                                name="note"
                                 value={this.state.note}
                                placeholder="Schreibe hier eine Notiz"
                                onChange={this.changeHandler}

                              />
                              <center><Button color='primary'
                                onClick={this.submitHandler}
                              >Notiz hinzufügen</Button>
                              
                              <Badge className="mr-1" color="danger" pill>
                                      {this.state.note_err}
                                    </Badge><Badge className="mr-1" color="success" pill>
                                      {this.state.note_msg}
                                    </Badge>
                              </center>



                            </Col>
                          </Row>
                          <br></br>
                          <br></br>
                          <Row>
                            <Col md='12'>
                              <Card style={{
                                background: '#e3e5e6',
                              }}>
                                <h4 style={{ textAlign: 'center' }}>Dokument hochladen</h4>
                              </Card>
                              <Badge color="danger">{this.state.msg}</Badge><br></br>

                              <CardBody>
                                <Row>
                                  <Col md="6">

                                    <label className="title" style={{ fontSize: '12px' }}>Dokumenttitel</label>
                                    <Input
                                      value={this.state.doc_title}
                                      type="text"
                                      name="doc_title"
                                      onChange={this.changeHandler}
                                      style={{ fontSize: '15px', height: '35px', backgroundColor: '#f1f1f1' }}
                                    />
                                  </Col>
                                  <Col md="6">
                                    <Input
                                      key={this.state.theInputKey || ''}
                                      type="file"
                                      className="btn-round mb-3"
                                      color="primary"
                                      name="file"
                                      onChange={this.fileHandlerDoc}
                                      style={{ marginTop: '15px', fontSize: '15px' }}
                                    >
                                    </Input>
                                  </Col>
                                </Row>
                                <br></br>
                                <Row>
                                  <Col md="12" style={{ textAlign: 'center' }}>
                                    <Button className="cstm-btn-card-4"
                                      type="submit"
                                      className="btn-round mb-3"
                                      color="primary"
                                      onClick={this.submitHandlerDoc}
                                    >
                                      Dokument hochladen
                                    </Button>
                                  </Col>


                                </Row>



                                <Row>
                                  <Col md='12'>
                                    <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded, 2)}%</Progress>

                                  </Col>

                                </Row>

                              </CardBody>
                            </Col>
                          </Row>



                          {this.state.roles === 'admin' ?
                            <>                          <Row>
                              <Col md="12" style={{ margin: '15px' }}>
                                <h4>Refered By</h4>
                                <hr></hr>
                                {this.state.data6.map(user => {
                                  return (
                                    <>
                                      <Card style={{ backgroundColor: '#f5f5f5', padding: '10px', margin: '13px' }} >
                                        <Row>
                                          <Col md='2'>

                                            {user.user_profile === null ? null : <img style={{ width: '100px', height: '100px' }}
                                              alt="..."
                                              className="avatar border-gray"
                                              src={`https://unternehmen.hello-sister.de/uploads/${user.user_profile}`}


                                            />}
                                          </Col>

                                          <Col md='5'><h7 className="text-left"   >

                                            <b>{user.andre} {" "}
                                              {/* {user.title === 'Kein' ? null : user.title}  */}

                                              {user.f_name} {user.l_name}</b>

                                            <br></br>
                                            {user.telephone === null ? null : <><h7 className="text-left" style={{ fontSize: '13px' }}><i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${user.telephone}`} target="_blank" rel="noopener noreferrer">{user.telephone}</a></h7><br></br></>}
                                            <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp;{user.username}</h7><br></br>
                                            {/* <i class="fas fa-address-card"></i>&nbsp;
              <h7 className="text-left" style={{ fontSize: '13px' }}>{user.position}</h7><br></br> */}
                                            <i class="fa fa-money" aria-hidden="true"></i>&nbsp;<h7 className="text-left" style={{ fontSize: '13px' }}>{user.ref_status}</h7><br></br>
                                            {user.ref_status === "unpaid" ?
                                              <Button color="warning" onClick={this.payRef.bind(this, user.ref_code)}>Pay Referal bonus</Button>
                                              : null}
                                          </h7>
                                          </Col>
                                        </Row>


                                      </Card>

                                    </>
                                  );
                                })}
                                <hr></hr>
                              </Col>
                            </Row></>
                            :
                            null}



                        </Col>



                      </Row>





                      <CardBody>
                        {this.state.postData1Show ? <>   <h5 className="cstm-heading-backend"><b>Jobs anhand Präferenzen </b></h5>
                          <br></br>
                          {this.state.postData1}
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick1}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} /> </> : null}


                        {/* <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Online Seit</th>
                        <th className="text-left">Online Bis</th>
                        <th className="text-left">Job Title</th>
                        <th className="text-left">Unternehmen</th>
                        <th className="text-left">Bewerbungsstatus</th>
                        <th className="text-left">gesendet am</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Aktion</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                  
                    {this.state.data.map(item => {
                      return (
                        <>  
                   
                        <tr>
                               <td className="text-left">

<medium className="">
{item.created_date}
</medium> 
<br/>

</td>

<td className="text-left">

<medium className="">

{item.valid_till}
</medium> 
<br/>

</td>

<td className="text-left">
<Link to = {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer"> 
<Badge className="mr-1" color="primary" pill>
{item.job_function} <br></br> (m/w/d) <br></br> {item.profession}
</Badge></Link>
</td><td className="text-left">

<Link to = {`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer"> <medium>
  <b>{item.company_name}</b> <br></br>
   {item.address}  {item.haus_no}, {item.zip_code} {item.city}
  </medium></Link>
  

<br/>

</td>
<td className="text-left">
{this.state.data4.map(item1 => {
                      return (
                        <>  
                        {item.job_id === item1.job_id ? 
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item1.candidate_status} am {item1.action_date}
                          </Badge><br />
                          {item1.hosp_contract} {item1.contract_date_hosp}<br />
                          {item1.app_contract} {item1.contract_date_candid}
                          </span>                     
                        :                    
                        null
                        }
</>
                                 )
                               })} 
</td>  
<td className="text-left">
{item.sent_date}
</td>
<td className="text-left">
{item.match_status}
<br></br>
{this.state.data3.map(item1 => {
                      return (
                        <>  
                        {item.job_id === item1.job_id ? 
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item1.cnt} 
                          </Badge><br />
                          </span>                     
                        :                    
                        null
                        }
</>
                                 )
                               })} 
</td>
<td>
<Button
                            className="btn-icon"
                            color="dark"
                            id="tooltip366246640"
                            size="sm"
                            type="button"
                            onClick={this.closeHandler.bind(this, item.job_id,this.state.app_id)}
                          >
                            <i className="fa fa-toggle-off" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246640"
                          >
                            Close
                          </UncontrolledTooltip>
                          <Link to={`/admin/notes1/${item.job_id}/${this.state.app_id}`}>
                          <Button
                            className="btn-icon"
                            color="success"
                            id="tooltip366246651"
                            size="sm"
                            type="button"
                            //onClick={this.acceptHandle.bind(this, item.user_id)}
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246651"
                          >
                            Add Note
                          </UncontrolledTooltip>
                          </Link>
                      </td> 
                              </tr>
                                 </>
                                 )
                               })} 
                    </tbody>
                    
                  </Table>
              */}
                      </CardBody>



                      <CardBody>
                        {this.state.jobSearch ? <h5 className="cstm-heading-backend"><b>Jobs durch die Jobsuche </b></h5> : null}
                        <br></br>{this.state.data2.map(item => {
                          return (
                            <>
                              <Card style={{ backgroundColor: '#f5f5f5', padding: '10px' }} >

                                <Row>
                                  <Col md='2'>
                                    <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height='100' width='auto' />
                                  </Col>

                                  <Col md='5'>
                                    <h7 style={{ color: '#4287f5', fontSize: '10px' }}><Link to={`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">{item.company_name}</Link></h7>

                                    <br></br>
                                    <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`}>
                                      {item.job_field} {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}
                                    </Link>

                                    <br></br>
                                    <br></br>
                                    <Badge className="mr-1" color="success" pill>
                                      {item.candidate_status} am {item.action_date}
                                    </Badge>


                                  </Col>

                                  <Col md='3' className="text-left">


                                    <h7>
                                      {item.hosp_contract} {item.contract_date_hosp}<br /><br />
                                      {item.app_contract} {item.contract_date_candid}
                                    </h7>


                                  </Col>
                                  <Col md='2' className="text-right">


                                    <Button
                                      className="btn-icon"
                                      color="dark"
                                      id={"tooltip3662466401" + item.job_id}
                                      size="sm"
                                      type="button"
                                      onClick={this.closeHandler1.bind(this, item.job_id, this.state.app_id)}
                                    >
                                      <i className="fa fa-toggle-off" />
                                    </Button>{" "}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip3662466401" + item.job_id}
                                    >
                                      Close
                                    </UncontrolledTooltip>

                                  </Col>
                                </Row>
                                <br></br>
                                <Row style={{ backgroundColor: '#ffffff', marginTop: '-10px' }}>

                                  <Col md="3">
                                    <i class="far fa-clock"></i>
                                    &nbsp;&nbsp;
                                    <h7 style={{ fontSize: '11px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                                  </Col>
                                  <Col md="3">
                                    <i class="fas fa-hourglass-half"></i>
                                    &nbsp;&nbsp;
                                    <h7 style={{ fontSize: '11px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} {item.job_ternure2 !== null ? ',' + item.job_ternure2 : null} </h7>
                                  </Col>
                                  <Col md="3">
                                    <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                                    <h7 style={{ fontSize: '11px' }}> Online seit: {item.created_date}</h7>
                                  </Col>
                                  <Col md="3">
                                    <i class="far fa-calendar-alt"></i>

                                    &nbsp;&nbsp;
                                    <h7 style={{ fontSize: '11px' }}>Online bis: {item.valid_till} </h7>



                                  </Col>
                                </Row>
                                <div>
                                </div>

                              </Card>
                            </>
                          )
                        })}
                        {/* <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Online Seit</th>
                        <th className="text-left">Online Bis</th>
                        <th className="text-left">Job Title</th>
                        <th className="text-left">Unternehmen</th>
                        <th className="text-left">BEWERBUNGSSTATUS</th>
                        <th className="text-left">AKTION</th>
                       
                      
                      </tr>
                    </thead>
                    <tbody>
                  
                    {this.state.data2.map(item => {
                      return (
                        <>  
                   
                        <tr>
                               <td className="text-left">

<medium className="">
{item.created_date}
</medium> 
<br/>

</td>


<td className="text-left">

<medium className="">

{item.valid_till}
</medium> 
<br/>

</td>

<br/>
<Link to = {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer"> 
<Badge className="mr-1" color="primary" pill>
{item.job_function} (m/w/d)  {item.profession}
</Badge></Link>
<td className="text-left">

<Link to = {`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer"> <medium>
  <b>{item.company_name}</b> <br></br>
   {item.address}  {item.haus_no}, {item.zip_code} {item.city}
  </medium></Link>
  

<br/>

</td>
<td className="text-left">

                       
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item.candidate_status} am {item.action_date}
                          </Badge><br />
                          {item.hosp_contract} {item.contract_date_hosp}<br />
                          {item.app_contract} {item.contract_date_candid}
                          </span>                     
                       

                            </td>
                            <td>
<Button
                            className="btn-icon"
                            color="dark"
                            id="tooltip366246640"
                            size="sm"
                            type="button"
                            onClick={this.closeHandler1.bind(this, item.job_id,this.state.app_id)}
                          >
                            <i className="fa fa-toggle-off" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246640"
                          >
                            Close
                          </UncontrolledTooltip>
               
                      </td>

                              </tr>
                               
                                 </>
                                 )
                               })} 
                    </tbody>
                    
                  </Table>
              */}
                      </CardBody>


                    </CardBody>
                  </>
                );
              })}


            </Card>

          </Col>
        </Row>



      </div>
    );
  }
}

export default CandidateProfile1;
