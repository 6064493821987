import React from 'react'

import { Link } from "react-router-dom";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardFooter,
  } from "reactstrap";

import Ternure from "@material-ui/icons/BusinessCenter";
import Time from "@material-ui/icons/Timer";

const JobCardList = ({item}) => {
    return (
      <>
        <Card>
                <CardBody>
                  <Row>
                    <Col md="8">
                    
                    <h4 className="text-ubold">
                     {item.nameInstitut}
                </h4>
                <h5>
                {item.jobType}
                </h5>
                  <h6 className="author pull-left">{item.qualification} (m/w/d) {item.speciality}</h6>
                  
                  <div className="clearfix" />
                  <br/>
                  <Row>
                      <Col md="4">
                      <Ternure />
                      <br/>
                      <span className="small">
                      {item.workingTernure}
                      </span>
                      
                      </Col>
                      <Col md="4">
                      <Time /><br/>
                      <span className="small">
                      {item.workingTime}
                      </span>
                      </Col>
                      
                  </Row>
                  <br/>
                  {/* <Row>
                    <Col md="4">
                    {item.workFlow === "accepted" ?  <Badge className="mr-1" color="success" pill>
                        Eingewilligt
                        </Badge> : "" }
                        {item.workFlow === "rejected" ?   <Badge className="mr-1" color="danger" pill>
                        Abgelehnt
                        </Badge> : "" }
                        {item.workFlow === "notAnswer" ?   <Badge className="mr-1" color="info" pill>
                        Entscheidung steht aus
                        </Badge> : "" }
                      <br/>
                      am {item.lastDateAction}
                    </Col>
                  </Row> */}
                    </Col>
                  <Col md="4">
                    <span className="small">
<b>Veröffentlicht am:</b>  {item.dateCreated} <br/>
<b>Gültig bis:</b>  {item.dateExpired} <br/>
<b>Status:</b>  {item.status}
</span> <br/>
{/* <Link to={"/admin/edit-job"}>
                    <Button
             className="btn-round"
             color="primary"
             outline
             size="md"
            //  onClick={this.handleMoreClick}
             >
                <span>
                  Bearbeiten
                </span>
             </Button>
                    </Link> <br/> */}
                    <Link to={"/admin/detail-job"}>
<Button
             className="btn-round"
             color="primary"
             outline
             size="md"
            //  onClick={this.handleMoreClick}
             >
                <span>
                  Details
                </span>
             </Button>
                    </Link>
                  </Col>
                  </Row>
                  
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col md="6" className="ml-auto mr-auto justify-content-center">
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
              <br/>
              <br/>
              </>
    )
}

export default JobCardList