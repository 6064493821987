export const skills = [
  {
    label: "Altenpflege",
    value: "Altenpflege",
  },
  {
    label: "Krankenpflege",
    value: "Krankenpflege",
  },
  {
    label: "Therapeut",
    value: "Therapeut",
  },
  {
    label: "Medizintechnik",
    value: "Medizintechnik",
  },
  {
    label: "Rettungsdienst",
    value: "Rettungsdienst",
  },
  {
    label: "Praxispersonal",
    value: "Praxispersonal",
  },
  {
    label: "Pharmazie",
    value: "Pharmazie",
  },
];
