import React from "react";

// react plugin used to create DropdownMenu for selecting items
// import Select from "react-select";
// import { Link,Route,BrowserRouter as Router, Switch,Redirect, BrowserHistory,withRouter } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Row,
  Col,
} from "reactstrap";

// core components
//import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
const admin = [
  {
    id: "1",
    salutation: "Frau",
    title: "Prof",
    lastName: "Ketzer",
    firstName: "Dorothe",
    email: "d.ketzer@nn.de",
    telefon: "09887878",
    position: "CEO",
    status: "aktiv"
  }
]
class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levelSelect: null,
    };
  }
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="9">
              <Card>
                <CardHeader>
                  <h5 className="title">Dokumente </h5>
                  <p>Hier kannst Du Dokumente für Dein Bewerbungsprofil hochladen.</p>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Row>
            <Col md="9">
            {admin.map((admin) => {
                          return (
                            <>
                    <Row className="justify-content-center">
                    <Input 
                      type = "file"
                      className="btn-round mb-3"
                      color="primary"
                    >
                    </Input>
                    </Row>
                    <Row className="justify-content-center">
                      <Button
                      type = "submit"
                      className="btn-round mb-3"
                      color="primary"
                      >
                        Dokument auswählen                       
                      </Button>
                    </Row>
           </>
                          )
                        })}        
            </Col>
          </Row>   
                </CardBody>
              </Card>
            </Col>
          </Row> 
        </div>
      </>
    );
  }
}
export default Documents;
    
