import React, { useState } from "react";
import axios from 'axios';
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { Redirect, Link } from "react-router-dom";
import defaultImage from "assets/img/default-avatar.png";
import defaultAvatar from "assets/img/placeholder.jpg";
import validator from 'validator';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Badge
} from "reactstrap";

//react-select css
const customStyles = {
  control: base => ({
    ...base,
    height: 40,
    width: '100%',

    padding: '0px 0px',
    fontSize: '13px',
    top:'42%',
    backgroundColor:'#f1f1f1'
    
  }),

  
};
const admin = [
  {
    id: "1",
    salutation: "Frau",
    title: "Prof",
    lastName: "Ketzer",
    firstName: "Dorothe",
    email: "d.ketzer@nn.de",
    telefon: "09887878",
    position: "CEO",
    status: "aktiv"
  }
]
const countries = [
  { label: 'Germany', vlaue: 'DE' },
  { label: 'Afghanistan', vlaue: 'AF' },
  { label: 'Åland Islands', vlaue: 'AX' },
  { label: 'Albania', vlaue: 'AL' },
  { label: 'Algeria', vlaue: 'DZ' },
  { label: 'American Samoa', vlaue: 'AS' },
  { label: 'Andorra', vlaue: 'AD' },
  { label: 'Angola', vlaue: 'AO' },
  { label: 'Anguilla', vlaue: 'AI' },
  { label: 'Antarctica', vlaue: 'AQ' },
  { label: 'Antigua and Barbuda', vlaue: 'AG' },
  { label: 'Argentina', vlaue: 'AR' },
  { label: 'Armenia', vlaue: 'AM' },
  { label: 'Aruba', vlaue: 'AW' },
  { label: 'Australia', vlaue: 'AU' },
  { label: 'Austria', vlaue: 'AT' },
  { label: 'Azerbaijan', vlaue: 'AZ' },
  { label: 'Bahamas', vlaue: 'BS' },
  { label: 'Bahrain', vlaue: 'BH' },
  { label: 'Bangladesh', vlaue: 'BD' },
  { label: 'Barbados', vlaue: 'BB' },
  { label: 'Belarus', vlaue: 'BY' },
  { label: 'Belgium', vlaue: 'BE' },
  { label: 'Belize', vlaue: 'BZ' },
  { label: 'Benin', vlaue: 'BJ' },
  { label: 'Bermuda', vlaue: 'BM' },
  { label: 'Bhutan', vlaue: 'BT' },
  { label: 'Bolivia', vlaue: 'BO' },
  { label: 'Bosnia and Herzegovina', vlaue: 'BA' },
  { label: 'Botswana', vlaue: 'BW' },
  { label: 'Bouvet Island', vlaue: 'BV' },
  { label: 'Brazil', vlaue: 'BR' },
  { label: 'British Indian Ocean Territory', vlaue: 'IO' },
  { label: 'Brunei Darussalam', vlaue: 'BN' },
  { label: 'Bulgaria', vlaue: 'BG' },
  { label: 'Burkina Faso', vlaue: 'BF' },
  { label: 'Burundi', vlaue: 'BI' },
  { label: 'Cambodia', vlaue: 'KH' },
  { label: 'Cameroon', vlaue: 'CM' },
  { label: 'Canada', vlaue: 'CA' },
  { label: 'Cape Verde', vlaue: 'CV' },
  { label: 'Cayman Islands', vlaue: 'KY' },
  { label: 'Central African Republic', vlaue: 'CF' },
  { label: 'Chad', vlaue: 'TD' },
  { label: 'Chile', vlaue: 'CL' },
  { label: 'China', vlaue: 'CN' },
  { label: 'Christmas Island', vlaue: 'CX' },
  { label: 'Cocos (Keeling) Islands', vlaue: 'CC' },
  { label: 'Colombia', vlaue: 'CO' },
  { label: 'Comoros', vlaue: 'KM' },
  { label: 'Congo', vlaue: 'CG' },
  { label: 'Congo, The Democratic Republic of the', vlaue: 'CD' },
  { label: 'Cook Islands', vlaue: 'CK' },
  { label: 'Costa Rica', vlaue: 'CR' },
  { label: 'Cote D\'Ivoire', vlaue: 'CI' },
  { label: 'Croatia', vlaue: 'HR' },
  { label: 'Cuba', vlaue: 'CU' },
  { label: 'Cyprus', vlaue: 'CY' },
  { label: 'Czech Republic', vlaue: 'CZ' },
  { label: 'Denmark', vlaue: 'DK' },
  { label: 'Djibouti', vlaue: 'DJ' },
  { label: 'Dominica', vlaue: 'DM' },
  { label: 'Dominican Republic', vlaue: 'DO' },
  { label: 'Ecuador', vlaue: 'EC' },
  { label: 'Egypt', vlaue: 'EG' },
  { label: 'El Salvador', vlaue: 'SV' },
  { label: 'Equatorial Guinea', vlaue: 'GQ' },
  { label: 'Eritrea', vlaue: 'ER' },
  { label: 'Estonia', vlaue: 'EE' },
  { label: 'Ethiopia', vlaue: 'ET' },
  { label: 'Falkland Islands (Malvinas)', vlaue: 'FK' },
  { label: 'Faroe Islands', vlaue: 'FO' },
  { label: 'Fiji', vlaue: 'FJ' },
  { label: 'Finland', vlaue: 'FI' },
  { label: 'France', vlaue: 'FR' },
  { label: 'French Guiana', vlaue: 'GF' },
  { label: 'French Polynesia', vlaue: 'PF' },
  { label: 'French Southern Territories', vlaue: 'TF' },
  { label: 'Gabon', vlaue: 'GA' },
  { label: 'Gambia', vlaue: 'GM' },
  { label: 'Georgia', vlaue: 'GE' },
  
  { label: 'Ghana', vlaue: 'GH' },
  { label: 'Gibraltar', vlaue: 'GI' },
  { label: 'Greece', vlaue: 'GR' },
  { label: 'Greenland', vlaue: 'GL' },
  { label: 'Grenada', vlaue: 'GD' },
  { label: 'Guadeloupe', vlaue: 'GP' },
  { label: 'Guam', vlaue: 'GU' },
  { label: 'Guatemala', vlaue: 'GT' },
  { label: 'Guernsey', vlaue: 'GG' },
  { label: 'Guinea', vlaue: 'GN' },
  { label: 'Guinea-Bissau', vlaue: 'GW' },
  { label: 'Guyana', vlaue: 'GY' },
  { label: 'Haiti', vlaue: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', vlaue: 'HM' },
  { label: 'Holy See (Vatican City State)', vlaue: 'VA' },
  { label: 'Honduras', vlaue: 'HN' },
  { label: 'Hong Kong', vlaue: 'HK' },
  { label: 'Hungary', vlaue: 'HU' },
  { label: 'Iceland', vlaue: 'IS' },
  { label: 'India', vlaue: 'IN' },
  { label: 'Indonesia', vlaue: 'ID' },
  { label: 'Iran, Islamic Republic Of', vlaue: 'IR' },
  { label: 'Iraq', vlaue: 'IQ' },
  { label: 'Ireland', vlaue: 'IE' },
  { label: 'Isle of Man', vlaue: 'IM' },
  { label: 'Israel', vlaue: 'IL' },
  { label: 'Italy', vlaue: 'IT' },
  { label: 'Jamaica', vlaue: 'JM' },
  { label: 'Japan', vlaue: 'JP' },
  { label: 'Jersey', vlaue: 'JE' },
  { label: 'Jordan', vlaue: 'JO' },
  { label: 'Kazakhstan', vlaue: 'KZ' },
  { label: 'Kenya', vlaue: 'KE' },
  { label: 'Kiribati', vlaue: 'KI' },
  { label: 'Democratic People\'s Republic of Korea', vlaue: 'KP' },
  { label: 'Korea, Republic of', vlaue: 'KR' },
  { label: 'Kosovo', vlaue: 'XK' },
  { label: 'Kuwait', vlaue: 'KW' },
  { label: 'Kyrgyzstan', vlaue: 'KG' },
  { label: 'Lao People\'s Democratic Republic', vlaue: 'LA' },
  { label: 'Latvia', vlaue: 'LV' },
  { label: 'Lebanon', vlaue: 'LB' },
  { label: 'Lesotho', vlaue: 'LS' },
  { label: 'Liberia', vlaue: 'LR' },
  { label: 'Libyan Arab Jamahiriya', vlaue: 'LY' },
  { label: 'Liechtenstein', vlaue: 'LI' },
  { label: 'Lithuania', vlaue: 'LT' },
  { label: 'Luxembourg', vlaue: 'LU' },
  { label: 'Macao', vlaue: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', vlaue: 'MK' },
  { label: 'Madagascar', vlaue: 'MG' },
  { label: 'Malawi', vlaue: 'MW' },
  { label: 'Malaysia', vlaue: 'MY' },
  { label: 'Maldives', vlaue: 'MV' },
  { label: 'Mali', vlaue: 'ML' },
  { label: 'Malta', vlaue: 'MT' },
  { label: 'Marshall Islands', vlaue: 'MH' },
  { label: 'Martinique', vlaue: 'MQ' },
  { label: 'Mauritania', vlaue: 'MR' },
  { label: 'Mauritius', vlaue: 'MU' },
  { label: 'Mayotte', vlaue: 'YT' },
  { label: 'Mexico', vlaue: 'MX' },
  { label: 'Micronesia, Federated States of', vlaue: 'FM' },
  { label: 'Moldova, Republic of', vlaue: 'MD' },
  { label: 'Monaco', vlaue: 'MC' },
  { label: 'Mongolia', vlaue: 'MN' },
  { label: 'Montenegro', vlaue: 'ME' },
  { label: 'Montserrat', vlaue: 'MS' },
  { label: 'Morocco', vlaue: 'MA' },
  { label: 'Mozambique', vlaue: 'MZ' },
  { label: 'Myanmar', vlaue: 'MM' },
  { label: 'Namibia', vlaue: 'NA' },
  { label: 'Nauru', vlaue: 'NR' },
  { label: 'Nepal', vlaue: 'NP' },
  { label: 'Netherlands', vlaue: 'NL' },
  { label: 'Netherlands Antilles', vlaue: 'AN' },
  { label: 'New Caledonia', vlaue: 'NC' },
  { label: 'New Zealand', vlaue: 'NZ' },
  { label: 'Nicaragua', vlaue: 'NI' },
  { label: 'Niger', vlaue: 'NE' },
  { label: 'Nigeria', vlaue: 'NG' },
  { label: 'Niue', vlaue: 'NU' },
  { label: 'Norfolk Island', vlaue: 'NF' },
  { label: 'Northern Mariana Islands', vlaue: 'MP' },
  { label: 'Norway', vlaue: 'NO' },
  { label: 'Oman', vlaue: 'OM' },
  { label: 'Pakistan', vlaue: 'PK' },
  { label: 'Palau', vlaue: 'PW' },
  { label: 'Palestinian Territory, Occupied', vlaue: 'PS' },
  { label: 'Panama', vlaue: 'PA' },
  { label: 'Papua New Guinea', vlaue: 'PG' },
  { label: 'Paraguay', vlaue: 'PY' },
  { label: 'Peru', vlaue: 'PE' },
  { label: 'Philippines', vlaue: 'PH' },
  { label: 'Pitcairn', vlaue: 'PN' },
  { label: 'Poland', vlaue: 'PL' },
  { label: 'Portugal', vlaue: 'PT' },
  { label: 'Puerto Rico', vlaue: 'PR' },
  { label: 'Qatar', vlaue: 'QA' },
  { label: 'Reunion', vlaue: 'RE' },
  { label: 'Romania', vlaue: 'RO' },
  { label: 'Russian Federation', vlaue: 'RU' },
  { label: 'Rwanda', vlaue: 'RW' },
  { label: 'Saint Helena', vlaue: 'SH' },
  { label: 'Saint Kitts and Nevis', vlaue: 'KN' },
  { label: 'Saint Lucia', vlaue: 'LC' },
  { label: 'Saint Pierre and Miquelon', vlaue: 'PM' },
  { label: 'Saint Vincent and the Grenadines', vlaue: 'VC' },
  { label: 'Samoa', vlaue: 'WS' },
  { label: 'San Marino', vlaue: 'SM' },
  { label: 'Sao Tome and Principe', vlaue: 'ST' },
  { label: 'Saudi Arabia', vlaue: 'SA' },
  { label: 'Senegal', vlaue: 'SN' },
  { label: 'Serbia', vlaue: 'RS' },
  { label: 'Seychelles', vlaue: 'SC' },
  { label: 'Sierra Leone', vlaue: 'SL' },
  { label: 'Singapore', vlaue: 'SG' },
  { label: 'Slovakia', vlaue: 'SK' },
  { label: 'Slovenia', vlaue: 'SI' },
  { label: 'Solomon Islands', vlaue: 'SB' },
  { label: 'Somalia', vlaue: 'SO' },
  { label: 'South Africa', vlaue: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', vlaue: 'GS' },
  { label: 'Spain', vlaue: 'ES' },
  { label: 'Sri Lanka', vlaue: 'LK' },
  { label: 'Sudan', vlaue: 'SD' },
  { label: 'Suriname', vlaue: 'SR' },
  { label: 'Svalbard and Jan Mayen', vlaue: 'SJ' },
  { label: 'Swaziland', vlaue: 'SZ' },
  { label: 'Sweden', vlaue: 'SE' },
  { label: 'Switzerland', vlaue: 'CH' },
  { label: 'Syrian Arab Republic', vlaue: 'SY' },
  { label: 'Taiwan', vlaue: 'TW' },
  { label: 'Tajikistan', vlaue: 'TJ' },
  { label: 'Tanzania, United Republic of', vlaue: 'TZ' },
  { label: 'Thailand', vlaue: 'TH' },
  { label: 'Timor-Leste', vlaue: 'TL' },
  { label: 'Togo', vlaue: 'TG' },
  { label: 'Tokelau', vlaue: 'TK' },
  { label: 'Tonga', vlaue: 'TO' },
  { label: 'Trinidad and Tobago', vlaue: 'TT' },
  { label: 'Tunisia', vlaue: 'TN' },
  { label: 'Turkey', vlaue: 'TR' },
  { label: 'Turkmenistan', vlaue: 'TM' },
  { label: 'Turks and Caicos Islands', vlaue: 'TC' },
  { label: 'Tuvalu', vlaue: 'TV' },
  { label: 'Uganda', vlaue: 'UG' },
  { label: 'Ukraine', vlaue: 'UA' },
  { label: 'United Arab Emirates', vlaue: 'AE' },
  { label: 'United Kingdom', vlaue: 'GB' },
  { label: 'United States', vlaue: 'US' },
  { label: 'United States Minor Outlying Islands', vlaue: 'UM' },
  { label: 'Uruguay', vlaue: 'UY' },
  { label: 'Uzbekistan', vlaue: 'UZ' },
  { label: 'Vanuatu', vlaue: 'VU' },
  { label: 'Venezuela', vlaue: 'VE' },
  { label: 'Viet Nam', vlaue: 'VN' },
  { label: 'Virgin Islands, British', vlaue: 'VG' },
  { label: 'Virgin Islands, U.S.', vlaue: 'VI' },
  { label: 'Wallis and Futuna', vlaue: 'WF' },
  { label: 'Western Sahara', vlaue: 'EH' },
  { label: 'Yemen', vlaue: 'YE' },
  { label: 'Zambia', vlaue: 'ZM' },
  { label: 'Zimbabwe', vlaue: 'ZW' }
]

const salutation = [
  // {
  //   id: "0",
  //   label: "Bitte Auswahlen",
  //   value: ""
  // },
  {
    id: "1",
    label: "Herr",
    value: "Herr"
  },
  {
    id: "2",
    label: "Frau",
    value: "Frau"
  }
]
const title = [

  {
    id: "1",
    label: "Prof.",
    value: "Prof."
  },
  {
    id: "2",
    label: "Dr.",
    value: "Dr."
  },
  {
    id: "3",
    label: "Prof. Dr.",
    value: "Prof. Dr."
  },
  {
    id: "4",
    label: "Kein",
    value: "Kein"
  }
]

class CandidateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutation: null,
      picture: '',
      title: null,
      countriesSelect : null,
      f_name : '',
      l_name : '',
      date_of_birth : null,
      haus_no : '',
      post_code : '',
      city : '',
      telephone : '',
      about_me : '',
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  
  
  
  changeHandler = e => {
		this.setState({ [e.target.name]: e.target.value })
  }
 
  checkFileSize=(e)=>{
    let files = e.target.files
    let size = 5 * 1024 * 1024
    let err = ""; 
    for(var x = 0; x<files.length; x++) {
    if (files[x].size > size) {
     err += files[x].type+' ist zu groß, bitte wählen Sie eine kleinere Datei. Maximal 10 MB\n';
   }
 };
 if (err !== '') {
    e.target.value = null
    ////console.log(err)
    toast.error(err)
    return false
}

return true;

}

  checkMimeType=(e)=>{
    //getting file object
    let files = e.target.files 
    //define message container
    let err = ''
    // list allow mime type
   const types =['image/gif', "image/png", "image/jpg", "image/jpeg"]
    // loop access array
    for(var x = 0; x<files.length; x++) {
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err += files[x].type+' ist kein unterstütztes Format. Sie können nur Fotos oder Dokumente in folgenden Formaten hochladen: .jpeg, .png, .gif, .pdf, .docx';
       }
     };
  
   if (err !== '') { // if message not same old that mean has error 
        e.target.value = null // discard selected file
        //console.log(err)
        toast.error(err)
         return false; 
    }
   return true;
  
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    //let file = [];
  
    if(this.checkMimeType(e) && this.checkFileSize(e)){

      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
       
        }
    //  new Compressor(e.target.files[0], {
     //   quality: 0.6,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
      //  success(result1) {
        //  this.setState({
        //    file
        //  })

        //  file.push(result1) ;
         // file = result1;
          // The third parameter is required for server
       
  //},
     
//});

//console.log(file)
//var file = new File([blob], "my_image.png",{type:"image/png", lastModified:new Date().getTime()})

//console.log([file[0]])


//var file123 = new File([file[0]], e.target.files[0].name,{type:e.target.files[0].type, lastModified:new Date().getTime()})


//console.log(file123);


  }
  fileHandlerDoc = e => {
    var files = e.target.files[0]
   
    if(this.checkMimeType(e) && this.checkFileSize(e)){
		this.setState({ 
      file: files,
        loaded: 0,
      //[e.target.name]: e.target.value 
    })
  }
  }
  handleSubmit(e) {
    e.preventDefault();
    //const { userId, password } = this.state
    var salutation1;
    var file;
    var title1;
    var countriesSelect1;
    var test
    console.log(this.state)
    if(this.state.file === null)
    {
      if(this.state.salutation === null){
        const andr_err = "Bitte wählen Sie Anrede ein aus";
        this.setState({andr_err})
      }else{
          this.setState({andr_err:undefined})
        }
        //  if(this.state.title === null){
        //   const titel_err = "Bitte wählen Sie ein Titel aus";
        //   this.setState({titel_err})
          
        // }else{
        //     this.setState({titel_err:undefined})
        //   }
             if(validator.isEmpty(this.state.f_name, {ignore_whitespace:true})){
             const f_name_err = "Bitte geben Sie Ihren Vornamen ein";
             this.setState({f_name_err})
            
           }else{
             this.setState({f_name_err:undefined})
            }
            //  if(validator.isEmpty(this.state.l_name, {ignore_whitespace:true})){
            //   const l_name_err = "Bitte geben Sie Ihren Nachnamen ein";
            //   this.setState({l_name_err})
            // }else{
            //     this.setState({l_name_err:undefined})
            //   }
              //  if(this.state.date_of_birth === null){
              //   const DOB_err = "Bitte geben Sie Ihr Geburtsdatum ein";
              //   this.setState({DOB_err})}
                 if(validator.isEmpty(this.state.haus_no, {ignore_whitespace:true})){
                  const haus_err = "Bitte geben Sie Ihre Straße und Hausnummer ein";
                  this.setState({haus_err})
                }else{
                    this.setState({haus_err:undefined})
                  }
                   if(!validator.isNumeric(this.state.post_code)){
                    const post_err = "Bitte geben Sie Ihre Postleitzahl ein";
                    this.setState({post_err})
                  }else{
                      this.setState({post_err:undefined})
                    }
                     if(validator.isEmpty(this.state.city, {ignore_whitespace:true})){
                      const city_err = "Bitte geben Sie Ihr Ort ein";
                      this.setState({city_err})
                    }else{
                        this.setState({city_err:undefined})
                      }
                       if(this.state.countriesSelect === null){
                        const land_err = "Bitte geben Sie Ihr Land ein";
                        this.setState({land_err})
                      }else{
                          this.setState({land_err:undefined})
                        }
                         if(validator.isEmpty(this.state.telephone, {ignore_whitespace:true})){
                          const tele_err = "Bitte geben Sie Ihre Telefonnummer ein";
                          this.setState({tele_err})
                        }else{
                            this.setState({tele_err:undefined})
                          }
                          if(validator.isEmpty(this.state.about_me, {ignore_whitespace:true})){
                            const about_err = "Bitte füllen Sie das Feld Über mich aus";
                            this.setState({about_err})
                          }else{
                            this.setState({about_err:undefined})
                          }
    if(this.state.salutation !== null && !validator.isEmpty(this.state.f_name, {ignore_whitespace:true}) && !validator.isEmpty(this.state.l_name, {ignore_whitespace:true}) && !validator.isEmpty(this.state.haus_no, {ignore_whitespace:true}) && validator.isNumeric(this.state.post_code) && !validator.isEmpty(this.state.city, {ignore_whitespace:true}) && this.state.countriesSelect!==null && !validator.isEmpty(this.state.telephone, {ignore_whitespace:true}) && !validator.isEmpty(this.state.about_me, {ignore_whitespace:true})) {
      if(this.state.picture !== null){
        //console.log("upload with picture")
        if(this.state.salutation.label === undefined ){
          salutation1 = this.state.salutation;
         this.setState({salutation1})
         //console.log("from state",salutation1)
       }else{
          salutation1 = this.state.salutation.label;
         this.setState({salutation1})
         //console.log("from label",salutation1)
       }
      //  if(this.state.title.label === undefined ){
      //    title1 = this.state.title;
      //    //this.setState({title1:this.state.title})
      //    //console.log("from state",title1)
      //  }else{
      //    title1 = this.state.title.label;
      //    //this.setState({title1:this.state.title.label})
      //    //console.log("from label",title1)
      //  }
       if(this.state.countriesSelect.label === undefined ){
           countriesSelect1 = this.state.countriesSelect;
           this.setState({countriesSelect1})
           //console.log("from state",countriesSelect1)
           }else{
           countriesSelect1 = this.state.countriesSelect.label;
           this.setState({countriesSelect1})
           //console.log("from label",countriesSelect1)
           }
           //console.log("outside of if else",salutation1,title1,countriesSelect1)
        axios
			.post('/api2/updateuserprofile2', {...this.state,title1,salutation1,countriesSelect1})
			.then(response => {
        //console.log(response)
        const code = response.data;
        // if(code === "code1"){
        //   this.setState({redirect : true})
        // }else{
          const profilesucc = "Profil erfolgreich aktualisiert"
          this.setState({profilesucc})
          //this.setState({redirect1 : true})
        //}
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
			})
			.catch(error => {
        //console.log(error.response);
        const profileerr = error.response.data.Message;
        this.setState({profileerr});
        const f_name_err = error.response.data.fname_err;
        const l_name_err = error.response.data.lname_err;
        const haus_err = error.response.data.haus_no_err;
        const post_err = error.response.data.post_code_err;
        const city_err = error.response.data.city_err;
        const land_err = error.response.data.countriesSelect1_err;
        const tele_err = error.response.data.telephone_err;
        const about_err = error.response.data.about_me_err;
        this.setState({f_name_err,l_name_err,haus_err,post_err,city_err,land_err,tele_err,about_err})
      })
      }
      else{
        if(this.state.salutation.label === undefined ){
          salutation1 = this.state.salutation;
         this.setState({salutation1})
         //console.log("from state",salutation1)
       }else{
          salutation1 = this.state.salutation.label;
         this.setState({salutation1})
         //console.log("from label",salutation1)
       }
       
      //  if(this.state.title.label === undefined ){
      //    title1 = this.state.title;
      //    //this.setState({title1:this.state.title})
      //    //console.log("from state",title1)
      //  }else{
      //    title1 = this.state.title.label;
      //    //this.setState({title1:this.state.title.label})
      //    //console.log("from label",title1)
      //  }
       if(this.state.countriesSelect.label === undefined ){
           countriesSelect1 = this.state.countriesSelect;
           this.setState({countriesSelect1})
           //console.log("from state",countriesSelect1)
           }else{
           countriesSelect1 = this.state.countriesSelect.label;
           this.setState({countriesSelect1})
           //console.log("from label",countriesSelect1)
           }
           //console.log("outside of if else",salutation1,title1,countriesSelect1)
        axios
        .post('/api2/updateuserprofile1', {...this.state,title1,salutation1,countriesSelect1})
        .then(response => {
          //console.log(response)
          const code = response.data;
          
          // if(code === "code1"){
          //   this.setState({redirect : true})
          // }else{
            const profilesucc = "Profil erfolgreich aktualisiert"
            this.setState({profilesucc})
            // this.setState({redirect1 : true})
            //window.location.reload()
          //}
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        })
        .catch(error => {
          //console.log(error.response);
          const msg = error.response.data.Message;
          this.setState({msg});
          const f_name_err = error.response.data.fname_err;
          const l_name_err = error.response.data.lname_err;
          const haus_err = error.response.data.haus_no_err;
          const post_err = error.response.data.post_code_err;
          const city_err = error.response.data.city_err;
          const land_err = error.response.data.countriesSelect1_err;
          const tele_err = error.response.data.telephone_err;
          const about_err = error.response.data.about_me_err;
          this.setState({f_name_err,l_name_err,haus_err,post_err,city_err,land_err,tele_err,about_err})
        })
      }
		
    }
    
    }
/////////////////////////////////////////////////////optional area of picture

else{
   
  if(this.state.salutation === null){
        const andr_err = "Bitte geben Sie die Anrede ein";
        this.setState({andr_err})}
        //  if(this.state.title === null){
        //   const titel_err = "Bitte wählen Sie ein Titel aus";
        //   this.setState({titel_err})}
           if(this.state.f_name === ''){
            const f_name_err = "Bitte geben Sie Ihren Vornamen ein";
            this.setState({f_name_err})}
             if(this.state.l_name === ''){
              const l_name_err = "Bitte geben Sie Ihren Nachnamen ein";
              this.setState({l_name_err})}
              //  if(this.state.date_of_birth === null){
              //   const DOB_err = "Bitte geben Sie Ihr Geburtsdatum ein";
              //   this.setState({DOB_err})}
                 if(this.state.haus_no === ''){
                  const haus_err = "Bitte geben Sie Ihre Straße und Hausnummer ein";
                  this.setState({haus_err})}
                   if(this.state.post_code === ''){
                    const post_err = "Bitte geben Sie Ihre Postleitzahl ein";
                    this.setState({post_err})}
                     if(this.state.city === ''){
                      const city_err = "Bitte geben Sie Ihr Ort ein";
                      this.setState({city_err})}
                       if(this.state.countriesSelect === null){
                        const land_err = "Bitte geben Sie Ihr Land ein";
                        this.setState({land_err})}
                         if(this.state.telephone === ''){
                          const tele_err = "Bitte geben Sie Ihre Telefonnummer ein";
                          this.setState({tele_err})}
                           if(this.state.about_me === ''){
                            const about_err = "Bitte füllen Sie das Feld Über mich aus";
                            this.setState({about_err})}
                            if(this.state.salutation !== null && this.state.f_name !== '' && this.state.l_name!=='' && this.state.haus_no!=='' && this.state.post_code!=='' && this.state.city!=='' && this.state.countriesSelect!==null&& this.state.telephone !=='' && this.state.about_me !== '') {
                              if(this.state.salutation.label === undefined ){
                                salutation1 = this.state.salutation;
                               this.setState({salutation1})
                               //console.log("from state",salutation1)
                             }else{
                                salutation1 = this.state.salutation.label;
                               this.setState({salutation1})
                               //console.log("from label",salutation1)
                             }
                             
                            //  if(this.state.title.label === undefined ){
                            //    title1 = this.state.title;
                            //    //this.setState({title1:this.state.title})
                            //    //console.log("from state",title1)
                            //  }else{
                            //    title1 = this.state.title.label;
                            //    //this.setState({title1:this.state.title.label})
                            //    //console.log("from label",title1)
                            //  }
                             if(this.state.countriesSelect.label === undefined ){
                                 countriesSelect1 = this.state.countriesSelect;
                                 this.setState({countriesSelect1})
                                 //console.log("from state",countriesSelect1)
                                 }else{
                                 countriesSelect1 = this.state.countriesSelect.label;
                                 this.setState({countriesSelect1})
                                 //console.log("from label",countriesSelect1)
                              }
                              
                                    // new Compressor(this.state.file, {
                                    //   quality: 0.6,
                                    //   //user_id: this.state.user_id,
                                    //   success(result1) {
                                    //     console.log(result1)
                                    //     test = result1;
                                    //     console.log("user_id",useState)
                                    //       },
                                    // });
                              //ddconsole.log(file.File.size)
                                 //console.log("outside of if else",salutation1,title1,countriesSelect1)  
    
                               
                                
                                const data = new FormData()         
    data.set('user_id',this.state.user_id)
    data.set('salutation',salutation1)
    data.set('title',title1)
    data.set('f_name',this.state.f_name)
    data.set('l_name',this.state.l_name)
    //data.set('date_of_birth',this.state.date_of_birth)
    data.set('haus_no',this.state.haus_no)
    //data.set('company_address',this.state.company_address)
    data.set('post_code',this.state.post_code)
    data.set('city',this.state.city)
    data.set('telephone',this.state.telephone)
    data.set('about_me',this.state.about_me)
    data.set('countriesSelect',countriesSelect1)
   // data.append('file', result);
   data.append('file', this.state.file)
		axios
			.post('/api2/updateuserprofile', data)
			.then(response => {
        //console.log(response)
        const code = response.data;
        // if(code === "code1"){
        //   this.setState({redirect : true})
        // }else{
          const profilesucc = "Profil erfolgreich aktualisiert"
          this.setState({profilesucc})
          //this.setState({redirect1 : true})
        //}
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
			})
			.catch(error => {
        //console.log(error.response);
        const msg = error.response.data.Message;
        this.setState({msg});
        const f_name_err = error.response.data.fname_err;
        const l_name_err = error.response.data.lname_err;
        const haus_err = error.response.data.haus_no_err;
        const post_err = error.response.data.post_code_err;
        const city_err = error.response.data.city_err;
        const land_err = error.response.data.countriesSelect1_err;
        const tele_err = error.response.data.telephone_err;
        const about_err = error.response.data.about_me_err;
        this.setState({f_name_err,l_name_err,haus_err,post_err,city_err,land_err,tele_err,about_err})
      })
          
  

    }
  }
}
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
}
  componentDidMount(){
    console.log(this.state)
    axios
    .post('/api2/userprofile', this.state)
      .then(response => {
      this.setState({profilesucc:undefined})
      console.log(response)
      const err = response.data.length;
      this.setState({err})
      console.log(err)
     //this.setState({salutation})
    //  const title = response.data[0].title;
    //  this.setState({title})
    //  localStorage.setItem('title',JSON.stringify(title));
    // console.log(title)
     const salutation = response.data[0].salutation;
     this.setState({salutation})
     localStorage.setItem('salutation',JSON.stringify(salutation));
     console.log(salutation)
     const f_name = response.data[0].f_name;
     this.setState({f_name})
     localStorage.setItem('f_name',JSON.stringify(f_name));
     console.log(f_name)
     const l_name = response.data[0].l_name;
     this.setState({l_name})
     localStorage.setItem('l_name',JSON.stringify(l_name));
     const haus_no = response.data[0].haus_no;
     this.setState({haus_no})
     const post_code = response.data[0].post_code;
     this.setState({post_code})
     const city = response.data[0].city;
     this.setState({city})
     const countriesSelect = response.data[0].country;
     this.setState({countriesSelect})
     const niceDate = response.data[0].niceDate;
     this.setState({niceDate})
     const telephone = response.data[0].telephone;
     this.setState({telephone})
     const about_me = response.data[0].about_me;
     this.setState({about_me})
     const userprofile = response.data[0].user_profile;
     this.setState({userprofile})
     const picture = response.data[0].picture;
     this.setState({picture})
    console.log(picture);
     localStorage.userprofile('userprofile',JSON.stringify(userprofile));
    
    
    
    //console.log(andre)
    })
    .catch(error => {
      console.log(error.response);
      // const msg = error.response.data.Message;
      // this.setState({msg});
    })
    	
  }
  componentWillMount(){
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    this.setState({ user_id })
    console.log("user ID",user_id)
    // const fname = JSON.parse(localStorage.getItem('fname'))
    // this.setState({fname})
    // const lname = JSON.parse(localStorage.getItem('lname'))
    // this.setState({lname})
  }
  removeImage(e){
    console.log(this.state)
    axios.post("/api2/profiledelpic", this.state)
     .then(response => { // then print response status
      // const msg = response.data.Message;
      // this.setState({msg});
       console.log(response)
       this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
      //this.setState({redirect : true})
      //window.location.reload();
    })
    .catch(error => {
     console.log(error);
   })
  }
  componentWillUnmount(){
  clearTimeout(this.intervalID);
}
  render() {
    const { redirect } = this.state;
     if (redirect) {
        return <Redirect to='/admin/beruf'/>;
      }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
            {/* <Form className="form" method="" onSubmit={this.handleSubmit}> */}
              <Card>
                <CardBody>
                <Row>
            <Col md="12">
            <Form className="form" method="" onSubmit={this.handleSubmit}>
             
                <CardHeader>
                  <h3 className="title text-center">Profil</h3>
                  <Badge color="warning">{this.state.msg}</Badge>
                 
                  <Badge color="warning">{this.state.msg2}</Badge>
                  <Badge color="warning">{this.state.msg3}</Badge>
                  <Badge color="warning">{this.state.msg4}</Badge>
                  <Badge color="warning">{this.state.msg5}</Badge>
                  <Badge color="warning">{this.state.msg6}</Badge>
                  <Badge color="warning">{this.state.msg7}</Badge>
                  <Badge color="warning">{this.state.msg8}</Badge>
                  <Badge color="warning">{this.state.msg9}</Badge>
                  <Badge color="warning">{this.state.msg10}</Badge>
                  <Badge color="warning">{this.state.msg11}</Badge>
                  <Badge color="warning">{this.state.msg12}</Badge>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Row>
            <Col md="12">
            {/* {admin.map(() => {
                          return (
                            <> */}
                             <div className="text-center">
             
             {this.state.err ===  0 ? 
          <span>   <div className="fileinput text-center">
          <input type="file" onChange={this.handleImageChange} ref="fileInput"  name = "file" />
          <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          
            <img src={`${this.state.imagePreviewUrl}`} alt="..." />
          
            {/* <img src={`/uploads/${this.state.picture}`} alt="..." /> */}
          
          </div>
         
          <div>
            {this.state.file === null ? (
             <span>  <Button color="primary" style={{width: '147px',
              height: '65px',
              fontSize: '11px'}} onClick={() => this.handleClick()}>
             {this.props.avatar ? "Ihr Photo" : "Profilfoto auswählen  (Optional)"} 
           </Button>
           </span> 
  
            ) : (
              <span>
                <Button color="primary" style={{width: '112px',
              height: '42px',
              fontSize: '11px'}} onClick={() => this.handleClick()}>
                  Ändern
                </Button>
                {this.props.avatar ? <br /> : null}
                <Button
                  color="danger"
                  style={{width: '112px',
                  height: '42px',
                  fontSize: '9px',
                  borderRadius: '32px',
                  boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                  fontWeight: '400',
                  border: 'none !important',
                  padding: '13px 27px',
                  background: '#dc3545'
                }}
                  onClick={() => this.handleRemove()}
                >
                  <i className="fa fa-times" />
                  Löschen
                </Button>
              </span>
            )}
          </div>
        </div> </span>
          :
          <span>   
            {this.state.picture === null ?  
            <div className="fileinput text-center">
               <input type="file" onChange={this.handleImageChange} ref="fileInput"  name = "file" />
               <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
               
                 <img src={`${this.state.imagePreviewUrl}`} alt="..." />
               
                 {/* <img src={`/uploads/${this.state.picture}`} alt="..." /> */}
               
               </div>
              
               <div>
                 {this.state.file === null ? (
                  <span>  <Button color="primary" style={{width: 'auto',
                   height: 'auto',
                   fontSize: 'auto'}} onClick={() => this.handleClick()}>
                  {this.props.avatar ? "Ihr Photo" : "Wählen"} 
                </Button>
                </span> 
       
                 ) : (
                   <span>
                     <Button color="primary" style={{width: '112px',
                   height: '42px',
                   fontSize: '11px'}} onClick={() => this.handleClick()}>
                       Ändern
                     </Button>
                     {this.props.avatar ? <br /> : null}
                     <Button
                       color="danger"
                       style={{width: '112px',
                       height: '42px',
                       fontSize: '9px',
                       borderRadius: '32px',
                       boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                       fontWeight: '400',
                       border: 'none !important',
                       padding: '13px 27px',
                       background: '#dc3545'
                     }}
                       onClick={() => this.handleRemove()}
                     >
                       <i className="fa fa-times" />
                       Löschen
                     </Button>
                   </span>
                 )}
               </div>
             </div> :  
        <span> 
                  <img src ={`../profile/${this.state.picture}`} alt='' height = '200' width = 'auto' />
                  <br></br><br></br>
            
               <Button
                  color="danger"
                  style={{width: '112px',
                  height: '42px',
                  fontSize: '9px',
                  borderRadius: '32px',
                  boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                  fontWeight: '400',
                  border: 'none !important',
                  padding: '13px 27px',
                  background: '#dc3545'
                }}
                onClick={() => this.removeImage()}
              >
                
                Löschen</Button>
              {/*     </Col><Col> <br></br> <input type="file" name="myImage"   
                  onChange={this.handleImageChange} ref="fileInput"  
                  accept="image/x-png,image/gif,image/jpeg"
             
              /></Col></Row>   */}

                </span>
               }
       </span> }
             
           </div>
           <br></br>
           <Row>
             <Col md = '2'></Col>
             <Col md = '8'><Row>
             <Col className="" md="6">   <FormGroup>
               
               <label>Anrede</label>
                 <Badge color="warning">{this.state.andr_err}</Badge>
               
                 <Select
                  // backgroundColor = '#f1f1f1'
                   className="primary"
                   classNamePrefix="react-select "
                   name="salutation"
                   value={this.state.salutation}
                   onChange={value =>
                     this.setState({ salutation: value })
                   }
                  //onChange={this.changeHandler}
                   options={salutation}
                   placeholder={this.state.salutation}
                   //placeholder="Bitte auswählen"
                   styles={customStyles}
                 />
               </FormGroup> 
             </Col>
          
             {/* <Col className="" md="6"> 
               <FormGroup>
                 <label>Titel</label>
                 <Badge color="warning">{this.state.titel_err}</Badge>
               
                 <Select
                  // className="react-select primary"
                   classNamePrefix="react-select"
                   name="title"
                   //defaultValue={this.state.title}
                   onChange={value =>
                     this.setState({ title: value })
                   }
                  //onChange={this.changeHandler}
                   options={title}
                   placeholder={this.state.title}
                   styles={customStyles}
                 />
               </FormGroup>
             </Col> */}
             <Col className="" md="6">
               <FormGroup>
                 <label>Vorname</label>
                 <Badge color="warning">{this.state.f_name_err}</Badge>
               
                 <Input
                  style={{backgroundColor: '#f1f1f1'}}
                  

                   value={this.state.f_name}
                   //placeholder="Vorname"
                   type="text"
                   name = "f_name"
                   onChange={this.changeHandler}
                  // required
                 />
               </FormGroup>
             </Col>
           </Row>
                   <br></br>
           <Row>
          
             <Col className="" md="6">
               <FormGroup>
                 <label>Nachname</label>
                 <Badge color="warning">{this.state.l_name_err}</Badge>
               
                 <Input
                   value={this.state.l_name}
                   placeholder="Nachname"
                   type="text"
                  name ="l_name"
                  onChange={this.changeHandler}
                  //required
                  style={{backgroundColor: '#f1f1f1'}}
                 />
               </FormGroup>
             </Col>

             <Col md="6">
             <FormGroup>
             <label className="title">Straße und Hausnummer</label>
                      <Badge color="warning">{this.state.haus_err}</Badge>
               
               <Input
                        //placeholder="Bitte kommen Sie herein"
                        type="Text"
                        autoComplete="off"
                        name = "haus_no"
                        value = {this.state.haus_no}   
                        onChange={this.changeHandler}
                        style={{backgroundColor: '#f1f1f1'}}
                        //required 
                      />
                      </FormGroup>
             </Col>

           </Row>
       
               <Row>
                <Col md = '12'>
                <FormGroup> 
               {/* <label className="title">Geburtsdatum</label>
               <Badge color="warning">{this.state.DOB_err}</Badge>
               <Input
                style={{backgroundColor: '#f1f1f1'}}
                         placeholder="DD.MM.YYYY"
                        type="date"
                        autoComplete="off"
                        name = "date_of_birth"  
                        value = {this.state.date_of_birth}
                        onChange={this.changeHandler} 
                        lang="de-DE"
                        //required
                      /> */}
                      {/* <br></br>
                      <br></br> */}
                    
               
                      <br></br>
                      <br></br>
                      <Row>
                        <Col>
                      <label className="title">Postleitzahl</label>
                      <Badge color="warning">{this.state.post_err}</Badge>
               
               <Input
                        //placeholder="Bitte kommen Sie herein"
                        value = {this.state.post_code}
                        type="text"
                        autoComplete="off"
                        name = "post_code"  
                        onChange={this.changeHandler}
                        //required  
                        style={{backgroundColor: '#f1f1f1'}}
                      />
                      </Col>
                      <Col>
                      <label className="title">Ort</label>
                      <Badge color="warning">{this.state.city_err}</Badge>
               
               <Input
                        //placeholder="Bitte kommen Sie herein"
                        type="Text"
                        autoComplete="off"
                        name = "city" 
                        value = {this.state.city}
                        onChange={this.changeHandler}
                        style={{backgroundColor: '#f1f1f1'}}
                        //required   
                      />
                         {/* <GooglePlacesAutocomplete
                     // value={this.state.statesSelect}
                     // value={city}
                     //onChange={value =>
                     //  this.setState({ cities: value })}
                   // onChange={(e)=> this.handleChangeCity(e, index)}
                      onSelect={({description})=>(this.setState({city:description}))}
                      // onSelect={({description})=>(this.handleChangeCity(, description))}
                     autocompletionRequest={{
                       componentRestrictions: {
                         country: 'de'
                       },
                       types: ["(regions)"],
                       terms:[
                             'cities'
                       ]
                     }}
                     placeholder={this.state.city}
                     inputClassName='form-control'
                     inputStyle={{height: '38px'}}
                   required
                  
                   /> */}
                      </Col>
                      </Row>
                      <br></br>
                      <br></br>
                      <label className="title">Land</label>
                      <Badge color="warning">{this.state.land_err}</Badge>
               
                      <Select
                  //  className="react-select primary"
                   classNamePrefix="react-select"
                   name="countriesSelect"
                  // defaultValue={{ label: , value: 0 }}
                   // defaultValue={{ label: this.state.countriesSelect, value: this.state.countriesSelect}}
                   //value={this.state.countriesSelect}
                   onChange={value =>
                     this.setState({ countriesSelect: value })
                   }
                   options={countries}
                   placeholder={this.state.countriesSelect}
                   styles={customStyles}
                 />
                      <br></br> 
                       <br></br>
                      <label className="title">Telefon</label>
                      <Badge color="warning">{this.state.tele_err}</Badge>
{/*                
               <Input
                        placeholder="Bitte kommen Sie herein"
                        type = "telephone"
                        autoComplete="off"
                        name = "telephone"  
                        value = {this.state.telephone}
                        onChange={this.changeHandler}
                        required  
                        style={{backgroundColor: '#f1f1f1'}}
                      /> */}
                       <PhoneInput
                            country={'de'}
                            inputStyle={{ width:'100%',backgroundColor: '#f1f1f1'}}
                            value={this.state.telephone}
                            onChange={telephone => this.setState({ telephone })}
                            placeholder={"49 1629 123456"}
                          />
                      <br></br>
                      <br></br>
                      <label className="title">Über mich</label><Badge color="warning">{this.state.about_err}</Badge>

                      <br></br>
                     
                      {/* <Textarea
                      className = "bordor_textaea"
                      rows='6'
                      cols='50'
                      name="about_me"
                      placeholder="Beschreiben Sie kurz Ihre Erfahrungen, Motivation und Ziele. Es muss nicht perfekt sein, Sie können diese später jederzeit bearbeiten."
                      //value={this.state.value} 
                      onChange={this.changeHandler}
                       /> */}
                      {/* <br></br> */}
                      <Input 
                      //  style={{backgroundColor: '#f1f1f1'}}
                    style={{fontSize: '15px',
                    backgroundColor: '#f1f1f1'}}
                    type="textarea"
                        rows = "15"
                        cols = "5"
                      name="about_me"
                      value={this.state.about_me}
                      //placeholder="Beschreiben Sie kurz Ihre Erfahrungen, Motivation und Ziele. Es muss nicht perfekt sein, Sie können diese später jederzeit bearbeiten."
                      onChange={this.changeHandler}
                      />
                      
                </FormGroup>
                </Col>
                 
              </Row>
                 
              <br></br>
         
             <h4><Badge color="success">{this.state.profilesucc}</Badge><Badge color="danger">{this.state.profileerr}</Badge> </h4> 
           </Col>
           
           
             <Col md = '2'></Col>
           </Row>
           
   
             
          
                  {/* </Form>    */}
         
            </Col>
          </Row>
                     
                </CardBody>
                <CardFooter>
                <div className="text-center">
                <Button    color="primary" type ="submit">Speichern</Button> 
                </div>
                </CardFooter>
              
              </Form>
            </Col>
          </Row> 
                </CardBody>
              </Card>
                   
              {/* </Form> */}
            </Col>
          </Row> 
        </div>
      </>
    );
  }
}
export default CandidateProfile;
