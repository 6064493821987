import React, {useState, useEffect} from "react";
import "./style1.css";
import axios from "axios";
import {Redirect} from "react-router-dom";
import {Button, Card, CardHeader, Row, Col} from "reactstrap";
import DOMPurify from "dompurify";

const Imprint = (props) => {
  const [state, setState] = useState({
    salutationSelect: null,
    titleSelect: null,
    hosp: [],
    offset: 0,
    perPage: 1,
    currentPage: 0,
    state1: false,
    value: "",
    user_id: null,
    hosp_id: null,
    job_id: null,
    company_name: "",
    address: "",
    haus_noo: "",
    zip_code: "",
    cityy: "",
    today: "",
    app_id: null,
    f_name: "",
    l_name: "",
    haus_no: "",
    post_code: "",
    city: "",
    redirect: false,
    msg: "",
    contract_id: null,
  });

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    setState((prevState) => ({...prevState, user_id}));

    const {applyjob_id} = props.match.params;
    fetchCandidContract(applyjob_id);
  }, [props.match.params]);

  const fetchCandidContract = (applyjob_id) => {
    axios
      .post("/api2/candidcontract", {applyjob_id})
      .then((response) => {
        const hospitalData = response.data.hospital[0];
        const candidateData = response.data.candidate[0];
        console.log(candidateData);
        setState((prevState) => ({
          ...prevState,
          hosp_id: hospitalData.hosp_id,
          job_id: hospitalData.job_id,
          company_name: hospitalData.company_name,
          address: hospitalData.address,
          haus_noo: hospitalData.haus_no,
          zip_code: hospitalData.zip_code,
          city: hospitalData.city,
          today: hospitalData.today,
          app_id: candidateData.app_id,
          f_name: candidateData.f_name,
          l_name: candidateData.l_name,
          haus_no: candidateData.haus_no,
          post_code: candidateData.post_code,
          app_city: candidateData.city,
          contract_id: candidateData.contract_id,
        }));
        fetchEditorContract(candidateData.contract_id);
      })
      .catch((error) => {
        console.log(error.response);
        setState((prevState) => ({
          ...prevState,
          msg: error.response.data.Message,
        }));
      });
  };

  const fetchEditorContract = (contract_id) => {
    axios
      .post("/api2/GetEditorContractCandidate_single_download", {contract_id})
      .then((response) => {
        const value = response.data.result[0].EditorDesc;
        // console.log(value)
        setState((prevState) => ({...prevState, value}));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post("/api2/submitcontractapp", state)
      .then((response) => {
        setState((prevState) => ({...prevState, redirect: true}));
      })
      .catch((error) => {
        console.log(error.response);
        setState((prevState) => ({
          ...prevState,
          msg: error.response.data.Message,
        }));
      });
  };

  const variables = {
    f_name: state.f_name,
    l_name: state.l_name,
    applicant_house: state.haus_no,
    post_code: state.post_code,
    city: state.app_city,
    company_name: state.company_name,
    address: state.address,
    job_house: state.haus_noo,
    zip_code: state.zip_code,
    hospital_city: state.city,
    contract_date_candid: state.contract_date_candid,
  };

  const replacePlaceholders = (template, variables) => {
    return template.replace(/{{(\w+)}}/g, (match, key) => {
      return variables[key] || match;
    });
  };

  const replacedContent = replacePlaceholders(state.value, variables);

  const {redirect} = state;
  if (redirect) {
    return <Redirect to="/admin/ContractEnd" />;
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                {/* <h5 className="cstm-heading-backend">
                  <b>Kandidaten Vertrag</b>
                </h5>
                <hr></hr>
                <b>
                  <center>
                    Vertrag zwischen der Vermittlungsagentur (VA) und dem
                    Vermittlungssuchenden (VS) für Festanstellung
                  </center>
                </b>
                <br></br>
                Zwischen der Gül GmbH, vertreten durch den Geschäftsführer Deniz
                Gül, An der Trift 40, 66123 Saarbrücken<br></br>
                <br></br>- nachfolgend: „Vermittlungsagentur“ bzw. „VA“ -
                <br></br>
                <br></br>
                und
                <br></br>
                <br></br>
                <b>
                  {state.f_name} {state.l_name}, {state.haus_no},{" "}
                  {state.post_code} {state.city}
                </b>
                <br></br>
                <br></br>- nachfolgend: „Vermittlungssuchender“ bzw. „VS“ -
                <br></br>
                <br></br>
                <br></br>
                wird nachfolgende Vereinbarung zum Zwecke der Vermittlung einer
                Anstellung mit der Einrichtung{" "}
                <b>
                  {state.company_name}, {state.address} {state.haus_noo},{" "}
                  {state.zip_code} {state.cityy}.
                </b>{" "}
                getroffen:
                <br></br>
                <br></br>
                <br></br> */}
                <div
                  style={{fontFamily: "times new roman", color: "black"}}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(replacedContent),
                  }}></div>
                <Row>
                  <Col>
                    <b>Saarbrücken, {state.today}</b>{" "}
                  </Col>
                  <Col>
                    <b>………………………………</b>
                  </Col>
                </Row>{" "}
                <br></br>
                <Row>
                  <Col>
                    {" "}
                    <u>Ort, Datum</u>{" "}
                  </Col>
                  <Col>
                    <u>Ort, Datum</u>
                  </Col>
                </Row>
                <br></br>
                <br></br>
                <Row>
                  <Col>
                    <img src="/sign.png" alt="" height="50" width="100" />{" "}
                    <br></br>{" "}
                    <img src="/stamp.png" alt="" height="50" width="100" />{" "}
                  </Col>
                  <Col>
                    <b>………………………………</b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <u>Unterschrift VA </u>{" "}
                  </Col>
                  <Col>
                    <u>Unterschrift VS</u>
                  </Col>
                </Row>
                <br></br>
                <br></br>
                <Button color="primary" onClick={submitHandler}>
                  Send
                </Button>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Imprint;
