import React from "react";
import Axios from 'axios';
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  Form,
  FormGroup,
  Table,
  Badge,
  CardFooter
} from "reactstrap";
//import ComponentInvoice from "./component_v2"


class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthSelect : null,
      dateSelect : null,
      data : [],
      data1 : [],
      data2 : [],
      bill : [],
      error: [],
      visible : false,
      state1: false,
      state2: false,
      job_id: '',
      currentMonth : []
    };
   
  }
  componentWillMount(){
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    this.setState({user_id})
  }
  componentDidMount(){
    Axios.get("/api2/currentmonthincome", this.state)
   .then(response => {
    console.log(response)
    const currentMonth = response.data
    this.setState({currentMonth})
    console.log(currentMonth);
    const helloMedic = currentMonth.totalhm
    this.setState({helloMedic})
    console.log(helloMedic);
    const basictotalhm = currentMonth.basictotalhm
    this.setState({basictotalhm})
    console.log(basictotalhm);
    const premiumtotalhm = currentMonth.premiumtotalhm
    this.setState({premiumtotalhm})
    console.log(premiumtotalhm);
    const pricebasic = currentMonth.pricebasic
    this.setState({pricebasic})
    console.log(pricebasic);
    const pricepremium = currentMonth.pricepremium
    this.setState({pricepremium})
    console.log(pricepremium);
    const jobs = currentMonth.totalamountjobs
    this.setState({jobs})
    console.log(jobs);
    const total = helloMedic + jobs ;
    this.setState({total})
    console.log(total);
 })
 .catch(error => {
  console.log(error);
})
  }
  submitHandler = e =>{
    e.preventDefault();
    console.log(this.state)
    if(this.state.dateSelect === null){
      const montherr = "Bitte Monat auswählen"
      this.setState({montherr})
    }else{
      console.log(this.state)
      var month = this.state.dateSelect._d.getMonth() + 1; 
      console.log(month)
     var year = this.state.dateSelect._d.getFullYear()
     console.log(year)
     Axios.post("/api2/monthincome", {...this.state,month,year})
     .then(response => {
     console.log(response)
     const currentMonth1 = response.data
     this.setState({currentMonth1})
     console.log(currentMonth1);
     const data1 = response.data.totalhm;
     this.setState({data1})
     console.log(data1)
     const data2 = response.data.totalamountjobs;
     this.setState({data2})
     console.log(data2)
     const basictotalhm1 = currentMonth1.basictotalhm
     this.setState({basictotalhm1})
     console.log(basictotalhm1);
     const premiumtotalhm1 = currentMonth1.premiumtotalhm
     this.setState({premiumtotalhm1})
     console.log(premiumtotalhm1);
     const pricebasic1 = currentMonth1.pricebasic
     this.setState({pricebasic1})
     console.log(pricebasic1);
     const pricepremium1 = currentMonth1.pricepremium
     this.setState({pricepremium1})
     console.log(pricepremium1);
     const total1 = data1 + data2 ;
     this.setState({total1})
     console.log(total1);
     this.setState({state1:true})
     this.setState({state2:false})
 })
 .catch(error => {
  console.log(error);
  const montherr = error.response.data.msg;
  this.setState({montherr})
  this.setState({state1:false})
  this.setState({state2:true})

})
  }
}
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
            <Card>
            <CardHeader>
            <h5 className="title">Einnahmen</h5>
            </CardHeader>
         <hr></hr>
        <CardBody>
          <Form>
          <Row>
    <Col md="12">
    
                    <Row>
     
     <Col className="" md="12">
       
        <FormGroup>
        <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center" >Packages</th>
                        <th className="text-center">Search Hello-Medic</th>
                        <th className="text-center">Stellenanzeigen</th>
                      </tr>
                    </thead>
                    <tbody>
                   
                  
                         
                           
                              <tr>
                             <td className="text-center">
                            <medium className="text-italic">
                            <b><u>Basic :</u> </b> <br></br>
                            <b><u>Premium :</u></b> <br></br>
                            <hr></hr>
                            <b><u>Total :</u> </b>
                            </medium>
                            </td> 
                              <td className="text-center" >
                            <medium className="text-italic">
                            <b> {this.state.basictotalhm}</b> <br></br>
                            <b> {this.state.premiumtotalhm}</b> <br></br>
                            <hr></hr>
                            <b> {this.state.helloMedic}</b>
                            </medium>
                            </td> 
                        <td className="text-center" >
                        <medium className="text-italic">
                        <b>{this.state.pricebasic}</b> <br></br>
                        <b>{this.state.pricepremium}</b> <br></br>
                        <hr></hr>
                        <b>{this.state.jobs}</b>      
                            </medium>
                        
                        </td> 
                        </tr>
                          
                 
                    </tbody>
                 
                  </Table>
                </CardBody>
                <CardFooter>
                  <center>
                    <b><u> Total Ammount</u></b> 
                    <br></br>
                    <br></br>
                    <b>{this.state.total}</b>
                    
                  </center>
                </CardFooter>
        </FormGroup> 
     </Col>
     </Row>
     
               </Col>
  </Row>
          </Form>
        </CardBody>
      </Card>
              <Card>
                <CardBody>
                   <label>Wählen Sie den Monat aus</label>
                <ReactDatetime
                           locale="de"
                           dateFormat="YYYY-MM" 
                           inputProps={{
                             className: "form-control",
                             placeholder: "Bitte auswählen"
                           }}
                           timeFormat={false}
                           closeOnSelect = {true}
                           name="monthSelect"
                          
                          onChange={value =>
                            this.setState({ dateSelect: value })
                          }
                           value={this.state.dateSelect}
                     />
                     <Button color="info" onClick={this.submitHandler}>Rechnungen anzeigen</Button>
                     <hr></hr>
                </CardBody>
                        {this.state.state2  ? <CardFooter><Badge color = "danger" style = {{fontSize: '15px'}}>{this.state.montherr}</Badge></CardFooter>  : null}
                {this.state.state1 ? <CardFooter>
                
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center" >Packages</th>
                        <th className="text-center">Search Hello-Medic</th>
                        <th className="text-center">Stellenanzeigen</th>
                      </tr>
                    </thead>
                    <tbody>
                   
                  
                         
                           
                              <tr>
                             <td className="text-center">
                            <medium className="text-italic">
                            <b><u>Basic :</u> </b> <br></br>
                            <b><u>Premium :</u></b> <br></br>
                            <hr></hr>
                            <b><u>Total :</u> </b>
                            </medium>
                            </td> 
                              <td className="text-center" >
                            <medium className="text-italic">
                            <b> {this.state.basictotalhm1}</b> <br></br>
                            <b> {this.state.premiumtotalhm1}</b> <br></br>
                            <hr></hr>
                            <b> {this.state.data1}</b>
                            </medium>
                            </td> 
                        <td className="text-center" >
                        <medium className="text-italic">
                        <b>{this.state.pricebasic1}</b> <br></br>
                        <b>{this.state.pricepremium1}</b> <br></br>
                        <hr></hr>
                        <b>{this.state.data2}</b>      
                            </medium>
                        
                        </td> 
                        </tr>
                          
                 
                    </tbody>
                 
                  </Table>

                  <CardFooter>
                  <center>
                    <b><u> Total Ammount</u></b> 
                    <br></br>
                    <br></br>
                    <b>{this.state.total1}</b>
                    
                  </center>
                </CardFooter>
                   
                </CardFooter> : null }
                
               
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Panels;

