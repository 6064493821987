import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Button, Row, Col } from 'reactstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContractEditor = () => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("user_id");

  const submitHandler = async () => {
    setLoading(true);
    try {
      await axios.post('/api2/EditorContractCandidateNew', { value, user_id: userId });
      toast.success("Content saved successfully!");
    } catch (error) {
      console.error("Error saving content:", error);
      toast.error("Failed to save content.");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api2/GetEditorContractCandidate');
      const fetchedValue = response.data.result[0]?.EditorDesc || '';
      setValue(fetchedValue);
    } catch (error) {
      console.error("Error fetching content:", error);
      toast.error("Failed to fetch content.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content">
      <ToastContainer />
      <div style={{ background: 'white' }}>
        <Row>
          <Col md="8">
            <h4 style={{ textAlign: "center" }}>Vertrag Kandidaten (New Version)</h4>
          </Col>
          <Col md="4">
            <Link to="/admin/AGBEditor_New_Version_Kandidaten">
              <Button style={{ marginTop: '25px' }} color="primary">
                Add New (Kandidaten)
              </Button>
            </Link>
          </Col>
        </Row>
        <SunEditor
          setContents={value}
          onChange={setValue}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['paragraphStyle', 'blockquote'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['removeFormat'],
              '/', // Line break
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['table', 'link'],
              ['fullScreen', 'showBlocks', 'codeView'],
              ['preview', 'print'],
              ['save', 'template'],
            ],
          }}
        />
        <Button
          style={{ float: 'right' }}
          color="primary"
          onClick={submitHandler}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default ContractEditor;
