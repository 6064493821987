import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContractEditor = () => {
  const [value, setValue] = useState('');
  const userId = localStorage.getItem("user_id");

  const submitHandler = async () => {
    try {
      await axios.post('/api2/EditorContractCompanyNew', { value, user_id: userId });
      toast.success("Content saved successfully!");
    } catch (error) {
      console.error("Error saving content:", error);
      toast.error("Failed to save content.");
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post('/api2/GetEditorContractCompany');
      const fetchedValue = response.data.result[0]?.EditorDesc || '';
      setValue(fetchedValue);
    } catch (error) {
      console.error("Error fetching content:", error);
      toast.error("Failed to fetch content.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content">
      <ToastContainer />
      <Row>
        <Col md="8">
          <h4>Vertrag Unternehmen (New Version)</h4>
        </Col>
      </Row>
      <br /> <br />
      <div>
        <SunEditor
          setContents={value}
          onChange={setValue}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['paragraphStyle', 'blockquote'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['removeFormat'],
              '/', // Line break
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['table', 'link'],
              ['fullScreen', 'showBlocks', 'codeView'],
              ['preview', 'print'],
              ['save', 'template'],
            ]
          }}
        />
        <Button
          style={{ float: 'right' }}
          color="primary"
          onClick={submitHandler}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ContractEditor;
