import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import classnames from "classnames";
import Button_loading from "components/Button_loading";
import Moment from "moment";
import SunEditor from "suneditor-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AGBEditor = () => {
  const [value, setValue] = useState(""); // Editor content
  const [loading, setLoading] = useState(false); // Loading state for button
  const [disabled, setDisabled] = useState(false); // Disabled state for button
  const [activeTab, setActiveTab] = useState("1"); // Active tab state
  const [DatenList, setDatenList] = useState([]); // List of archived Datenschutz items
  const [DatenId, setDatenId] = useState(null); // ID of the current Datenschutz item
  const userId = localStorage.getItem("user_id"); // Get user ID from localStorage

  // Function to fetch the latest Datenschutz item
  const fetchEditorData = useCallback(async () => {
    setLoading(true);
    setDisabled(true);
    try {
      const response = await axios.post("/api2/GetEditorDatenschutz", { user_id: userId });
      const editorData = response.data.result[0];
      setValue(editorData.EditorDesc);
      setDatenId(editorData.Daten_id);
    } catch (error) {
      console.error("Error fetching EditorDatenschutz:", error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, [userId]);

  // Function to fetch all Datenschutz items (excluding latest)
  const fetchDatenList = useCallback(async () => {
    try {
      const response = await axios.post("/api2/GetEditorDatenschutzAll", { user_id: userId });
      setDatenList(response.data.result);
    } catch (error) {
      console.error("Error fetching Datenschutz list:", error);
    }
  }, [userId]);

  // Initial data fetch on mount
  useEffect(() => {
    fetchEditorData();
    fetchDatenList();
  }, [fetchEditorData, fetchDatenList]);

  // Function to handle saving the edited Datenschutz item
  const submitHandler = async () => {
    setLoading(true);
    setDisabled(true);
    try {
      await axios.post("/api2/EditorDatenschutz", { value, Daten_id: DatenId });
      fetchEditorData(); // Refetch to update with latest data
      fetchDatenList(); // Refetch to update archived list
      toast.success("Datenschutz saved successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Error saving Datenschutz. Please try again.");
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  // Function to toggle between tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="content">
      <ToastContainer position="top-right" autoClose={3000} />
      <Nav pills style={{ justifyContent: "center" }}>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggleTab("1")}
          >
            Datenschutz
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggleTab("2")}
          >
            Datenschutz Archived
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div style={{ background: "white" }}>
            <Row>
              <Col md="8">
                <h4>Datenschutz</h4>
              </Col>
              <Col md="4">
                <Link to="/admin/Datenschutz_New_Version">
                  <Button style={{ marginTop: "25px" }} color="primary">
                    Add New (Datenschutz)
                  </Button>
                </Link>
              </Col>
            </Row>

            <SunEditor
              setContents={value}
              onChange={setValue}
              lang="en"
              width="100%"
              height="100%"
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize", "formatBlock"],
                  ["paragraphStyle", "blockquote"],
                  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                  ["fontColor", "hiliteColor", "textStyle"],
                  ["removeFormat"],
                  ["outdent", "indent"],
                  ["align", "horizontalRule", "list", "lineHeight"],
                  ["table", "link"],
                  ["fullScreen", "showBlocks", "codeView"],
                  ["preview", "print"],
                ],
              }}
            />
            <div style={{ marginBottom: "70px" }}>
              <Button_loading
                style={{ float: "right" }}
                color="primary"
                onClick={submitHandler}
                loading={loading}
                disabled={disabled}
              >
                Save
              </Button_loading>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div style={{ background: "white" }}>
            <Card>
              <CardHeader>
                <h4>Datenschutz List</h4>
              </CardHeader>
              <CardBody>
                {DatenList.map((user) => (
                  <div key={user.Daten_id}>
                    <Link to={`Datenschutz_edit/${user.Daten_id}`}>
                      <li>{Moment(user.Date).format("DD.MM.YYYY")}</li>
                    </Link>
                  </div>
                ))}
              </CardBody>
            </Card>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default AGBEditor;
