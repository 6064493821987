import React, {useState} from "react";
import axios from "axios";
import "./style1.css";
import ReactPaginate from "react-paginate";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      hosp: [],
      offset: 0,
      perPage: 10,
      currentPage: 0,
      state1: false,
      modal: false,
      currentFeedback: null,
      feedbackText: "",
    };
  }

  componentDidMount() {
    this.fetchFeedback();
  }

  fetchFeedback = () => {
    axios
      .get("/api2/hospitalfeedback")
      .then((response) => {
        const hosp = response.data;
        const slice = hosp.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const postData = slice.map((item) => (
          <React.Fragment key={item.user_id}>
            <tr>
              <td>
                <label className="text-ubold">
                  {item.feedbackdate}
                  <br></br>
                </label>
              </td>
              <td className="text-left">
                <label className="text-ubold">{item.hosp_name}</label>
              </td>
              <td className="text-left">
                <label className="text-ubold">
                  {item.title === "Kein" ? null : item.title} {item.f_name}{" "}
                  {item.l_name} <br></br>
                  {item.telephone}
                </label>
              </td>

              <td className="text-left">
                <label className="text-ubold p_wrap">{item.text}</label>
              </td>
              <td className="text-left">
                <label className="text-ubold p_wrap">
                  {item.feedback_status}
                </label>
              </td>
              <td className="text-right">
                {item.feedback_status === "pending" && (
                  <>
                    <Button
                      className="btn-icon"
                      color="success"
                      id="tooltip366246651"
                      size="sm"
                      type="button"
                      onClick={() => this.acceptHandle(item.user_id)}>
                      <i className="fa fa-check-circle" />
                    </Button>{" "}
                    <UncontrolledTooltip delay={0} target="tooltip366246651">
                      Akzeptieren
                    </UncontrolledTooltip>
                  </>
                )}
                <Button
                  className="btn-icon"
                  color="danger"
                  id="tooltip366246652"
                  size="sm"
                  type="button"
                  onClick={() => this.deleteHandle(item.user_id)}>
                  <i className="fa fa-trash" />
                </Button>{" "}
                <UncontrolledTooltip delay={0} target="tooltip366246652">
                  Ablehnen
                </UncontrolledTooltip>
                <Button
                  className="btn-icon"
                  color="primary"
                  id="tooltip366246653"
                  size="sm"
                  type="button"
                  style={{marginLeft: "0px"}}
                  onClick={() => this.openModal(item)}>
                  <i className="fa fa-edit" />
                </Button>{" "}
                <UncontrolledTooltip delay={0} target="tooltip366246653">
                  Bearbeiten
                </UncontrolledTooltip>
              </td>
            </tr>
          </React.Fragment>
        ));
        this.setState({state1: true});
        this.setState({
          pageCount: Math.ceil(hosp.length / this.state.perPage),
          postData,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteHandle = (user_id) => {
    axios
      .post("/api2/delfeedback", {user_id})
      .then((response) => {
        console.log(response);
        this.fetchFeedback();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  acceptHandle = (user_id) => {
    axios
      .post("/api2/acceptfeedback", {user_id})
      .then((response) => {
        console.log(response);
        this.fetchFeedback();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.fetchFeedback();
      }
    );
  };

  openModal = (item) => {
    this.setState({
      modal: true,
      currentFeedback: item,
      feedbackText: item.text,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
      currentFeedback: null,
      feedbackText: "",
    });
  };

  handleFeedbackChange = (e) => {
    this.setState({feedbackText: e.target.value});
  };

  updateFeedback = () => {
    const {currentFeedback, feedbackText} = this.state;
    axios
      .post(`/api2/updatefeedbackhospital/${currentFeedback.user_id}`, {
        text: feedbackText,
      })
      .then((response) => {
        console.log(response);
        this.closeModal();
        this.fetchFeedback();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend">
                    <b>Unternehmen Bewertungen</b>
                  </h5>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Vom</th>
                        <th className="text-left">Unternehmen</th>
                        <th className="text-left">Kontakt</th>
                        <th className="text-left">Bewertung</th>
                        <th className="text-right">Status</th>
                        <th className="text-right">Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>{this.state.postData}</tbody>
                    <br></br>
                    <br></br>
                    {this.state.state1 ? (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={this.state.modal}
            toggle={this.closeModal}
            size="lg"
            fullscreen="md">
            <ModalHeader toggle={this.closeModal}>Bearbeiten</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="feedbackText">Feedback</Label>
                <Input
                  type="textarea"
                  name="text"
                  id="feedbackText"
                  value={this.state.feedbackText}
                  onChange={this.handleFeedbackChange}
                  style={{height: "200px", maxHeight: "400px", width: "100%"}} // Adjust height and width as needed
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.updateFeedback}>
                Update
              </Button>{" "}
              <Button color="secondary" onClick={this.closeModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

export default Imprint;
