import React, { useRef, useState } from "react";
import { useNotifications } from "./NotificationContext";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";

function NotificationDropdown() {
  const { notifications, fetchNotifications } = useNotifications();
  const dropdownRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(600); // default value
console.log(notifications)
  const handleNotificationClick = async (notification) => {
    await axios.post("/api2/updateNotificationStatus", {
      job_id: notification.job_id,
      app_id: notification.app_id,
      id: notification.id,
      k_user_id: notification.k_user_id,
      user_id: notification.user_id,
      type: notification.type,
    });
    // Fetch the notifications again
    fetchNotifications();
  };

  // Function to get the appropriate URL based on notification type
  const getNotificationLink = (notification) => {
    switch (notification.type) {
      case "new_job":
        return `/admin/job-profile-new/${notification.job_id}`;
      case "deactivated_job":
        return `/admin/job-profile-new/${notification.job_id}`;
      case "new_comment":
        return `/admin/candidate_status?status=${notification.status}&appid=${notification.app_id}&jobid=${notification.job_id}&recipient=${notification.recipient_type}`;
      case "new_invited_person":
        return `/admin/institution-list-new`;
      case "new_company":
        return `/admin/institution-details/${notification.hosp_id}`;
      case "candidate_message":
        return `/admin/messages/${notification.user_id}`;
        case "hospital_message":
            return `/admin/hospital_chat/`;
      default:
        // Default to job application link
        return `/admin/job-profile-single/${notification.job_id}/${notification.app_id}`;
    }
  };

  // Function to render notification content based on type
  const renderNotificationContent = (notification) => {
    switch (notification.type) {
      case "new_job":
        return (
          <>
            <b>Neue Job:</b> <br />
            <small>{notification?.company_name}</small> <br />
            <small>
              {notification?.job_function} (M/W/D) {notification?.profession}
            </small>
          </>
        );
      case "deactivated_job":
        return (
          <>
            <b>Deaktiv Job:</b> <br />
            <small>{notification?.company_name} - </small> <br />
            <small>
              {notification?.job_function} (M/W/D) {notification?.profession}
            </small>
          </>
        );
      case "new_comment":
        return (
          <>
            {notification.author_type === "company" ? (
              <>
                <b>Neue Notiz:(Unternehmen)</b> <br />
                <small>Job-ID: {notification?.job_id}</small> <br />
                <small>
                  {notification?.job_function} (M/W/D) {notification?.profession}
                </small>{" "}
                <br />
                <small>
                  Kandidat: {notification?.f_name} {notification?.l_name}
                </small>{" "}
                <br />
                <small>unternehmen: {notification.company_name}</small> <br />
              </>
            ) : (
              <>
                <b>Neue Notiz:(Kandidat)</b> <br />
                <small>Job-ID: {notification?.job_id}</small> <br />
                <small>
                  {notification?.job_function} (M/W/D) {notification?.profession}
                </small>{" "}
                <br />
                <small>
                  Kandidat: {notification?.f_name} {notification?.l_name}
                </small>{" "}
                <br />
                <small>unternehmen: {notification?.company_name}</small> <br />
              </>
            )}
          </>
        );
      case "new_invited_person":
        return (
          <>
            <b>Kollege wurde eingeladen</b> <br />
            <small>
              Eingeladen durch: {notification.adminDetails?.f_name}{" "}
              {notification.adminDetails?.l_name}
            </small>{" "}
            <br />
            <small>
              Geladener Kollege: {notification?.k_fname} {notification?.k_lname}
            </small>{" "}
            <br />
            <small>
              unternehmen: {notification.adminDetails?.company_name}
            </small>{" "}
            <br />
          </>
        );
      case "new_company":
        return (
          <>
            <b>Registrierung Unternehmen</b> <br />
            <small>
              Name: {notification?.f_name} {notification?.l_name}
            </small>{" "}
            <br />
            <small>Telephone: {notification?.telephone}</small> <br />
            <small>Unternehmen: {notification?.hosp_name}</small> <br />
          </>
        );
      case "candidate_message":
        return (
          <>
            <b>Neue Nachricht vom Kandidaten:</b> <br />
            <small>{notification?.f_name} {notification.l_name}</small> <br />
            <small>{notification?.message}</small> <br />
            <small>Gesendet am: {new Date(notification?.timestamp).toLocaleString()}</small>
          </>
        );
        case "hospital_message":
            return (
              <>
                <b>Neue Nachricht vom Unternehmen:</b> <br />
                <small>{notification?.f_name} {notification.l_name}</small> <br />
                <small>{notification?.message}</small> <br />
                <small>Gesendet am: {new Date(notification?.timestamp).toLocaleString()}</small>
              </>
            );
      default:
        // Handle job application notifications
        return (
          <>
            <b>{notification?.company_name}</b> <br />
            <small>
              {notification?.job_function} (M/W/D) {notification?.profession}
            </small>
            <br />
            <small>
              User: {notification?.f_name} {notification?.l_name}
            </small>{" "}
            <br />
            <small>Status: {notification.candidate_status}</small>
          </>
        );
    }
  };

  // Ensure notifications is an array before attempting to use filter
  const notificationCount = Array.isArray(notifications)
    ? notifications.filter((n) => !n.admin_seen).length
    : 0;

  return (
    <>
      <UncontrolledDropdown nav ref={dropdownRef}>
        <DropdownToggle nav>
          🔔 {notificationCount}
        </DropdownToggle>
        <DropdownMenu
          right
          style={{
            overflowY: "scroll",
            marginLeft: "-178px",
            maxHeight: maxHeight,
            maxWidth: "400px",
          }}>
          {Array.isArray(notifications) &&
            notifications.slice(0, 359).map((notification, index) => (
              <DropdownItem
                key={index}
                tag={Link}
                to={getNotificationLink(notification)}
                onClick={() => handleNotificationClick(notification)}>
                {renderNotificationContent(notification)}
                <hr />
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default NotificationDropdown;
