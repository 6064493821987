import React from "react";
//import { Link } from "react-router-dom"
// reactstrap components
import {
  Button,
} from "reactstrap";
// import { Link } from "@material-ui/core";

const TableCandidateJobDetails = ({item, index}) => {
    return (
      <>
                        <td className="text-center">
                         {index}
                        </td>
                        <td className="text-left">
                        {item.invoiceDate}
                        </td>
                        <td className="text-left">
                        <Button href='src/assets/img/invoice-example.png' download color="primary">
                          Alle Rechnung drucken
                        </Button>
                        </td>
                        <td className="text-center">
                        {item.dueDate}
                        </td>
                        <td className="text-center">
                        € {item.total},-
                        </td>
      </>
    );
}

export default TableCandidateJobDetails;
