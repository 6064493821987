import React from "react";
import axios from 'axios';
import {Redirect,Link} from "react-router-dom"
// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Button,
  Badge,
  UncontrolledTooltip
} from "reactstrap";

//import data



class CandidateProfile1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        notedata : [],
        note : ''
    };
  }
  handleMoreClick = () => {
    this.setState({
      buttonToRender: (this.state.buttonToRender + 2)
    });
  };


  componentDidMount(){
  
  }
  componentWillMount(){
    console.log("props",this.props)
    const {note_id} = this.props.match.params;
    console.log(note_id)
    this.setState({note_id})
    const email = JSON.parse(localStorage.getItem('email'))
    console.log(email)
   this.setState({email})
    axios.post('/api2/getthisnotes',{...this.state,note_id})
    .then(response => {
      console.log(response)
       const note = response.data[0].admin_notes;
       this.setState({ note });
      // // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      // console.log(note);
      // const notedata = response.data
      // this.setState({ notedata });
      //state : {user : this.state.user}
      
      //this.setState({redirect : true})
    })
    .catch(error => {
      console.log(error.response);

      // const msg = error.response.data.Message;
      // this.setState({msg});
    })
  }
  componentWillUnmount(){
   
  }
  
  submitHandler = () =>{
    console.log(this.state)
    if(this.state.note=== ""){
      const note_err = "Hinweis ist leer"
      this.setState({note_err})
    }else{
      this.setState({note_err:undefined})
      axios
      .post('/api2/addathisnote',this.state)
      .then(response =>{
        console.log(response)
        //this.setState({redirect:true})
        const note_msg = response.data.msg;
         this.setState({note_msg})
       // window.location.reload();
        // const WorkExp1 = response.data;
        // this.setState({ WorkExp1 });
      })
      .catch(error =>{
        console.log(error)
      })
    }
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  // deletenoteHandle(noteid){
  //   console.log("note id",noteid)
  //   axios
  //     .post('/api2/delnote1',{...this.state,noteid})
  //     .then(response =>{
  //       console.log(response)
  //       const note_msg = response.data.msg;
  //       this.setState({note_msg})
  //       window.location.reload();
  //       // const WorkExp1 = response.data;
  //       // this.setState({ WorkExp1 });
  //     })
  //     .catch(error =>{
  //       console.log(error)
  //     })
  // }
  //   EditnoteHandle(noteid){
  //   console.log("note id",noteid)
  //   // axios
  //   //   .post('/api2/delnote1',{...this.state,noteid})
  //   //   .then(response =>{
  //   //     console.log(response)
  //   //     const note_msg = response.data.msg;
  //   //     this.setState({note_msg})
  //   //     window.location.reload();
  //   //     // const WorkExp1 = response.data;
  //   //     // this.setState({ WorkExp1 });
  //   //   })
  //   //   .catch(error =>{
  //   //     console.log(error)
  //   //   })
  // }
  render() {
     const { redirect } = this.state;
    // const {isPasswordShown} = this.state;
     if (redirect) {
       return <Redirect to={`/admin/editnotes/${this.state.noteid}`}/>;
     }
    return (
      <div className="content">
     
         <Badge className="mr-1" color="danger" pill>
                       {this.state.note_err}
                        </Badge><Badge className="mr-1" color="success" pill>
                       {this.state.note_msg}
                        </Badge>
                    <Card>
                <CardBody>
                {/* <Table responsive>
                    <thead>
                      <tr>
                        <th>Admin Id</th>
                        <th>Note Datum</th>
                        <th>Note</th>
                        <th>Löschen</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.notedata.map(user => {
    return (
      <>
                      <tr>
                      <td>{user.admin_email}</td>
                       <td>{user.note_date}</td>
                       <td>{user.admin_notes}</td>
                       <td> <Button
                            className="btn-icon"
                            color="danger"
                            id="tooltip3662466420"
                            size="sm"
                            type="button"
                            onClick={this.deletenoteHandle.bind(this, user.notice_id)}
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip3662466420"
                          >
                            Löschen 
            </UncontrolledTooltip>
            <Button
                            className="btn-icon"
                            color="primary"
                            id="tooltip3662466421"
                            size="sm"
                            type="button"
                            onClick={this.EditnoteHandle.bind(this, user.notice_id)}
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip3662466421"
                          >
                            Edit 
            </UncontrolledTooltip>
          </td>



                      </tr>
                      </>
    );
})}
                    </tbody>
                  </Table> */}
                  <br></br>
                  <br></br>
                  <center> <textarea
                      rows='6'
                      cols='110'
                      name="note"
                      value={this.state.note}
                      //placeholder="Schreibe hier eine Notiz"
                      onChange={this.changeHandler}
                      /> 
                      <br />
                      {this.state.admin_id}<br />
                      {this.state.note_date}<br></br>
                      <Button color = 'primary'
                      onClick = {this.submitHandler}
                      >Submit</Button></center>
               
                </CardBody>
              </Card>
                 
           
 
      </div>
    );
  }
}

export default CandidateProfile1;
