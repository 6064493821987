import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
} from "reactstrap";


import TarifUpgrade from "building_block/upgrades_booked/tarif_admin"


const MyContract = ({item}) => {

    return (
      <div className="content">
            <Row className="">
            <Col md="12">
              <Card className="">
                <CardHeader>
                  
                  </CardHeader>  
                <CardBody>
{item.dateFirstBookedInstitut.map((item) => {
  return (
<TarifUpgrade item={item}/>
  )
})}

                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    );
  }


export default MyContract;
