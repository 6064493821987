
import React from "react";
import { Spinner } from 'reactstrap';
import loadable from '@loadable/component';

import CandidateList from "views/pages/candidate/list/CandidateList.jsx";
import CandidateListt from "views/pages/CandidateList1.js";
import InstitutionLists from "views/pages/klinik/list/InstitutionList.jsx";
import ContactInstitution from "views/pages/contact/list/ContactList.jsx";
import InstitutionDetails from "views/pages/klinik/details/InstitutionDetails.jsx";
// import InstitutionUserDetails from "views/pages/klinik/details/InstitutionUserDetails.jsx";
import InstitutionUserDetails from "views/pages/klinik/details/UserDetailsKollegen";

//import ProfileCandidate from "views/pages/candidate/profile/CandidateProfile.jsx";
import Dashboard from "views/Dashboard.jsx";
import EditJob from "views/pages/job/job_edit/editJob.jsx";
import CreateJob from "views/pages/CreateJob";
import CandidateByJob from "views/pages/CandidateByJob";
import CandidateByStellen from "views/pages/CandidateByStellen";
import KontaktPersonDetails from "views/pages/KontaktPersonDetails";
import JobsByStellen from "views/pages/JobsByStellen";
import Jobbs from "views/pages/jobs1";
import CandidateProfilFirst from "views/pages/CandidateProfileFirst";
import Referal2 from "views/pages/Referal2";
import ReferalLast from "views/pages/referal_msg";
// const Referal = loadable(() => import("views/pages/Referal"), {
//   fallback:<div className="spinner_full"><Spinner style={{ width: "5rem", height: "5rem"}} color="primary" /></div>,
// })

// const ReferalLast = loadable(() => import("views/pages/referal_msg.js"), {
//   fallback:<div className="spinner_full"><Spinner style={{ width: "5rem", height: "5rem"}} color="primary" /></div>,
// })
// const CandidateProfilFirst = loadable(() => import("views/pages/CandidateProfileFirst"), {
//   fallback:<div><Spinner color="success" /></div>,
// })
// import ExtendedForms from "views/forms/ExtendedForms.jsx";
// import ExtendedTables from "views/tables/ExtendedTables.jsx";
// import FullScreenMap from "views/maps/FullScreenMap.jsx";
// import GoogleMaps from "views/maps/GoogleMaps.jsx";
// import GridSystem from "views/components/GridSystem.jsx";
// import Icons from "views/components/Icons.jsx";
// import LockScreen from "views/pages/LockScreen.jsx";

// import Notifications from "views/components/Notifications.jsx";
// import Panels from "views/components/Panels.jsx";
// import ReactTables from "views/tables/ReactTables.jsx";
import ForgotPassword from "views/pages/Forgot_Password.jsx";
// import RegularForms from "views/forms/RegularForms.jsx";
// import RegularTables from "views/tables/RegularTables.jsx";
// import SweetAlert from "views/components/SweetAlert.jsx";
// import Timeline from "views/pages/Timeline.jsx";
// import Typography from "views/components/Typography.jsx";
// import UserProfile from "views/pages/UserProfile.jsx";
// import KlinikProfile from "views/pages/klinik/profil/KlinikProfile.jsx";
// import ContactProfile from "views/pages/contact/ContactProfile.jsx";
// import ContactEdit from "views/pages/contact/ContactEdit.jsx";
// import ContactAdd from "views/pages/contact/ContactAdd.jsx";
// import DoctorJobs from "views/pages/job/job_list/doctor/DoctorJobs.jsx"
// import SisterJobs from "views/pages/job/job_list/sister/SisterJobs.jsx"
// import TherapeutJobs from "views/pages/job/job_list/therapeut/TherapeutJobs.jsx"
// import ValidationForms from "views/forms/ValidationForms.jsx";
// import VectorMap from "views/maps/VectorMap.jsx";
// import Widgets from "views/Widgets.jsx";
// import Wizard from "views/forms/Wizard.jsx";
// import CreateJob from "views/pages/job/job_add/CreateJob.jsx";
// import EditJob from "views/pages/job/job_edit/JobEdit.jsx";
import DetailJob from "views/pages/job/job_details/DetailJob.jsx";
import CandidateProfile from "views/pages/candidate/profile/CandidateProfile";
// import PrincipalPages from "views/pages/setting/principal/Principal.jsx";
// import NotificationPages from "views/pages/setting/notification/Notification.jsx";
// import Upgrades from "views/pages/administrative/explore/Explore.jsx"
import UpgradesSample from "views/pages/administrative/explore_sample/Explore.jsx"

// import AddKlinik from "views/pages/klinik/klinik_add/AddKlinik.jsx"
// import Invoice from "views/pages/administrative/invoice/Invoice.jsx"
// import Messages from "views/pages/message/src"
import Anfragen from "views/pages/Anfragen.jsx"

import JobProfile from "views/pages/job/profile/jobProfile"
import KandidateDetails from "building_block/sections_job_profile_tabs/kandidateDetails"
import hospitalFeedback from "views/pages/hospitalFeedback.jsx"
import candidateFeedback from "views/pages/candidateFeedback.jsx"
import candidateFeedbackEdit from "views/pages/candidateFeedbackEdit.jsx"
import hospitalContract from "views/pages/hospitalContract.jsx"
import candidateContract from "views/pages/candidateContract.jsx"
import Logo from "views/pages/hospitalLogo"
import EINNAHMENN from "views/pages/Income"
import ContractPage from "views/pages/Contract"
import Referal from "views/pages/referal.jsx"
import Referal1 from "views/pages/referal1"
import KandidateContractPage from "views/pages/KandidateContractPage"
import ContractEnd from "views/pages/ContractEnd"
import createJobLast from 'views/pages/create-job-last';
import Notes from "views/pages/Notes"
import Editnotes from "views/pages/Editnotes"
import Notes1 from "views/pages/Notes1"
import Sentjob from "views/pages/sentjob"
import JobsList from "views/pages/job/job_list/JobsList.jsx"
//import Login from "views/pages/Login.jsx";
import addUser from "views/pages/addUser";
import ContactEditor from "views/pages/Editor/ContactEditor";
import ImpressumEditor from "views/pages/Editor/impressum";
import AGBEditor from "views/pages/Editor/AGB_Editor";
import AGBEditor_New_Company from "views/pages/Editor/AGB_Editor_New_Company";
import AGBEditor_New_Candidate from "views/pages/Editor/AGB_Editor_New_Candidate";
import DatenschutzEditor from "views/pages/Editor/Datenschutz";
import DatenschutzEditor_New from "views/pages/Editor/Datenschutz_new";
import ContractEditor from "views/pages/Editor/Editor_contract";
import ContractEditor_New_Candidate from "views/pages/Editor/Editor_contract_new";
import ContractEditor_New_Version_Unternehmen from "views/pages/Editor/Editor_contract_new_unternehmen";
import Conditions_Editor from "views/pages/Editor/conditions_Company";
import AGB_Candidate_Edit from "views/pages/Editor/AGB_Candidate_Edit";
import AGB_Company_Edit from "views/pages/Editor/AGB_Company_Edit";
import Datenschutz_edit from "views/pages/Editor/Datenschutz_edit"
import candidate_vertrag_edit from "views/pages/Editor/Contract_Candidate_Edit"
import company_vertrag_edit from "views/pages/Editor/Contract_Company_Edit"
import InvoiceTable from "views/pages/Invoices";
import Invoice_setting from "views/pages/Invoice_setting";


const Login = loadable(() => import("views/pages/Login.jsx"), {
  fallback: <div><Spinner color="success" /></div>,
})
const Account = loadable(() => import("views/pages/Account.jsx"), {
  fallback: <div><Spinner color="success" /></div>,
})
const CandidateProfil1 = loadable(() => import("views/pages/CandidateProfile1"), {
  fallback: <div><Spinner color="success" /></div>,
})

// const Message = loadable(() => import("building_block/message/components/ConversationList/index"), {
//   fallback: <div><Spinner color="success" /></div>,
// })

const Message = loadable(() => import("./views/pages/ChatApp/ChatApp"), {
  fallback: <div><Spinner color="success" /></div>,
})

const hosp_messages = loadable(() => import("./views/pages/ChatApp/ChatAppHosp/ChatAppHosp"), {
  fallback: <div><Spinner color="success" /></div>,
})


const MessageList = loadable(() => import("./views/pages/ChatApp/MessageList"), {
  fallback: <div><Spinner color="success" /></div>,
})

const ML = loadable(() => import("building_block/message/components/MessageList/index"), {
  fallback: <div><Spinner color="success" /></div>,
})

const Message1 = loadable(() => import("building_block/message/components/ConversationList/index1"), {
  fallback: <div><Spinner color="success" /></div>,
})
const ML1 = loadable(() => import("building_block/message/components/MessageList/index1"), {
  fallback: <div><Spinner color="success" /></div>,
})

const DashboardNew = loadable(() => import("views/Dashoboard/dashboard"),{
  fallback: <div><Spinner color="success" /></div>,
})

const candidateListNew = loadable(() => import("views/pages/candidate/NewCandidate/NewCandidateList"),{
  fallback: <div><Spinner color="success" /></div>,
})

const JobSearchCard = loadable(() => import("views/pages/job/NewJobList/JobList"), {
  fallback: <div><Spinner color="success" /></div>,
})

const NewHospitalList = loadable(() => import("views/pages/klinik/Hospital/HospitalList"),{
  fallback: <div><Spinner color="success" /></div>,
})

const KandidateDetailsNew = loadable(() => import("views/pages/candidate/candidateDetails/candidateDetails"),{
  fallback: <div><Spinner color="success" /></div>
})

const JobDetails = loadable(() => import("views/pages/job/profile/NewJobDetails"), {
  fallback: <div><Spinner color="success" /></div>,
})

const JobDetailsSingle = loadable(() => import("views/pages/job/profile/NewJobDetailsSingle"), {
  fallback: <div><Spinner color="success" /></div>,
})

const Applications_list = loadable(() => import("views/pages/applications/application_list"),{
  fallback: <div><Spinner color="success" /></div>,
})

const JobMatchList = loadable(() => import("views/pages/JobMatches/JobMatchList"),{
  fallback: <div><Spinner color="success" /></div>,
})

const CompanyContractList = loadable(() => import("views/pages/contract/CompanyContractList"),{
  fallback: <div><Spinner color="success" /></div>,
})

const CronJob = loadable(() => import("views/pages/CronJobs/CronJob"),{
  fallback: <div><Spinner color="success" /></div>,
})


function roles() {
  if (localStorage.getItem('roles') === 'admin user') {
    const routes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        component: Dashboard,
        layout: "/admin",
        //invisible: true
      },
    
      {
        path: "/candidate-profileee",
        name: "Profil",
        icon: "fas fa-user-tie",
        component: CandidateProfil1,
        layout: "/admin"
      },
      {
        path: "/candidate-profile",
        name: "Mein Profil",
        icon: "fas fa-user-tie",
        component: CandidateProfilFirst,
        layout: "/admin",
        invisible: true
      },

      // {
      //   path: "/message/:job_id/:hosp_id",
      //   name: "Nachrichten",
      //   icon: "nc-icon nc-chat-33",
      //   component: Message,
      //   layout: "/admin",
      //   invisible: false
      // },


      // {
      //   path: "/messages-list/:job_id/:hosp_id",
      //   name: "Chat",
      //   icon: "nc-icon nc-chat-33",
      //   component: ML,
      //   layout: "/admin",
      //   invisible: true
      // },
      //{
      //   path: "/notes/:applyjob_id",    
      //   name: "Notes",
      //   icon: "nc-icon nc-bank",
      //   component: Notes,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/sent_job/:job_id",    
      //   name: "Sentjob",
      //   icon: "nc-icon nc-bank",
      //   component: Sentjob,
      //   layout: "/admin",
      //   invisible: true
      // },

      {
        path: "/notes1/:job_id/:app_id",
        name: "Notes1",
        icon: "nc-icon nc-bank",
        component: Notes1,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/candidates",
        name: "Kandidaten",
        icon: "fas fa-users",
        component: CandidateList,
        layout: "/admin"
      },
      {
        path: "/candidatelist1/:job_id/:hosp_id",
        name: "Candidate List",
        mini: "A",
        icon: "nc-icon nc-bank",
        component: CandidateListt,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/jobs",
        name: "jobs",
        icon: "nc-icon nc-single-copy-04",
        component: JobsList,
        layout: "/admin",
        //invisible: roles()
      },
      {
        path: "/Referal2",
        name: "Freunde werben",
        icon: "nc-icon nc-send",
        component: Referal2,
        layout: "/admin",
        invisible: "true"
      },
      {
        path: "/referallast",
        name: "Freunde werben",
        icon: "nc-icon nc-send",
        component: ReferalLast,
        layout: "/admin",
        invisible: "true"
      },
      // {
      //   path: "/institution-list",
      //   name: "Unternehmen",
      //   icon: "fas fa-hospital",
      //   component: InstitutionLists,
      //   layout: "/admin",
      //   invisible: true
      // }, 
      // {
      //   path: "/candidatebyjob",
      //   name: "Matches",
      //   icon: "fas fa-check-circle",
      //   component: CandidateByJob,
      //   layout: "/admin",
      //   invisible: true
      // }, 
      // {
      //   collapse: true,
      //   name: "VERTRÄGE",
      //   icon: "fas fa-file-contract",
      //   state: "Exploreeee",
      //   views: [
      //     {
      //       path: "/hospitalVertrag",
      //       name: "Unternehmen",
      //       mini: "UV",
      //       component: hospitalContract,
      //       layout: "/admin",
      //     },

      //     {
      //       path: "/candidateVertrag",
      //       name: "Kandidaten",
      //       mini: "KV",
      //       component: candidateContract,
      //       layout: "/admin",
      //     }

      //   ]
      // },
      // {
      //   collapse: true,
      //   name: "Feedback",
      //   icon: "fas fa-comment-alt",
      //   state: "Exploreee",
      //   views: [
      //     {
      //       path: "/hospitalFeedback",
      //       name: "Unternehmen",
      //       mini: "UF",
      //       component: hospitalFeedback,
      //       layout: "/admin",
      //     },

      //     {
      //       path: "/candidateFeedback",
      //       name: "Kandidaten",
      //       mini: "KF",
      //       component: candidateFeedback,
      //       layout: "/admin",
      //     }

      //   ]
      // },
      // {
      //   collapse: true,
      //   name: "Hospitals",
      //   icon: "nc-icon nc-book-bookmark",
      //   state: "pagesCollapse",
      //   views: [
      //     {
      //       path: "/institution-list",
      //       name: "Verzeichnis",
      //       mini: "V",
      //       component: InstitutionLists,
      //       layout: "/admin"
      //     },
      //     {
      //       path: "/institution-details/:hosp_id/:username",
      //       name: "id",
      //       mini: "V",
      //       component: InstitutionDetails,
      //       layout: "/admin",
      //      invisible: true
      //     },
      //     {
      //       path: "/contact-institution",
      //       name: "Ansprechpartner",
      //       mini: "AP",
      //       component: ContactInstitution,
      //       layout: "/admin"
      //     },  
      //   ],
      //   invisible: true  
      // },


      // {
      //   path: "/Logo",
      //   name: "Logos",
      //   icon: "fas fa-eye",
      //   component: Logo,
      //   layout: "/admin",
      //   invisible: true
      // },
      {
        path: "/referals1",
        name: "Referals",
        icon: "fas fa-user-friends",
        component: Referal1,
        layout: "/admin",
        invisible: false
      },

      // {
      //   path: "/details",
      //   name: "Anfragen",
      //   icon: "fa fa-eye",
      //   component: Anfragen,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/ContractPage/:applyjob_id",
      //   name: "ContractPage",
      //   component: ContractPage,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/KandidateContractPage/:applyjob_id",
      //   name: "KandidateContractPage",
      //   component: KandidateContractPage,
      //   layout: "/admin",
      //   invisible: true
      // },

      // {
      //   path: "/ContractEnd",
      //   name: "ContractEnd",
      //   component: ContractEnd,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/EditJob/:job_id",
      //   name: "EditJob",
      //   mini: "JP",
      //   component: EditJob,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/CreateJob/:hosp_id",
      //   name: "CreateJob",
      //   mini: "CJ",
      //   component: CreateJob,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/invoice",
      //   name: "Einnahmen",
      //   icon: "nc-icon nc-money-coins",
      //   component: Upgrades,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/invoice-sample",
      //   name: "Rechnungen",
      //   icon: "nc-icon nc-money-coins",
      //   component: UpgradesSample,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/create-job-last",
      //   name: "Create Job Last",
      //   mini: "PKG",
      //   component: createJobLast,
      //   layout: "/admin",
      //   invisible: true
      // },

      // {
      //   path: "/candidate-profile",
      //   name: "CandidateProfile",
      //   mini: "CDP",
      //   component: CandidateProfile,
      //   layout: "/admin",
      //   invisible: true
      // },
      {
        path: "/job-profile/:job_id/:app_id",
        name: "jobProfile",
        mini: "JP",
        component: JobProfile,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
        invisible: true
      },
      // {
      //   path: "/password-reset",
      //   name: "ForgotPassword",
      //   mini: "FP",
      //   component: ForgotPassword,
      //   layout: "/auth",
      //   invisible: true
      // },
      // {
      //     path: "/detail-job",
      //     name: "DetailJob",
      //     mini: "DJ",
      //     component: DetailJob,
      //     layout: "/admin",
      //     invisible: true
      //   },
      {
        path: "/detail-kandidate/:app_id",
        name: "KandidateDetails",
        mini: "KD",
        component: KandidateDetails,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/detail-kandidate-new/:app_id",
        name: "KandidateDetails",
        mini: "KD",
        component: KandidateDetailsNew,
        layout: "/admin",
        invisible: true
      },
      //   {
      //     path: "/income",
      //     name: "EINNAHMEN",
      //     component: EINNAHMENN,
      //     layout: "/admin",
      //     icon: "nc-icon nc-money-coins",
      //     invisible: true

      //   },

      //   {
      //     path: "/candidatebystellen",
      //     name: "Nicht Beworben",
      //     icon: "nc-icon nc-book-bookmark",
      //     component: CandidateByStellen,
      //     layout: "/admin",
      //     invisible: true
      //   }, 
      {
        path: "/kontaktpersondetails/:hosp_id",
        name: "Kontakt Person Details",
        icon: "nc-icon nc-book-bookmark",
        component: KontaktPersonDetails,
        layout: "/admin",
        invisible: true
      },
      //   {
      //     path: "/jobsbystellen/:app_id",
      //     name: "Jobs By Stellen",
      //    icon: "nc-icon nc-book-bookmark",
      //     component: JobsByStellen,
      //     layout: "/admin",
      //     invisible: true
      //   }, 
      //   {
      //     path: "/Jobs1/:hosp_id",
      //     name: "Alle Jobs",
      //     icon: "nc-icon nc-chart-bar-32",
      //     component: Jobbs,
      //     layout: "/admin",
      //     invisible: true
      //   },
    ];

    return routes;

  }
  else if (localStorage.getItem('roles') === 'school') {
    const routes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        component: Dashboard,
        layout: "/admin",
        //invisible: true
      },
      {
        path: "/candidate-profileee",
        name: "Profil",
        icon: "fas fa-user-tie",
        component: CandidateProfil1,
        layout: "/admin"
      },
      {
        path: "/candidate-profile",
        name: "Mein Profil",
        icon: "fas fa-user-tie",
        component: CandidateProfilFirst,
        layout: "/admin",
        invisible: true
      },
      // {
      //   path: "/notes/:applyjob_id",    
      //   name: "Notes",
      //   icon: "nc-icon nc-bank",
      //   component: Notes,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/sent_job/:job_id",    
      //   name: "Sentjob",
      //   icon: "nc-icon nc-bank",
      //   component: Sentjob,
      //   layout: "/admin",
      //   invisible: true
      // },

      {
        path: "/notes1/:job_id/:app_id",
        name: "Notes1",
        icon: "nc-icon nc-bank",
        component: Notes1,
        layout: "/admin",
        invisible: true
      },
      // {
      //   path: "/candidates",
      //   name: "Kandidaten",
      //   icon: "fas fa-users",
      //   component: CandidateList,
      //   layout: "/admin"
      // },
      {
        path: "/candidatelist1/:job_id/:hosp_id",
        name: "Candidate List",
        mini: "A",
        icon: "nc-icon nc-bank",
        component: CandidateListt,
        layout: "/admin",
        invisible: true
      },
      // {
      //   path: "/jobs",
      //   name: "jobs",
      //   icon: "nc-icon nc-single-copy-04",
      //   component: JobsList,
      //   layout: "/admin",
      //   //invisible: roles()
      // },
      {
        path: "/Referal2",
        name: "Freunde werben",
        icon: "nc-icon nc-send",
        component: Referal2,
        layout: "/admin",
        invisible: "true"
      },
      {
        path: "/referallast",
        name: "Freunde werben",
        icon: "nc-icon nc-send",
        component: ReferalLast,
        layout: "/admin",
        invisible: "true"
      },
      // {
      //   path: "/institution-list",
      //   name: "Unternehmen",
      //   icon: "fas fa-hospital",
      //   component: InstitutionLists,
      //   layout: "/admin",
      //   invisible: true
      // }, 
      // {
      //   path: "/candidatebyjob",
      //   name: "Matches",
      //   icon: "fas fa-check-circle",
      //   component: CandidateByJob,
      //   layout: "/admin",
      //   invisible: true
      // }, 
      // {
      //   collapse: true,
      //   name: "VERTRÄGE",
      //   icon: "fas fa-file-contract",
      //   state: "Exploreeee",
      //   views: [
      //     {
      //       path: "/hospitalVertrag",
      //       name: "Unternehmen",
      //       mini: "UV",
      //       component: hospitalContract,
      //       layout: "/admin",
      //     },

      //     {
      //       path: "/candidateVertrag",
      //       name: "Kandidaten",
      //       mini: "KV",
      //       component: candidateContract,
      //       layout: "/admin",
      //     }

      //   ]
      // },
      // {
      //   collapse: true,
      //   name: "Feedback",
      //   icon: "fas fa-comment-alt",
      //   state: "Exploreee",
      //   views: [
      //     {
      //       path: "/hospitalFeedback",
      //       name: "Unternehmen",
      //       mini: "UF",
      //       component: hospitalFeedback,
      //       layout: "/admin",
      //     },

      //     {
      //       path: "/candidateFeedback",
      //       name: "Kandidaten",
      //       mini: "KF",
      //       component: candidateFeedback,
      //       layout: "/admin",
      //     }

      //   ]
      // },
      // {
      //   collapse: true,
      //   name: "Hospitals",
      //   icon: "nc-icon nc-book-bookmark",
      //   state: "pagesCollapse",
      //   views: [
      //     {
      //       path: "/institution-list",
      //       name: "Verzeichnis",
      //       mini: "V",
      //       component: InstitutionLists,
      //       layout: "/admin"
      //     },
      //     {
      //       path: "/institution-details/:hosp_id/:username",
      //       name: "id",
      //       mini: "V",
      //       component: InstitutionDetails,
      //       layout: "/admin",
      //      invisible: true
      //     },
      //     {
      //       path: "/contact-institution",
      //       name: "Ansprechpartner",
      //       mini: "AP",
      //       component: ContactInstitution,
      //       layout: "/admin"
      //     },  
      //   ],
      //   invisible: true  
      // },


      // {
      //   path: "/Logo",
      //   name: "Logos",
      //   icon: "fas fa-eye",
      //   component: Logo,
      //   layout: "/admin",
      //   invisible: true
      // },
      {
        path: "/referals1",
        name: "Referals",
        icon: "fas fa-user-friends",
        component: Referal1,
        layout: "/admin",
        invisible: false
      },

      // {
      //   path: "/details",
      //   name: "Anfragen",
      //   icon: "fa fa-eye",
      //   component: Anfragen,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/ContractPage/:applyjob_id",
      //   name: "ContractPage",
      //   component: ContractPage,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/KandidateContractPage/:applyjob_id",
      //   name: "KandidateContractPage",
      //   component: KandidateContractPage,
      //   layout: "/admin",
      //   invisible: true
      // },

      // {
      //   path: "/ContractEnd",
      //   name: "ContractEnd",
      //   component: ContractEnd,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/EditJob/:job_id",
      //   name: "EditJob",
      //   mini: "JP",
      //   component: EditJob,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/CreateJob/:hosp_id",
      //   name: "CreateJob",
      //   mini: "CJ",
      //   component: CreateJob,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/invoice",
      //   name: "Einnahmen",
      //   icon: "nc-icon nc-money-coins",
      //   component: Upgrades,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/invoice-sample",
      //   name: "Rechnungen",
      //   icon: "nc-icon nc-money-coins",
      //   component: UpgradesSample,
      //   layout: "/admin",
      //   invisible: true
      // },
      // {
      //   path: "/create-job-last",
      //   name: "Create Job Last",
      //   mini: "PKG",
      //   component: createJobLast,
      //   layout: "/admin",
      //   invisible: true
      // },

      // {
      //   path: "/candidate-profile",
      //   name: "CandidateProfile",
      //   mini: "CDP",
      //   component: CandidateProfile,
      //   layout: "/admin",
      //   invisible: true
      // },
      {
        path: "/job-profile/:job_id/:app_id",
        name: "jobProfile",
        mini: "JP",
        component: JobProfile,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
        invisible: true
      },
      // {
      //   path: "/password-reset",
      //   name: "ForgotPassword",
      //   mini: "FP",
      //   component: ForgotPassword,
      //   layout: "/auth",
      //   invisible: true
      // },
      // {
      //     path: "/detail-job",
      //     name: "DetailJob",
      //     mini: "DJ",
      //     component: DetailJob,
      //     layout: "/admin",
      //     invisible: true
      //   },
      {
        path: "/detail-kandidate/:app_id",
        name: "KandidateDetails",
        mini: "KD",
        component: KandidateDetails,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/detail-kandidate-new/:app_id",
        name: "KandidateDetails",
        mini: "KD",
        component: KandidateDetailsNew,
        layout: "/admin",
        invisible: true
      },
      //   {
      //     path: "/income",
      //     name: "EINNAHMEN",
      //     component: EINNAHMENN,
      //     layout: "/admin",
      //     icon: "nc-icon nc-money-coins",
      //     invisible: true

      //   },

      //   {
      //     path: "/candidatebystellen",
      //     name: "Nicht Beworben",
      //     icon: "nc-icon nc-book-bookmark",
      //     component: CandidateByStellen,
      //     layout: "/admin",
      //     invisible: true
      //   }, 
      {
        path: "/kontaktpersondetails/:hosp_id",
        name: "Kontakt Person Details",
        icon: "nc-icon nc-book-bookmark",
        component: KontaktPersonDetails,
        layout: "/admin",
        invisible: true
      },
      //   {
      //     path: "/jobsbystellen/:app_id",
      //     name: "Jobs By Stellen",
      //    icon: "nc-icon nc-book-bookmark",
      //     component: JobsByStellen,
      //     layout: "/admin",
      //     invisible: true
      //   }, 
      //   {
      //     path: "/Jobs1/:hosp_id",
      //     name: "Alle Jobs",
      //     icon: "nc-icon nc-chart-bar-32",
      //     component: Jobbs,
      //     layout: "/admin",
      //     invisible: true
      //   },
    ];

    return routes;
  }
  else {
    const routes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        component: DashboardNew,
        layout: "/admin",
        //invisible: true
      },
      {
        path: "/notes/:applyjob_id",
        name: "Notes",
        icon: "nc-icon nc-bank",
        component: Notes,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/editnotes/:note_id",
        name: "Editnotes",
        icon: "nc-icon nc-bank",
        component: Editnotes,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/sent_job/:job_id",
        name: "Sentjob",
        icon: "nc-icon nc-bank",
        component: Sentjob,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/notes1/:job_id/:app_id",
        name: "Notes1",
        icon: "nc-icon nc-bank",
        component: Notes1,
        layout: "/admin",
        invisible: true
      },
       // {
      //   path: "/candidates",
      //   name: "Kandidaten",
      //   icon: "fas fa-users",
      //   component: CandidateList,
      //   layout: "/admin"
      // },

      {
        path: "/candidatesNew",
        name: "Kandidaten",
        icon: "fas fa-users",
        component: candidateListNew,
        layout: "/admin"
      },
      {
        path: "/candidatelist1/:job_id/:hosp_id",
        name: "Candidate List",
        //mini: "A",
        icon: "nc-icon nc-bank",
        component: CandidateListt,
        layout: "/admin",
        invisible: true
      },
      // {
      //   path: "/jobs",
      //   name: "jobs",
      //   icon: "nc-icon nc-single-copy-04",
      //   component: JobsList,
      //   layout: "/admin",
      // //  invisible: true
      // },
      {
        path: "/jobsNew",
        name: "Jobs",
        icon: "nc-icon nc-single-copy-04",
        component: JobSearchCard,
        layout: "/admin",
        invisible: false
      },
      {
        path: "/institution-list-new",
        name: "Unternehmen",
        icon: "fas fa-hospital",
        component: NewHospitalList,
        layout: "/admin",
        //invisible: true
      },
      // {
      //   path: "/institution-list",
      //   name: "Unternehmen",
      //   icon: "fas fa-hospital",
      //   component: InstitutionLists,
      //   layout: "/admin",
      //   //invisible: true
      // },
       {
         path: "/candidate_status",
         name: "Bewerbungen",
         icon: "fas fa-check-circle",
         component: Applications_list,
         layout: "/admin",
         //invisible: true
       },
      {
        path: "/job_matches_list",
        name: "Matches",
        icon: "fas fa-check-circle",
        component: JobMatchList,
        layout: "/admin",
        //invisible: true
      },

     //{
     //  path: "/candidatebyjob",
     //  name: "Matches",
     //  icon: "fas fa-check-circle",
     //  component: CandidateByJob,
     //  layout: "/admin",
     //  //invisible: true
     //},
     {
      path: "/hospitalVertragNew",
      name: "VERTRÄGE",
      mini: "UV",
      icon: "fas fa-file-contract",
      component: CompanyContractList,
      layout: "/admin",
    
    },

      // {
      //   collapse: true,
      //   name: "VERTRÄGE",
      //   icon: "fas fa-file-contract",
      //   state: "Exploreeee",
      //   views: [
      //     {
      //       path: "/hospitalVertrag",
      //       name: "Unternehmen",
      //       mini: "UV",
      //       component: hospitalContract,
      //       layout: "/admin",
      //     },

      //     {
      //       path: "/candidateVertrag",
      //       name: "Kandidaten",
      //       mini: "KV",
      //       component: candidateContract,
      //       layout: "/admin",
      //     }

      //   ]
      // },
      {
        collapse: true,
        name: "Feedback",
        icon: "fas fa-comment-alt",
        state: "Exploreee",
        views: [
          {
            path: "/hospitalFeedback",
            name: "Unternehmen",
            mini: "UF",
            component: hospitalFeedback,
            layout: "/admin",
          },

          {
            path: "/candidateFeedback",
            name: "Kandidaten",
            mini: "KF",
            component: candidateFeedback,
            layout: "/admin",
          },

          {
            path: "/candidateFeedbackEdit/:appid",
            name: "Kandidaten",
            mini: "KF",
            component: candidateFeedbackEdit,
            layout: "/admin",
            invisible: true
          }

        ]
      },
      {
        collapse: true,
        name: "Hospitals",
        icon: "nc-icon nc-book-bookmark",
        state: "pagesCollapse",
        views: [
          {
            path: "/institution-list",
            name: "Verzeichnis",
            mini: "V",
            component: InstitutionLists,
            layout: "/admin"
          },
          {
            path: "/institution-details/:hosp_id",
            name: "id",
            mini: "V",
            component: InstitutionDetails,
            layout: "/admin",
            // invisible: true
          },
          {
            path: "/institutionuser-details/:user_id",
            name: "userdetails",
            mini: "V",
            component: InstitutionUserDetails,
            layout: "/admin",
            // invisible: true
          },
          {
            path: "/contact-institution",
            name: "Ansprechpartner",
            mini: "AP",
            component: ContactInstitution,
            layout: "/admin"
          },
        ],
        invisible: true
      },


      // {
      //   path: "/Logo",
      //   name: "Logos",
      //   icon: "fas fa-eye",
      //   component: Logo,
      //   layout: "/admin",
      //   //invisible: true
      // },
      {
        path: "/referals",
        name: "Referals",
        icon: "fas fa-user-friends",
        component: Referal,
        layout: "/admin",
        //invisible: true
      },
    {
      path: "/adduser",
      name: "Add User",
      icon: "fas fa-user-plus",
      component: addUser,
      layout: "/admin",
      invisible: true
    },
    {
      path: "/account",
      name: "Passwort ändern",
      icon: "fa fa-key",
      component: Account,
      layout: "/admin",
      invisible: true
    },
      {
        path: "/details",
        name: "Anfragen",
        icon: "fa fa-eye",
        component: Anfragen,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/ContractPage/:applyjob_id",
        name: "ContractPage",
        component: ContractPage,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/KandidateContractPage/:applyjob_id",
        name: "KandidateContractPage",
        component: KandidateContractPage,
        layout: "/admin",
        invisible: true
      },

      {
        path: "/ContractEnd",
        name: "ContractEnd",
        component: ContractEnd,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/EditJob/:job_id",
        name: "EditJob",
        mini: "JP",
        component: EditJob,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/CreateJob/:hosp_id",
        name: "CreateJob",
        mini: "CJ",
        component: CreateJob,
        layout: "/admin",
        invisible: true
      },
      // {
      //   path: "/invoice",
      //   name: "Einnahmen",
      //   icon: "nc-icon nc-money-coins",
      //   component: Upgrades,
      //   layout: "/admin",
      //   invisible: true
      // },
      {
        path: "/invoice-sample",
        name: "Rechnungen",
        icon: "nc-icon nc-money-coins",
        component: UpgradesSample,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/create-job-last",
        name: "Create Job Last",
        mini: "PKG",
        component: createJobLast,
        layout: "/admin",
        invisible: true
      },

      {
        path: "/candidate-profile",
        name: "CandidateProfile",
        mini: "CDP",
        component: CandidateProfile,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/job-profile/:job_id/:app_id",
        name: "jobProfile",
        mini: "JP",
        component: JobProfile,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/job-profile-new/:job_id",
        name: "jobProfile",
        mini: "JP",
        component: JobDetails,
        layout: "/admin",
        invisible: true
      },

      {
        path: "/job-profile-single/:job_id/:app_id",
        name: "jobProfile",
        mini: "JP",
        component: JobDetailsSingle,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
        invisible: true
      },
      {
        path: "/password-reset",
        name: "ForgotPassword",
        mini: "FP",
        component: ForgotPassword,
        layout: "/auth",
        invisible: true
      },
      {
        path: "/detail-job",
        name: "DetailJob",
        mini: "DJ",
        component: DetailJob,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/detail-kandidate/:app_id",
        name: "KandidateDetails",
        mini: "KD",
        component: KandidateDetails,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/detail-kandidate-new/:app_id",
        name: "KandidateDetails",
        mini: "KD",
        component: KandidateDetailsNew,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/income",
        name: "EINNAHMEN",
        component: EINNAHMENN,
        layout: "/admin",
        icon: "nc-icon nc-money-coins",
        invisible: true

      },

      {
        path: "/candidatebystellen",
        name: "Nicht Beworben",
        icon: "nc-icon nc-book-bookmark",
        component: CandidateByStellen,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/kontaktpersondetails/:hosp_id",
        name: "Kontakt Person Details",
        icon: "nc-icon nc-book-bookmark",
        component: KontaktPersonDetails,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/jobsbystellen/:app_id",
        name: "Jobs By Stellen",
        // icon: "nc-icon nc-book-bookmark",
        component: JobsByStellen,
        layout: "/admin",
        invisible: true
      },
      {
        path: "/Jobs1/:hosp_id",
        name: "Alle Jobs",
        //icon: "nc-icon nc-chart-bar-32",
        component: Jobbs,
        layout: "/admin",
        invisible: true
      },
      // {
      //   path: "/message/",
      //   name: "Chat KANDIDATEN",
      //   icon: "nc-icon nc-chat-33",
      //   component: Message,
      //   layout: "/admin",
      //   invisible: false
      // },

      {
        path: "/message-list/:receiverId",
        name: "Chat KANDIDATEN",
        icon: "nc-icon nc-chat-33",
        component: MessageList,
        layout: "/admin",
        exact: true, // Ensure exact match
        invisible: true
      },

      {
        path: "/messages/:receiverId",
        name: "Chat KANDIDATEN",
        icon: "nc-icon nc-chat-33",
        component: Message,
        layout: "/admin",
        exact: true, // Ensure exact match
        invisible: true
      },
      {
        path: "/messages",
        name: "Chat KANDIDATEN",
        icon: "nc-icon nc-chat-33",
        component: Message,
        layout: "/admin",
        exact: true, // Ensure exact match
        invisible: false
      },

      {
        path: "/hospital_chat/",
        name: "Unternehmen chat",
        icon: "nc-icon nc-chat-33",
        component: hosp_messages,
        layout: "/admin",
        exact: true, // Ensure exact match
        invisible: false
      },
      {
        path: "/hospital_chat/:receiverIdHosp",
        name: "Unternehmen chat",
        icon: "nc-icon nc-chat-33",
        component: hosp_messages,
        layout: "/admin",
        exact: true, // Ensure exact match
        invisible: true
      }, 
      // {
      //   path: "/message_hospital/",
      //   name: "Chat Unternehmen",
      //   icon: "nc-icon nc-chat-33",
      //   component: Message1,
      //   layout: "/admin",
      //   invisible: false
      // },
      // {
      //   path: "/messages-list/:receiver_id",
      //   name: "Chat",
      //   icon: "nc-icon nc-chat-33",
      //   component: ML,
      //   layout: "/admin",
      //   invisible: true
      // },
      {
        path: "/messages-list-hospital/:receiver_id",
        name: "Chat",
        icon: "nc-icon nc-chat-33",
        component: ML1,
        layout: "/admin",
        invisible: true
      },
      {
        collapse: true,
        name: "Editor",
        icon: "nc-icon nc-book-bookmark",
        state: "Explor",
        views: [
          {
            path: "/contactEditor",
            name: "Kontakt",
            mini: "KT",
            component: ContactEditor,
            layout: "/admin",
           // invisible: true
          },
          {
            path: "/ImpressumEditor",
            name: "Impressum",
            mini: "IM",
            component: ImpressumEditor,
            layout: "/admin",
           // invisible: true
          },
          {
            path: "/AGBEditor",
            name: "AGB",
            mini: "AGB",
            component: AGBEditor,
            layout: "/admin",
           // invisible: true
          },
          {
            path: "/AGBEditor_New_Version_Unternehmen",
            name: "AGB Editor",
            mini: "AGE",
            component: AGBEditor_New_Company,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/AGBEditor_New_Version_Kandidaten",
            name: "AGB Editor",
            mini: "AGE",
            component: AGBEditor_New_Candidate,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/Datenschutz_Editor",
            name: "Datenschutz",
            mini: "DC",
            component: DatenschutzEditor,
            layout: "/admin",
            invisible: false
          },
          {
            path: "/Datenschutz_New_Version",
            name: "Datenschutz Editor",
            mini: "DED",
            component: DatenschutzEditor_New,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/ContractEditor",
            name: "Vertrag",
            mini: "VE",
            component: ContractEditor,
            layout: "/admin",
            invisible: false
          },
          {
            path: "/ContractEditor_New_Version_Kandidaten",
            name: "Vertrag Editor New Version",
            mini: "VE",
            component: ContractEditor_New_Candidate,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/ContractEditor_New_Version_Unternehmen",
            name: "Vertrag Editor New Version",
            mini: "VE",
            component: ContractEditor_New_Version_Unternehmen,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/Conditions_Editor",
            name: "Konditionen",
            mini: "CE",
            component: Conditions_Editor,
            layout: "/admin",
            invisible: false
          },
          {
            path: "/AGB_candidate_edit/:AGB_Candidate_id",
            name: "AGB Candidate Edit",
            mini: "CE",
            component: AGB_Candidate_Edit,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/AGB_company_edit/:AGB_Company_id",
            name: "AGB Company Edit",
            mini: "CE",
            component: AGB_Company_Edit,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/Datenschutz_edit/:Daten_id",
            name: "Datenschutz edit",
            mini: "CE",
            component: Datenschutz_edit,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/candidate_vertrag_edit/:Contract_Candidate_id",
            name: "Candidate Vertrag Edit",
            mini: "CE",
            component: candidate_vertrag_edit,
            layout: "/admin",
            invisible: true
          },
          {
            path: "/company_vertrag_edit/:Contract_Company_id",
            name: "Company Vertrag Edit",
            mini: "CE",
            component: company_vertrag_edit,
            layout: "/admin",
            invisible: true
          },
        ],
        
      },
           {
          collapse: true,
          name: "Rechnungen",
          icon: "fas fa-file-contract",
          state: "inv",
          views: [
            {
              path: "/invoices",
              name: "Unternehmen",
              mini: "Un",
             // icon: "fas fa-file-contract",
              component: InvoiceTable,
              layout: "/admin",
              invisible: false
            },
            {
              path: "/invoices_setting",
              name: "Einstellungen",
              mini: "E",
             // icon: "fas fa-file-contract",
              component: Invoice_setting,
              layout: "/admin",
              invisible: false
            },
          ]
        },

        {
          path: "/cron_jobs",
          name: "Cron Jobs",
          icon: "nc-icon nc-money-coins",
          component: CronJob,
          layout: "/admin",
          invisible: false
        },
        
    
    
    ];
    return routes;

  }
}
// const routes = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     icon: "nc-icon nc-bank",
//     component: Dashboard,
//     layout: "/admin",
//     //invisible: true
//   },
//   {
//     path: "/notes/:applyjob_id",    
//     name: "Notes",
//     icon: "nc-icon nc-bank",
//     component: Notes,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/sent_job/:job_id",    
//     name: "Sentjob",
//     icon: "nc-icon nc-bank",
//     component: Sentjob,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/notes1/:job_id/:app_id",    
//     name: "Notes1",
//     icon: "nc-icon nc-bank",
//     component: Notes1,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/candidates",
//     name: "Kandidaten",
//     icon: "fas fa-users",
//     component: CandidateList,
//     layout: "/admin"
//   },
//   {
//     path: "/candidatelist1/:job_id/:hosp_id",
//     name: "Candidate List",
//     //mini: "A",
//     icon: "nc-icon nc-bank",
//     component: CandidateListt,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/jobs",
//     name: "jobs",
//     icon: "nc-icon nc-single-copy-04",
//     component: JobsList,
//     layout: "/admin",
//     invisible: roles()
//   },
//   {
//     path: "/institution-list",
//     name: "Unternehmen",
//     icon: "fas fa-hospital",
//     component: InstitutionLists,
//     layout: "/admin",
//     //invisible: true
//   }, 
//   {
//     path: "/candidatebyjob",
//     name: "Matches",
//     icon: "fas fa-check-circle",
//     component: CandidateByJob,
//     layout: "/admin",
//     //invisible: true
//   }, 
//   {
//     collapse: true,
//     name: "VERTRÄGE",
//     icon: "fas fa-file-contract",
//     state: "Exploreeee",
//     views: [
//       {
//         path: "/hospitalVertrag",
//         name: "Unternehmen",
//         mini: "UV",
//         component: hospitalContract,
//         layout: "/admin",
//       },

//       {
//         path: "/candidateVertrag",
//         name: "Kandidaten",
//         mini: "KV",
//         component: candidateContract,
//         layout: "/admin",
//       }

//     ]
//   },
//   {
//     collapse: true,
//     name: "Feedback",
//     icon: "fas fa-comment-alt",
//     state: "Exploreee",
//     views: [
//       {
//         path: "/hospitalFeedback",
//         name: "Unternehmen",
//         mini: "UF",
//         component: hospitalFeedback,
//         layout: "/admin",
//       },

//       {
//         path: "/candidateFeedback",
//         name: "Kandidaten",
//         mini: "KF",
//         component: candidateFeedback,
//         layout: "/admin",
//       }

//     ]
//   },
//   {
//     collapse: true,
//     name: "Hospitals",
//     icon: "nc-icon nc-book-bookmark",
//     state: "pagesCollapse",
//     views: [
//       {
//         path: "/institution-list",
//         name: "Verzeichnis",
//         mini: "V",
//         component: InstitutionLists,
//         layout: "/admin"
//       },
//       {
//         path: "/institution-details/:hosp_id/:username",
//         name: "id",
//         mini: "V",
//         component: InstitutionDetails,
//         layout: "/admin",
//        // invisible: true
//       },
//       {
//         path: "/contact-institution",
//         name: "Ansprechpartner",
//         mini: "AP",
//         component: ContactInstitution,
//         layout: "/admin"
//       },  
//     ],
//     invisible: true  
//   },


//   {
//     path: "/Logo",
//     name: "Logos",
//     icon: "fas fa-eye",
//     component: Logo,
//     layout: "/admin",
//     //invisible: true
//   },
//   {
//     path: "/referals",
//     name: "Referals",
//     icon: "fas fa-user-friends",
//     component: Referal,
//     layout: "/admin",
//     //invisible: true
//   },

//   {
//     path: "/details",
//     name: "Anfragen",
//     icon: "fa fa-eye",
//     component: Anfragen,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/ContractPage/:applyjob_id",
//     name: "ContractPage",
//     component: ContractPage,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/KandidateContractPage/:applyjob_id",
//     name: "KandidateContractPage",
//     component: KandidateContractPage,
//     layout: "/admin",
//     invisible: true
//   },

//   {
//     path: "/ContractEnd",
//     name: "ContractEnd",
//     component: ContractEnd,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/EditJob/:job_id",
//     name: "EditJob",
//     mini: "JP",
//     component: EditJob,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/CreateJob/:hosp_id",
//     name: "CreateJob",
//     mini: "CJ",
//     component: CreateJob,
//     layout: "/admin",
//     invisible: true
//   },
//   // {
//   //   path: "/invoice",
//   //   name: "Einnahmen",
//   //   icon: "nc-icon nc-money-coins",
//   //   component: Upgrades,
//   //   layout: "/admin",
//   //   invisible: true
//   // },
//   {
//     path: "/invoice-sample",
//     name: "Rechnungen",
//     icon: "nc-icon nc-money-coins",
//     component: UpgradesSample,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/create-job-last",
//     name: "Create Job Last",
//     mini: "PKG",
//     component: createJobLast,
//     layout: "/admin",
//     invisible: true
//   },

//   {
//     path: "/candidate-profile",
//     name: "CandidateProfile",
//     mini: "CDP",
//     component: CandidateProfile,
//     layout: "/admin",
//     invisible: true
//   },
//   {
//     path: "/job-profile/:job_id/:app_id",
//     name: "jobProfile",
//     mini: "JP",
//     component: JobProfile,
//     layout: "/admin",
//     invisible: true
//   },
//   {  path: "/login",
//     name: "Login",
//     mini: "L",
//     component: Login,
//     layout: "/auth",
//     invisible: true
//   },
//   {
//     path: "/password-reset",
//     name: "ForgotPassword",
//     mini: "FP",
//     component: ForgotPassword,
//     layout: "/auth",
//     invisible: true
//   },
//   {
//       path: "/detail-job",
//       name: "DetailJob",
//       mini: "DJ",
//       component: DetailJob,
//       layout: "/admin",
//       invisible: true
//     },
//     {
//       path: "/detail-kandidate/:app_id",
//       name: "KandidateDetails",
//       mini: "KD",
//       component: KandidateDetails,
//       layout: "/admin",
//       invisible: true
//     },
//     {
//       path: "/income",
//       name: "EINNAHMEN",
//       component: EINNAHMENN,
//       layout: "/admin",
//       icon: "nc-icon nc-money-coins",
//       invisible: true

//     },

//     {
//       path: "/candidatebystellen",
//       name: "Nicht Beworben",
//       icon: "nc-icon nc-book-bookmark",
//       component: CandidateByStellen,
//       layout: "/admin",
//       invisible: true
//     }, 
//     {
//       path: "/kontaktpersondetails/:hosp_id",
//       name: "Kontakt Person Details",
//       icon: "nc-icon nc-book-bookmark",
//       component: KontaktPersonDetails,
//       layout: "/admin",
//       invisible: true
//     }, 
//     {
//       path: "/jobsbystellen/:app_id",
//       name: "Jobs By Stellen",
//      // icon: "nc-icon nc-book-bookmark",
//       component: JobsByStellen,
//       layout: "/admin",
//       invisible: true
//     }, 
//     {
//       path: "/Jobs1/:hosp_id",
//       name: "Alle Jobs",
//       //icon: "nc-icon nc-chart-bar-32",
//       component: Jobbs,
//       layout: "/admin",
//       invisible: true
//     },
//   ];

// {
//     path: "/message",
//     name: "MESSAGE",
//     icon: "nc-icon nc-email-85",
//     component: Messages,
//     layout: "/admin-message"
// },
// {
//   collapse: true,
//   name: "VAKANZEN",
//   icon: "nc-icon nc-book-bookmark",
//   state: "pagesCollapse",
//   views: [
//     {
//       path: "/doctor-jobs",
//       name: "Arzt",
//       mini: "A",
//       component: DoctorJobs,
//       layout: "/admin"
//     },
//     {
//       path: "/sister-jobs",
//       name: "Pflegekraft",
//       mini: "P",
//       component: SisterJobs,
//       layout: "/admin"
//     },
//     {
//       path: "/therapeut-jobs",
//       name: "Therapeut",
//       mini: "T",
//       component: TherapeutJobs,
//       layout: "/admin"
//     }
//   ]
// },
// {
//   path: "/create-job",
//   name: "JOB ANLEGEN",
//   icon: "nc-icon nc-chart-bar-32",
//   component: CreateJob,
//   layout: "/admin"
// },
// {
//   path: "/message",
//   name: "MESSAGE",
//   icon: "nc-icon nc-email-85",
//   component: Messages,
//   layout: "/admin-message"
// },

// {
//   path: "/following",
//   name: "Following",
//   icon: "nc-icon nc-satisfied",
//   component: FollowingCandidate,
//   layout: "/admin"
// },
// {
//   collapse: true,
//   name: "Tarif",
//   icon: "nc-icon nc-money-coins",
//   state: "Explore",
//   views: [
//     {
//       path: "/explore",
//       name: "Explore",
//       mini: "E",
//       component: Upgrades,
//       layout: "/admin"
//     },
//     {
//       path: "/invoice",
//       name: "Invoice",
//       mini: "R",
//       component: Invoice,
//       layout: "/admin",
//       invisible: true
//     }
//   ]
// },
// {

// {
//   path: "/password-reset",
//   name: "ForgotPassword",
//   mini: "FP",
//   component: ForgotPassword,
//   layout: "/auth",
//   invisible: true
// },
// {
//   path: "/user-profile",
//   name: "UserProfile",
//   mini: "UP",
//   component: UserProfile,
//   layout: "/admin",
//   invisible: true
// },
// {
//   path: "/klinik-profile",
//   name: "KlinikProfile",
//   mini: "KP",
//   component: KlinikProfile,
//   layout: "/admin",
//   invisible: true
// },
// {
//   path: "/contact-profile",
//   name: "ContactProfile",
//   mini: "CP",
//   component: ContactProfile,
//   layout: "/admin",
//   invisible: true
// },
// {
//   path: "/contact-edit",
//   name: "ContactProfile",
//   mini: "CP",
//   component: ContactEdit,
//   layout: "/admin",
//   invisible: true
// },
// {
//   path: "/contact-add",
//   name: "ContactAdd",
//   mini: "CP",
//   component: ContactAdd,
//   layout: "/admin",
//   invisible: true
// },
// {
//   path: "/edit-job",
//   name: "EditJob",
//   mini: "EJ",
//   component: EditJob,
//   layout: "/admin",
//   invisible: true
// },
// {
//   path: "/detail-job",
//   name: "DetailJob",
//   mini: "DJ",
//   component: DetailJob,
//   layout: "/admin",
//   invisible: true
// }
// ,

// ,
// {
//   path: "/principal",
//   name: "PrincipalPages",
//   mini: "PP",
//   component: PrincipalPages,
//   layout: "/admin",
//   invisible: true
// }
// ,
// {
//   path: "/notification",
//   name: "NotificationPages",
//   mini: "NP",
//   component: NotificationPages,
//   layout: "/admin",
//   invisible: true
// }
// ,
// {
//   path: "/add-klinik",
//   name: "AddKlinik",
//   mini: "AK",
//   component: AddKlinik,
//   layout: "/admin",
//   invisible: true
// }

export default roles();
