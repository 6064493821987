import React from "react";
import ReactDOM from "react-dom";
import { Spinner } from 'reactstrap';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay'

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

//import AuthLayout from "layouts/Auth.jsx";
//import AdminLayout from "layouts/Admin.jsx";
import AdminMessageLayout from "layouts/Admin_Message.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "assets/css/style-custom.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import * as serviceWorker from './serviceWorker';
import { subscribeUser } from './subscription';
import { subscribeUser1 } from './subscription1';
import "./config/axiosConfig"; // Import Axios config file
const AuthLayout = loadable(() => import("layouts/Auth.jsx"), {
  fallback:<div><Spinner color="success" /></div>,
})

// const AdminLayout = loadable(() => import("layouts/Admin.jsx"), {
//   fallback:<div><Spinner color="success" /></div>,
// })
const AdminLayout = loadable(() =>
  pMinDelay(import('layouts/Admin.jsx'), 2000),{
    fallback:<div><Spinner color="success" /></div>,
  }
)

const hist = createBrowserHistory();

ReactDOM.render(

 <Router history={hist}>
    <Switch>
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/admin-message" render={props => <AdminMessageLayout {...props} />} />
      {/* <Redirect to="/admin/dashboard" /> */}
      <Redirect from="/" to="/auth/login" />
      </Switch>
  </Router>
  ,
  document.getElementById("root")
);
serviceWorker.register();
subscribeUser()
subscribeUser1()
