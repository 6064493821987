import React from 'react'

//import { Link } from "react-router-dom";
// reactstrap components
import {
    Row,
    Col,
  } from "reactstrap";

const JobWishCardList = ({item}) => {
    return (
      <>
      {item.jobWish.map((jw) => {
        return (
<Row className="ml-auto mr-auto justify-content-center">
<Col lg="10" md="10" sm="10">
  <Row className="ml-auto mr-auto justify-content-center">
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Beruf
            </b>
      {jw.jobType.map((jt) => {
        return (
          <div>
            <small>
              {jt.name}
            </small>
          </div>
        )
      })}
      </div>
   
    </Col>
    {/* <Col lg="4" md="4" sm="4">
      {item.salary !== " " ? 
     <div className="text-left">
     <b>
      Gehaltvorstellung
    </b> <br/>
    <small>
      € {item.salary},- pro Jahr
    </small>
     </div> 
     : ""
    }
    </Col> */}
    
  </Row>
  <br/>
  <Row className="ml-auto mr-auto justify-content-center">
  <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Funktion
            </b>
      {jw.qualifications.map((jt) => {
        return (
          <div>
            <small>
              {jt.name}
            </small>
          </div>
        )
      })}
      </div>
   
    </Col>
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Fachgebiet
            </b>
      {jw.specializations.map((jt) => {
        return (
          <div>
            <small>
              {jt.name}
            </small>
          </div>
        )
      })}
      </div>
   
    </Col>
  </Row>
  <br />
  {/* <Row className="ml-auto mr-auto justify-content-center">
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Abeitzeit
            </b>
      {jw.workingTimes.map((jt) => {
        return (
          <div>
           
            <small>
              {jt.name}
            </small>
          </div>
        )
      })}
      </div>
   
    </Col>
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Anstellungsart
            </b>
      {jw.workingTernures.map((jt) => {
        return (
          <div>
            <small>
              {jt.name}
            </small>
          </div>
        )
      })}
      </div>
   
    </Col>
  </Row>
  <br />
  <Row className="ml-auto mr-auto justify-content-center">
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Taetigkeiten
            </b>
      {jw.sectorTypes.map((jt) => {
        return (
          <div>
           
            <small>
              {jt.name}
            </small>
          </div>
        )
      })}
      </div>
   
    </Col>
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Arbeitsbeginn
            </b> <br/>
            <small>
            {jw.startDate}
            </small>

      </div>
   
    </Col>
  </Row>
  <br />
  <Row className="ml-auto mr-auto justify-content-center">
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Standort
            </b>
      {jw.placeToWork.map((jt) => {
        return (
          <div>
            <small>
              {jt.name}
            </small>
          </div>
        )
      })}
      </div>
   
    </Col>
    <Col lg="4" md="4" sm="4">
      <div className="text-left">
      <b>
              Radius
            </b> <br/>
            <small>
            {jw.radius} km
            </small>

      </div>
   
    </Col>
  </Row> */}
</Col>
</Row>

        )
      })}
      </>
      
    )
}

export default JobWishCardList