import React, {useState, useEffect} from "react";
import axios from "axios";
import Button_loading from "components/Button_loading";
import SunEditor from "suneditor-react";
import {ToastContainer, toast} from "react-toastify";

const Editor = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const userId = localStorage.getItem("user_id"); // Get user ID from localStorage
  let isMounted = true; // Define isMounted in the component scope

  const submitHandler = async () => {
    setLoading(true);
    setDisabled(true);
    try {
      await axios.post("/api2/EditorContact", {value, user_id: userId});
      toast.success("Content saved successfully!");
      // Reload editor content after save
      await fetchEditorContent();
    } catch (error) {
      console.error("Error saving content:", error);
      toast.error("Failed to save content.");
    } finally {
      if (isMounted) {
        setLoading(false);
        setDisabled(false);
      }
    }
  };

  const fetchEditorContent = async () => {
    setLoading(true);
    setDisabled(true);
    try {
      const response = await axios.post("/api2/GetEditorContact", {
        user_id: userId,
      });
      if (isMounted) {
        const fetchedValue = response.data.result[0]?.EditorDesc || "";
        setValue(fetchedValue);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    } finally {
      if (isMounted) {
        setLoading(false);
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    fetchEditorContent();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, []);

  const onChange = (content) => {
    setValue(content);
  };

  return (
    <div className="content">
      <ToastContainer />
      <div style={{background: "white"}}>
        <h4 style={{textAlign: "center"}}>Kontakt</h4>
        <SunEditor
          setContents={value}
          onChange={onChange}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle", "blockquote"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
              ],
              ["fontColor", "hiliteColor", "textStyle"],
              ["removeFormat"],
              "/", // Line break
              ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],
              ["table", "link"],
              ["fullScreen", "showBlocks", "codeView"],
              ["preview", "print"],
              ["save", "template"],
            ],
          }}
        />
        <Button_loading
          style={{float: "right"}}
          color="primary"
          onClick={submitHandler}
          loading={loading}
          disabled={disabled}>
          Save
        </Button_loading>
      </div>
    </div>
  );
};

export default Editor;
