import React from "react";
import { Link } from "react-router-dom"
import axios from 'axios';
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  Badge,
  UncontrolledTooltip
} from "reactstrap";
import Time from "@material-ui/icons/Timer";
//import data
import { candidate } from "variables/general.jsx"
//import TabsProfileJob from "building_block/sections_job_profile_tabs"
class JobProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [""],
      buttonToRender: 4,
      horizontalTabs: "home",
      records: [],
      cand: []
    };
  }
  handleMoreClick = () => {
    this.setState({
      buttonToRender: (this.state.buttonToRender + 2)
    });
  };
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  componentDidMount() {
    const roles = localStorage.getItem('roles')
    this.setState({ roles })
    console.log(roles);
    console.log(this.props.match.params)
    const { job_id } = this.props.match.params;
   // console.log(job_id)
    //localStorage.setItem('job_id', JSON.stringify(job_id));
   // const { app_id } = this.props.match.params;
   // console.log(app_id)
    // if (app_id === "undefined") {
    //   console.log("without app id works")
      axios.post('/api2/jobdetails', { ...this.state, job_id })
        .then(response => {
          console.log(response)
          // const { job_id } = this.props.match.params;
          // console.log(job_id);
          // this.setState({job_id})
          // const job_id = this.props.match.params;
          // this.setState({job_id})
          const records = response.data;
          // let job = null;
          // records.forEach(element => {
          //   if(element.job_id === job_id){
          //     job = element;
          //   }        
          // });
          // records = [job];
          this.setState({ records });
          console.log(records)
          //console.log("RECORDS", job, records);
          localStorage.setItem('records', JSON.stringify(this.state.records));
          /* console.log(jobdata); */
          console.log("test", this.props.match.params)
        })
        .catch(error => {
          console.log(error)
        })
   // } 
    // else {
    //   console.log("with app id works")
    //   axios.post('/api2/jobdetails1', { ...this.state, job_id, app_id })
    //     .then(response => {
    //       console.log(response)
    //       const { job_id } = this.props.match.params;
    //       console.log(job_id);
    //       this.setState({ job_id })
    //       // const job_id = this.props.match.params;
    //       // this.setState({job_id})
    //       let records = response.data;
    //       let job = null;
    //       // records.forEach(element => {
    //       //   if(element.job_id === job_id){
    //       //     job = element;
    //       //   }        
    //       // });
    //       // records = [job];
    //       this.setState({ records });
    //       console.log("RECORDS", job, records);
    //       localStorage.setItem('records', JSON.stringify(this.state.records));
    //       /* console.log(jobdata); */
    //       console.log("test", this.props.match.params)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }
    /////////////////////////////////////
    axios.post('/api2/getcandid', { ...this.state, job_id })
      .then(response => {
        console.log(response)
        const cand = response.data;
        this.setState({ cand });
        console.log(cand);
      })
      .catch(error => {
        console.log(error)
      })
  }
  logoSelect(joblogo, jobid, company) {
    //alert(id);
    console.log(joblogo, jobid, company);
    //this.setState(});
    //console.log(this.state.hosp_logo);
    if (joblogo === null) {
      var errmsg = "no logo found";
      this.setState({ errmsg })
    } else {
      axios
        .post('/api2/postlogo', { ...this.state, joblogo, jobid, company })
        .then(response => {
          console.log(response)
          //this.setState({redirect : true})
          //window.location.reload(true);
          var resmsg = response.data.msg;
          this.setState({ resmsg });
        })
        .catch(error => {
          console.log(error)
          var reserrmsg = error.response.data.msg;
          this.setState({ reserrmsg });
        })
    }
  }
  render() {
    return (
      <div className="content">
        {candidate.map(() => {
          return (
            <Row className="ml-auto mr-auto justify-content-center">
              <Col md="12">
                <Card className="card-user">
                  <Badge color="danger">{this.state.errmsg}</Badge>
                  <Badge color="success">{this.state.resmsg}</Badge>
                  <CardBody>
                    {this.state.records.map(item => {
                      return (
                        <>
                          <CardBody>
                            <Row>
                              <Col md='2'>
                                {item.logo === null || item.logo === " " ? null :
                                  <img src={`https://unternehmen.hello-doctor.de/uploads/${item.logo}`} alt='' height='80' width='auto' />
                                } </Col>

                              <Col md='6'><h7 className="text-left"   >
                                <h7 style={{ color: '#4287f5', fontSize: '10px' }}>{item.company_name}</h7>
                                <br></br>
                                <b>{item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}</b>
                                <br></br>
                                <i class="fas fa-map-marker-alt">

                                </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                                  {item.zip_code}  {item.city}, {item.state}</h7>
                              </h7>
                              </Col>

                              <Col className="text-right">

                                <b> Inserats-Nr. {item.job_id} </b>

                                <br></br>
                                {this.state.roles !== 'admin' ?
                                  null
                                  :
                                  <> <Button color="primary"
                                    onClick={this.logoSelect.bind(this, item.logo, item.job_id, item.company_name)}
                                  >
                                    Select this Logo
                                  </Button></>}

                              </Col>

                            </Row>

                            <div>


                              <hr></hr>
                              <Row>

                                <Col md="3">
                                  <Time />&nbsp;&nbsp;
                                  <h7 style={{ fontSize: '13px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                                </Col>
                                <Col md="3">
                                  <i class="fas fa-hourglass-half"></i>
                                  &nbsp;&nbsp;
                                  <h7 style={{ fontSize: '13px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} </h7>
                                </Col>
                                <Col md="3">
                                  <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                                  <h7 style={{ fontSize: '13px' }}> Online seit: {item.created_date}</h7>
                                </Col>
                                <Col md="3">
                                  <i class="far fa-calendar-alt"></i>

                                  &nbsp;&nbsp;
                                  {item.job_status === 'online' ? <h7 style={{ fontSize: '13px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                                    :
                                    <h7 style={{ fontSize: '13px', color: '#ff0000' }}>Offline : {item.valid_till} </h7>}
                                </Col>
                              </Row>
                              <hr></hr>
                              <CardHeader>
                                <h7 className="title"><b>Einrichtung:</b></h7>
                                <p className="p_wrap" style={{ fontSize: '14px' }}>
                                  {item.sector}
                                </p>
                              </CardHeader>
                              <CardHeader>
                                <h7 className="title"><b>Einleitung:</b></h7>
                                <p className="p_wrap" style={{ fontSize: '14px' }}>
                                  {item.about_us}
                                </p>
                              </CardHeader>
                              <CardHeader>
                                <h7 ><b>Ihr Profil:</b></h7>
                                <p className="p_wrap" style={{ fontSize: '14px' }}>
                                  {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession} </p>
                              </CardHeader>
                              <CardHeader>
                                <h7><b>Ihre Aufgaben:</b></h7>
                                <p className="p_wrap" style={{ fontSize: '14px' }}>
                                  {item.discription}
                                </p>
                              </CardHeader>
                              <CardHeader>
                                <h7><b>Benefits:</b></h7>
                                <p className="p_wrap" style={{ fontSize: '14px' }}>
                                  {item.benefits}
                                </p>  </CardHeader>
                              {item.salary === null ? null :
                                <>
                                  <CardHeader>
                                    <h7><b>Vergütung:</b></h7>
                                    <p className="p_wrap" style={{ fontSize: '14px' }}>
                                      {item.salary} (EUR) Bruttogehalt pro Jahr
                                    </p>
                                  </CardHeader>

                                </>
                              }
                            </div>
                          </CardBody>
                        </>
                      );
                    })}
                    <br></br>

                    <CardHeader>
                      <h5> <b>Kandidaten für  die Stellenanzeige</b>

                      </h5>
                    </CardHeader>
                    <CardBody>
                      {this.state.cand.map((item1) => {
                        return (
                          <>
                            <Card style={{ backgroundColor: '#f5f5f5', padding: '10px' }}>
                              <Row>
                                <Col md='2'>
                                  {item1.picture === null || item1.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
                                    alt="..."
                                    className="avatar border-gray"
                                    src={`https://hello-doctor.de/uploads/${item1.picture}`}


                                  />}
                                </Col>

                                <Col md='6'><h7 className="text-left"   >
                                  <Link to={`/admin/detail-kandidate/${item1.app_id}`}>
                               {item1.title === 'Kein' ? null : item1.title} {item1.f_name} {item1.l_name}
                                  </Link>
                                  <br></br>
                                  <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-mobile-alt"></i> &nbsp;{item1.telephone}</h7><br></br>
                                  <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp;{item1.username}</h7><br></br>
                                  <i class="fas fa-map-marker-alt"></i>
                                  <h7 className="text-left" style={{ fontSize: '13px' }}>&nbsp;{item1.haus_no}, {item1.post_code} {item1.city}, &nbsp;{item1.country}</h7>
                                </h7>
                                </Col>
                                <Col md='4' className="text-right">
                                  {item1.action_date}<br />

                                  {item1.candidate_status === 'Bewerbung verworfen' && <Badge style={{ fontSize: '12px' }} className="mr-1" id={"tooltip21" + item1.app_id} color="warning" >
                                    <i class="far fa-times-circle"></i>  &nbsp;&nbsp;
                                    {item1.candidate_status}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip21" + item1.app_id}
                                    >Du hast den Match abgelehnt.</UncontrolledTooltip>
                                  </Badge>
                                  }
                                  {item1.candidate_status === 'Beworben' && <Badge style={{ fontSize: '12px', backgroundColor: '#6c757d' }} className="mr-1" id={"tooltip31" + item1.app_id}>
                                    <i class="fas fa-check"></i>
                                    &nbsp;&nbsp;
                                    {item1.candidate_status}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip31" + item1.app_id}
                                    >Du hast dich erfolgreich für den Job beworben.</UncontrolledTooltip>
                                  </Badge>
                                  }

                                  {item1.candidate_status === 'Einstellungszusage' && <Badge style={{ fontSize: '12px' }} id={"tooltip11" + item1.app_id} className="mr-1" color="success" >
                                    <i class="fas fa-check"></i>
                                    &nbsp;&nbsp;
                                    {item1.candidate_status}

                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip11" + item1.app_id}
                                    >Gratuliere. Du hast den Job erhalten.</UncontrolledTooltip>
                                  </Badge>}

                                  {item1.candidate_status === 'Bewerbung angenommen' && <Badge style={{ fontSize: '12px' }} className="mr-1" id={"tooltip41" + item1.app_id} color="primary" >
                                    <i class="fas fa-check"></i>
                                    &nbsp;&nbsp;
                                    {item1.candidate_status}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip41" + item1.app_id}
                                    >Das Unternehmen hat Deine Bewerbung für ein Interview-Prozess akzeptiert.</UncontrolledTooltip>
                                  </Badge>
                                  }
                                  {item1.candidate_status === 'Bewerbung im Prüfung' && <Badge style={{ fontSize: '12px' }} className="mr-1" id={"tooltip51" + item1.app_id} color="info" >
                                    <i class="far fa-clock"></i>
                                    &nbsp;&nbsp;
                                    {item1.candidate_status}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip51" + item1.app_id}
                                    >Deine Bewerbung wartet auf die Entscheidung vom Unternehmen.</UncontrolledTooltip>
                                  </Badge>
                                  }
                                  {item1.candidate_status === 'Bewerbung abgelehnt' && <Badge style={{ fontSize: '12px' }} className="mr-1" id={"tooltip61" + item1.app_id} color="danger" >
                                    <i class="far fa-times-circle"></i>
                                    &nbsp;&nbsp;
                                    {item1.candidate_status}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip61" + item1.app_id}
                                    >Das Unternehmen hat Deine Bewerbung abgelehnt.</UncontrolledTooltip>
                                  </Badge>
                                  }
                                  {item1.candidate_status === 'closed' && <Badge style={{ fontSize: '12px' }} className="mr-1" id={"tooltip61" + item1.app_id} color="danger" >
                                    <i class="far fa-times-circle"></i>
                                    &nbsp;&nbsp;
                                    {item1.candidate_status}
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={"tooltip61" + item1.app_id}
                                    >Admin closed this job.</UncontrolledTooltip>
                                  </Badge>
                                  }


                                </Col>

                              </Row>



                            </Card>
                          </>
                        )
                      })}
                    </CardBody>

                    <hr />




                  </CardBody>

                </Card>

              </Col>
            </Row>
          )

        })}
      </div>
    );
  }
}

export default JobProfile;
