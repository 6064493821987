import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AGBEditor = () => {
  const [value, setValue] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Get the user ID from local storage
    const user_id = localStorage.getItem('user_id');
    setUserId(user_id);

    // Fetch the initial data for the editor
    axios
      .post('/api2/GetEditorDatenschutz', { user_id })
      .then(response => {
        const editorValue = response.data.result[0].EditorDesc;
        setValue(editorValue);
      })
      .catch(error => {
        console.log(error.response);
        toast.error('Error fetching data.');
      });
  }, []);

  const submitHandler = () => {
    axios
      .post('/api2/EditorDatenschutzNew', { value, user_id: userId })
      .then(() => {
        toast.success('Saved successfully!');
        setTimeout(() => setRedirect(true), 2000); // Redirect after a delay
      })
      .catch(error => {
        console.log(error.response);
        toast.error('Error saving data.');
      });
  };

  if (redirect) {
    return <Redirect to="/admin/Datenschutz_Editor" />;
  }

  return (
    <div className="content">
      <ToastContainer />
      <div style={{ background: 'white' }}>
        <Row>
          <Col md="8">
            <h4>Datenschutz (New Version)</h4>
          </Col>
        </Row>

        <SunEditor
          setContents={value}
          onChange={setValue}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['paragraphStyle', 'blockquote'],
              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['removeFormat'],
              '/', // Line break
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['table', 'link'],
              ['fullScreen', 'showBlocks', 'codeView'],
              ['preview', 'print'],
              ['save', 'template'],
            ],
          }}
        />

        <div style={{ marginBottom: '70px' }}>
          <Button style={{ float: 'right' }} color="primary" onClick={submitHandler}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AGBEditor;
