import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";

//import data
import { candidate } from "variables/general.jsx";

//import component
import SectionDetails from "./section_candidate_details"
import SectionWish from "./section_candidate_jobwish"
import TableEducation from "./section_candidate_education"
import TableExperience from "./section_candidate_experience"
import TableLanguages from "./section_candidate_languages"
import SP from "./section_candidate_sp"

class Panels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalTabs: "details",
      openedCollapses: [""]
    };
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            aria-expanded={this.state.horizontalTabs === "details"}
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "details"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "details" })
                            }
                          >
                           Mein Profil
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "jobwish"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "jobwish"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "jobwish" })
                            }
                          >
                            Beruf und Kompetenzen
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "sp"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "sp"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "sp" })
                            }
                          >
                           Stellen Präferenzen
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "experience"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "experience"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "experience" })
                            }
                          >
                            Arbeitserfahrung
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "education"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "education"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "education" })
                            }
                          >
                            Ausbildung
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "languages"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "languages"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "languages" })
                            }
                          >
                            Sprachen
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "resume"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "resume"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "resume" })
                            }
                          >
                            Dokumente
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={this.state.horizontalTabs}
                  >
                    <TabPane tabId="details" role="tabpanel">
                      {candidate.map((i) => {
                        return (
                          <SectionDetails item={i}/>
                        )
                      })}
                    </TabPane>
                    <TabPane tabId="jobwish" role="tabpanel">
                    {candidate.map((i) => {
                        return (
                          <SectionWish item={i}/>
                        )
                      })}
                    </TabPane>
                    <TabPane tabId="languages" role="tabpanel">
                    {candidate.map((i) => {
                        return (
                          <TableLanguages item={i}/>
                        )
                      })}
                    </TabPane>
                    <TabPane tabId="education" role="tabpanel">
                    {candidate.map((i) => {
                        return (
                          <TableEducation item={i}/>
                        )
                      })}
                    </TabPane>
                    <TabPane tabId="experience" role="tabpanel">
                    {candidate.map((i) => {
                        return (
                          <TableExperience item={i}/>
                        )
                      })}
                    </TabPane>
                    <TabPane tabId="sp" role="tabpanel">
                    {candidate.map((i) => {
                        return (
                          <SP item={i}/>
                        )
                      })}
                    </TabPane>
                    {/* <TabPane tabId="resume" role="tabpanel">
                      <Card>
                <CardBody>
                  <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                  >
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <h4>
<a
                          aria-expanded={this.state.openedCollapses.includes(
                            "collapseOne"
                          )}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => this.collapsesToggle("collapseOne")}
                        >
                          Ausbildung
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                        </h4>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                          "collapseOne"
                        )}
                      >
                        <CardBody>
                          {candidate.map((item) => {
                            return (
                              <TableEducation item={item} />
                            )
                          })}
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <h4>
<a
                          aria-expanded={this.state.openedCollapses.includes(
                            "collapseTwo"
                          )}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => this.collapsesToggle("collapseTwo")}
                        >
                          Berufserfahrung
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                        </h4>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                          "collapseTwo"
                        )}
                      >
                        <CardBody>
                        {candidate.map((item) => {
                            return (
                              <TableExperience item={item} />
                            )
                          })}
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <h4>
<a
                          aria-expanded={this.state.openedCollapses.includes(
                            "collapseThree"
                          )}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => this.collapsesToggle("collapseThree")}
                        >
                          Sprache
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                        </h4>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                          "collapseThree"
                        )}
                      >
                        <CardBody>
                        {candidate.map((item) => {
                            return (
                              <TableLanguages item={item} />
                            )
                          })}
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </CardBody>
              </Card>
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Panels;
