import React from 'react'

//import { Link } from "react-router-dom";
// reactstrap components
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Container,

  } from "reactstrap";

  //import CardBasic from "building_block/card_tarif/basic"
  import CardFlatrate from "building_block/card_tarif/flatrate"
  // import CardUltimate from "building_block/card_tarif/ultimate"
  // import CardEnterprise from "building_block/card_tarif/enterprise"

  const Tarif = ({item}) => {

  // pills for the last pricing
  //const [pillActive, setPillActive] = React.useState("current");

    return (
        <div className="pricing-5 section-gray">
        <Container>
          <Row className="justify-content-center">

            <Col md="10">
              <h2 className="title text-center">Tarif</h2>
              <Row className="ml-auto mr-auto justify-content-center">
                    <Col md="6">
                      <CardFlatrate />
                    </Col>
                  </Row>
              <br />
            </Col>
          </Row>
          <Row className="justify-content-center">
            
              <Col md="10">
              
                
                  <div className="space-top" />
                  
                  <Card className="text-center">
                <CardBody>
                  <Container>
                  <Row>
                    <Col md="7">
                      <b>
                    Vertragsbeginn:
                      </b>
                    </Col>
                    <Col md="5">
                    {item.startDate}
                    </Col>
                    <br/>
                    <Col md="7">
                      <b>
                    Frühestmögliches Vertragsende:
                      </b>
                    </Col>
                    <Col md="5">
                    {item.earliestDateEndContract}
                    </Col>
                    <br/>
                    <Col md="7">
                      <b>
                      Letztmöglicher Kündigungseingang bis:
                      </b>
                    </Col>
                    <Col md="5">
                    {item.deadlineTermination}
                    </Col>
                  </Row>
                  </Container>
                  
                </CardBody>
              </Card>
              <Row>
                    <Col className="text-center">
                    <Button href='src/assets/img/invoice-example.png' download color="primary">
                          Rechnung drucken
                        </Button>
                    </Col>
                  </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default Tarif