import React from "react";
import axios from "axios";
import {Redirect, Link} from "react-router-dom";
import Select from "react-select";
import "./style1.css";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Button,
  Badge,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    width: "100%",

    padding: "0px 0px",
    fontSize: "13px",
    top: "42%",
    backgroundColor: "#f1f1f1",
  }),
};
const salutation1 = [
  {
    id: "1",
    label: "Herr",
    value: "H",
  },
  {
    id: "2",
    label: "Frau",
    value: "F",
  },
];
class HospitalProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [""],
      buttonToRender: 4,
      horizontalTabs: "details",
      hosp: [],
      person: [],
      errmsg: "",
      reserrmsg: "",
      resmsg: "",
      state1: false,
      state2: false,
      offset1: 0,
      perPage1: 10,
      currentPage1: 0,
      salutation1: null,
      c_email: "",
      c_fname: "",
      c_lname: "",
      c_Telefon: "",
      c_position: "",
      note: "",
      getnotes: [],
      showFullText: false, // State variable to control text display
    };
    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }

  // Method to toggle the text display
  toggleTextDisplay = () => {
    this.setState((prevState) => ({
      showFullText: !prevState.showFullText,
    }));
  };

  // Method to limit text to 150 words
  limitText = (text, wordLimit) => {
    if (!text) return ""; // Return an empty string if text is null or undefined
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggle1() {
    this.setState({
      modal1: !this.state.modal1,
    });
  }
  changeHandler = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };
  componentWillMount() {
    const email = JSON.parse(localStorage.getItem("user"));
    this.setState({email});
    // console.log(this.state.email);
  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  acceptHandle(usernum, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(usernum);
    axios
      .post("/api2/accepthospitaluser", {...this.state, usernum})
      .then((response) => {
        console.log(response);
        const msg1 = "Sie akzeptieren die Anfrage";
        this.setState({msg1});
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  rejectHandle(usernum, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(usernum);
    axios
      .post("/api2/rejecthospitaluser", {...this.state, usernum})
      .then((response) => {
        console.log(response);
        const msg1 = "Sie lehnen diese Anfrage ab";
        this.setState({msg1});
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  deleteHandle = (e) => {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(this.state.userid);
    axios
      .post("/api2/deletehospitaluser", this.state)
      .then((response) => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({msg});
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  submitNoteHandler = (e) => {
    var note_err;
    console.log("submit data", this.state);
    if (this.state.note === "") {
      note_err = "Notiz ist leer";
      this.setState({note_err});
    } else {
      this.setState({note_err: undefined});
      console.log("everything is ok");
      axios
        .post("/api2/addhospitalnote", this.state)
        .then((response) => {
          console.log(response);
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  deleteNoteHandler(noteid, e) {
    console.log("noteID", noteid);
    axios
      .post("/api2/deletehospitalnote", {...this.state, noteid})
      .then((response) => {
        console.log(response);
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  editHandle(userid, e) {
    console.log("userid", userid);
    this.setState({userid});
    this.setState({redirect: true});
  }
  handleSubmit = (e) => {
    console.log(this.state);
    var mail_err;
    var c_fname_err;
    var c_lname_err;
    var c_anrede_err;
    if (this.state.salutation1 === null) {
      c_anrede_err = "anrede is empty";
      this.setState({c_anrede_err});
    } else {
      this.setState({c_anrede_err: undefined});
    }
    if (this.state.c_email === "") {
      mail_err = "mail is empty";
      this.setState({mail_err});
    } else {
      this.setState({mail_err: undefined});
    }
    if (this.state.c_fname === "") {
      c_fname_err = "Vorname is empty";
      this.setState({c_fname_err});
    } else {
      this.setState({c_fname_err: undefined});
    }
    if (this.state.c_lname === "") {
      c_lname_err = "Vorname is empty";
      this.setState({c_lname_err});
    } else {
      this.setState({c_lname_err: undefined});
    }
    if (
      mail_err === undefined &&
      c_fname_err === undefined &&
      c_lname_err === undefined &&
      c_anrede_err === undefined
    ) {
      console.log("Everything is fine", this.state.hospid);
      axios
        .post("/api2/Kollegeninvite", this.state)
        .then((response) => {
          // then print response status
          console.log(response);
          var succ_msg = "Kollege erfolgreich hinzugefügt";
          this.setState({succ_msg});
          window.location.reload();
          //this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        })
        .catch((error) => {
          console.log(error.response);
          const final_err = error.response.data.msg;
          this.setState({final_err});
        });
    }
  };
  async componentDidMount() {
    const {hosp_id} = this.props.match.params;
    this.setState({hosp_id});

    try {
      const hospResponse = await axios.post("/api2/hospitalsdetails", {
        hosp_id,
      });
      let hosp = hospResponse.data;

      // Fetch pre-signed URLs for hosp_logo from S3
      hosp = await Promise.all(
        hosp.map(async (user) => {
          const logoUrl = user.hosp_logo
            ? await this.fetchCompanyLogo(user.hosp_logo)
            : null;
          return {
            ...user,
            hosp_logo: logoUrl,
          };
        })
      );

      this.setState({hosp});
      localStorage.setItem("hosp", JSON.stringify(this.state.hosp));

      const check1 = hosp[0].mailoption1;
      this.setState({check1, state1: check1 === 1});

      const check2 = hosp[0].mailoption2;
      this.setState({check2, state2: check2 === 1});
    } catch (error) {
      console.error("Error fetching hospital details:", error);
    }

    try {
      const notesResponse = await axios.post("/api2/gethospitalnote", {
        hosp_id,
      });
      this.setState({getnotes: notesResponse.data});
    } catch (error) {
      console.error("Error fetching hospital notes:", error);
      this.setState({getnotes: []});
    }

    try {
      const contactsResponse = await axios.post("/api2/hospitalscontacts", {
        hosp_id,
      });
      let person = contactsResponse.data;

      // Fetch pre-signed URLs for user_profile from S3
      person = await Promise.all(
        person.map(async (contact) => {
          const profileUrl = contact.user_profile
            ? await this.fetchCompanyLogo(contact.user_profile)
            : null;
          return {
            ...contact,
            user_profile: profileUrl,
          };
        })
      );
    
      this.setState({person});
    } catch (error) {
      console.error("Error fetching hospital contacts:", error);
    }

    // try {
    //   const jobsResponse = await axios.post("/api2/allhospitaljobs", {hosp_id});
    //   console.log(jobsResponse);

    //   // Check if `candidate` exists in the response and is an array
    //   const candidatelist = jobsResponse.data.candidate || [];

    //   // Ensure jobcount exists in the state (assuming it's set in another part of your app)
    //   const jobcount = this.state.jobcount || [];

    //   const slice1 = candidatelist.slice(
    //     this.state.offset1,
    //     this.state.offset1 + this.state.perPage1
    //   );

    //   const postData1 = slice1.map((item) => (
    //     <React.Fragment key={item.job_id}>
    //       <Card style={{backgroundColor: "#f1f1f1", padding: "10px"}}>
    //         <Row>
    //           {/* <Col md="2">
    //             {item.logo && (
    //               <img
    //                 src={item.logo}
    //                 alt=""
    //                 height="80"
    //                 width="auto"
    //               />
    //             )}
    //           </Col> */}
    //           <Col md="7">
    //             <h7 style={{color: "#4287f5", fontSize: "10px"}}>
    //               {item.company_name}
    //             </h7>
    //             <br />
    //             <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`}>
    //               {item.job_function} (m/w/d){" "}
    //               {item.profession !== "undefined" && item.profession}
    //             </Link>
    //             <br />
    //             <i className="fas fa-map-marker-alt"></i>
    //             <h7 className="text-left" style={{fontSize: "13px"}}>
    //               {item.address} {item.haus_no}, {item.zip_code} {item.city},{" "}
    //               {item.state}
    //             </h7>
    //             <br />
    //             {/* Ensure jobcount is defined and map through it */}
    //             {jobcount.length > 0 &&
    //               jobcount.map(
    //                 (item1) =>
    //                   item.job_id === item1.job_id && (
    //                     <Badge
    //                       key={item1.job_id}
    //                       className="mr-1"
    //                       color="success"
    //                       pill>
    //                       Bewerbungen: {item1.cnt}
    //                     </Badge>
    //                   )
    //               )}
    //           </Col>
    //         </Row>
    //         <br />
    //         <Row style={{backgroundColor: "#ffffff", marginTop: "-10px"}}>
    //           <Col md="3">
    //             <i className="far fa-clock"></i>&nbsp;&nbsp;
    //             <h7 style={{fontSize: "11px"}}>
    //               {item.job_worktime}{" "}
    //               {item.job_worktime1 && `, ${item.job_worktime1}`}
    //             </h7>
    //           </Col>
    //           <Col md="3">
    //             <i className="fas fa-hourglass-half"></i>&nbsp;&nbsp;
    //             <h7 style={{fontSize: "11px"}}>
    //               {item.job_ternure}{" "}
    //               {item.job_ternure1 && `, ${item.job_ternure1}`}
    //             </h7>
    //           </Col>
    //           <Col md="3">
    //             <i className="far fa-calendar-check"></i>&nbsp;&nbsp;
    //             <h7 style={{fontSize: "11px"}}>
    //               Online seit: {item.created_date}
    //             </h7>
    //           </Col>
    //           <Col md="3">
    //             <i className="far fa-calendar-alt"></i>&nbsp;&nbsp;&nbsp;
    //             {item.job_status === "online" && (
    //               <h7 style={{fontSize: "11px", color: "#28a745"}}>
    //                 Online bis: {item.valid_till}
    //               </h7>
    //             )}
    //             {item.job_status === "offline" && (
    //               <h7 style={{fontSize: "11px", color: "#ff0800"}}>
    //                 Offline seit: {item.valid_till}
    //               </h7>
    //             )}
    //             {item.job_status === "deactive" && (
    //               <h7 style={{fontSize: "11px", color: "#ff0800"}}>
    //                 Deactive seit: {item.valid_till}
    //               </h7>
    //             )}
    //           </Col>
    //         </Row>
    //       </Card>
    //     </React.Fragment>
    //   ));

    //   this.setState({
    //     pageCount1: Math.ceil(candidatelist.length / this.state.perPage1),
    //     postData1,
    //     state3: true,
    //   });
    // } catch (error) {
    //   console.error("Error fetching jobs:", error);
    //   this.setState({state3: false});
    // }
  }

  // handleMoreClick = (event) => {
  //   this.setState({
  //     buttonToRender: this.state.buttonToRender + 2,
  //   });
  // };
  // handlePageClick1 = (e) => {
  //   const selectedPage1 = e.selected;
  //   const offset1 = selectedPage1 * this.state.perPage1;

  //   this.setState(
  //     {
  //       currentPage1: selectedPage1,
  //       offset1: offset1,
  //     },
  //     () => {
  //       this.componentDidMount();
  //     }
  //   );
  // };
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  // collapsesToggle = (collapse) => {
  //   let openedCollapses = this.state.openedCollapses;
  //   if (openedCollapses.includes(collapse)) {
  //     this.setState({
  //       openedCollapses: openedCollapses.filter((item) => item !== collapse),
  //     });
  //   } else {
  //     openedCollapses.push(collapse);
  //     this.setState({
  //       openedCollapses: openedCollapses,
  //     });
  //   }
  // };

  acceptHandlehosp = async (hosp_id) => {
    axios
      .post("/api2/accepthospital", {hospid: hosp_id})
      .then((response) => {
        if (response) {
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          toast.success("Konto erfolgreich aktiviert.");
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
    // Call the callback function after successful API request
  };

  offlineHandle = async (hosp_id) => {
    axios
      .post("/api2/rejecthospital1", {hospid: hosp_id})
      .then((response) => {
        if (response) {
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          toast.success("Konto erfolgreich deaktiviert.");
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response);
      });
  };

  // Function to fetch company logo from S3
  // async fetchCompanyLogo(filename) {
  //   try {
  //     const response = await axios.get(
  //       `/api2/getProfilePictureCompany/${filename}`,
  //       {
  //         responseType: "blob",
  //       }
  //     );
  //     const imageUrl = URL.createObjectURL(response.data);
  //     console.log(imageUrl);
  //     return imageUrl;
  //   } catch (error) {
  //     console.error("Error fetching company logo:", error);
  //     return null;
  //   }
  // }

  // This function will call the backend API to get the pre-signed URL
  fetchCompanyLogo = async (filename) => {
    if (!filename) return null;

    try {
      // Make an API request to your backend to get the pre-signed URL
      const response = await axios.get(
        `/api2/s3-presigned-url?filename=${filename}`
      );
      return response.data.url; // This URL is a pre-signed S3 URL
    } catch (error) {
      console.error("Error fetching pre-signed URL", error);
      return null;
    }
  };

  render() {
    const {redirect} = this.state;
    // const {isPasswordShown} = this.state;
    if (redirect) {
      return (
        <Redirect to={`/admin/institutionuser-details/${this.state.userid}`} />
      );
    }
    return (
      <div className="content">
        <ToastContainer />
        {this.state.hosp.map((user) => {
          return (
            <Row className="ml-auto mr-auto justify-content-center">
              <Col md="12">
                <Card className="card-user">
                  {this.state.hosp.map((user) => {
                    return (
                      <>
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <Row>
                                <Col
                                  md="2"
                                  style={{
                                    padding: "8px",
                                    marginLeft: "29px",
                                    marginTop: "10px",
                                  }}>
                                  {user.hosp_logo ? (
                                    <img
                                      style={{width: "100px", height: "100px"}}
                                      alt="..."
                                      className="avatar border-gray"
                                      src={user.hosp_logo}
                                    />
                                  ) : null}
                                </Col>
                                <Col md="4">
                                  <h5 cl assName="text-primary">
                                    <b> {user.hosp_name} </b>{" "}
                                  </h5>
                                  <p style={{fontSize: "14px"}}>
                                    <i class="fas fa-map-marker-alt"></i>&nbsp;{" "}
                                    {user.adress} {user.haus_number},{" "}
                                    {user.zip_code} {user.city}
                                    <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {user.state}, {user.country}
                                  </p>
                                  {user.f_name === null ? null : (
                                    <>
                                      {" "}
                                      <li>
                                        {user.f_name} {user.l_name}
                                      </li>
                                    </>
                                  )}
                                  {user.telephone === null ? null : (
                                    <>
                                      {" "}
                                      <li>
                                        <i className="fab fa-whatsapp"></i>{" "}
                                        &nbsp;
                                        <a
                                          href={`https://wa.me/${user.telephone}`}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          {user.telephone}
                                        </a>
                                      </li>
                                    </>
                                  )}
                                  {user.username === null ? null : (
                                    <>
                                      {" "}
                                      <li>
                                        <strong>
                                          <i className="fas fa-envelope-open-text"></i>
                                          &nbsp;
                                        </strong>{" "}
                                        {user.username}
                                      </li>
                                    </>
                                  )}
                                  {user.status && (
                                    <>
                                      <li>
                                        <strong>
                                          {user.status === "aktiv" ? (
                                            <span style={{color: "#28a745"}}>
                                              Konto Status: {user.status}
                                            </span>
                                          ) : (
                                            <span style={{color: "#ff0000"}}>
                                              Konto Status: {user.status}
                                            </span>
                                          )}
                                        </strong>
                                      </li>
                                    </>
                                  )}
                                </Col>

                                <Col md="5" className="text-right" style={{}}>
                                  {user.status && (
                                    <>
                                      {user.status === "aktiv" ? (
                                        <>
                                          <button
                                            className="custom-button-deactive"
                                            onClick={this.offlineHandle.bind(
                                              this,
                                              user.hosp_id
                                            )}>
                                            <i className="fa fa-lock" />
                                            Konto Deaktivieren
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="custom-button"
                                            onClick={this.acceptHandlehosp.bind(
                                              this,
                                              user.hosp_id
                                            )}>
                                            <i
                                              className="fa fa-unlock"
                                              aria-hidden="true">
                                              Konto Aktivieren
                                            </i>
                                          </button>
                                        </>
                                      )}
                                    </>
                                  )}
                                </Col>
                              </Row>
                              <Badge color="warning">{this.state.errmsg}</Badge>
                              <Badge color="warning">
                                {this.state.reserrmsg}
                              </Badge>
                              <Badge color="success">{this.state.resmsg}</Badge>
                              {/* <Row>
                                <Col md="12" style={{margin: "15px"}}>
                                  <h4>Account</h4>
                                  <hr></hr>

                                  <h6 className="title">
                                    {" "}
                                    <u>Benachrichtigungen Verwalten </u>{" "}
                                  </h6>

                                  <br></br>

                                  {this.state.hosp.map((user) => {
                                    return (
                                      <>
                                        <center>
                                          {this.state.state1 ? (
                                            <p>
                                              Allgemeine E-Mail
                                              Benachrichtigungen aktivieren
                                            </p>
                                          ) : null}
                                        </center>
                                      </>
                                    );
                                  })}
                                </Col>
                              </Row> */}
                              <Row>
                                <Col md="12" style={{margin: "15px"}}>
                                  <h4>Über Uns</h4>
                                  <hr></hr>
                                  <h6 className="title">
                                    {" "}
                                    <u>Beschreibung</u>{" "}
                                  </h6>
                                  <p className="p_wrap">
                                    {user.hosp_disc
                                      ? this.state.showFullText
                                        ? user.hosp_disc
                                        : this.limitText(user.hosp_disc, 50)
                                      : "Keine Beschreibung verfügbar"}
                                    {user.hosp_disc && (
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                        }}
                                        onClick={this.toggleTextDisplay}>
                                        {this.state.showFullText
                                          ? " weniger anzeigen"
                                          : " mehr anzeigen"}
                                      </span>
                                    )}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12" style={{margin: "15px"}}>
                                  <h4>Ansprechpartner</h4>
                                  <hr></hr>
                                  {this.state.person.map((user) => {
                                    return (
                                      <>
                                        <Card
                                          style={{
                                            backgroundColor: "#f5f5f5",
                                            padding: "10px",
                                            margin: "13px",
                                          }}>
                                          <Row>
                                            <Col md="2">
                                              {user.user_profile ===
                                              null ? null : (
                                                <img
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                  }}
                                                  alt="..."
                                                  className="avatar border-gray"
                                                  src={user.user_profile}
                                                />
                                              )}
                                            </Col>

                                            <Col md="3">
                                              <h7 className="text-left">
                                                <b>
                                                  {user.Admin_id === null ? (
                                                    <p
                                                      style={{
                                                        color: "#51cbce",
                                                      }}>
                                                      Super Admin
                                                    </p>
                                                  ) : null}
                                                </b>
                                                <b>
                                                  {user.title === "Kein"
                                                    ? null
                                                    : user.title}{" "}
                                                  {user.f_name} {user.l_name}
                                                </b>
                                                <br></br>
                                                {user.telephone ===
                                                null ? null : (
                                                  <>
                                                    <h7
                                                      className="text-left"
                                                      style={{
                                                        fontSize: "13px",
                                                      }}>
                                                      <i class="fas fa-mobile-alt"></i>{" "}
                                                      &nbsp;
                                                      <a
                                                        href={`tel:${user.telephone}`}>
                                                        {user.telephone}
                                                      </a>
                                                    </h7>
                                                    <br></br>
                                                  </>
                                                )}
                                                {user.telephone ===
                                                null ? null : (
                                                  <>
                                                    <h7
                                                      className="text-left"
                                                      style={{
                                                        fontSize: "13px",
                                                      }}>
                                                      <i class="fab fa-whatsapp"></i>{" "}
                                                      &nbsp;
                                                      <a
                                                        href={`https://wa.me/${user.telephone}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {user.telephone}
                                                      </a>
                                                    </h7>
                                                    <br></br>
                                                  </>
                                                )}
                                                <h7
                                                  className="text-left"
                                                  style={{fontSize: "13px"}}>
                                                  <i class="fas fa-envelope-open-text"></i>
                                                  &nbsp;{user.username}
                                                </h7>
                                                <br></br>
                                                <i class="fas fa-address-card"></i>
                                                &nbsp;
                                                <h7
                                                  className="text-left"
                                                  style={{fontSize: "13px"}}>
                                                  {user.position}
                                                </h7>
                                              </h7>
                                            </Col>
                                            <Col md="3">
                                              <h7
                                                className="text-left"
                                                style={{fontSize: "11px"}}>
                                                <b>Registrierung:</b>{" "}
                                                {user.created_date}
                                              </h7>
                                              <br></br>
                                              <h7
                                                className="text-left"
                                                style={{fontSize: "11px"}}>
                                                <b>Profile aktualisiert:</b>{" "}
                                                {user.last_update}
                                              </h7>
                                              <br></br>
                                              <h7
                                                className="text-left"
                                                style={{fontSize: "11px"}}>
                                                <b>Eingeloggt zuletzt:</b>{" "}
                                                {user.last_login_date}
                                              </h7>
                                              <br></br>
                                              {user.status === "aktiv" ? (
                                                <h7
                                                  className="text-left"
                                                  style={{
                                                    fontSize: "11px",
                                                    color: "#28a745",
                                                  }}>
                                                  <b>Konto Status:</b>{" "}
                                                  {user.status}{" "}
                                                </h7>
                                              ) : (
                                                <h7
                                                  className="text-left"
                                                  style={{
                                                    fontSize: "11px",
                                                    color: "#ff0800",
                                                  }}>
                                                  <b>Konto Status:</b>{" "}
                                                  {user.status}{" "}
                                                </h7>
                                              )}
                                              <div
                                                className="text-left"
                                                style={{fontSize: "11px"}}>
                                                <span>
                                                  {user.mailoption1 === 1 ? (
                                                    <span
                                                      style={{
                                                        color: "#28a745",
                                                      }}>
                                                      <b>
                                                        {" "}
                                                        Nachrichten Emails:{" "}
                                                      </b>

                                                      {user.mailoption1 === 1
                                                        ? "aktiv"
                                                        : ""}
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color: "#ff0000",
                                                      }}>
                                                      <b>
                                                        {" "}
                                                        Nachrichten Emails:{" "}
                                                      </b>

                                                      {user.mailoption1 === 0
                                                        ? "deaktiv"
                                                        : ""}
                                                    </span>
                                                  )}
                                                </span>
                                              </div>
                                              <div
                                                className="text-left"
                                                style={{fontSize: "11px"}}>
                                                <span>
                                                  {user.is_email_allow_hosp ===
                                                  1 ? (
                                                    <span
                                                      style={{
                                                        color: "#28a745",
                                                      }}>
                                                      <b> Karrieretipps: </b>

                                                      {user.is_email_allow_hosp ===
                                                      1
                                                        ? "aktiv"
                                                        : ""}
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color: "#ff0000",
                                                      }}>
                                                      <b> Karrieretipps: </b>

                                                      {user.is_email_allow_hosp ===
                                                      0
                                                        ? "deaktiv"
                                                        : ""}
                                                    </span>
                                                  )}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col md="4">
                                              {user.status === "aktiv" ? (
                                                <button
                                                  className="custom-button-deactive"
                                                  type="button"
                                                  onClick={this.rejectHandle.bind(
                                                    this,
                                                    user.user_id
                                                  )}>
                                                  <i className="fa fa-toggle-off" />
                                                  Deaktivieren
                                                </button>
                                              ) : (
                                                <button
                                                  className="custom-button"
                                                  type="button"
                                                  onClick={this.acceptHandle.bind(
                                                    this,
                                                    user.user_id
                                                  )}>
                                                  <i
                                                    class="fa fa-unlock"
                                                    aria-hidden="true"></i>
                                                  Aktivieren
                                                </button>
                                              )}
                                              <Link
                                                to={`/admin/messages-list-hospital/${user.user_id}`}>
                                                <button
                                                  className="custom-button"
                                                  id={
                                                    "tooltip366246647" +
                                                    user.user_id
                                                  }

                                                  //onClick={this.toggle}
                                                  //onClick={this.deleteHandle.bind(this, item1.user_id)}
                                                  //onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                                                >
                                                  <i className="fa fa-comments" />
                                                  Chat
                                                </button>{" "}
                                              </Link>
                                              <button
                                                className="custom-button-deactive"
                                                id={
                                                  "tooltip366246642" +
                                                  user.user_id
                                                }
                                                type="button"
                                                onClick={() =>
                                                  this.setState(
                                                    {userid: user.user_id},
                                                    this.toggle
                                                  )
                                                }
                                                //onClick={this.deleteHandle.bind(this, item1.user_id)}
                                              >
                                                <i className="fa fa-trash" />
                                                Konto löschen
                                              </button>{" "}
                                              <button
                                                className="custom-button"
                                                id={
                                                  "tooltip366246652" +
                                                  user.user_id
                                                }
                                                //style = {{marginLeft:"0px"}}
                                                onClick={this.editHandle.bind(
                                                  this,
                                                  user.user_id
                                                )}>
                                                <i className="fa fa-edit" />
                                                Bearbeiten
                                              </button>{" "}
                                              {/* </Link> */}
                                              <Modal
                                                isOpen={this.state.modal}
                                                toggle={this.toggle}
                                                className={
                                                  this.props.className
                                                }>
                                                <ModalHeader
                                                  toggle={
                                                    this.toggle
                                                  }></ModalHeader>
                                                <ModalBody>
                                                  <h4>
                                                    <b>
                                                      Unternehmen dauerhaft
                                                      löschen
                                                    </b>
                                                  </h4>
                                                  <Badge color="warning">
                                                    {this.state.reply}
                                                  </Badge>
                                                  <br />
                                                  Sind Sie sicher, dass Sie
                                                  diesen Unternehmen löschen
                                                  möchten?<br></br>
                                                  <br></br>
                                                  <center>
                                                    <Button
                                                      onClick={this.toggle}
                                                      style={{
                                                        width: "auto",
                                                        height: "32px",
                                                        fontSize: "9px",
                                                        borderRadius: "32px",
                                                        boxShadow:
                                                          "4.5px 7.794px 13px 0px #afc1ec",
                                                        fontWeight: "400",
                                                        border:
                                                          "none !important",
                                                        padding: "9px 27px",
                                                        // background: '#dc3545',
                                                        marginBottom: "1rem",
                                                      }}>
                                                      Abbrechen
                                                    </Button>
                                                    <Button
                                                      color="primary"
                                                      style={{
                                                        width: "auto",
                                                        height: "32px",
                                                        fontSize: "9px",
                                                        borderRadius: "32px",
                                                        boxShadow:
                                                          "4.5px 7.794px 13px 0px #afc1ec",
                                                        fontWeight: "400",
                                                        border:
                                                          "none !important",
                                                        padding: "10px 27px",
                                                        background: "#dc3545",
                                                      }}
                                                      onClick={
                                                        this.deleteHandle
                                                      }>
                                                      Bestätigen{" "}
                                                    </Button>
                                                  </center>
                                                </ModalBody>
                                              </Modal>
                                              {/* /////////////////////////////////////////////////////////////////////////////// */}
                                              <Modal
                                                isOpen={this.state.modal1}
                                                toggle={this.toggle1}
                                                className={
                                                  this.props.className
                                                }>
                                                <ModalHeader
                                                  toggle={this.toggle1}>
                                                  Kollegen Einladen
                                                </ModalHeader>
                                                <ModalBody>
                                                  <FormGroup>
                                                    <Badge color="warning">
                                                      {this.state.c_anrede_err}
                                                    </Badge>
                                                    <br></br>
                                                    {/* <label  style={{fontSize: '12px'}}>Anrede</label> */}
                                                    <Select
                                                      styles={customStyles}
                                                      // className="react-select primary"
                                                      classNamePrefix="react-select"
                                                      name="salutation1"
                                                      //value={this.state.salutation}
                                                      onChange={(value) =>
                                                        this.setState({
                                                          salutation1: value,
                                                        })
                                                      }
                                                      options={salutation1}
                                                      placeholder="Anrede"
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Badge color="warning">
                                                      {this.state.mail_err}
                                                    </Badge>
                                                    <br></br>
                                                    {/* <label  style={{fontSize: '12px'}}>Email</label> */}
                                                    <Input
                                                      style={{
                                                        fontSize: "15px",
                                                        height: "35px",
                                                        backgroundColor:
                                                          "#f1f1f1",
                                                      }}
                                                      //value={this.state.email}
                                                      placeholder="E-Mail"
                                                      type="text"
                                                      name="c_email"
                                                      onChange={
                                                        this.changeHandler
                                                      }
                                                      required
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Badge color="warning">
                                                      {this.state.c_fname_err}
                                                    </Badge>
                                                    <br></br>
                                                    {/* <label  style={{fontSize: '12px'}}>Vorname</label> */}
                                                    <Input
                                                      style={{
                                                        fontSize: "15px",
                                                        height: "35px",
                                                        backgroundColor:
                                                          "#f1f1f1",
                                                      }}
                                                      //value={this.state.email}
                                                      placeholder="Vorname"
                                                      type="text"
                                                      name="c_fname"
                                                      onChange={
                                                        this.changeHandler
                                                      }
                                                      required
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <Badge color="warning">
                                                      {this.state.c_lname_err}
                                                    </Badge>
                                                    <br></br>
                                                    {/* <label  style={{fontSize: '12px'}}>Nachname</label> */}
                                                    <Input
                                                      style={{
                                                        fontSize: "15px",
                                                        height: "35px",
                                                        backgroundColor:
                                                          "#f1f1f1",
                                                      }}
                                                      //value={this.state.email}
                                                      placeholder="Nachname"
                                                      type="text"
                                                      name="c_lname"
                                                      onChange={
                                                        this.changeHandler
                                                      }
                                                      required
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <br></br>
                                                    {/* <Badge color="warning">{this.state.c_lname_err}</Badge><br></br> */}
                                                    {/* <label  style={{fontSize: '12px'}}>Nachname</label> */}
                                                    <Input
                                                      style={{
                                                        fontSize: "15px",
                                                        height: "35px",
                                                        backgroundColor:
                                                          "#f1f1f1",
                                                      }}
                                                      //value={this.state.email}
                                                      placeholder="Position (Optional)"
                                                      type="text"
                                                      name="c_position"
                                                      onChange={
                                                        this.changeHandler
                                                      }
                                                    />
                                                  </FormGroup>
                                                  <FormGroup>
                                                    <br></br>
                                                    {/* <Badge color="warning">{this.state.c_lname_err}</Badge><br></br> */}
                                                    {/* <label  style={{fontSize: '12px'}}>Nachname</label> */}
                                                    <Input
                                                      style={{
                                                        fontSize: "15px",
                                                        height: "35px",
                                                        backgroundColor:
                                                          "#f1f1f1",
                                                      }}
                                                      //value={this.state.email}
                                                      placeholder="Telefon (Optional)"
                                                      type="text"
                                                      name="c_Telefon"
                                                      onChange={
                                                        this.changeHandler
                                                      }
                                                    />
                                                  </FormGroup>
                                                  <center>
                                                    <Button
                                                      color="primary"
                                                      style={{
                                                        width: "auto",
                                                        height: "32px",
                                                        fontSize: "9px",
                                                        borderRadius: "32px",
                                                        boxShadow:
                                                          "4.5px 7.794px 13px 0px #afc1ec",
                                                        fontWeight: "400",
                                                        border:
                                                          "none !important",
                                                        padding: "10px 27px",
                                                        background: "#dc3545",
                                                      }}
                                                      onClick={
                                                        this.handleSubmit
                                                      }>
                                                      Einladen{" "}
                                                    </Button>
                                                    <br></br>
                                                    <br></br>
                                                    <Badge color="warning">
                                                      {this.state.final_err}
                                                    </Badge>
                                                  </center>
                                                </ModalBody>
                                              </Modal>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </>
                                    );
                                  })}
                                  <Card
                                    style={{
                                      background: "#e3e5e6",
                                    }}>
                                    <h4 style={{textAlign: "center"}}>
                                      Notizen
                                    </h4>
                                  </Card>
                                  <Card>
                                    <CardHeader
                                      style={{
                                        background: "#e3e5e6",
                                        padding: "10px",
                                      }}>
                                      <Row>
                                        <Col md="3">Email</Col>
                                        <Col md="3">Datum</Col>
                                        <Col md="4">Notizen</Col>
                                        <Col md="2">Aktion</Col>
                                      </Row>
                                    </CardHeader>

                                    {this.state.getnotes.map((item) => {
                                      return (
                                        <>
                                          <Row style={{padding: "10px"}}>
                                            <Col md="3">
                                              <span style={{fontSize: "14px"}}>
                                                {item.email}
                                              </span>
                                            </Col>
                                            <Col md="3">
                                              <span style={{fontSize: "14px"}}>
                                                {item.h_note_date}
                                              </span>
                                            </Col>
                                            <Col md="4">
                                              <span
                                                style={{
                                                  fontSize: "14px",
                                                  whiteSpace: "pre-line",
                                                }}>
                                                {item.a_note}
                                              </span>
                                            </Col>
                                            <Col md="2">
                                              <Button
                                                color="danger"
                                                id={
                                                  "tooltip366246640" +
                                                  item.a_note_id
                                                }
                                                size="md"
                                                type="button"
                                                onClick={this.deleteNoteHandler.bind(
                                                  this,
                                                  item.a_note_id
                                                )}>
                                                Löschen
                                              </Button>{" "}
                                              <UncontrolledTooltip
                                                delay={0}
                                                target={
                                                  "tooltip366246640" +
                                                  item.a_note_id
                                                }>
                                                Löschen
                                              </UncontrolledTooltip>
                                            </Col>
                                          </Row>

                                          <hr />
                                        </>
                                      );
                                    })}

                                    <center>
                                      <Badge className="mr-1" color="danger">
                                        {this.state.note_err}
                                      </Badge>
                                      <Input
                                        type="textarea"
                                        name="note"
                                        // value={this.state.note}
                                        placeholder="Schreibe hier eine Notiz"
                                        onChange={this.changeHandler}
                                        // rows={5}
                                        // cols={1}
                                        // style={{ width: "523px", fontSize:"14px" }}
                                      />
                                      <Button
                                        color="primary"
                                        onClick={this.submitNoteHandler}>
                                        Notiz hinzufügen
                                      </Button>
                                    </center>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </>
                    );
                  })}

                  {/* <CardHeader>
                    <h5>
                      <b>Jobs</b>
                    </h5>
                  </CardHeader>
                  <CardBody>
                    {this.state.postData1}
                    {this.state.state3 ? (
                      <>
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount1}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick1}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </>
                    ) : null}
                  </CardBody> */}
                </Card>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
}

export default HospitalProfile;
