import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import ReactPaginate from 'react-paginate';
import "./style1.css";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Form,
  FormGroup,
  Row,
  Col,
  //Table,
  Button,
  UncontrolledTooltip,
  Badge
} from "reactstrap";


//import TableCandidateList from "building_block/table_candidate_list"
const radius = [
  { value: '5', label: '5 KM', id: "1" },
  { value: '10', label: '10 KM', id: "2" },
  { value: '25', label: '25 KM', id: "3" },
  { value: '50', label: '50 KM', id: "4" },
  { value: '75', label: '75 KM', id: "5" },
]
const opt = [
  {
    id: '2',
    label: 'Altenpflege',
    value: 'alt'
  },
  {
    id: '3',
    label: 'Krankenpflege',
    value: 'krank'
  },
  {
    id: "4",
    label: "Therapeut",
    value: "tera"
  },
  {
    id: '5',
    label: 'Medizintechnik',
    value: 'mediz'
  },
  {
    id: '6',
    label: 'Rettungsdienst',
    value: 'ret'
  },
  {
    id: '7',
    label: 'Praxispersonal',
    value: 'prax'
  },
  {
    id: '8',
    label: 'Pharmazie',
    value: 'phar'
  }
]
const opt2 = [
  { value: 'krank0', label: 'Bitte auswählen', link: 'krank' },
  { value: 'krank1', label: 'Anästhesieschwester', link: 'krank' },
  { value: 'krank3', label: 'Anästhesietechnische Assistentin (ATA)', link: 'krank' },
  { value: 'krank4', label: 'Fachkrankenschwester', link: 'krank' },
  { value: 'krank5', label: 'Gesundheitspflegehelfer/Krankenpflegehelfer', link: 'krank' },
  { value: 'krank6', label: 'Gesundheitspfleger/Krankenpfleger', link: 'krank' },
  { value: 'krank7', label: 'Hebamme', link: 'krank' },
  { value: 'krank8', label: 'Heilerziehungspfleger', link: 'krank' },
  { value: 'krank9', label: 'Kinderkrankenschwester', link: 'krank' },
  { value: 'krank10', label: 'OP Schwester', link: 'krank' },
  { value: 'krank11', label: 'Operationstechnische Assistentin (OTA)', link: 'krank' },
  { value: 'krank12', label: 'Pflegeassistent', link: 'krank' },
  { value: 'krank13', label: 'Pflegedienstleitung', link: 'krank' },
  { value: 'krank14', label: 'Stationsleitung', link: 'krank' },
  { value: 'krank15', label: 'Study Nurse', link: 'krank' },

  { value: 'ret1', label: 'Bitte auswählen', link: 'ret' },
  { value: 'ret2', label: 'Rettungsassistentin', link: 'ret' },
  { value: 'ret3', label: 'Rettungshelfer', link: 'ret' },
  { value: 'ret4', label: 'Rettungssanitäter', link: 'ret' },

  { value: 'mediz1', label: 'Bitte auswählen', link: 'mediz' },
  { value: 'mediz2', label: 'Medizininformatiker', link: 'mediz' },
  { value: 'mediz3', label: 'Medizinisch-technische Laboratoriumsassistentin (MTLA)', link: 'mediz' },
  { value: 'mediz4', label: 'Medizintechnik-Ingenieur', link: 'mediz' },
  { value: 'mediz5', label: 'Medizintechniker', link: 'mediz' },
  { value: 'mediz6', label: 'MTA Funktionsdiagnostik (MTAF', link: 'mediz' },
  { value: 'mediz7', label: 'MTA Radiologie (MTRA)', link: 'mediz' },
  { value: 'mediz8', label: 'Zahntechniker', link: 'mediz' },

  { value: 'phar1', label: 'Bitte auswählen', link: 'phar' },
  { value: 'phar2', label: 'Apotheker', link: 'phar' },
  { value: 'phar3', label: 'Pharmakant', link: 'phar' },
  { value: 'phar4', label: 'Pharmareferent', link: 'phar' },
  { value: 'phar5', label: 'Pharmazeutisch-kaufmännische Angestellte (PKA)', link: 'phar' },
  { value: 'phar6', label: 'Pharmazeutisch-technische Assistentin (PTA)', link: 'phar' },

  { value: 'prax1', label: 'Bitte auswählen', link: 'prax' },
  { value: 'prax2', label: 'Medizinische Fachangestellte', link: 'prax' },
  { value: 'prax3', label: 'Praxismanagerin', link: 'prax' },
  { value: 'prax4', label: 'Zahnmedizinische Fachangestellte', link: 'prax' },
  { value: 'prax5', label: 'Tiermedizinische Fachangestellte', link: 'prax' },

  { value: 'alt1', label: 'Bitte auswählen', link: 'alt' },
  { value: 'alt2', label: 'Altenpflegehelfer', link: 'alt' },
  { value: 'alt3', label: 'Altenpfleger', link: 'alt' },
  { value: 'alt4', label: 'Betreuungskraft', link: 'alt' },
  { value: 'alt5', label: 'Fachaltenpfleger', link: 'alt' },
  { value: 'alt6', label: 'Hauswirtschafterin', link: 'alt' },
  { value: 'alt7', label: 'Pflegedienstleitung', link: 'alt' },

  { value: 'tera1', label: 'Bitte auswählen', link: 'tera' },
  { value: 'tera2', label: 'Ergotherapeut', link: 'tera' },
  { value: 'tera3', label: 'Erzieher', link: 'tera' },
  { value: 'tera4', label: 'Heilpraktiker', link: 'tera' },
  { value: 'tera5', label: 'Kinderpfleger', link: 'tera' },
  { value: 'tera6', label: 'Kunsttherapeut', link: 'tera' },
  { value: 'tera7', label: 'Logopäde', link: 'tera' },
  { value: 'tera8', label: 'Masseur/medizinischer Bademeister', link: 'tera' },
  { value: 'tera9', label: 'Motopäde', link: 'tera' },
  { value: 'tera10', label: 'Musiktherapeut', link: 'tera' },
  { value: 'tera11', label: 'Orthoptist', link: 'tera' },
  { value: 'tera12', label: 'Podologe', link: 'tera' },
  { value: 'tera13', label: 'Physiotherapeut', link: 'tera' },
  { value: 'tera15', label: 'Psychotherapeut', link: 'tera' },
  { value: 'tera16', label: 'Psychologe', link: 'tera' },
  { value: 'tera17', label: 'Sozialpädagoge', link: 'tera' },
];
const opt3 = [
  //{ value: 'doctor6', label: 'Select', link: 'doctor1' },
  //   { value: 'doctor7', label: 'Akupunktur', link: 'doctor1' },
  //   { value: 'doctor8', label: 'Allergologie', link: 'doctor1' },
  //   { value: 'doctor9', label: 'Allgemeinchirurgie', link: 'doctor1' },
  //   { value: 'doctor10', label: 'Allgemeinmedizin', link: 'doctor1' },
  //   { value: 'doctor11', label: 'Anatomie', link: 'doctor1' },
  //   { value: 'doctor12', label: 'Andrologie', link: 'doctor1' },
  //   { value: 'doctor13', label: 'Anästhesiologie', link: 'doctor1' },
  //   { value: 'doctor14', label: 'Arbeitsmedizin', link: 'doctor1' },
  //   { value: 'doctor15', label: 'Augenheilkunde', link: 'doctor1' },
  //   { value: 'doctor16', label: 'Biochemie', link: 'doctor1' },
  //   { value: 'doctor17', label: 'Chirurgie', link: 'doctor1' },
  //   { value: 'doctor18', label: 'Dermatohistologie', link: 'doctor1' },
  //   { value: 'doctor19', label: 'Diabetologie', link: 'doctor1' },
  //   { value: 'doctor20', label: 'Flugmedizin', link: 'doctor1' },
  //   { value: 'doctor21', label: 'Forensische Psychiatrie', link: 'doctor1' },
  //   { value: 'doctor22', label: 'Gefäßchirugie', link: 'doctor1' },
  //   { value: 'doctor23', label: 'Geriatrie', link: 'doctor1' },
  //   { value: 'doctor24', label: 'Gynäkologie und Geburtshilfe', link: 'doctor1' },
  //   { value: 'doctor25', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor1' },
  //   { value: 'doctor26', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor1' },
  //   { value: 'doctor27', label: 'Gynäkologische Onkologie', link: 'doctor1' },
  //   { value: 'doctor28', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor1' },
  //   { value: 'doctor29', label: 'Handchirurgie', link: 'doctor1' },
  //   { value: 'doctor30', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor1' },
  //   { value: 'doctor31', label: 'Herzchirurgie', link: 'doctor1' },
  //   { value: 'doctor32', label: 'Homöopathie', link: 'doctor1' },
  //   { value: 'doctor33', label: 'Humangenetik', link: 'doctor1' },
  //   { value: 'doctor34', label: 'Hygiene und Umweltmedizin', link: 'doctor1' },
  //   { value: 'doctor35', label: 'Hämostaseologie', link: 'doctor1' },
  //   { value: 'doctor36', label: 'Infektiologie', link: 'doctor1' },
  //   { value: 'doctor37', label: 'Innere Medizin', link: 'doctor1' },
  //   { value: 'doctor38', label: 'Innere Medizin und Angiologie', link: 'doctor1' },
  //   { value: 'doctor39', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor1' },
  //   { value: 'doctor40', label: 'Innere Medizin und Gastroenterologie', link: 'doctor1' },
  //   { value: 'doctor41', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor1' },
  //   { value: 'doctor42', label: 'Innere Medizin und Kardiologie', link: 'doctor1' },
  //   { value: 'doctor43', label: 'Innere Medizin und Nephrologie', link: 'doctor1' },
  //   { value: 'doctor44', label: 'Innere Medizin und Pneumologie', link: 'doctor1' },
  //   { value: 'doctor45', label: 'Innere Medizin und Rheumatologie', link: 'doctor1' },
  //   { value: 'doctor46', label: 'Intensivmedizin', link: 'doctor1' },
  //   { value: 'doctor47', label: 'Interventionelle Kardiologie', link: 'doctor1' },
  //   { value: 'doctor48', label: 'Kinder- und Jugendmedizin', link: 'doctor1' },
  //   { value: 'doctor49', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor1' },
  //   { value: 'doctor50', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor1' },
  //   { value: 'doctor51', label: 'Kinder-Gastroenterologie', link: 'doctor1' },
  //   { value: 'doctor52', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor1' },
  //   { value: 'doctor53', label: 'Kinder-Kardiologie', link: 'doctor1' },
  //   { value: 'doctor54', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor1' },
  //   { value: 'doctor55', label: 'Kinder-Pneumologie', link: 'doctor1' },
  //   { value: 'doctor56', label: 'Kinder-Rheumatologie', link: 'doctor1' },
  //   { value: 'doctor57', label: 'Kinderchirurgie', link: 'doctor1' },
  //   { value: 'doctor58', label: 'Kinderradiologie', link: 'doctor1' },
  //   { value: 'doctor59', label: 'Klinische Pharmakologie', link: 'doctor1' },
  //   { value: 'doctor60', label: 'Krankenhaushygiene', link: 'doctor1' },
  //   { value: 'doctor61', label: 'Labordiagnostik-fachgebunden', link: 'doctor1' },
  //   { value: 'doctor62', label: 'Labormedizin', link: 'doctor1' },
  //   { value: 'doctor63', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor1' },
  //   { value: 'doctor64', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor1' },
  //   { value: 'doctor65', label: 'Medikamentöse Tumortherapie', link: 'doctor1' },
  //   { value: 'doctor66', label: 'Medizinische Informatik', link: 'doctor1' },
  //   { value: 'doctor67', label: 'Mikrobiologie', link: 'doctor1' },
  //   { value: 'doctor68', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor1' },
  //   { value: 'doctor69', label: 'Naturheilverfahren', link: 'doctor1' },
  //   { value: 'doctor70', label: 'Neonatologie', link: 'doctor1' },
  //   { value: 'doctor71', label: 'Neurochirurgie', link: 'doctor1' },
  //   { value: 'doctor72', label: 'Neurologie', link: 'doctor1' },
  //   { value: 'doctor73', label: 'Neuropädiatrie', link: 'doctor1' },
  //   { value: 'doctor74', label: 'Neuroradiologie', link: 'doctor1' },
  //   { value: 'doctor75', label: 'Notfallmedizin', link: 'doctor1' },
  //   { value: 'doctor76', label: 'Nuklearmedizin', link: 'doctor1' },
  //   { value: 'doctor77', label: 'Orthopädie und Unfallchirurgie', link: 'doctor1' },
  //   { value: 'doctor78', label: 'Orthopädische Rheumatologie', link: 'doctor1' },
  //   { value: 'doctor79', label: 'Palliativmedizin', link: 'doctor1' },
  //   { value: 'doctor80', label: 'Pathologie', link: 'doctor1' },
  //   { value: 'doctor81', label: 'Pharmakologie', link: 'doctor1' },
  //   { value: 'doctor82', label: 'Pharmakologie und Texikologie', link: 'doctor1' },
  //   { value: 'doctor83', label: 'Phlebologie', link: 'doctor1' },
  //   { value: 'doctor84', label: 'Physikalische Therapie und Balneologie', link: 'doctor1' },
  //   { value: 'doctor85', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor1' },
  //   { value: 'doctor86', label: 'Physiologie', link: 'doctor1' },
  //   { value: 'doctor87', label: 'Plastische Operationen', link: 'doctor1' },
  //   { value: 'doctor88', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor1' },
  //   { value: 'doctor89', label: 'Proktologie', link: 'doctor1' },
  //   { value: 'doctor90', label: 'Psychiatrie und Psychotherapie', link: 'doctor1' },
  //   { value: 'doctor91', label: 'Psychoanalyse', link: 'doctor1' },
  //   { value: 'doctor92', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor1' },
  //   { value: 'doctor93', label: 'Psychotherapie-fachgebunden', link: 'doctor1' },
  //   { value: 'doctor94', label: 'Radiologie', link: 'doctor1' },
  //   { value: 'doctor95', label: 'Rechtsmedizin', link: 'doctor1' },
  //   { value: 'doctor96', label: 'Rhytmologie', link: 'doctor1' },
  //   { value: 'doctor97', label: 'Schlafmedizin', link: 'doctor1' },
  //   { value: 'doctor98', label: 'Sozialmedizin', link: 'doctor1' },
  //   { value: 'doctor99', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor1' },
  //   { value: 'doctor100', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor1' },
  //   { value: 'doctor101', label: 'Spezielle Schmerztherapie', link: 'doctor1' },
  //   { value: 'doctor102', label: 'Spezielle Unfallchirurgie', link: 'doctor1' },
  //   { value: 'doctor103', label: 'Sportmedizin', link: 'doctor1' },
  //   { value: 'doctor104', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor1' },
  //   { value: 'doctor105', label: 'Strahlentherapie', link: 'doctor1' },
  //   { value: 'doctor106', label: 'Suchtmedizinische Grundversorgung', link: 'doctor1' },
  //   { value: 'doctor107', label: 'Thoraxchirurgie', link: 'doctor1' },
  //   { value: 'doctor108', label: 'Transfusionsmedizin', link: 'doctor1' },
  //   { value: 'doctor109', label: 'Tropenmedizin', link: 'doctor1' },
  //   { value: 'doctor110', label: 'Urologie', link: 'doctor1' },
  //   { value: 'doctor111', label: 'Viszeralchirurgie', link: 'doctor1' },
  //   { value: 'doctor112', label: 'Zahnmedizin', link: 'doctor1' },
  //   { value: 'doctor113', label: 'Ärztliches Qualitätsmanagement', link: 'doctor1' },
  //   { value: 'doctor114', label: 'Öffentliche Gesundheitswesen', link: 'doctor1' },
  //   ///////////////////////////////////////////////////////////////////////////////////////////////
  //  // { value: 'doctor1111', label: 'Select', link: 'doctor2' },
  //   { value: 'doctor115', label: 'Akupunktur', link: 'doctor2' },
  //   { value: 'doctor116', label: 'Allergologie', link: 'doctor2' },
  //   { value: 'doctor117', label: 'Allgemeinchirurgie', link: 'doctor2' },
  //   { value: 'doctor118', label: 'Allgemeinmedizin', link: 'doctor2' },
  //   { value: 'doctor119', label: 'Anatomie', link: 'doctor2' },
  //   { value: 'doctor120', label: 'Andrologie', link: 'doctor2' },
  //   { value: 'doctor121', label: 'Anästhesiologie', link: 'doctor2' },
  //   { value: 'doctor122', label: 'Arbeitsmedizin', link: 'doctor2' },
  //   { value: 'doctor123', label: 'Augenheilkunde', link: 'doctor2' },
  //   { value: 'doctor124', label: 'Biochemie', link: 'doctor2' },
  //   { value: 'doctor125', label: 'Chirurgie', link: 'doctor2' },
  //   { value: 'doctor126', label: 'Dermatohistologie', link: 'doctor2' },
  //   { value: 'doctor127', label: 'Diabetologie', link: 'doctor2' },
  //   { value: 'doctor128', label: 'Flugmedizin', link: 'doctor2' },
  //   { value: 'doctor129', label: 'Forensische Psychiatrie', link: 'doctor2' },
  //   { value: 'doctor130', label: 'Gefäßchirugie', link: 'doctor2' },
  //   { value: 'doctor131', label: 'Geriatrie', link: 'doctor2' },
  //   { value: 'doctor132', label: 'Gynäkologie und Geburtshilfe', link: 'doctor2' },
  //   { value: 'doctor133', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor2' },
  //   { value: 'doctor134', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor2' },
  //   { value: 'doctor135', label: 'Gynäkologische Onkologie', link: 'doctor2' },
  //   { value: 'doctor136', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor2' },
  //   { value: 'doctor137', label: 'Handchirurgie', link: 'doctor2' },
  //   { value: 'doctor138', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor2' },
  //   { value: 'doctor139', label: 'Herzchirurgie', link: 'doctor2' },
  //   { value: 'doctor140', label: 'Homöopathie', link: 'doctor2' },
  //   { value: 'doctor141', label: 'Humangenetik', link: 'doctor2' },
  //   { value: 'doctor141', label: 'Hygiene und Umweltmedizin', link: 'doctor2' },
  //   { value: 'doctor142', label: 'Hämostaseologie', link: 'doctor2' },
  //   { value: 'doctor143', label: 'Infektiologie', link: 'doctor2' },
  //   { value: 'doctor144', label: 'Innere Medizin', link: 'doctor2' },
  //   { value: 'doctor145', label: 'Innere Medizin und Angiologie', link: 'doctor2' },
  //   { value: 'doctor146', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor2' },
  //   { value: 'doctor147', label: 'Innere Medizin und Gastroenterologie', link: 'doctor2' },
  //   { value: 'doctor148', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor2' },
  //   { value: 'doctor149', label: 'Innere Medizin und Kardiologie', link: 'doctor2' },
  //   { value: 'doctor150', label: 'Innere Medizin und Nephrologie', link: 'doctor2' },
  //   { value: 'doctor151', label: 'Innere Medizin und Pneumologie', link: 'doctor2' },
  //   { value: 'doctor152', label: 'Innere Medizin und Rheumatologie', link: 'doctor2' },
  //   { value: 'doctor153', label: 'Intensivmedizin', link: 'doctor2' },
  //   { value: 'doctor154', label: 'Interventionelle Kardiologie', link: 'doctor2' },
  //   { value: 'doctor155', label: 'Kinder- und Jugendmedizin', link: 'doctor2' },
  //   { value: 'doctor156', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor2' },
  //   { value: 'doctor157', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor2' },
  //   { value: 'doctor158', label: 'Kinder-Gastroenterologie', link: 'doctor2' },
  //   { value: 'doctor159', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor2' },
  //   { value: 'doctor160', label: 'Kinder-Kardiologie', link: 'doctor2' },
  //   { value: 'doctor161', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor2' },
  //   { value: 'doctor162', label: 'Kinder-Pneumologie', link: 'doctor2' },
  //   { value: 'doctor163', label: 'Kinder-Rheumatologie', link: 'doctor2' },
  //   { value: 'doctor164', label: 'Kinderchirurgie', link: 'doctor2' },
  //   { value: 'doctor165', label: 'Kinderradiologie', link: 'doctor2' },
  //   { value: 'doctor166', label: 'Klinische Pharmakologie', link: 'doctor2' },
  //   { value: 'doctor167', label: 'Krankenhaushygiene', link: 'doctor2' },
  //   { value: 'doctor168', label: 'Labordiagnostik-fachgebunden', link: 'doctor2' },
  //   { value: 'doctor169', label: 'Labormedizin', link: 'doctor2' },
  //   { value: 'doctor170', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor2' },
  //   { value: 'doctor171', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor2' },
  //   { value: 'doctor172', label: 'Medikamentöse Tumortherapie', link: 'doctor2' },
  //   { value: 'doctor173', label: 'Medizinische Informatik', link: 'doctor2' },
  //   { value: 'doctor174', label: 'Mikrobiologie', link: 'doctor2' },
  //   { value: 'doctor175', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor2' },
  //   { value: 'doctor176', label: 'Naturheilverfahren', link: 'doctor2' },
  //   { value: 'doctor177', label: 'Neonatologie', link: 'doctor2' },
  //   { value: 'doctor178', label: 'Neurochirurgie', link: 'doctor2' },
  //   { value: 'doctor179', label: 'Neurologie', link: 'doctor2' },
  //   { value: 'doctor180', label: 'Neuropädiatrie', link: 'doctor2' },
  //   { value: 'doctor181', label: 'Neuroradiologie', link: 'doctor2' },
  //   { value: 'doctor182', label: 'Notfallmedizin', link: 'doctor2' },
  //   { value: 'doctor183', label: 'Nuklearmedizin', link: 'doctor2' },
  //   { value: 'doctor184', label: 'Orthopädie und Unfallchirurgie', link: 'doctor2' },
  //   { value: 'doctor185', label: 'Orthopädische Rheumatologie', link: 'doctor2' },
  //   { value: 'doctor186', label: 'Palliativmedizin', link: 'doctor2' },
  //   { value: 'doctor187', label: 'Pathologie', link: 'doctor2' },
  //   { value: 'doctor188', label: 'Pharmakologie', link: 'doctor2' },
  //   { value: 'doctor189', label: 'Pharmakologie und Texikologie', link: 'doctor2' },
  //   { value: 'doctor190', label: 'Phlebologie', link: 'doctor2' },
  //   { value: 'doctor191', label: 'Physikalische Therapie und Balneologie', link: 'doctor2' },
  //   { value: 'doctor192', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor2' },
  //   { value: 'doctor193', label: 'Physiologie', link: 'doctor2' },
  //   { value: 'doctor194', label: 'Plastische Operationen', link: 'doctor2' },
  //   { value: 'doctor195', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor2' },
  //   { value: 'doctor196', label: 'Proktologie', link: 'doctor2' },
  //   { value: 'doctor197', label: 'Psychiatrie und Psychotherapie', link: 'doctor2' },
  //   { value: 'doctor198', label: 'Psychoanalyse', link: 'doctor2' },
  //   { value: 'doctor199', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor2' },
  //   { value: 'doctor200', label: 'Psychotherapie-fachgebunden', link: 'doctor2' },
  //   { value: 'doctor201', label: 'Radiologie', link: 'doctor2' },
  //   { value: 'doctor202', label: 'Rechtsmedizin', link: 'doctor2' },
  //   { value: 'doctor203', label: 'Rhytmologie', link: 'doctor2' },
  //   { value: 'doctor204', label: 'Schlafmedizin', link: 'doctor2' },
  //   { value: 'doctor205', label: 'Sozialmedizin', link: 'doctor2' },
  //   { value: 'doctor206', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor2' },
  //   { value: 'doctor207', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor2' },
  //   { value: 'doctor208', label: 'Spezielle Schmerztherapie', link: 'doctor2' },
  //   { value: 'doctor209', label: 'Spezielle Unfallchirurgie', link: 'doctor2' },
  //   { value: 'doctor210', label: 'Sportmedizin', link: 'doctor2' },
  //   { value: 'doctor211', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor2' },
  //   { value: 'doctor212', label: 'Strahlentherapie', link: 'doctor2' },
  //   { value: 'doctor213', label: 'Suchtmedizinische Grundversorgung', link: 'doctor2' },
  //   { value: 'doctor214', label: 'Thoraxchirurgie', link: 'doctor2' },
  //   { value: 'doctor215', label: 'Transfusionsmedizin', link: 'doctor2' },
  //   { value: 'doctor216', label: 'Tropenmedizin', link: 'doctor2' },
  //   { value: 'doctor217', label: 'Urologie', link: 'doctor2' },
  //   { value: 'doctor218', label: 'Viszeralchirurgie', link: 'doctor2' },
  //   { value: 'doctor219', label: 'Zahnmedizin', link: 'doctor2' },
  //   { value: 'doctor220', label: 'Ärztliches Qualitätsmanagement', link: 'doctor2' },
  //   { value: 'doctor221', label: 'Öffentliche Gesundheitswesen', link: 'doctor2' },
  //   ////////////////////////////////////////////////////////////////////////////////////////////////////
  //  // { value: 'doctor6123', label: 'Select', link: 'doctor3' },
  //   { value: 'docto1', label: 'Akupunktur', link: 'doctor3' },
  //   { value: 'docto2', label: 'Allergologie', link: 'doctor3' },
  //   { value: 'docto3', label: 'Allgemeinchirurgie', link: 'doctor3' },
  //   { value: 'docto4', label: 'Allgemeinmedizin', link: 'doctor3' },
  //   { value: 'docto5', label: 'Anatomie', link: 'doctor3' },
  //   { value: 'docto6', label: 'Andrologie', link: 'doctor3' },
  //   { value: 'docto7', label: 'Anästhesiologie', link: 'doctor3' },
  //   { value: 'docto8', label: 'Arbeitsmedizin', link: 'doctor3' },
  //   { value: 'docto9', label: 'Augenheilkunde', link: 'doctor3' },
  //   { value: 'docto10', label: 'Biochemie', link: 'doctor3' },
  //   { value: 'docto11', label: 'Chirurgie', link: 'doctor3' },
  //   { value: 'docto12', label: 'Dermatohistologie', link: 'doctor3' },
  //   { value: 'docto13', label: 'Diabetologie', link: 'doctor3' },
  //   { value: 'docto14', label: 'Flugmedizin', link: 'doctor3' },
  //   { value: 'docto15', label: 'Forensische Psychiatrie', link: 'doctor3' },
  //   { value: 'docto16', label: 'Gefäßchirugie', link: 'doctor3' },
  //   { value: 'docto17', label: 'Geriatrie', link: 'doctor3' },
  //   { value: 'docto18', label: 'Gynäkologie und Geburtshilfe', link: 'doctor3' },
  //   { value: 'docto19', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor3' },
  //   { value: 'docto20', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor3' },
  //   { value: 'docto21', label: 'Gynäkologische Onkologie', link: 'doctor3' },
  //   { value: 'docto22', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor3' },
  //   { value: 'docto23', label: 'Handchirurgie', link: 'doctor3' },
  //   { value: 'docto24', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor3' },
  //   { value: 'docto25', label: 'Herzchirurgie', link: 'doctor3' },
  //   { value: 'docto26', label: 'Homöopathie', link: 'doctor3' },
  //   { value: 'docto27', label: 'Humangenetik', link: 'doctor3' },
  //   { value: 'docto28', label: 'Hygiene und Umweltmedizin', link: 'doctor3' },
  //   { value: 'docto29', label: 'Hämostaseologie', link: 'doctor3' },
  //   { value: 'docto30', label: 'Infektiologie', link: 'doctor3' },
  //   { value: 'docto31', label: 'Innere Medizin', link: 'doctor3' },
  //   { value: 'docto32', label: 'Innere Medizin und Angiologie', link: 'doctor3' },
  //   { value: 'docto33', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor3' },
  //   { value: 'docto34', label: 'Innere Medizin und Gastroenterologie', link: 'doctor3' },
  //   { value: 'docto35', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor3' },
  //   { value: 'docto36', label: 'Innere Medizin und Kardiologie', link: 'doctor3' },
  //   { value: 'docto37', label: 'Innere Medizin und Nephrologie', link: 'doctor3' },
  //   { value: 'docto38', label: 'Innere Medizin und Pneumologie', link: 'doctor3' },
  //   { value: 'docto39', label: 'Innere Medizin und Rheumatologie', link: 'doctor3' },
  //   { value: 'docto40', label: 'Intensivmedizin', link: 'doctor3' },
  //   { value: 'docto41', label: 'Interventionelle Kardiologie', link: 'doctor3' },
  //   { value: 'docto42', label: 'Kinder- und Jugendmedizin', link: 'doctor3' },
  //   { value: 'docto43', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor3' },
  //   { value: 'docto44', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor3' },
  //   { value: 'docto45', label: 'Kinder-Gastroenterologie', link: 'doctor3' },
  //   { value: 'docto46', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor3' },
  //   { value: 'docto47', label: 'Kinder-Kardiologie', link: 'doctor3' },
  //   { value: 'docto48', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor3' },
  //   { value: 'docto49', label: 'Kinder-Pneumologie', link: 'doctor3' },
  //   { value: 'docto50', label: 'Kinder-Rheumatologie', link: 'doctor3' },
  //   { value: 'docto51', label: 'Kinderchirurgie', link: 'doctor3' },
  //   { value: 'docto52', label: 'Kinderradiologie', link: 'doctor3' },
  //   { value: 'docto53', label: 'Klinische Pharmakologie', link: 'doctor3' },
  //   { value: 'docto54', label: 'Krankenhaushygiene', link: 'doctor3' },
  //   { value: 'docto55', label: 'Labordiagnostik-fachgebunden', link: 'doctor3' },
  //   { value: 'docto56', label: 'Labormedizin', link: 'doctor3' },
  //   { value: 'docto57', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor3' },
  //   { value: 'docto58', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor3' },
  //   { value: 'docto59', label: 'Medikamentöse Tumortherapie', link: 'doctor3' },
  //   { value: 'docto60', label: 'Medizinische Informatik', link: 'doctor3' },
  //   { value: 'docto61', label: 'Mikrobiologie', link: 'doctor3' },
  //   { value: 'docto62', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor3' },
  //   { value: 'docto63', label: 'Naturheilverfahren', link: 'doctor3' },
  //   { value: 'docto64', label: 'Neonatologie', link: 'doctor3' },
  //   { value: 'docto65', label: 'Neurochirurgie', link: 'doctor3' },
  //   { value: 'docto66', label: 'Neurologie', link: 'doctor3' },
  //   { value: 'docto67', label: 'Neuropädiatrie', link: 'doctor3' },
  //   { value: 'docto68', label: 'Neuroradiologie', link: 'doctor3' },
  //   { value: 'docto69', label: 'Notfallmedizin', link: 'doctor3' },
  //   { value: 'docto70', label: 'Nuklearmedizin', link: 'doctor3' },
  //   { value: 'docto71', label: 'Orthopädie und Unfallchirurgie', link: 'doctor3' },
  //   { value: 'docto72', label: 'Orthopädische Rheumatologie', link: 'doctor3' },
  //   { value: 'docto73', label: 'Palliativmedizin', link: 'doctor3' },
  //   { value: 'docto74', label: 'Pathologie', link: 'doctor3' },
  //   { value: 'docto75', label: 'Pharmakologie', link: 'doctor3' },
  //   { value: 'docto76', label: 'Pharmakologie und Texikologie', link: 'doctor3' },
  //   { value: 'docto77', label: 'Phlebologie', link: 'doctor3' },
  //   { value: 'docto78', label: 'Physikalische Therapie und Balneologie', link: 'doctor3' },
  //   { value: 'docto79', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor3' },
  //   { value: 'docto80', label: 'Physiologie', link: 'doctor3' },
  //   { value: 'docto81', label: 'Plastische Operationen', link: 'doctor3' },
  //   { value: 'docto82', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor' },
  //   { value: 'docto83', label: 'Proktologie', link: 'doctor3' },
  //   { value: 'docto84', label: 'Psychiatrie und Psychotherapie', link: 'doctor3' },
  //   { value: 'docto85', label: 'Psychoanalyse', link: 'doctor3' },
  //   { value: 'docto86', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor3' },
  //   { value: 'docto87', label: 'Psychotherapie-fachgebunden', link: 'doctor3' },
  //   { value: 'docto88', label: 'Radiologie', link: 'doctor3' },
  //   { value: 'docto89', label: 'Rechtsmedizin', link: 'doctor3' },
  //   { value: 'docto90', label: 'Rhytmologie', link: 'doctor3' },
  //   { value: 'docto91', label: 'Schlafmedizin', link: 'doctor3' },
  //   { value: 'docto92', label: 'Sozialmedizin', link: 'doctor3' },
  //   { value: 'docto93', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor3' },
  //   { value: 'docto94', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor3' },
  //   { value: 'docto95', label: 'Spezielle Schmerztherapie', link: 'doctor3' },
  //   { value: 'docto96', label: 'Spezielle Unfallchirurgie', link: 'doctor3' },
  //   { value: 'docto97', label: 'Sportmedizin', link: 'doctor3' },
  //   { value: 'docto98', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor3' },
  //   { value: 'docto99', label: 'Strahlentherapie', link: 'doctor3' },
  //   { value: 'docto100', label: 'Suchtmedizinische Grundversorgung', link: 'doctor3' },
  //   { value: 'docto101', label: 'Thoraxchirurgie', link: 'doctor3' },
  //   { value: 'docto102', label: 'Transfusionsmedizin', link: 'doctor3' },
  //   { value: 'docto103', label: 'Tropenmedizin', link: 'doctor3' },
  //   { value: 'docto104', label: 'Urologie', link: 'doctor3' },
  //   { value: 'docto105', label: 'Viszeralchirurgie', link: 'doctor3' },
  //   { value: 'docto106', label: 'Zahnmedizin', link: 'doctor3' },
  //   { value: 'docto107', label: 'Ärztliches Qualitätsmanagement', link: 'doctor3' },
  //   { value: 'docto108', label: 'Öffentliche Gesundheitswesen', link: 'doctor3' },
  //   /////////////////////////////////////////////////////////////////////////////////////////////////////
  //  // { value: 'doctor1001', label: 'Select', link: 'doctor4' },
  //   { value: '1doctor', label: 'Akupunktur', link: 'doctor4' },
  //   { value: '2doctor', label: 'Allergologie', link: 'doctor4' },
  //   { value: '3doctor', label: 'Allgemeinchirurgie', link: 'doctor4' },
  //   { value: '4doctor', label: 'Allgemeinmedizin', link: 'doctor4' },
  //   { value: '5doctor', label: 'Anatomie', link: 'doctor4' },
  //   { value: '6doctor', label: 'Andrologie', link: 'doctor4' },
  //   { value: '7doctor', label: 'Anästhesiologie', link: 'doctor4' },
  //   { value: '8doctor', label: 'Arbeitsmedizin', link: 'doctor4' },
  //   { value: '9doctor', label: 'Augenheilkunde', link: 'doctor4' },
  //   { value: '10doctor', label: 'Biochemie', link: 'doctor4' },
  //   { value: '11doctor', label: 'Chirurgie', link: 'doctor4' },
  //   { value: '12doctor', label: 'Dermatohistologie', link: 'doctor4' },
  //   { value: '13doctor', label: 'Diabetologie', link: 'doctor4' },
  //   { value: '14doctor', label: 'Flugmedizin', link: 'doctor4' },
  //   { value: '15doctor', label: 'Forensische Psychiatrie', link: 'doctor4' },
  //   { value: '16doctor', label: 'Gefäßchirugie', link: 'doctor4' },
  //   { value: '17doctor', label: 'Geriatrie', link: 'doctor4' },
  //   { value: '18doctor', label: 'Gynäkologie und Geburtshilfe', link: 'doctor4' },
  //   { value: '19doctor', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor4' },
  //   { value: '20doctor', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor4' },
  //   { value: '21doctor', label: 'Gynäkologische Onkologie', link: 'doctor4' },
  //   { value: '22doctor', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor4' },
  //   { value: '23doctor', label: 'Handchirurgie', link: 'doctor4' },
  //   { value: '24doctor', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor4' },
  //   { value: '25doctor', label: 'Herzchirurgie', link: 'doctor4' },
  //   { value: '26doctor', label: 'Homöopathie', link: 'doctor4' },
  //   { value: '27doctor', label: 'Humangenetik', link: 'doctor4' },
  //   { value: '28doctor', label: 'Hygiene und Umweltmedizin', link: 'doctor4' },
  //   { value: '29doctor', label: 'Hämostaseologie', link: 'doctor4' },
  //   { value: '30doctor', label: 'Infektiologie', link: 'doctor4' },
  //   { value: '31doctor', label: 'Innere Medizin', link: 'doctor4' },
  //   { value: '32doctor', label: 'Innere Medizin und Angiologie', link: 'doctor4' },
  //   { value: '33doctor', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor4' },
  //   { value: '34doctor', label: 'Innere Medizin und Gastroenterologie', link: 'doctor4' },
  //   { value: '35doctor', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor4' },
  //   { value: '36doctor', label: 'Innere Medizin und Kardiologie', link: 'doctor4' },
  //   { value: '37doctor', label: 'Innere Medizin und Nephrologie', link: 'doctor4' },
  //   { value: '38doctor', label: 'Innere Medizin und Pneumologie', link: 'doctor4' },
  //   { value: '39doctor', label: 'Innere Medizin und Rheumatologie', link: 'doctor4' },
  //   { value: '40doctor', label: 'Intensivmedizin', link: 'doctor4' },
  //   { value: '41doctor', label: 'Interventionelle Kardiologie', link: 'doctor4' },
  //   { value: '42doctor', label: 'Kinder- und Jugendmedizin', link: 'doctor4' },
  //   { value: '43doctor', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor4' },
  //   { value: '44doctor', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor4' },
  //   { value: '45doctor', label: 'Kinder-Gastroenterologie', link: 'doctor4' },
  //   { value: '46doctor', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor4' },
  //   { value: '47doctor', label: 'Kinder-Kardiologie', link: 'doctor4' },
  //   { value: '48doctor', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor4' },
  //   { value: '49doctor', label: 'Kinder-Pneumologie', link: 'doctor4' },
  //   { value: '50doctor', label: 'Kinder-Rheumatologie', link: 'doctor4' },
  //   { value: '51doctor', label: 'Kinderchirurgie', link: 'doctor4' },
  //   { value: '52doctor', label: 'Kinderradiologie', link: 'doctor4' },
  //   { value: '53doctor', label: 'Klinische Pharmakologie', link: 'doctor4' },
  //   { value: '54doctor', label: 'Krankenhaushygiene', link: 'doctor4' },
  //   { value: '55doctor', label: 'Labordiagnostik-fachgebunden', link: 'doctor4' },
  //   { value: '56doctor', label: 'Labormedizin', link: 'doctor4' },
  //   { value: '57doctor', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor4' },
  //   { value: '58doctor', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor4' },
  //   { value: '59doctor', label: 'Medikamentöse Tumortherapie', link: 'doctor4' },
  //   { value: '60doctor', label: 'Medizinische Informatik', link: 'doctor4' },
  //   { value: '61doctor', label: 'Mikrobiologie', link: 'doctor4' },
  //   { value: '62doctor', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor4' },
  //   { value: '63doctor', label: 'Naturheilverfahren', link: 'doctor4' },
  //   { value: '64doctor', label: 'Neonatologie', link: 'doctor4' },
  //   { value: '65doctor', label: 'Neurochirurgie', link: 'doctor4' },
  //   { value: '66doctor', label: 'Neurologie', link: 'doctor4' },
  //   { value: '67doctor', label: 'Neuropädiatrie', link: 'doctor4' },
  //   { value: '68doctor', label: 'Neuroradiologie', link: 'doctor4' },
  //   { value: '69doctor', label: 'Notfallmedizin', link: 'doctor4' },
  //   { value: '70doctor', label: 'Nuklearmedizin', link: 'doctor4' },
  //   { value: '71doctor', label: 'Orthopädie und Unfallchirurgie', link: 'doctor4' },
  //   { value: '72doctor', label: 'Orthopädische Rheumatologie', link: 'doctor4' },
  //   { value: '73doctor', label: 'Palliativmedizin', link: 'doctor4' },
  //   { value: '74doctor', label: 'Pathologie', link: 'doctor4' },
  //   { value: '75doctor', label: 'Pharmakologie', link: 'doctor4' },
  //   { value: '76doctor', label: 'Pharmakologie und Texikologie', link: 'doctor4' },
  //   { value: '77doctor', label: 'Phlebologie', link: 'doctor4' },
  //   { value: '78doctor', label: 'Physikalische Therapie und Balneologie', link: 'doctor4' },
  //   { value: '79doctor', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor4' },
  //   { value: '80doctor', label: 'Physiologie', link: 'doctor4' },
  //   { value: '81doctor', label: 'Plastische Operationen', link: 'doctor4' },
  //   { value: '82doctor', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor4' },
  //   { value: '83doctor', label: 'Proktologie', link: 'doctor4' },
  //   { value: '84doctor', label: 'Psychiatrie und Psychotherapie', link: 'doctor4' },
  //   { value: '85doctor', label: 'Psychoanalyse', link: 'doctor4' },
  //   { value: '86doctor', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor4' },
  //   { value: '87doctor', label: 'Psychotherapie-fachgebunden', link: 'doctor4' },
  //   { value: '88doctor', label: 'Radiologie', link: 'doctor4' },
  //   { value: '89doctor', label: 'Rechtsmedizin', link: 'doctor4' },
  //   { value: '90doctor', label: 'Rhytmologie', link: 'doctor4' },
  //   { value: '91doctor', label: 'Schlafmedizin', link: 'doctor4' },
  //   { value: '92doctor', label: 'Sozialmedizin', link: 'doctor4' },
  //   { value: '93doctor', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor4' },
  //   { value: '94doctor', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor4' },
  //   { value: '95doctor', label: 'Spezielle Schmerztherapie', link: 'doctor4' },
  //   { value: '96doctor', label: 'Spezielle Unfallchirurgie', link: 'doctor4' },
  //   { value: '97doctor', label: 'Sportmedizin', link: 'doctor4' },
  //   { value: '98doctor', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor4' },
  //   { value: '99doctor', label: 'Strahlentherapie', link: 'doctor4' },
  //   { value: '100doctor', label: 'Suchtmedizinische Grundversorgung', link: 'doctor4' },
  //   { value: '101doctor', label: 'Thoraxchirurgie', link: 'doctor4' },
  //   { value: '102doctor', label: 'Transfusionsmedizin', link: 'doctor4' },
  //   { value: '103doctor', label: 'Tropenmedizin', link: 'doctor4' },
  //   { value: '104doctor', label: 'Urologie', link: 'doctor4' },
  //   { value: '105doctor', label: 'Viszeralchirurgie', link: 'doctor4' },
  //   { value: '106doctor', label: 'Zahnmedizin', link: 'doctor4' },
  //   { value: '107octor', label: 'Ärztliches Qualitätsmanagement', link: 'doctor4' },
  //   { value: '108doctor', label: 'Öffentliche Gesundheitswesen', link: 'doctor4' },
  //   ///////////////////////////////////////////////////////////////////////////////////////////////
  //  // { value: 'doctor6123', label: 'Select', link: 'doctor5' },
  //   { value: '109doctor', label: 'Akupunktur', link: 'doctor5' },
  //   { value: '110doctor', label: 'Allergologie', link: 'doctor5' },
  //   { value: '111doctor', label: 'Allgemeinchirurgie', link: 'doctor5' },
  //   { value: '112doctor', label: 'Allgemeinmedizin', link: 'doctor5' },
  //   { value: '113doctor', label: 'Anatomie', link: 'doctor5' },
  //   { value: '114doctor', label: 'Andrologie', link: 'doctor5' },
  //   { value: '115doctor', label: 'Anästhesiologie', link: 'doctor5' },
  //   { value: '116doctor', label: 'Arbeitsmedizin', link: 'doctor5' },
  //   { value: '117doctor', label: 'Augenheilkunde', link: 'doctor5' },
  //   { value: '118doctor', label: 'Biochemie', link: 'doctor5' },
  //   { value: '119doctor', label: 'Chirurgie', link: 'doctor5' },
  //   { value: '120doctor', label: 'Dermatohistologie', link: 'doctor5' },
  //   { value: '121doctor', label: 'Diabetologie', link: 'doctor5' },
  //   { value: '122doctor', label: 'Flugmedizin', link: 'doctor5' },
  //   { value: '123doctor', label: 'Forensische Psychiatrie', link: 'doctor5' },
  //   { value: '124doctor', label: 'Gefäßchirugie', link: 'doctor5' },
  //   { value: '125doctor', label: 'Geriatrie', link: 'doctor5' },
  //   { value: '126doctor', label: 'Gynäkologie und Geburtshilfe', link: 'doctor5' },
  //   { value: '127doctor', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor5' },
  //   { value: '128doctor', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor5' },
  //   { value: '129doctor', label: 'Gynäkologische Onkologie', link: 'doctor5' },
  //   { value: '130doctor', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor5' },
  //   { value: '131doctor', label: 'Handchirurgie', link: 'doctor5' },
  //   { value: '132doctor', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor5' },
  //   { value: '133doctor', label: 'Herzchirurgie', link: 'doctor5' },
  //   { value: '134doctor', label: 'Homöopathie', link: 'doctor5' },
  //   { value: '135doctor', label: 'Humangenetik', link: 'doctor5' },
  //   { value: '136doctor', label: 'Hygiene und Umweltmedizin', link: 'doctor5' },
  //   { value: '137doctor', label: 'Hämostaseologie', link: 'doctor5' },
  //   { value: '138doctor', label: 'Infektiologie', link: 'doctor5' },
  //   { value: '139doctor', label: 'Innere Medizin', link: 'doctor5' },
  //   { value: '140doctor', label: 'Innere Medizin und Angiologie', link: 'doctor5' },
  //   { value: '141doctor', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor5' },
  //   { value: '142doctor', label: 'Innere Medizin und Gastroenterologie', link: 'doctor5' },
  //   { value: '143doctor', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor5' },
  //   { value: '144doctor', label: 'Innere Medizin und Kardiologie', link: 'doctor5' },
  //   { value: '145doctor', label: 'Innere Medizin und Nephrologie', link: 'doctor5' },
  //   { value: '146doctor', label: 'Innere Medizin und Pneumologie', link: 'doctor5' },
  //   { value: '147doctor', label: 'Innere Medizin und Rheumatologie', link: 'doctor5' },
  //   { value: '148doctor', label: 'Intensivmedizin', link: 'doctor5' },
  //   { value: '149doctor', label: 'Interventionelle Kardiologie', link: 'doctor5' },
  //   { value: '150doctor', label: 'Kinder- und Jugendmedizin', link: 'doctor5' },
  //   { value: '151doctor', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor5' },
  //   { value: '152doctor', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor5' },
  //   { value: '153doctor', label: 'Kinder-Gastroenterologie', link: 'doctor5' },
  //   { value: '154doctor', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor5' },
  //   { value: '155doctor', label: 'Kinder-Kardiologie', link: 'doctor5' },
  //   { value: '156doctor', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor5' },
  //   { value: '157doctor', label: 'Kinder-Pneumologie', link: 'doctor5' },
  //   { value: '158doctor', label: 'Kinder-Rheumatologie', link: 'doctor5' },
  //   { value: '158doctor', label: 'Kinderchirurgie', link: 'doctor5' },
  //   { value: '160doctor', label: 'Kinderradiologie', link: 'doctor5' },
  //   { value: '161doctor', label: 'Klinische Pharmakologie', link: 'doctor5' },
  //   { value: '162doctor', label: 'Krankenhaushygiene', link: 'doctor5' },
  //   { value: '163doctor', label: 'Labordiagnostik-fachgebunden', link: 'doctor5' },
  //   { value: '164doctor', label: 'Labormedizin', link: 'doctor5' },
  //   { value: '165doctor', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor5' },
  //   { value: '166doctor', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor5' },
  //   { value: '167doctor', label: 'Medikamentöse Tumortherapie', link: 'doctor5' },
  //   { value: '168doctor', label: 'Medizinische Informatik', link: 'doctor5' },
  //   { value: '169doctor', label: 'Mikrobiologie', link: 'doctor5' },
  //   { value: '170doctor', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor5' },
  //   { value: '171doctor', label: 'Naturheilverfahren', link: 'doctor5' },
  //   { value: '172doctor', label: 'Neonatologie', link: 'doctor5' },
  //   { value: '173doctor', label: 'Neurochirurgie', link: 'doctor5' },
  //   { value: '174doctor', label: 'Neurologie', link: 'doctor5' },
  //   { value: '175doctor', label: 'Neuropädiatrie', link: 'doctor5' },
  //   { value: '176doctor', label: 'Neuroradiologie', link: 'docto5' },
  //   { value: '177doctor', label: 'Notfallmedizin', link: 'doctor5' },
  //   { value: '178doctor', label: 'Nuklearmedizin', link: 'doctor5' },
  //   { value: '179doctor', label: 'Orthopädie und Unfallchirurgie', link: 'doctor5' },
  //   { value: '180doctor', label: 'Orthopädische Rheumatologie', link: 'doctor5' },
  //   { value: '181doctor', label: 'Palliativmedizin', link: 'doctor5' },
  //   { value: '182doctor', label: 'Pathologie', link: 'doctor5' },
  //   { value: '183doctor', label: 'Pharmakologie', link: 'doctor5' },
  //   { value: '184doctor', label: 'Pharmakologie und Texikologie', link: 'doctor5' },
  //   { value: '185doctor', label: 'Phlebologie', link: 'doctor5' },
  //   { value: '186doctor', label: 'Physikalische Therapie und Balneologie', link: 'doctor5' },
  //   { value: '187doctor', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor5' },
  //   { value: '188doctor', label: 'Physiologie', link: 'doctor5' },
  //   { value: '189doctor', label: 'Plastische Operationen', link: 'doctor5' },
  //   { value: '190doctor', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor5' },
  //   { value: '191doctor', label: 'Proktologie', link: 'doctor5' },
  //   { value: '192doctor', label: 'Psychiatrie und Psychotherapie', link: 'doctor5' },
  //   { value: '193doctor', label: 'Psychoanalyse', link: 'doctor5' },
  //   { value: '194doctor', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor5' },
  //   { value: '195doctor', label: 'Psychotherapie-fachgebunden', link: 'doctor5' },
  //   { value: '196doctor', label: 'Radiologie', link: 'doctor5' },
  //   { value: '197doctor', label: 'Rechtsmedizin', link: 'doctor5' },
  //   { value: '198doctor', label: 'Rhytmologie', link: 'doctor5' },
  //   { value: '199doctor', label: 'Schlafmedizin', link: 'doctor5' },
  //   { value: '200doctor', label: 'Sozialmedizin', link: 'doctor5' },
  //   { value: '201doctor', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor5' },
  //   { value: '202doctor', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor5' },
  //   { value: '203doctor', label: 'Spezielle Schmerztherapie', link: 'doctor5' },
  //   { value: '204doctor', label: 'Spezielle Unfallchirurgie', link: 'doctor5' },
  //   { value: '205doctor', label: 'Sportmedizin', link: 'doctor5' },
  //   { value: '206doctor', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor5' },
  //   { value: '207doctor', label: 'Strahlentherapie', link: 'doctor5' },
  //   { value: '208doctor', label: 'Suchtmedizinische Grundversorgung', link: 'doctor5' },
  //   { value: '209doctor', label: 'Thoraxchirurgie', link: 'doctor5' },
  //   { value: '210doctor', label: 'Transfusionsmedizin', link: 'doctor5' },
  //   { value: '211doctor', label: 'Tropenmedizin', link: 'doctor5' },
  //   { value: '212doctor', label: 'Urologie', link: 'doctor5' },
  //   { value: '213doctor', label: 'Viszeralchirurgie', link: 'doctor5' },
  //   { value: '214doctor', label: 'Zahnmedizin', link: 'doctor5' },
  //   { value: '215doctor', label: 'Ärztliches Qualitätsmanagement', link: 'doctor5' },
  //   { value: '216doctor', label: 'Öffentliche Gesundheitswesen', link: 'doctor5' },
  //   ////////////////////////////////////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank1' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank1' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank1' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank1' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank1' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank1' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank1' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank1' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank1' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank1' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank1' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank1' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank1' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank1' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank1' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank1' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank1' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank1' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank1' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank1' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank1' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank1' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank1' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank1' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank1' },
  { value: 'Fach27', label: 'Urologie', link: 'krank1' },
  //////////////////////////////////////////////////////////////////////////////
  //{ value: 'Fach1', label: 'Select', link: 'krank3' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank3' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank3' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank3' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank3' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank3' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank3' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank3' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank3' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank3' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank3' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank3' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank3' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank3' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank3' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank3' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank3' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank3' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank3' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank3' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank3' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank3' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank3' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank3' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank3' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank3' },
  { value: 'Fach27', label: 'Urologie', link: 'krank3' },
  ///////////////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank4' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank4' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank4' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank4' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank4' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank4' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank4' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank4' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank4' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank4' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank4' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank4' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank4' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank4' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank4' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank4' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank4' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank4' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank4' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank4' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank4' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank4' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank4' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank4' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank4' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank4' },
  { value: 'Fach27', label: 'Urologie', link: 'krank4' },
  //////////////////////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank5' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank5' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank5' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank5' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank5' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank5' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank5' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank5' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank5' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank5' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank5' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank5' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank5' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank5' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank5' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank5' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank5' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'kran5k' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank5' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank5' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank5' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank5' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank5' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank5' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank5' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank5' },
  { value: 'Fach27', label: 'Urologie', link: 'krank5' },
  ///////////////////////////////////////////////////////////////////////
  //{ value: 'Fach1', label: 'Select', link: 'krank6' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank6' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank6' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank6' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank6' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank6' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank6' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank6' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank6' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank6' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank6' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank6' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank6' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank6' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank6' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank6' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank6' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank6' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank6' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank6' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank6' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank6' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank6' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank6' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank6' },
  { value: 'Fach27', label: 'Urologie', link: 'krank6' },
  //////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank7' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank7' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank7' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank7' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank7' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank7' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank7' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank7' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank7' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank7' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank7' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank7' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank7' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank7' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank7' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank7' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank7' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank7' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank7' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank7' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank7' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank7' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank7' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank7' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank7' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank7' },
  { value: 'Fach27', label: 'Urologie', link: 'krank7' },
  /////////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank8' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank8' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank8' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank8' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank8' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank8' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank8' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank8' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank8' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank8' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank8' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank8' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank8' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank8' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank8' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank8' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank8' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank8' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank8' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank8' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank8' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank8' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank8' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank8' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank8' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank8' },
  { value: 'Fach27', label: 'Urologie', link: 'krank8' },
  ///////////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank9' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank9' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank9' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank9' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank9' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank9' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank9' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank9' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank9' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank9' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank9' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank9' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank9' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank9' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank9' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank9' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank9' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank9' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank9' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank9' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank9' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank9' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank9' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank9' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank9' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank9' },
  { value: 'Fach27', label: 'Urologie', link: 'krank9' },
  ////////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank10' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank10' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank10' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank10' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank10' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank10' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank10' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank10' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank10' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank10' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank10' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank10' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank10' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank10' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank10' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank10' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank10' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank10' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank10' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank10' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank10' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank10' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank10' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank10' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank10' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank10' },
  { value: 'Fach27', label: 'Urologie', link: 'krank10' },
  ////////////////////////////////////////////////////////////////////
  //{ value: 'Fach1', label: 'Select', link: 'krank11' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank11' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank11' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank11' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank11' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank11' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank11' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank11' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank11' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank11' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank11' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank11' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank11' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank11' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank11' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank11' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank11' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank11' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank11' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank11' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank11' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank11' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank11' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank11' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank11' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank11' },
  { value: 'Fach27', label: 'Urologie', link: 'krank11' },
  //////////////////////////////////////////////////////////////////////////////
  // { value: 'Fach1', label: 'Select', link: 'krank12' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank12' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank12' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank12' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank12' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank12' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank12' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank12' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank12' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank12' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank12' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank12' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank12' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank12' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank12' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank12' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank12' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank12' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank12' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank12' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank12' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank12' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank12' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank12' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank12' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank12' },
  { value: 'Fach27', label: 'Urologie', link: 'krank12' },
  ////////////////////////////////////////////////////////////////////
  //{ value: 'Fach1', label: 'Select', link: 'krank13' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank13' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank13' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank13' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank13' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank13' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank13' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank13' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank13' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank13' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank13' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank12' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank13' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank13' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank13' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank13' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank13' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank13' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank13' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank13' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank13' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank13' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank13' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank13' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank13' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank13' },
  { value: 'Fach27', label: 'Urologie', link: 'krank13' },
  /////////////////////////////////////////////////////////////////
  //{ value: 'Fach1', label: 'Select', link: 'krank14' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank14' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank14' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank14' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank14' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank14' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank14' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank14' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank14' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank14' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank14' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank14' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank14' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank14' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank14' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank14' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank14' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank14' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank14' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank14' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank14' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank14' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank14' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank14' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank14' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank14' },
  { value: 'Fach27', label: 'Urologie', link: 'krank14' },
  ///////////////////////////////////////////////////////////////////
  //{ value: 'Fach1', label: 'Select', link: 'krank15' },
  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank15' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank15' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank15' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank15' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank15' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank15' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank15' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank15' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank15' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank15' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank15' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank15' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank15' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank15' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank15' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank15' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank15' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank15' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank15' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank15' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank15' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank15' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank15' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank15' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank15' },
  { value: 'Fach27', label: 'Urologie', link: 'krank15' },
]
const option = [{
  value: "1",
  label: "Alle",
  fontSize: '166px'
},
{
  value: "2",
  label: "Online",
  fontSize: '166px'
},
{
  value: "3",
  label: "Offline",
  fontSize: '166px'
},
{
  value: "4",
  label: "Deactive",
  fontSize: '166px'
}
]
class JobsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalTabs: "HelloMatch",
      statusSelect: null,
      jobTypeSingleSelect: null,
      zmSelect: null,
      tvSelect: null,
      institutionTypeSelect: "",
      qualificationSingleSelect: null,
      specializationMultipleSelect: null,
      workingTimeSelect: null,
      workingTernureSelect: null,
      approbationSelect: "",
      occupationPermitSelect: "",
      qualificationMultiSelect: null,
      specializationMultiSelect: null,
      countrySingleSelect: "",
      citySingleSelect: null,
      statusSelect1: null,
      decisionSingleSelect: null,
      valueCountryD: "1",
      valueCountryS: "2",
      valueCountryL: "3",
      openedCollapses: ["collapseOne", "collapse1"],
      buttonToRender: 4,
      visible: false,
      visible1: false,
      optSelect: {},
      opt2Select: {},
      opt3Select: null,
      jobsData: [],
      jobcount: [],
      jobcount1: [],
      statuss: [],
      area: '',
      offset: 0,
      perPage: 10,
      currentPage: 0,
      offset1: 0,
      perPage1: 20,
      currentPage1: 0,
      offset2: 0,
      perPage2: 20,
      currentPage2: 0,
      state1: false,
      state2: false,
      state3: false,
      state4: false,
      state5: false,
      job_value: null,

    };
  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  statusHandler = e => {
    console.log("job_status", this.state.job_value)
    var errvalue
    //var reserr
    if (this.state.job_value === null) {
      errvalue = "Please select"
      this.setState({ errvalue })
    } else {
      this.setState({ errvalue: undefined })
    }
    if (errvalue === undefined) {
      axios.post("/api2/jobsearchbyvalue", this.state)
        .then(response => {
          console.log(response)
          this.setState({ reserr: undefined })
          //  const filter = response.data.result;
          //  this.setState({filter});
          //  this.setState({state1:false})
          //  localStorage.setItem('filter', JSON.stringify(this.state.filter));
          //  console.log(filter); 
          //  this.makePostData1(this.state.filter);
        })
        .catch(error => {
          console.log(error);
          const reserr = error.response.data.Message;
          this.setState({ reserr })
          this.setState({ state2: false })
        })
    }
  }
  handleMoreClick = () => {
    this.setState({
      buttonToRender: (this.state.buttonToRender + 2)
    });
  };
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };

  changeHandler2 = optSelect => {
    this.setState(pv => {
      return { ...pv, optSelect: optSelect };
    }, () => {
      if (this.state.optSelect.label === "Arzt" ||
        this.state.optSelect.label === "Altenpflege" ||
        this.state.optSelect.label === "Krankenpflege" ||
        this.state.optSelect.label === "Therapeut" ||
        this.state.optSelect.label === "Medizintechnik" ||
        this.state.optSelect.label === "Rettungsdienst" ||
        this.state.optSelect.label === "Praxispersonal" ||
        this.state.optSelect.label === "Pharmazie") {
        let nextVal = opt2.filter((o) => o.link === this.state.optSelect.value)[0];
        this.changeHandler1(nextVal);
        this.setState(pv => ({ ...pv, visible: true }));
      } else {
        this.setState({ visible: false });
      }
    });

  }

  changeHandler1 = optSelect => {
    this.setState(() => ({ opt2Select: optSelect }), () => {
      if (this.state.opt2Select.label === "Assistenzarzt" ||
        this.state.opt2Select.label === "Facharzt" ||
        this.state.opt2Select.label === "Oberarzt" ||
        this.state.opt2Select.label === "Ltd, Oberarzt" ||
        this.state.opt2Select.label === "Chefarzt" ||
        this.state.opt2Select.label === "Anästhesieschwester" ||
        this.state.opt2Select.label === "Anästhesietechnische Assistentin (ATA)" ||
        this.state.opt2Select.label === "Fachkrankenschwester" ||
        this.state.opt2Select.label === "Gesundheitspflegehelfer/Krankenpflegehelfer" ||
        this.state.opt2Select.label === "Gesundheitspfleger/Krankenpfleger" ||
        this.state.opt2Select.label === "Hebamme" ||
        this.state.opt2Select.label === "Heilerziehungspfleger" ||
        this.state.opt2Select.label === "Kinderkrankenschwester" ||
        this.state.opt2Select.label === "OP Schwester" ||
        this.state.opt2Select.label === "Operationstechnische Assistentin (OTA)" ||
        this.state.opt2Select.label === "Pflegeassistent" ||
        this.state.opt2Select.label === "Pflegedienstleitung" ||
        this.state.opt2Select.label === "Stationsleitung" ||
        this.state.opt2Select.label === "Study Nurse") {
        let nextVal1 = opt3.filter((p) => p.link === this.state.opt2Select.value)[0];
        this.changeHandler3(nextVal1);
        this.setState({ visible1: true })
        console.log("if works", this.state);
      } else {
        this.setState({ visible1: false });
        console.log("else works", this.state);
      }

    })
  }

  changeHandler3 = opt2Select => {
    this.setState(pv => {
      return { ...pv, opt3Select: opt2Select };
    });
  }
  componentDidMount() {
    const roles = localStorage.getItem('roles')
    this.setState({ roles })
    console.log(roles);
    let badgecolor = "success";
    this.setState({ badgecolor })
    axios
      .post('/api2/alljobs', this.state)
      .then(response => {
        //console.log(response) 
        const jobcount = response.data.jobcount;
        this.setState({ jobcount })
        //console.log(jobcount) 
        const jobsData = response.data.responsePayload.results;
        this.setState({ jobsData })
        const currentPage = response.data.responsePayload.pagination.current;
        this.setState({ currentPage })
        const pageCount = response.data.responsePayload.pagination.TotalPages;
        this.setState({ pageCount })
        //console.log(jobsData)
        //const jobcount = response.data.jobcount;
        // this.setState({ jobcount })
        this.setState({ state1: true, state2: false, state3: false, state4: false, state5: false, })
        // console.log(jobcount)

        //const slice = jobsData.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = jobsData.map(item => <React.Fragment>

          <Card >
            <CardBody>
              <Row>
                <Col md='2'>
                  <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height='80' width='auto' />
                </Col>

                <Col md='6'>
                  <h7 style={{ color: '#4287f5', fontSize: '10px' }}>{item.company_name}</h7>

                  <br></br>
                  <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`}>
                    {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}
                  </Link>

                  <br></br>

                  <i class="fas fa-map-marker-alt">

                  </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                    {item.zip_code}  {item.city}, {item.state}</h7>
                  <br></br>  <br></br>
                  {this.state.jobcount.map(item1 => {
                    return (
                      <>
                        {item.job_id === item1.job_id ?
                          <span>
                            <Badge className="mr-1" color="success" pill>
                              Bewerbungen:  {item1.cnt}
                            </Badge>
                          </span>
                          :
                          null
                        }
                      </>
                    )
                  })}
                </Col>
                {this.state.roles === 'admin' ?
                  <> <Col md='4' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
                    <br></br>
                    <Button
                      style={{ marginRight: '-3px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip366246639" + item.job_id}
                      size="sm"
                      type="button"
                      onClick={this.acceptHandle.bind(this, item.job_id)}

                    >
                      <i class="fa fa-unlock" aria-hidden="true"></i>
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246639" + item.job_id}

                    >
                      Aktivieren
                    </UncontrolledTooltip>
                    <Button
                      style={{ marginRight: '-4px' }}
                      className="btn-icon"
                      color="dark"
                      id={"tooltip36624664222" + item.job_id}
                      size="sm"
                      type="button"
                      onClick={this.offlineHandle.bind(this, item.job_id)}
                    >
                      <i className="fa fa-lock" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip36624664222" + item.job_id}
                    >
                      Offline
                    </UncontrolledTooltip>



                    <Button
                      className="btn-icon"
                      color="danger"
                      id={"tooltip366246640" + item.job_id}
                      size="sm"
                      type="button"
                      onClick={this.rejectHandle.bind(this, item.job_id)}
                    >
                      <i className="fa fa-toggle-off" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246640" + item.job_id}
                    >
                      Deaktivieren
                    </UncontrolledTooltip>
                    <br></br>
                    <Link to={`/admin/EditJob/${item.job_id}`}>
                      <Button
                        style={{ marginTop: '-5px' }}
                        className="btn-icon"
                        color="success"
                        id={"tooltip36624664212" + item.job_id}
                        //id="tooltip36624664212"
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-edit" />
                      </Button>{" "}
                      <UncontrolledTooltip
                        delay={0}
                        target={"tooltip36624664212" + item.job_id}
                      >
                        Bearbeiten
                      </UncontrolledTooltip>
                    </Link>
                    <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="info"
                      id={"tooltip36624664211" + item.job_id}
                      size="sm"
                      type="button"
                      onClick={this.renewHandle.bind(this, item.job_id)}
                    >
                      <i class="fa fa-battery-quarter" aria-hidden="true"></i>
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip36624664211" + item.job_id}
                    >
                      Verlängern
                    </UncontrolledTooltip>

                    <Link to={`/admin/sent_job/${item.job_id}`}>
                      <Button style={{ marginTop: '-5px' }}
                        className="btn-icon"
                        color="primary"
                        id={"tooltip36624664213" + item.job_id}
                        size="sm"
                        type="button"
                      >
                        <i className="fa fa-envelope-open" />
                      </Button>{" "}
                      <UncontrolledTooltip
                        delay={0}
                        target={"tooltip36624664213" + item.job_id}
                      >
                        Senden
                      </UncontrolledTooltip>
                    </Link>
                  </Col>
                  </> : null}

              </Row>
              <br></br>
              <Row style={{ backgroundColor: '#f1f1f1', marginTop: '-10px' }}>

                <Col md="3">
                  <i class="far fa-clock"></i>
                  &nbsp;&nbsp;
                  <h7 style={{ fontSize: '11px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                </Col>
                <Col md="3">
                  <i class="fas fa-hourglass-half"></i>
                  &nbsp;&nbsp;
                  <h7 style={{ fontSize: '11px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} </h7>
                </Col>
                <Col md="3">
                  <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                  <h7 style={{ fontSize: '11px' }}> Online seit: {item.created_date}</h7>
                </Col>
                <Col md="3">
                  <i class="far fa-calendar-alt"></i>

                  &nbsp;&nbsp;
                  {item.job_status === 'online' ? <h7 style={{ fontSize: '11px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                    :
                    item.job_status === 'offline' ? <h7 style={{ fontSize: '11px', color: '#ff0800' }}>Offline seit: {item.valid_till} </h7>
                      :
                      item.job_status === 'deactive' ? <h7 style={{ fontSize: '11px', color: '#ff0800' }}>Deactive seit: {item.valid_till} </h7>
                        :
                        null
                  }


                </Col>
              </Row>
              <div>
              </div>
            </CardBody>
          </Card>


        </React.Fragment>)
        localStorage.setItem('jobsData', JSON.stringify(this.state.jobsData));
        // console.log(jobsData);
        // console.log(this.state.perPage);
        this.setState({
          //pageCount: Math.ceil(jobsData.length / this.state.perPage),
          postData
        })
        // console.log(pageCount);     
      })
      .catch(error => {
        console.log(error);
      })
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
    console.log()
  }
  changeHandler10 = (job_value) => {
    this.setState(pv => {
      return { ...pv, job_value: job_value };
    }
    );
    console.log(job_value)
    axios.post("/api2/jobsearchbyvalue", { ...this.state, job_value })
      .then(response => {
        console.log(response)
        const statuss = response.data.onlinejobs;
        this.setState({ statuss });
        const jobcount1 = response.data.jobcount;
        this.setState({ jobcount1 });
        this.setState({ state1: false, state2: false, state3: true, state4: false, state5: false })
        localStorage.setItem('statuss', JSON.stringify(this.state.statuss));
        console.log(statuss);
        console.log(jobcount1);
        this.makePostData2(this.state.statuss);
      })
      .catch(error => {
        console.log(error);
        const reserr = error.response.data.Message;
        this.setState({ reserr })
        this.setState({ state4: true, state1: false, state2: false, state3: false, state5: false })
      })
  }
  handlePageClick = (e) => {
    window.scrollTo(0, 100);
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.componentDidMount()
    });
    // window.location.reload();
  };
  acceptHandle(jobid, e) {
    //e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(this.state)
    console.log("job value", this.state.job_value)
    if (this.state.job_value === null) {
      console.log("simple works")
      axios
        .post('/api2/acceptjob', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const msg = "Sie akzeptieren die Anfrage";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload();
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      console.log("with filter works")
      axios
        .post('/api2/acceptjob1', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const statuss = response.data.onlinejobs;
          this.setState({ statuss });
          const jobcount1 = response.data.jobcount;
          this.setState({ jobcount1 });
          this.setState({ state1: false, state2: false, state3: true, state4: false, state5: false })
          localStorage.setItem('statuss', JSON.stringify(this.state.statuss));
          console.log(statuss);
          console.log(jobcount1);
          this.makePostData2(this.state.statuss);
        })
        .catch(error => {
          console.log(error);
          const reserr = error.response.data.Message;
          this.setState({ reserr })
          this.setState({ state4: true, state1: false, state2: false, state3: false, state5: false })
        })
    }

  }
  offlineHandle(jobid, e) {
    //e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(jobid)
    if (this.state.job_value === null) {
      console.log("simple works")
      axios
        .post('/api2/offlinejob', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const msg = "Sie akzeptieren die Anfrage";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload();
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      console.log("with filter works")
      axios
        .post('/api2/offlinejob1', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const statuss = response.data.onlinejobs;
          this.setState({ statuss });
          const jobcount1 = response.data.jobcount;
          this.setState({ jobcount1 });
          this.setState({ state1: false, state2: false, state3: true, state4: false, state5: false })
          localStorage.setItem('statuss', JSON.stringify(this.state.statuss));
          console.log(statuss);
          console.log(jobcount1);
          this.makePostData2(this.state.statuss);
        })
        .catch(error => {
          console.log(error);
          const reserr = error.response.data.Message;
          this.setState({ reserr })
          this.setState({ state4: true, state1: false, state2: false, state3: false, state5: false })
        })
    }
  }
  renewHandle(jobid, e) {
    //e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(jobid)
    if (this.state.job_value === null) {
      console.log("simple works")
      axios
        .post('/api2/renewjob', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const msg = "Sie akzeptieren die Anfrage";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload();
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      console.log("with filter works")
      axios
        .post('/api2/renewjob1', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const statuss = response.data.onlinejobs;
          this.setState({ statuss });
          const jobcount1 = response.data.jobcount;
          this.setState({ jobcount1 });
          this.setState({ state1: false, state2: false, state3: true, state4: false, state5: false })
          localStorage.setItem('statuss', JSON.stringify(this.state.statuss));
          console.log(statuss);
          console.log(jobcount1);
          this.makePostData2(this.state.statuss);
        })
        .catch(error => {
          console.log(error);
          const reserr = error.response.data.Message;
          this.setState({ reserr })
          this.setState({ state4: true, state1: false, state2: false, state3: false, state5: false })
        })
    }
  }
  rejectHandle(jobid, e) {
    //e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(jobid)
    if (this.state.job_value === null) {
      console.log("simple works")
      axios
        .post('/api2/rejectjob', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const msg = "Sie akzeptieren die Anfrage";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload();
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      console.log("with filter works")
      axios
        .post('/api2/rejectjob1', { ...this.state, jobid })
        .then(response => {
          console.log(response)
          const statuss = response.data.onlinejobs;
          this.setState({ statuss });
          const jobcount1 = response.data.jobcount;
          this.setState({ jobcount1 });
          this.setState({ state1: false, state2: false, state3: true, state4: false, state5: false })
          localStorage.setItem('statuss', JSON.stringify(this.state.statuss));
          console.log(statuss);
          console.log(jobcount1);
          this.makePostData2(this.state.statuss);
        })
        .catch(error => {
          console.log(error);
          const reserr = error.response.data.Message;
          this.setState({ reserr })
          this.setState({ state4: true, state1: false, state2: false, state3: false, state5: false })
        })
    }
  }
  submitHandler = e => {
    e.preventDefault();
    console.log(this.state)
    var errmsg1;
    var errmsg2;
    var errmsg3;
    var opterr;
    var city
    var mainerr;
    console.log(this.state)
    if (this.state.area !== "") {
      var somestring = this.state.area;
      var index = somestring.indexOf(" ");
      var postcode = somestring.substr(0, index);
      console.log("first part", postcode)
      //this.setState({postcode});
      city = somestring.substr(index + 1);
      console.log("Second part city", city);
      this.setState({ city });
    } else {
      city = "";
    }
    if (this.state.area === "" && this.state.statusSelect1 !== null) {
      console.log("not allowed")
      errmsg1 = "Bitte wählen Sie Ort";
      this.setState({ errmsg1 })
    } else {
      errmsg1 = ""
      this.setState({ errmsg1 })
    }
    if (this.state.opt3Select !== null) {
      if (this.state.opt3Select.length > 3) {
        errmsg2 = "Maximal Drei Fachgebiet";
        this.setState({ errmsg2 })
      } else {
        errmsg2 = ""
        this.setState({ errmsg2 })
      }
    } else {
      errmsg2 = ""
      this.setState({ errmsg2 })
    }
    if (this.state.statusSelect !== null) {
      if (this.state.statusSelect.length > 3) {
        errmsg3 = "Maximal Drei Einrichtungen";
        this.setState({ errmsg3 })
      } else {
        errmsg3 = ""
        this.setState({ errmsg3 })
      }
    } else {
      errmsg3 = ""
      this.setState({ errmsg3 })
    }
    // if(this.state.optSelect.label === undefined){
    //   opt1 = ""
    //   console.log("abc")
    // }
    if (this.state.area === "" && this.state.optSelect.label === undefined && this.state.statusSelect === null && this.state.zmSelect === null && this.state.tvSelect === null) {
      console.log("please check")
      mainerr = "Bitte überarbeiten Sie Ihre Suche"
      this.setState({ mainerr })
    } else {
      mainerr = ""
      this.setState({ mainerr })
    }
    if (this.state.optSelect.label !== undefined) {
      if ((this.state.optSelect.label === "Arzt" || this.state.optSelect.label === "Krankenpflege") && this.state.opt2Select.label !== "Bitte auswählen" && this.state.opt3Select === null) {
        opterr = "Bitte wählen Sie Fachgebiet"
        this.setState({ opterr })
      } else {
        opterr = "";
        this.setState({ opterr })
      }
    } else {
      opterr = "";
      this.setState({ opterr })
    }
    if (errmsg1 === "" && errmsg2 === "" && errmsg3 === "" && mainerr === "" && opterr === "") {
      console.log(errmsg1, errmsg2)
      console.log("everything is ok")
      //Axios.post("/api/frontfilterjobs", {...this.state,city})
      axios.post("/api2/jobsearch", { ...this.state, city })
        .then(response => {
          console.log(response)
          this.setState({ errmsg: undefined })
          const filter = response.data.result;
          this.setState({ filter });
          this.setState({ state1: false, state2: true, state3: false, state4: false, state5: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
        })
        .catch(error => {
          const errmsg = error.response.data.msg;
          console.log(errmsg)
          this.setState({ errmsg })
          this.setState({ state1: false, state2: false, state3: false, state4: false, state5: true })
          // this.setState({state2:false})
        })
    }
  }

  makePostData1(filter) {

    const slice1 = filter.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
    const postData1 = slice1.map(item => <React.Fragment>
      <Card >
        <CardBody>
          <Row>
            <Col md='2'>
              <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height='80' width='auto' />
            </Col>

            <Col md='6'>
              <h7 style={{ color: '#4287f5', fontSize: '10px' }}>{item.company_name}</h7>

              <br></br>
              <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`}>
                {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}
              </Link>

              <br></br>

              <i class="fas fa-map-marker-alt">

              </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                {item.zip_code}  {item.city}, {item.state}</h7>
              <br></br>  <br></br>
              {this.state.jobcount.map(item1 => {
                return (
                  <>
                    {item.job_id === item1.job_id ?
                      <span>
                        <Badge className="mr-1" color="success" pill>
                          Bewerbungen:  {item1.cnt}
                        </Badge>
                      </span>
                      :
                      null
                    }
                  </>
                )
              })}

            </Col>
            {this.state.roles === 'admin' ?
              <> <Col md='4' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
                <br></br>
                <Button
                  style={{ marginRight: '-3px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip366246639" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.acceptHandle.bind(this, item.job_id)}

                >
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246639" + item.job_id}

                >
                  Aktivieren
                </UncontrolledTooltip>
                <Button
                  style={{ marginRight: '-4px' }}
                  className="btn-icon"
                  color="dark"
                  id={"tooltip36624664222" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.offlineHandle.bind(this, item.job_id)}
                >
                  <i className="fa fa-lock" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip36624664222" + item.job_id}
                >
                  Offline
                </UncontrolledTooltip>



                <Button
                  className="btn-icon"
                  color="danger"
                  id={"tooltip366246640" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.rejectHandle.bind(this, item.job_id)}
                >
                  <i className="fa fa-toggle-off" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246640" + item.job_id}
                >
                  Deaktivieren
                </UncontrolledTooltip>
                <br></br>
                <Link to={`/admin/EditJob/${item.job_id}`}>
                  <Button
                    style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="success"
                    id={"tooltip36624664212" + item.job_id}
                    //id="tooltip36624664212"
                    size="sm"
                    type="button"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip36624664212" + item.job_id}
                  >
                    Bearbeiten
                  </UncontrolledTooltip>
                </Link>
                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="info"
                  id={"tooltip36624664211" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.renewHandle.bind(this, item.job_id)}
                >
                  <i class="fa fa-battery-quarter" aria-hidden="true"></i>
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip36624664211" + item.job_id}
                >
                  Verlängern
                </UncontrolledTooltip>

                <Link to={`/admin/sent_job/${item.job_id}`}>
                  <Button style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="primary"
                    id={"tooltip36624664213" + item.job_id}
                    size="sm"
                    type="button"
                  >
                    <i className="fa fa-envelope-open" />
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip36624664213" + item.job_id}
                  >
                    Senden
                  </UncontrolledTooltip>
                </Link>
              </Col>
              </> : null}

          </Row>
          <br></br>
          <Row style={{ backgroundColor: '#f1f1f1', marginTop: '-10px' }}>

            <Col md="3">
              <i class="far fa-clock"></i>
              &nbsp;&nbsp;
              <h7 style={{ fontSize: '11px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
            </Col>
            <Col md="3">
              <i class="fas fa-hourglass-half"></i>
              &nbsp;&nbsp;
              <h7 style={{ fontSize: '11px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} </h7>
            </Col>
            <Col md="3">
              <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
              <h7 style={{ fontSize: '11px' }}> Online seit: {item.created_date}</h7>
            </Col>
            <Col md="3">
              <i class="far fa-calendar-alt"></i>

              &nbsp;&nbsp;
              {item.job_status === 'online' ? <h7 style={{ fontSize: '11px', color: '#28a745' }}>Online bis: {item.valid_tll} </h7>
                :
                item.job_status === 'offline' ? <h7 style={{ fontSize: '11px', color: '#ff0800' }}>Offline seit: {item.valid_tll} </h7>
                  :
                  item.job_status === 'deactive' ? <h7 style={{ fontSize: '11px', color: '#ff0800' }}>Deactive seit: {item.valid_tll} </h7>
                    :
                    null
              }


            </Col>
          </Row>
          <div>
          </div>
        </CardBody>
      </Card>

    </React.Fragment>)
    this.setState({
      pageCount1: Math.ceil(filter.length / this.state.perPage1),
      postData1
    });
  }
  handlePageClick1 = (e) => {
    window.scrollTo(0, 100);
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;

    this.setState({
      currentPage1: selectedPage1,
      offset1: offset1
    }, () => {
      this.makePostData1(this.state.filter);
    });

  };


  makePostData2(statuss) {

    const slice2 = statuss.slice(this.state.offset2, this.state.offset2 + this.state.perPage2)
    const postData2 = slice2.map(item => <React.Fragment>
      <Card >
        <CardBody>
          <Row>
            <Col md='2'>
              <img src={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height='80' width='auto' />
            </Col>

            <Col md='6'>
              <h7 style={{ color: '#4287f5', fontSize: '10px' }}>{item.company_name}</h7>

              <br></br>
              <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`}>
                {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}
              </Link>

              <br></br>

              <i class="fas fa-map-marker-alt">

              </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                {item.zip_code}  {item.city}, {item.state}</h7>
              <br></br>  <br></br>
              {this.state.jobcount1.map(item1 => {
                return (
                  <>
                    {item.job_id === item1.job_id ?
                      <span>
                        <Badge className="mr-1" color="success" pill>
                          Bewerbungen:  {item1.cnt}
                        </Badge>
                      </span>
                      :
                      null
                    }
                  </>
                )
              })}

            </Col>
            {this.state.roles === 'admin' ?
              <><Col md='4' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
                <br></br>
                <Button
                  style={{ marginRight: '-3px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip366246639" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.acceptHandle.bind(this, item.job_id)}

                >
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246639" + item.job_id}

                >
                  Aktivieren
                </UncontrolledTooltip>
                <Button
                  style={{ marginRight: '-4px' }}
                  className="btn-icon"
                  color="dark"
                  id={"tooltip36624664222" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.offlineHandle.bind(this, item.job_id)}
                >
                  <i className="fa fa-lock" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip36624664222" + item.job_id}
                >
                  Offline
                </UncontrolledTooltip>



                <Button
                  className="btn-icon"
                  color="danger"
                  id={"tooltip366246640" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.rejectHandle.bind(this, item.job_id)}
                >
                  <i className="fa fa-toggle-off" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246640" + item.job_id}
                >
                  Deaktivieren
                </UncontrolledTooltip>
                <br></br>
                <Link to={`/admin/EditJob/${item.job_id}`}>
                  <Button
                    style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="success"
                    id={"tooltip36624664212" + item.job_id}
                    //id="tooltip36624664212"
                    size="sm"
                    type="button"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip36624664212" + item.job_id}
                  >
                    Bearbeiten
                  </UncontrolledTooltip>
                </Link>
                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="info"
                  id={"tooltip36624664211" + item.job_id}
                  size="sm"
                  type="button"
                  onClick={this.renewHandle.bind(this, item.job_id)}
                >
                  <i class="fa fa-battery-quarter" aria-hidden="true"></i>
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip36624664211" + item.job_id}
                >
                  Verlängern
                </UncontrolledTooltip>

                <Link to={`/admin/sent_job/${item.job_id}`}>
                  <Button style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="primary"
                    id={"tooltip36624664213" + item.job_id}
                    size="sm"
                    type="button"
                  >
                    <i className="fa fa-envelope-open" />
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip36624664213" + item.job_id}
                  >
                    Senden
                  </UncontrolledTooltip>
                </Link>
              </Col>
              </> : null}

          </Row>
          <br></br>
          <Row style={{ backgroundColor: '#f1f1f1', marginTop: '-10px' }}>

            <Col md="3">
              <i class="far fa-clock"></i>
              &nbsp;&nbsp;
              <h7 style={{ fontSize: '11px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
            </Col>
            <Col md="3">
              <i class="fas fa-hourglass-half"></i>
              &nbsp;&nbsp;
              <h7 style={{ fontSize: '11px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} </h7>
            </Col>
            <Col md="3">
              <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
              <h7 style={{ fontSize: '11px' }}> Online seit: {item.created_date}</h7>
            </Col>
            <Col md="3">
              <i class="far fa-calendar-alt"></i>

              &nbsp;&nbsp;
              {item.job_status === 'online' ? <h7 style={{ fontSize: '11px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                :
                item.job_status === 'offline' ? <h7 style={{ fontSize: '11px', color: '#ff0800' }}>Offline seit: {item.valid_till} </h7>
                  :
                  item.job_status === 'deactive' ? <h7 style={{ fontSize: '11px', color: '#ff0800' }}>Deactive seit: {item.valid_till} </h7>
                    :
                    null
              }


            </Col>
          </Row>
          <div>
          </div>
        </CardBody>
      </Card>

    </React.Fragment>)
    this.setState({
      pageCount2: Math.ceil(statuss.length / this.state.perPage2),
      postData2
    });
  }
  handlePageClick2 = (e) => {
    window.scrollTo(0, 100);
    const selectedPage2 = e.selected;
    const offset2 = selectedPage2 * this.state.perPage2;

    this.setState({
      currentPage2: selectedPage2,
      offset2: offset2
    }, () => {
      this.makePostData2(this.state.statuss);
    });

  };
  render() {
    const filteredOpt = opt2.filter((o) => o.link === this.state.optSelect.value)
    const filteredOpt1 = opt3.filter((p) => p.link === this.state.opt2Select.value)
    const Unternehmenstätigkeit = [
      {
        id: "0",
        label: "Altenheim / Pflegeheim",
        value: "A"
      },
      {
        id: "1",
        label: "Arztpraxis",
        value: "B"
      },
      {
        id: "2",
        label: "Krankenhaus",
        value: "C"
      },
      {
        id: "3",
        label: "MVZ",
        value: "D"
      },
      {
        id: "4",
        label: "Pflegedienst",
        value: "E"
      },
      {
        id: "5",
        label: "Reha-Klinik",
        value: "T"
      }

    ]
    return (
      <>


        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <h4> <b>
                        Jobs
                      </b></h4>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>



                  <Row>
                    <Col md="12">
                      <Row className="justify-content-center ml-auto mr-auto">
                        <Col md="12">
                          <Card>
                            <CardBody>
                              <div
                                aria-multiselectable={true}
                                className="card-collapse"
                                id="accordion"
                                role="tablist"
                              >
                                <CardTitle tag="h4" className="">Filter</CardTitle>
                                <Card className="card-plain">
                                  <Badge color="danger">{this.state.errmsg}</Badge>
                                  <CardHeader role="tab">

                                    Jobs suchen nach

                                  </CardHeader>

                                  <CardBody>
                                    <Badge color="danger">{this.state.mainerr}</Badge><br></br>
                                    <Badge color="danger">{this.state.opterr}</Badge>
                                    <Form action="#" method="#">

                                      <Row className="section-bottom-20 section-bottom-xs-10">
                                        <Col md="6">
                                          <label>Stadt</label><Badge color="danger">{this.state.errmsg1}</Badge>

                                          {/*                       
                      <Input placeholder="" type="text" />
                       */}
                                          <GooglePlacesAutocomplete
                                            onChange={this.changeHandler}
                                            onSelect={({ description }) => (this.setState({ area: description }))}
                                            autocompletionRequest={{
                                              componentRestrictions: {
                                                country: 'de'
                                              },
                                              types: ["(regions)"],
                                              terms: [
                                                'cities'
                                              ]
                                            }}
                                            placeholder='Wo? z.B. Berlin'
                                            inputClassName='form-control'

                                          />


                                        </Col>
                                        <Col md="6">
                                          <label>Radius</label>
                                          <Select
                                            className="react-select info"
                                            classNamePrefix="react-select"
                                            placeholder="Bitte auswählen"
                                            name="statusSelect1"
                                            value={this.state.statusSelect1}
                                            onChange={value =>
                                              this.setState({ statusSelect1: value })
                                            }
                                            options={radius}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="" md="4">
                                          <FormGroup>
                                            <label >Berufsgruppe</label>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              name="titleSelect"
                                              value={this.state.optSelect}
                                              onChange={this.changeHandler2}
                                              options={opt}
                                              placeholder="Select..."
                                            />
                                          </FormGroup>
                                        </Col>
                                        {this.state.visible ?
                                          <Col className="" md="4">
                                            <FormGroup>
                                              <label>Funktion, Position</label>
                                              <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="titleSelect"
                                                value={this.state.opt2Select}
                                                onChange={this.changeHandler1}
                                                options={filteredOpt}
                                              />
                                            </FormGroup>
                                          </Col>
                                          : null}
                                        {this.state.visible1 ?
                                          <Col className="" md="4">
                                            <FormGroup>
                                              <label>Fachgebiet, Fachabteilungen (Max. 3 Fachgebiete, Fachabteilungen)</label><Badge color="danger">{this.state.errmsg2}</Badge><Badge color="danger">{this.state.opterr}</Badge>
                                              <Select
                                                isMulti
                                                closeMenuOnSelect={false}
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="titleSelect"
                                                value={this.state.opt3Select}
                                                onChange={this.changeHandler3}
                                                options={filteredOpt1}
                                              />
                                            </FormGroup>
                                          </Col>
                                          : null}

                                      </Row>
                                      <br />
                                      <Row className="section-bottom-20 section-bottom-xs-10">
                                        <Col md="4">
                                          <label>Einrichtung</label><Badge color="danger">{this.state.errmsg3}</Badge>
                                          <Select
                                            isMulti
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="statusSelect"
                                            value={this.state.statusSelect}
                                            onChange={value =>
                                              this.setState({ statusSelect: value })
                                            }
                                            options={Unternehmenstätigkeit}
                                            placeholder="Bitte auswählen"
                                          />
                                        </Col>

                                      </Row>

                                      <Row>
                                        <Col md="6">
                                          <label>Anstellungsart</label>
                                          <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="zmSelect"
                                            value={this.state.zmSelect}
                                            onChange={value =>
                                              this.setState({ zmSelect: value })
                                            }
                                            options={[{
                                              value: "1",
                                              label: "Festanstellung"
                                            },
                                            {
                                              value: "2",
                                              label: "Befristet"
                                            }]}
                                            placeholder="Bitte auswählen"
                                          />
                                        </Col>
                                        <Col md="6">
                                          <label>Zeitmodell</label>
                                          <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="tvSelect"
                                            value={this.state.tvSelect}
                                            onChange={value =>
                                              this.setState({ tvSelect: value })
                                            }
                                            options={[{
                                              value: "1",
                                              label: "Vollzeit"
                                            },
                                            {
                                              value: "2",
                                              label: "Teilzeit"
                                            }]}
                                            placeholder="Bitte auswählen"
                                          />
                                        </Col>
                                      </Row>
                                    </Form>
                                  </CardBody>

                                </Card>



                              </div>
                            </CardBody>
                            <CardFooter>
                              <Row>
                                <Col className="text-center">
                                  <Button
                                    className="btn-round center"
                                    outline
                                    size="md"
                                    color="primary"
                                    onClick={this.submitHandler}
                                  >
                                    Suchen
                                  </Button>
                                </Col>
                              </Row>

                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                      <hr />

                    </Col>
                  </Row>
                  {/* <Message /> */}
                </CardBody>
              </Card>
              <hr />
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <Card className="card-plain card-subcategories">
                    <CardBody>

                      <h3><b>Das sind Ihre Stellenanzeigen</b></h3>


                      <FormGroup>
                        <Badge color="danger">{this.state.errvalue}</Badge>
                        <Select
                          className="react-select primary"
                          name="job_value"
                          onChange={this.changeHandler10.bind(this)}
                          value={this.state.job_value}
                          options={option}
                          placeholder="Bitte wählen Sie zunächst den Anzeigenstatus aus"
                          type="text"
                        />
                      </FormGroup>
                    </CardBody>

                  </Card>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  {this.state.state1 ?
                    <>{this.state.postData} </>
                    :
                    <>{this.state.state2 ?
                      <>{this.state.postData1}</>
                      :
                      <>{this.state.state3 ?
                        <>{this.state.postData2}</> : null}</>}</>
                  }

                  {this.state.state4 ?

                    <Badge color="danger">{this.state.reserr}</Badge>
                    : null}
                  {this.state.state5 ?

                    <Badge color="danger">{this.state.errmsg}</Badge>
                    : null}

                  {this.state.state1 ?
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                    : null}


                  {this.state.state2 ?
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount1}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick1}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                    : null}
                  {this.state.state3 ?
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount2}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick2}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                    : null}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default JobsList;
