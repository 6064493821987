import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
import Select from "react-select";
import ReactPaginate from 'react-paginate';
import "../../style1.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Collapse,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  //Table,
  Button,
  UncontrolledTooltip,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
const admin = [
  {
    id: "1",
    salutation: "Frau",
    title: "Prof",
    lastName: "Ketzer",
    firstName: "Dorothe",
    email: "d.ketzer@nn.de",
    telefon: "09887878",
    position: "CEO",
    status: "aktiv",
    pos: "Select.."
  }]
const opt = [
  {
    id: '2',
    label: 'Altenpflege',
    value: 'alt'
  },
  {
    id: '3',
    label: 'Krankenpflege',
    value: 'krank'
  },
  {
    id: "4",
    label: "Therapeut",
    value: "tera"
  },
  {
    id: '5',
    label: 'Medizintechnik',
    value: 'mediz'
  },
  {
    id: '6',
    label: 'Rettungsdienst',
    value: 'ret'
  },
  {
    id: '7',
    label: 'Praxispersonal',
    value: 'prax'
  },
  {
    id: '8',
    label: 'Pharmazie',
    value: 'phar'
  }
]
const opt2 = [

  { value: 'doctor1', label: 'Assistenzarzt', link: 'A' },
  { value: 'doctor2', label: 'Facharzt', link: 'A' },
  { value: 'doctor3', label: 'Oberarzt', link: 'A' },
  { value: 'doctor4', label: 'Ltd, Oberarzt', link: 'A' },
  { value: 'doctor5', label: 'Chefarzt', link: 'A' },
  { value: 'krank1', label: 'Anästhesieschwester', link: 'krank' },
  { value: 'krank3', label: 'Anästhesietechnische Assistentin (ATA)', link: 'krank' },
  { value: 'krank4', label: 'Fachkrankenschwester', link: 'krank' },
  { value: 'krank5', label: 'Gesundheitspflegehelfer/Krankenpflegehelfer', link: 'krank' },
  { value: 'krank6', label: 'Gesundheitspfleger/Krankenpfleger', link: 'krank' },
  { value: 'krank7', label: 'Hebamme', link: 'krank' },
  { value: 'krank8', label: 'Heilerziehungspfleger', link: 'krank' },
  { value: 'krank9', label: 'Kinderkrankenschwester', link: 'krank' },
  { value: 'krank10', label: 'OP Schwester', link: 'krank' },
  { value: 'krank11', label: 'Operationstechnische Assistentin (OTA)', link: 'krank' },
  { value: 'krank12', label: 'Pflegeassistent', link: 'krank' },
  { value: 'krank13', label: 'Pflegedienstleitung', link: 'krank' },
  { value: 'krank14', label: 'Stationsleitung', link: 'krank' },
  { value: 'krank15', label: 'Study Nurse', link: 'krank' },

  { value: 'ret2', label: 'Rettungsassistentin', link: 'ret' },
  { value: 'ret3', label: 'Rettungshelfer', link: 'ret' },
  { value: 'ret4', label: 'Rettungssanitäter', link: 'ret' },


  { value: 'mediz2', label: 'Medizininformatiker', link: 'mediz' },
  { value: 'mediz3', label: 'Medizinisch-technische Laboratoriumsassistentin (MTLA)', link: 'mediz' },
  { value: 'mediz4', label: 'Medizintechnik-Ingenieur', link: 'mediz' },
  { value: 'mediz5', label: 'Medizintechniker', link: 'mediz' },
  { value: 'mediz6', label: 'MTA Funktionsdiagnostik (MTAF', link: 'mediz' },
  { value: 'mediz7', label: 'MTA Radiologie (MTRA)', link: 'mediz' },
  { value: 'mediz8', label: 'Zahntechniker', link: 'mediz' },


  { value: 'phar2', label: 'Apotheker', link: 'phar' },
  { value: 'phar3', label: 'Pharmakant', link: 'phar' },
  { value: 'phar4', label: 'Pharmareferent', link: 'phar' },
  { value: 'phar5', label: 'Pharmazeutisch-kaufmännische Angestellte (PKA)', link: 'phar' },
  { value: 'phar6', label: 'Pharmazeutisch-technische Assistentin (PTA)', link: 'phar' },


  { value: 'prax2', label: 'Medizinische Fachangestellte', link: 'prax' },
  { value: 'prax3', label: 'Praxismanagerin', link: 'prax' },
  { value: 'prax4', label: 'Zahnmedizinische Fachangestellte', link: 'prax' },
  { value: 'prax5', label: 'Tiermedizinische Fachangestellte', link: 'prax' },


  { value: 'alt2', label: 'Altenpflegehelfer', link: 'alt' },
  { value: 'alt3', label: 'Altenpfleger', link: 'alt' },
  { value: 'alt4', label: 'Betreuungskraft', link: 'alt' },
  { value: 'alt5', label: 'Fachaltenpfleger', link: 'alt' },
  { value: 'alt6', label: 'Hauswirtschafterin', link: 'alt' },
  { value: 'alt7', label: 'Pflegedienstleitung', link: 'alt' },


  { value: 'tera2', label: 'Ergotherapeut', link: 'tera' },
  { value: 'tera3', label: 'Erzieher', link: 'tera' },
  { value: 'tera4', label: 'Heilpraktiker', link: 'tera' },
  { value: 'tera5', label: 'Kinderpfleger', link: 'tera' },
  { value: 'tera6', label: 'Kunsttherapeut', link: 'tera' },
  { value: 'tera7', label: 'Logopäde', link: 'tera' },
  { value: 'tera8', label: 'Masseur/medizinischer Bademeister', link: 'tera' },
  { value: 'tera9', label: 'Motopäde', link: 'tera' },
  { value: 'tera10', label: 'Musiktherapeut', link: 'tera' },
  { value: 'tera11', label: 'Orthoptist', link: 'tera' },
  { value: 'tera12', label: 'Podologe', link: 'tera' },
  { value: 'tera13', label: 'Physiotherapeut', link: 'tera' },
  { value: 'tera15', label: 'Psychotherapeut', link: 'tera' },
  { value: 'tera16', label: 'Psychologe', link: 'tera' },
  { value: 'tera17', label: 'Sozialpädagoge', link: 'tera' },
];
const opt3 = [

  ////////////////////////////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank1' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank1' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank1' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank1' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank1' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank1' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank1' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank1' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank1' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank1' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank1' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank1' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank1' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank1' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank1' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank1' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank1' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank1' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank1' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank1' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank1' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank1' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank1' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank1' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank1' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank1' },
  { value: 'Fach27', label: 'Urologie', link: 'krank1' },
  //////////////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank3' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank3' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank3' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank3' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank3' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank3' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank3' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank3' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank3' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank3' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank3' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank3' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank3' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank3' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank3' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank3' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank3' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank3' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank3' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank3' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank3' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank3' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank3' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank3' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank3' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank3' },
  { value: 'Fach27', label: 'Urologie', link: 'krank3' },
  ///////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank4' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank4' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank4' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank4' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank4' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank4' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank4' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank4' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank4' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank4' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank4' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank4' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank4' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank4' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank4' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank4' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank4' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank4' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank4' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank4' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank4' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank4' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank4' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank4' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank4' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank4' },
  { value: 'Fach27', label: 'Urologie', link: 'krank4' },
  //////////////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank5' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank5' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank5' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank5' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank5' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank5' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank5' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank5' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank5' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank5' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank5' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank5' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank5' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank5' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank5' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank5' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank5' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank5' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank5' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank5' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank5' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank5' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank5' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank5' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank5' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank5' },
  { value: 'Fach27', label: 'Urologie', link: 'krank5' },
  ///////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank6' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank6' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank6' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank6' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank6' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank6' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank6' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank6' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank6' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank6' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank6' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank6' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank6' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank6' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank6' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank6' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank6' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank6' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank6' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank6' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank6' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank6' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank6' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank6' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank6' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank6' },
  { value: 'Fach27', label: 'Urologie', link: 'krank6' },
  //////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank7' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank7' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank7' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank7' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank7' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank7' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank7' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank7' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank7' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank7' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank7' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank7' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank7' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank7' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank7' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank7' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank7' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank7' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank7' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank7' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank7' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank7' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank7' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank7' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank7' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank7' },
  { value: 'Fach27', label: 'Urologie', link: 'krank7' },
  /////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank8' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank8' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank8' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank8' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank8' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank8' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank8' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank8' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank8' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank8' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank8' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank8' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank8' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank8' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank8' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank8' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank8' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank8' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank8' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank8' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank8' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank8' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank8' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank8' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank8' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank8' },
  { value: 'Fach27', label: 'Urologie', link: 'krank8' },
  ///////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank9' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank9' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank9' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank9' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank9' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank9' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank9' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank9' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank9' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank9' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank9' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank9' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank9' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank9' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank9' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank9' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank9' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank9' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank9' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank9' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank9' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank9' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank9' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank9' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank9' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank9' },
  { value: 'Fach27', label: 'Urologie', link: 'krank9' },
  ////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank10' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank10' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank10' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank10' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank10' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank10' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank10' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank10' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank10' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank10' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank10' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank10' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank10' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank10' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank10' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank10' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank10' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank10' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank10' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank10' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank10' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank10' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank10' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank10' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank10' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank10' },
  { value: 'Fach27', label: 'Urologie', link: 'krank10' },
  ////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank11' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank11' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank11' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank11' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank11' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank11' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank11' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank11' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank11' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank11' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank11' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank11' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank11' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank11' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank11' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank11' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank11' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank11' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank11' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank11' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank11' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank11' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank11' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank11' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank11' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank11' },
  { value: 'Fach27', label: 'Urologie', link: 'krank11' },
  //////////////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank12' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank12' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank12' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank12' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank12' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank12' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank12' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank12' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank12' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank12' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank12' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank12' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank12' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank12' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank12' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank12' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank12' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank12' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank12' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank12' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank12' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank12' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank12' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank12' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank12' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank12' },
  { value: 'Fach27', label: 'Urologie', link: 'krank12' },
  ////////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank13' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank13' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank13' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank13' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank13' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank13' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank13' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank13' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank13' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank13' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank13' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank13' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank13' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank13' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank13' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank13' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank13' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank13' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank13' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank13' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank13' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank13' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank13' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank13' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank13' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank13' },
  { value: 'Fach27', label: 'Urologie', link: 'krank13' },
  /////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank14' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank14' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank14' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank14' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank14' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank14' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank14' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank14' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank14' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank14' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank14' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank14' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank14' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank14' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank14' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank14' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank14' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank14' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank14' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank14' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank14' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank14' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank14' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank14' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank14' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank14' },
  { value: 'Fach27', label: 'Urologie', link: 'krank14' },
  ///////////////////////////////////////////////////////////////////

  { value: 'Fach2', label: 'Ambulante, Häusliche Pflege', link: 'krank15' },
  { value: 'Fach3', label: 'Anästhesie', link: 'krank15' },
  { value: 'Fach4', label: 'Augenheilkunde', link: 'krank15' },
  { value: 'Fach5', label: 'Behindertenpflege', link: 'krank15' },
  { value: 'Fach6', label: 'Chirurgie, OP-Bereich', link: 'krank15' },
  { value: 'Fach7', label: 'Dermatolgie', link: 'krank15' },
  { value: 'Fach8', label: 'Gastroenterologie', link: 'krank15' },
  { value: 'Fach9', label: 'Geriatrie', link: 'krank15' },
  { value: 'Fach10', label: 'Gynäkologie', link: 'krank15' },
  { value: 'Fach11', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'krank15' },
  { value: 'Fach12', label: 'Hygiene', link: 'krank15' },
  { value: 'Fach13', label: 'Hämatologie, Onkologie', link: 'krank15' },
  { value: 'Fach14', label: 'Häusliche Intensivpflege', link: 'krank15' },
  { value: 'Fach15', label: 'Innere Medizin', link: 'krank15' },
  { value: 'Fach16', label: 'Intensivmedizin', link: 'krank15' },
  { value: 'Fach17', label: 'Kardiologie', link: 'krank15' },
  { value: 'Fach18', label: 'Kinder-, und Jugendmedizin', link: 'krank15' },
  { value: 'Fach19', label: 'Neonatologie', link: 'krank15' },
  { value: 'Fach20', label: 'Neurologie', link: 'krank15' },
  { value: 'Fach21', label: 'Notaufnahme', link: 'krank15' },
  { value: 'Fach22', label: 'Orthopädie, Rehabilitation', link: 'krank15' },
  { value: 'Fach23', label: 'Palliativpflege', link: 'krank15' },
  { value: 'Fach24', label: 'Pathologie', link: 'krank15' },
  { value: 'Fach25', label: 'Psychiatrie', link: 'krank15' },
  { value: 'Fach28', label: 'Psychosomatische Medizin und Psychotherapie', link: 'krank15' },
  { value: 'Fach26', label: 'Radiologie', link: 'krank15' },
  { value: 'Fach27', label: 'Urologie', link: 'krank15' },
]
const option = [{
  value: "1",
  label: "Registrierungsdatum",
  fontSize: '166px'
},
{
  value: "2",
  label: "Profile Aktualisierungsdatum",
  fontSize: '166px'
},

]
class FollowingCandidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalTabs: "HelloMatch",
      jobTypeSingleSelect: null,
      qualificationSingleSelect: null,
      specializationMultipleSelect: null,
      workingTimeMultipleSelect: null,
      workingTernureMultipleSelect: null,
      qualificationMultiSelect: null,
      specializationMultiSelect: null,
      countrySingleSelect: null,
      citySingleSelect: null,
      radiusSingleSelect: null,
      decisionSingleSelect: null,
      optSelect: {},
      opt2Select: {},
      opt3Select: null,
      optSelect4: {},
      opt5Select: {},
      opt6Select: null,
      doctorSelect: null,
      functionsSelect: null,
      arbPSelect: null,
      funcSelect: {},
      teraSelect: null,
      eiSelect: null,
      statesSelect: null,
      statesSelect1: null,
      distanceSelect: null,
      openedCollapses: ["collapseOne", "collapse1"],
      visible: false,
      visible1: false,
      cand: [],
      filter: [],
      notescount: [],
      l_name: '',
      offset: 0,
      perPage: 20,
      currentPage: 0,
      offset1: 0,
      perPage1: 20,
      currentPage1: 0,
      state1: true,
      state2: true,
      show3: false,
      //modal: false,
      job_value: null
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });

  }
  changeHandler10 = (job_value) => {
    this.setState(pv => {
      return { ...pv, job_value: job_value };
    }
    );
    console.log(job_value)
    axios.post("/api2/candidbydate", { ...this.state, job_value })
      .then(response => {
        console.log(response)
        const filter = response.data;
        this.setState({ filter });
        this.setState({ state1: false })
        localStorage.setItem('filter', JSON.stringify(this.state.filter));
        console.log(filter);
        this.makePostData1(this.state.filter);
      })
      .catch(error => {
        console.log(error.response.data);
        const searcherr = error.response.data.msg;
        this.setState({ searcherr })
        this.setState({ state2: false })
      })
    /////////////////////////////////////////////
    axios.get('/api2/getnotes1')
      .then(response => {
        console.log(response)
        const data1 = response.data;
        this.setState({ data1 });
        console.log(data1);
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  changeHandler2 = optSelect => {
    this.setState(pv => {
      return { ...pv, optSelect: optSelect };
    }, () => {
      if (this.state.optSelect.label === "Arzt" ||
        this.state.optSelect.label === "Altenpflege" ||
        this.state.optSelect.label === "Krankenpflege" ||
        this.state.optSelect.label === "Therapeut" ||
        this.state.optSelect.label === "Medizintechnik" ||
        this.state.optSelect.label === "Rettungsdienst" ||
        this.state.optSelect.label === "Praxispersonal" ||
        this.state.optSelect.label === "Pharmazie") {
        let nextVal = opt2.filter((o) => o.link === this.state.optSelect.value)[0];
        this.changeHandler1(nextVal);
        this.setState(pv => ({ ...pv, visible: true }));
      } else {
        this.setState({ visible: false });
      }
    });

  }

  changeHandler1 = optSelect => {
    this.setState(() => ({ opt2Select: optSelect }), () => {
      if (this.state.opt2Select.label === "Assistenzarzt" ||
        this.state.opt2Select.label === "Facharzt" ||
        this.state.opt2Select.label === "Oberarzt" ||
        this.state.opt2Select.label === "Ltd, Oberarzt" ||
        this.state.opt2Select.label === "Chefarzt" ||
        this.state.opt2Select.label === "Anästhesieschwester" ||
        this.state.opt2Select.label === "Anästhesietechnische Assistentin (ATA)" ||
        this.state.opt2Select.label === "Fachkrankenschwester" ||
        this.state.opt2Select.label === "Gesundheitspflegehelfer/Krankenpflegehelfer" ||
        this.state.opt2Select.label === "Gesundheitspfleger/Krankenpfleger" ||
        this.state.opt2Select.label === "Hebamme" ||
        this.state.opt2Select.label === "Heilerziehungspfleger" ||
        this.state.opt2Select.label === "Kinderkrankenschwester" ||
        this.state.opt2Select.label === "OP Schwester" ||
        this.state.opt2Select.label === "Operationstechnische Assistentin (OTA)" ||
        this.state.opt2Select.label === "Pflegeassistent" ||
        this.state.opt2Select.label === "Pflegedienstleitung" ||
        this.state.opt2Select.label === "Stationsleitung" ||
        this.state.opt2Select.label === "Study Nurse") {
        let nextVal1 = opt3.filter((p) => p.link === this.state.opt2Select.value)[0];
        this.changeHandler3(nextVal1);
        this.setState({ visible1: true })
        console.log("if works", this.state);
      } else {
        this.setState({ visible1: false });
        console.log("else works", this.state);
      }

    })
  }

  changeHandler3 = opt2Select => {
    this.setState(pv => {
      return { ...pv, opt3Select: opt2Select };
    });
  }

  changeHandler4 = optSelect4 => {
    this.setState(pvr => {
      return { ...pvr, optSelect4: optSelect4 };
    }, () => {
      if (this.state.optSelect4.label === "Arzt" ||
        this.state.optSelect4.label === "Altenpflege" ||
        this.state.optSelect4.label === "Krankenpflege" ||
        this.state.optSelect4.label === "Therapeut" ||
        this.state.optSelect4.label === "Medizintechnik" ||
        this.state.optSelect4.label === "Rettungsdienst" ||
        this.state.optSelect4.label === "Praxispersonal" ||
        this.state.optSelect4.label === "Pharmazie") {
        let nextVal = opt2.filter((o) => o.link === this.state.optSelect4.value)[0];
        this.changeHandler5(nextVal);
        this.setState(pvr => ({ ...pvr, visible2: true }));
      } else {
        this.setState({ visible2: false });
      }
    });

  }

  changeHandler5 = optSelect4 => {
    this.setState(() => ({ opt5Select: optSelect4 }), () => {
      if (this.state.opt5Select.label === "Assistenzarzt" ||
        this.state.opt5Select.label === "Facharzt" ||
        this.state.opt5Select.label === "Oberarzt" ||
        this.state.opt5Select.label === "Ltd, Oberarzt" ||
        this.state.opt5Select.label === "Chefarzt" ||
        this.state.opt5Select.label === "Anästhesieschwester" ||
        this.state.opt5Select.label === "Anästhesietechnische Assistentin (ATA)" ||
        this.state.opt5Select.label === "Fachkrankenschwester" ||
        this.state.opt5Select.label === "Gesundheitspflegehelfer/Krankenpflegehelfer" ||
        this.state.opt5Select.label === "Gesundheitspfleger/Krankenpfleger" ||
        this.state.opt5Select.label === "Hebamme" ||
        this.state.opt5Select.label === "Heilerziehungspfleger" ||
        this.state.opt5Select.label === "Kinderkrankenschwester" ||
        this.state.opt5Select.label === "OP Schwester" ||
        this.state.opt5Select.label === "Operationstechnische Assistentin (OTA)" ||
        this.state.opt5Select.label === "Pflegeassistent" ||
        this.state.opt5Select.label === "Pflegedienstleitung" ||
        this.state.opt5Select.label === "Stationsleitung" ||
        this.state.opt5Select.label === "Study Nurse") {
        let nextVal1 = opt3.filter((p) => p.link === this.state.opt5Select.value)[0];
        this.changeHandler6(nextVal1);
        this.setState({ visible3: true })
        console.log("if works", this.state);
      } else {
        this.setState({ visible3: false });
        console.log("else works", this.state);
      }

    })
  }

  changeHandler6 = opt5Select => {
    this.setState(pv => {
      return { ...pv, opt6Select: opt5Select };
    });
  }
  componentDidMount() {
    const roles = localStorage.getItem('roles')
    this.setState({ roles })
    console.log(roles);
    axios
      .get('/api2/getnotes',)
      .then(response => {
        console.log(response);
        const notescount = response.data;
        this.setState({ notescount })
        console.log(notescount);
      })
      .catch(error => {
        console.log(error.response);
      })
    axios
      .get('/api2/allcandidate')
      .then(response => {
        console.log(response)
        const cand = response.data;
        const slice = cand.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = slice.map(item1 => <React.Fragment>

          <Card style={{ backgroundColor: '#f5f5f5' }} >
            <CardBody><Row>
              <Col md='2'>
                {item1.picture === null ? null : <img style={{ width: '100px', height: '100px' }}
                  alt="..."
                  className="avatar border-gray"
                  src={`https://hello-sister.de/uploads/${item1.picture}`}

                  q
                />}
              </Col>

              <Col md='5'><h7 className="text-left"   >
                <Link to={`/admin/detail-kandidate/${item1.applicant_id}`}>
                  {/* {item1.salutation} {" "} */}
                  {/* {item1.title === 'Kein' ? null : item1.title} */}
                  {item1.f_name} {item1.l_name}
                </Link>
                <br></br>
                {item1.telephone === null ? null : <><h7 className="text-left" style={{ fontSize: '13px' }}><i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${item1.telephone}`} target="_blank" rel="noopener noreferrer">{item1.telephone}</a></h7><br></br></>}
                <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp;{item1.username}</h7><br></br>
                <i class="fas fa-map-marker-alt"></i>&nbsp;
                <h7 className="text-left" style={{ fontSize: '13px' }}>{item1.haus_no}, {item1.post_code} {item1.applicant_city}, &nbsp;{item1.country}</h7>
                <br></br>
                {this.state.notescount.map(item => {
                  return (
                    <>
                      {item1.applicant_id === item.app_id ?
                        <span>
                          <Badge className="mr-1" color="success" pill>
                            Notizen: {item.cnt}
                          </Badge>
                        </span>
                        :
                        null
                      }
                      {/* <Badge className="mr-1" color="success" pill>
                        Notizen: {item.cnt}
                          </Badge> */}
                    </>
                  )
                })}
              </h7>
              </Col>
              <Col md='3'>
                <h7 className="text-left" style={{ fontSize: '11px' }}><b>Registrierung:</b> {item1.created_date}</h7><br></br>
                <h7 className="text-left" style={{ fontSize: '11px' }}><b>Profile aktualisiert:</b> {item1.last_update}</h7><br></br>
                <h7 className="text-left" style={{ fontSize: '11px' }}><b>Eingeloggt zuletzt:</b> {item1.last_login_date}</h7><br></br>
                {item1.status === 'aktiv' ? <h7 className="text-left" style={{ fontSize: '11px', color: '#28a745' }}><b>Konto Status:</b> {item1.status} </h7> :
                  item1.status === "Kandidaten gelöscht" ? <><h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><p>Konto Status: Konto wurde am {item1.account_del} vom{item1.status}</p>  </h7></> :
                    <h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><b>Konto Status:</b> {item1.status} </h7>
                }
              </Col>

              <Col md='2' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
                {this.state.roles === 'admin' ?
                  <>
                    <br></br>
                    <Button
                      className="btn-icon"
                      color="primary"
                      id={"tooltip366246639" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.acceptHandle.bind(this, item1.user_id)}
                    >
                      <i class="fa fa-unlock" aria-hidden="true"></i>
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246639" + item1.applicant_id}
                    >
                      Aktivieren
                    </UncontrolledTooltip>

                    <Button
                      className="btn-icon"
                      color="danger"
                      id={"tooltip366246640" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.rejectHandle.bind(this, item1.user_id)}
                    >
                      <i className="fa fa-toggle-off" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246640" + item1.applicant_id}
                    >
                      Deaktivieren
                    </UncontrolledTooltip>
                    <br></br>

                    <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip3662466421112" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.mailHandle.bind(this, item1.user_id)}

                    >
                      <i className="fa fa-envelope" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip3662466421112" + item1.applicant_id}
                    >
                      Profile Update Email
                    </UncontrolledTooltip>
                    <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip3662466421113" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.mailHandleFeed.bind(this, item1.user_id)}

                    >
                      <i className="fa fa-paper-plane" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip3662466421113" + item1.applicant_id}
                    >
                      Feedback Email
                    </UncontrolledTooltip>
                    <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="danger"
                      id={"tooltip366246642" + item1.applicant_id}
                      size="sm"
                      type="button"
                      //onClick={this.toggle}
                      //onClick={this.deleteHandle.bind(this, item1.user_id)}
                      onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                    >

                      <i className="fa fa-trash" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246642" + item1.applicant_id}
                    >
                      Löschen
                    </UncontrolledTooltip>

                  <Link to={`/admin/messages-list/${item1.user_id}`}>
                  <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip366246644" + item1.applicant_id}
                      size="sm"
                      type="button"
                      //onClick={this.toggle}
                      //onClick={this.deleteHandle.bind(this, item1.user_id)}
                      //onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                    >

                      <i className="fa fa-comments" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246644" + item1.applicant_id}
                    >
                      Chat
                    </UncontrolledTooltip>

                  </Link>
                   
                    
                  </>
                  :
                  <>
                    <br></br>
                    <Button
                      className="btn-icon"
                      color="primary"
                      id={"tooltip366246639" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.acceptHandle.bind(this, item1.user_id)}
                    >
                      <i class="fa fa-unlock" aria-hidden="true"></i>
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246639" + item1.applicant_id}
                    >
                      Aktivieren
                    </UncontrolledTooltip>

                    <Button
                      className="btn-icon"
                      color="danger"
                      id={"tooltip366246640" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.rejectHandle.bind(this, item1.user_id)}
                    >
                      <i className="fa fa-toggle-off" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246640" + item1.applicant_id}
                    >
                      Deaktivieren
                    </UncontrolledTooltip>
                    <br></br>

                    <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip3662466421112" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.mailHandle.bind(this, item1.user_id)}

                    >
                      <i className="fa fa-envelope" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip3662466421112" + item1.applicant_id}
                    >
                      Profile Update Email
                    </UncontrolledTooltip>
                    <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip3662466421113" + item1.applicant_id}
                      size="sm"
                      type="button"
                      onClick={this.mailHandleFeed.bind(this, item1.user_id)}

                    >
                      <i className="fa fa-paper-plane" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip3662466421113" + item1.applicant_id}
                    >
                      Feedback Email
                    </UncontrolledTooltip>

                    <Link to={`/admin/messages-list/${item1.user_id}`}>
                  <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip366246644" + item1.applicant_id}
                      size="sm"
                      type="button"
                      //onClick={this.toggle}
                      //onClick={this.deleteHandle.bind(this, item1.user_id)}
                      //onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                    >

                      <i className="fa fa-comments" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246644" + item1.applicant_id}
                    >
                      Chat
                    </UncontrolledTooltip>

                  </Link>

                  </>
                }
              </Col>


            </Row>

            </CardBody>

          </Card>

        </React.Fragment>)
        localStorage.setItem('cand', JSON.stringify(this.state.cand));
        console.log(cand);
        this.setState({
          pageCount: Math.ceil(cand.length / this.state.perPage),

          postData
        })
        console.log(cand);
      })
      .catch(error => {
        console.log(error);
      })
    //////////////////////////////////////////////////////

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.componentDidMount()
    });

  };

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  submitHandler = e => {
    e.preventDefault();
    var mainerr;
    var opt31err;
    var opt32err;
    var oneerr;
    //const { selectedFile, doc_title} = this.state
    if (this.state.optSelect.label === undefined && this.state.opt2Select.label === undefined && this.state.opt3Select === null && this.state.l_name === '') {
      console.log("empty")
      mainerr = 'Alle Felder sind leer'
      this.setState({ mainerr })
    } else {
      this.setState({ mainerr: undefined })
      console.log("not empty")
    }
    if (this.state.optSelect.label !== undefined && this.state.opt2Select.label !== undefined) {
      // if(this.state.optSelect.label === "Arzt" || this.state.optSelect.label === "Krankenpflege"){
      if (this.state.opt2Select.label !== "Select" && this.state.opt3Select === null) {
        console.log("opt3 is empty")
        opt31err = 'Bitte auswählen'
        this.setState({ opt31err })
      }
      else {
        this.setState({ opt31err: undefined })
      }
      // }else{
      //   this.setState({opt32err:undefined})
      //   this.setState({opt31err:undefined})
      // }
      if (this.state.opt3Select !== null) {
        if (this.state.opt3Select.length > 3) {
          opt32err = 'max 3 erlaubt'
          this.setState({ opt32err })
        } else {
          this.setState({ opt32err: undefined })
        }
      } else {
        this.setState({ opt32err: undefined })
      }
    } else {
      this.setState({ opt32err: undefined })
      this.setState({ opt31err: undefined })
    }
    if (this.state.l_name !== '' && this.state.optSelect.label !== undefined) {
      oneerr = 'Bitte verwenden Sie eine Suchoption';
      this.setState({ oneerr })
    } else {
      this.setState({ oneerr: undefined })
    }
    console.log(this.state)
    if (mainerr === undefined && opt31err === undefined && opt32err === undefined && oneerr === undefined) {
      console.log("everything ok")
      axios.post("/api2/candidatesearch1", this.state)
        .then(response => {
          console.log(response)
          const filter = response.data;
          this.setState({ filter });
          this.setState({ state1: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
        })
        .catch(error => {
          console.log(error.response.data);
          const searcherr = error.response.data.msg;
          this.setState({ searcherr })
          this.setState({ state2: false, state1: false })
        })
      //////////////////////////////////
      axios
        .get('/api2/getnotes',)
        .then(response => {
          console.log(response);
          const notescount = response.data;
          this.setState({ notescount })
          console.log(notescount);
          // const msg = "Sie lehnen diese Anfrage ab";
          //   this.setState({msg})
          // window.location.reload();
        })
        .catch(error => {
          console.log(error.response);
        })
    }
  }

  acceptHandle(userid, e) {
    e.preventDefault();
    console.log(this.state.job_value)
    if (this.state.job_value === null) {
      axios
        .post('/api2/acceptcandidate', { ...this.state, userid })
        .then(response => {
          console.log(response)
          const msg = "Sie akzeptieren die Anfrage";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload(true);
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      axios
        .post('/api2/acceptcandidate1', { ...this.state, userid })
        .then(response => {
          console.log(response)
          const filter = response.data;
          this.setState({ filter });
          this.setState({ state1: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
        })
        .catch(error => {
          console.log(error.response.data);
          const searcherr = error.response.data.msg;
          this.setState({ searcherr })
          this.setState({ state2: false })
        })
    }

  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  rejectHandle(userid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(userid)
    if (this.state.job_value === null) {
      axios
        .post('/api2/rejectcandidate', { ...this.state, userid })
        .then(response => {
          console.log(response);
          const msg = "Sie lehnen diese Anfrage ab";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload(true);
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      axios
        .post('/api2/rejectcandidate1', { ...this.state, userid })
        .then(response => {
          console.log(response);
          const filter = response.data;
          this.setState({ filter });
          this.setState({ state1: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
        })
        .catch(error => {
          console.log(error.response.data);
          const searcherr = error.response.data.msg;
          this.setState({ searcherr })
          this.setState({ state2: false })
        })
    }

  }
  deleteHandle = e => {
    //e.preventDefault();

    console.log(this.state.user_id)
    axios
      .post('/api2/deletecandidate', this.state)
      .then(response => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({ msg })
        window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  mailHandle(userid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(userid)
    axios
      .post('/api2/candidmail', { ...this.state, userid })
      .then(response => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({ msg })
        window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  mailHandleFeed(userid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(userid)
    axios
      .post('/api2/candidmailFeedback', { ...this.state, userid })
      .then(response => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({ msg })
        window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  makePostData1(filter) {
    const slice1 = filter.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
    const postData1 = slice1.map(item1 => <React.Fragment>


      <Card style={{ backgroundColor: '#f5f5f5' }} >
        <CardBody><Row>
          <Col md='2'>
            {item1.picture === null ? null : <img style={{ width: '100px', height: '100px' }}
              alt="..."
              className="avatar border-gray"
              src={`https://hello-sister.de/uploads/${item1.picture}`}


            />}
          </Col>

          <Col md='5'><h7 className="text-left"   >
            <Link to={`/admin/detail-kandidate/${item1.applicant_id}`}>
              {/* {item1.salutation} {" "} */}
              {/* {item1.title === 'Kein' ? null : item1.title} */}
              {item1.f_name} {item1.l_name}
            </Link>
            <br></br>
            {item1.telephone === null ? null : <><h7 className="text-left" style={{ fontSize: '13px' }}><i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${item1.telephone}`} target="_blank" rel="noopener noreferrer">{item1.telephone}</a></h7><br></br></>}
            <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp;{item1.username}</h7><br></br>
            <i class="fas fa-map-marker-alt"></i>&nbsp;
            <h7 className="text-left" style={{ fontSize: '13px' }}>{item1.haus_no}, {item1.post_code} {item1.applicant_city}, &nbsp;{item1.country}</h7>
            <br></br>
            {this.state.notescount.map(item => {
              return (
                <>
                  {item1.applicant_id === item.app_id ?
                    <span>
                      <Badge className="mr-1" color="success" pill>
                        Notizen: {item.cnt}
                      </Badge>
                    </span>
                    :
                    null
                  }
                  {/* <Badge className="mr-1" color="success" pill>
                        Notizen: {item.cnt}
                          </Badge> */}
                </>
              )
            })}
          </h7>
          </Col>
          <Col md='3'>
            <h7 className="text-left" style={{ fontSize: '11px' }}><b>Registrierung:</b> {item1.created_date}</h7><br></br>
            <h7 className="text-left" style={{ fontSize: '11px' }}><b>Profile aktualisiert:</b> {item1.last_update}</h7><br></br>
            <h7 className="text-left" style={{ fontSize: '11px' }}><b>Eingeloggt zuletzt:</b> {item1.last_login_date}</h7><br></br>
            {item1.status === 'aktiv' ? <h7 className="text-left" style={{ fontSize: '11px', color: '#28a745' }}><b>Konto Status:</b> {item1.status} </h7> :
              item1.status === "Kandidaten gelöscht" ? <><h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><p>Konto Status: Konto wurde am {item1.account_del} vom{item1.status}</p>  </h7></> :
                <h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><b>Konto Status:</b> {item1.status} </h7>
            }
          </Col>
          <Col md='2' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
            {this.state.roles === 'admin' ?
              <>
                <br></br>
                <Button
                  className="btn-icon"
                  color="primary"
                  id={"tooltip366246639" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.acceptHandle.bind(this, item1.user_id)}
                >
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246639" + item1.applicant_id}
                >
                  Aktivieren
                </UncontrolledTooltip>

                <Button
                  className="btn-icon"
                  color="danger"
                  id={"tooltip366246640" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.rejectHandle.bind(this, item1.user_id)}
                >
                  <i className="fa fa-toggle-off" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246640" + item1.applicant_id}
                >
                  Deaktivieren
                </UncontrolledTooltip>
                <br></br>

                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip3662466421112" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.mailHandle.bind(this, item1.user_id)}

                >
                  <i className="fa fa-envelope" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip3662466421112" + item1.applicant_id}
                >
                  Profile Update Email
                </UncontrolledTooltip>

                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip3662466421113" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.mailHandleFeed.bind(this, item1.user_id)}

                >
                  <i className="fa fa-paper-plane" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip3662466421113" + item1.applicant_id}
                >
                  Feedback Email
                </UncontrolledTooltip>


                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="danger"
                  id={"tooltip366246642" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                >
                  <i className="fa fa-trash" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246642" + item1.applicant_id}
                >
                  Löschen
                </UncontrolledTooltip>

                <Link to={`/admin/messages-list/${item1.user_id}`}>
                  <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip366246644" + item1.applicant_id}
                      size="sm"
                      type="button"
                      //onClick={this.toggle}
                      //onClick={this.deleteHandle.bind(this, item1.user_id)}
                      //onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                    >

                      <i className="fa fa-comments" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246644" + item1.applicant_id}
                    >
                      Chat
                    </UncontrolledTooltip>

                  </Link>

              </>

              :
              <>
                <br></br>
                <Button
                  className="btn-icon"
                  color="primary"
                  id={"tooltip366246639" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.acceptHandle.bind(this, item1.user_id)}
                >
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246639" + item1.applicant_id}
                >
                  Aktivieren
                </UncontrolledTooltip>

                <Button
                  className="btn-icon"
                  color="danger"
                  id={"tooltip366246640" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.rejectHandle.bind(this, item1.user_id)}
                >
                  <i className="fa fa-toggle-off" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246640" + item1.applicant_id}
                >
                  Deaktivieren
                </UncontrolledTooltip>
                <br></br>

                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip3662466421112" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.mailHandle.bind(this, item1.user_id)}

                >
                  <i className="fa fa-envelope" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip3662466421112" + item1.applicant_id}
                >
                  Profile Update Email
                </UncontrolledTooltip>
                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip3662466421113" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.mailHandleFeed.bind(this, item1.user_id)}

                >
                  <i className="fa fa-paper-plane" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip3662466421113" + item1.applicant_id}
                >
                  Feedback Email
                </UncontrolledTooltip>

                <Link to={`/admin/messages-list/${item1.user_id}`}>
                  <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="primary"
                      id={"tooltip366246644" + item1.applicant_id}
                      size="sm"
                      type="button"
                      //onClick={this.toggle}
                      //onClick={this.deleteHandle.bind(this, item1.user_id)}
                      //onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                    >

                      <i className="fa fa-comments" />
                    </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246644" + item1.applicant_id}
                    >
                      Chat
                    </UncontrolledTooltip>

                  </Link>
              </>
            }
          </Col>


        </Row>

        </CardBody>

      </Card>
    </React.Fragment>)
    this.setState({ state2: true })
    this.setState({
      pageCount1: Math.ceil(filter.length / this.state.perPage1),
      postData1
    });
  }
  handlePageClick1 = (e) => {
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;

    this.setState({
      currentPage1: selectedPage1,
      offset1: offset1
    }, () => {
      this.makePostData1(this.state.filter);
    });

  };

  ////////////////////////////////////////////////////////
  render() {




    const filteredOpt = opt2.filter((o) => o.link === this.state.optSelect.value)
    const filteredOpt1 = opt3.filter((p) => p.link === this.state.opt2Select.value)

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <h4> <b>
                        Kandidaten
                      </b></h4>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Row className="justify-content-center ml-auto mr-auto">
                        <Col md="">
                          <Card>
                            <CardBody>
                              <div
                                aria-multiselectable={true}
                                className="card-collapse"
                                id="accordion"
                                role="tablist"
                              >
                                <CardTitle tag="h4" className="">Filter</CardTitle>
                                <Card className="card-plain">
                                  <Badge color="danger">{this.state.mainerr}</Badge>
                                  <Badge color="danger">{this.state.oneerr}</Badge>
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={this.state.openedCollapses.includes(
                                        "collapseOne"
                                      )}
                                      href="#pablo"
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => this.collapsesToggle("collapseOne")}
                                    >
                                      Kandidaten suchen nach Persönliche Angaben{" "}
                                      <i className="nc-icon nc-minimal-down" />
                                    </a>
                                  </CardHeader>
                                  <Collapse
                                    role="tabpanel"
                                    isOpen={this.state.openedCollapses.includes(
                                      "collapseOne"
                                    )}
                                  >
                                    <CardBody>
                                      <Form action="#" method="#">
                                        <Row>
                                          <Col md="6">
                                            <label>Vollständiger Name</label>
                                            <FormGroup>
                                              <Input placeholder="Vorname/Nachname" type="text" name="l_name" onChange={this.changeHandler} />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={this.state.openedCollapses.includes(
                                        "collapseTwo"
                                      )}
                                      href="#pablo"
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => this.collapsesToggle("collapseTwo")}
                                    >
                                      Kandidaten suchen nach Stellenpräferenzen{" "}
                                      <i className="nc-icon nc-minimal-down" />
                                    </a>
                                  </CardHeader>
                                  <Collapse
                                    role="tabpanel"
                                    isOpen={this.state.openedCollapses.includes(
                                      "collapseTwo"
                                    )}
                                  >
                                    <CardBody>
                                      <div className="content">
                                        <Row>
                                          <Col>

                                            <Row>
                                              <Col md="12">
                                                {admin.map(() => {
                                                  return (
                                                    <>
                                                      <Row>
                                                        <Col className="" md="4">
                                                          <FormGroup>
                                                            <label >Berufsgruppe</label>
                                                            <Select
                                                              className="react-select primary"
                                                              classNamePrefix="react-select"
                                                              name="titleSelect"
                                                              value={this.state.optSelect}
                                                              onChange={this.changeHandler2}
                                                              options={opt}
                                                              placeholder="Select..."
                                                            />
                                                          </FormGroup>
                                                        </Col>
                                                        {this.state.visible ?
                                                          <Col className="" md="4">
                                                            <FormGroup>
                                                              <label>Funktion, Position</label>
                                                              <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="titleSelect"
                                                                value={this.state.opt2Select}
                                                                onChange={this.changeHandler1}
                                                                options={filteredOpt}
                                                              />
                                                            </FormGroup>
                                                          </Col>
                                                          : null}
                                                        {this.state.visible1 ?
                                                          <Col className="" md="4">
                                                            <FormGroup>
                                                              <label>Fachgebiet, Fachabteilungen</label><Badge color="danger">{this.state.opt32err}</Badge><Badge color="danger">{this.state.opt31err}</Badge>
                                                              <Select
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="titleSelect"
                                                                value={this.state.opt3Select}
                                                                onChange={this.changeHandler3}
                                                                options={filteredOpt1}
                                                              />
                                                            </FormGroup>
                                                          </Col>
                                                          : null}
                                                      </Row>
                                                    </>
                                                  )
                                                })}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </div>
                                    </CardBody>
                                  </Collapse>
                                </Card>

                              </div>
                            </CardBody>
                            <CardFooter>
                              <Row>
                                <Col className="text-center">
                                  <Button
                                    className="btn-round center"
                                    outline
                                    size="md"
                                    color="primary"
                                    onClick={this.submitHandler}
                                  >
                                    Suchen
                                  </Button>
                                </Col>
                              </Row>

                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                      <hr />
                      <FormGroup>
                        <Badge color="danger">{this.state.errvalue}</Badge>
                        <Select
                          className="react-select primary"
                          name="job_value"
                          onChange={this.changeHandler10.bind(this)}
                          value={this.state.job_value}
                          options={option}
                          placeholder="Sortieren nach"
                          type="text"
                        />
                      </FormGroup>
                      <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody>

                          {this.state.state1 ? <>
                            {this.state.postData}
                          </>
                            :
                            <>{this.state.state2 ?
                              <> {this.state.postData1} </>
                              : null}</>
                          }
                          <br></br>
                          <br></br>
                          {this.state.state1 ?
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"} />
                            :
                            <span> {this.state.state2 ? <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount1}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick1}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"} /> : <Badge color="danger">No Kandidates For this Result</Badge>}</span>
                          }
                          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
                          >
                            <ModalHeader toggle={this.toggle}></ModalHeader>
                            <ModalBody>
                              <h4 ><b>Kandidaten dauerhaft löschen</b></h4>
                              <br></br><Badge color="warning">{this.state.reply}</Badge><br />
                              Sind Sie sicher, dass Sie diesen Kandidaten löschen möchten?<br></br>
                              <br></br>
                              <center><Button
                                onClick={this.toggle}
                                style={{
                                  width: 'auto',
                                  height: '32px',
                                  fontSize: '9px',
                                  borderRadius: '32px',
                                  boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                  fontWeight: '400',
                                  border: 'none !important',
                                  padding: '9px 27px',
                                  // background: '#dc3545',
                                  marginBottom: '1rem'
                                }}>Abbrechen</Button>
                                <Button
                                  color="primary"
                                  style={{
                                    width: 'auto',
                                    height: '32px',
                                    fontSize: '9px',
                                    borderRadius: '32px',
                                    boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                    fontWeight: '400',
                                    border: 'none !important',
                                    padding: '10px 27px',
                                    background: '#dc3545'
                                  }}
                                  onClick={this.deleteHandle}
                                >

                                  Bestätigen </Button></center>

                            </ModalBody>
                          </Modal>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default FollowingCandidate;
