import React, {useState, useEffect} from "react";
import axios from "axios";
import {Row, Col, Button} from "reactstrap";
import {Redirect, useParams} from "react-router-dom";
import Moment from "moment";
import SunEditor from "suneditor-react";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AGBEditor = () => {
  const {Daten_id} = useParams();
  const [value, setValue] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [userId, setUserId] = useState(null);
  const [archivedDate, setArchivedDate] = useState(null);

  useEffect(() => {
    // Fetch user ID from local storage
    const storedUserId = localStorage.getItem("user_id");
    setUserId(storedUserId);

    // Fetch the specific data for editing
    axios
      .post("/api2/GetEditorDatenschutz_single", {Daten_id})
      .then((response) => {
        const editorValue = response.data.result[0].EditorDesc;
        const date = response.data.result[0].Date;
        setValue(editorValue);
        setArchivedDate(date);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error("Error fetching data.");
      });
  }, [Daten_id]);

  const submitHandler = () => {
    axios
      .post("/api2/EditorDatenschutz", {value, Daten_id: Daten_id})
      .then(() => {
        toast.success("Saved successfully!");
        setTimeout(() => setRedirect(true), 2000); // Redirect after a delay
      })
      .catch((error) => {
        console.log(error.response);
        toast.error("Error saving data.");
      });
  };

  if (redirect) {
    return <Redirect to="/admin/Datenschutz_Editor" />;
  }

  return (
    <div className="content">
      <ToastContainer />
      <div style={{background: "white"}}>
        <Row style={{padding: "12px"}}>
          <Col md="12">
            <h4>
              Datenschutz (Archived Date:{" "}
              {Moment(archivedDate).format("DD.MM.YYYY")})
            </h4>
          </Col>
        </Row>

        <SunEditor
          setContents={value}
          onChange={setValue}
          lang="en"
          width="100%"
          height="100%"
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle", "blockquote"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
              ],
              ["fontColor", "hiliteColor", "textStyle"],
              ["removeFormat"],
              "/", // Line break
              ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],
              ["table", "link"],
              ["fullScreen", "showBlocks", "codeView"],
              ["preview", "print"],
              ["save", "template"],
            ],
          }}
        />
        <div style={{marginBottom: "70px"}}>
          <Button
            style={{float: "right"}}
            color="primary"
            onClick={submitHandler}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AGBEditor;
