import React, {useState, useEffect, useCallback} from "react";
import DataTable from "react-data-table-component";
// import { Button } from 'rsuite';
import axios from "axios";
import {Link} from "react-router-dom";
import styled from "styled-components";
import Card from "reactstrap/lib/Card";
import {Button, Row, Col} from "reactstrap";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const TextField = styled.input`
  height: 32px;
  width: 120px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return day + "." + month + "." + year;
}
const FilterComponent = ({
  filterText,
  onFilter,
  onClear,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => (
  <>
    <Row style={{marginBottom: "14px", alignItems: "center"}}>
      <Col md="1">
        <label>Filter:</label>
      </Col>
      <Col md="3">
        <TextField
          id="search"
          type="text"
          placeholder="Kunde"
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
        />
      </Col>
      <Col md="3">
        <div>
          <DatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            minDate={startDate}
            maxDate={endDate}
            format="dd.MM.yyyy"
            placeholderText="Von:"
            locale="de"
            clearIcon={null}
            className="custom-datepicker"
          />
        </div>
      </Col>
      <Col md="3">
        <div>
          <DatePicker
            onChange={(date) => setEndDate(date)}
            value={endDate}
            format="dd.MM.yyyy"
            locale="de"
            className="custom-datepicker"
            clearIcon={null}
            minDate={startDate}
            maxDate={endDate}
            placeholderText="Bis:"
          />
        </div>
      </Col>
      <Col>
        <ClearButton type="button" onClick={onClear}>
          X
        </ClearButton>
      </Col>
    </Row>
  </>
);

const InvoiceTable = () => {
  const [invoices, setInvoices] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterDate, setFilterDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = ({selectedRows}) => {
    console.log(selectedRows);
    setSelectedRows(selectedRows);
  };

  const selectableRows = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
    onRowSelected: handleRowSelected,
    onRowsSelected: handleRowSelected,
    selectedRows: selectedRows,
  };

  // const filteredItems = invoices.filter(
  //     item => item.hosp_name && item.hosp_name.toLowerCase().includes(filterText.toLowerCase()) ||
  //         (!filterDate || item.created_at === filterDate.toISOString().substring(0, 10))
  // );

  let filteredItems = invoices;

  if (filterText) {
    filteredItems = filteredItems.filter(
      (item) =>
        item.hosp_name &&
        item.hosp_name.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  if (startDate && endDate) {
    filteredItems = filteredItems.filter(
      (item) => {
        if (!startDate || !endDate) return true;
        const start = moment(startDate).startOf("day");
        const end = moment(endDate).endOf("day");
        const date = moment(item.created_at);
        return date.isBetween(start, end, "day", "[]");
      }
      //filterDate ? moment(item.created_at).isSame(filterDate, 'day') : true
      //item.created_at === filterDate,
      // moment(item.created_at).isSame(filterDate, 'day')
    );
  }

  // const filteredItems = invoices.filter((item) =>
  //     item.hosp_name && item.name.toLowerCase().includes(filterText.toLowerCase())
  // ).filter((item) => {
  //     if (!startDate || !endDate) return true;
  //     const start = moment(startDate).startOf('day');
  //     const end = moment(endDate).endOf('day');
  //     const date = moment(item.date);
  //     return date.isBetween(start, end, 'day', '[]');
  // });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
      if (startDate || endDate) {
        setResetPaginationToggle(!resetPaginationToggle);
        setStartDate(null);
        setEndDate(null);
      }
    };

    return (
      <>
        <FilterComponent
          setStartDate={(date) => setStartDate(date)}
          startDate={startDate}
          setEndDate={(date) => setEndDate(date)}
          endDate={endDate}
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  const contextActions = React.useMemo(() => {
    const handleDownloadFiles = async () => {
      try {
        const response = await axios.post(
          "/api2/download-invoices",
          {
            invoices: selectedRows.map((row) => row.invoice_number),
          },
          {responseType: "blob"}
        );

        const blob = new Blob([response.data], {type: "application/zip"});

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "invoices.zip";
        link.click();

        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading invoices:", error);
      }
    };

    return (
      <Button color="primary" onClick={handleDownloadFiles} icon>
        Download ausgewählter Rechnungen
      </Button>
    );
  }, [selectedRows]);

  const GetInvoices = () => {
    axios
      .get("/api2/Getinvoices")
      .then((res) => {
        // console.log(res)
        setInvoices(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetInvoices();
  }, []);

  const AmountWithtax = (amount, taxRate) => {
    const parsedAmount = parseFloat(amount); // Convert amount to a number
    if (isNaN(parsedAmount)) {
      return "Invalid amount"; // Return an error message if the amount is not a valid number
    }

    const taxTotal = (parsedAmount * taxRate) / 100;
    const totalAmount = parsedAmount + taxTotal;
    return totalAmount.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleStatusUpdate = (id,status) => {
    axios
      .post("/api2/UpdateInvoiceStatus", {id: id,status:status})
      .then((res) => {
        console.log(res);
        //setInvoices(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
    const updatedInvoices = invoices.map((invoice) => {
      if (invoice.invoice_id === id) {
        return {
          ...invoice,
          status: invoice.status === "Paid" ? "pending" : "Paid",
        };
      }
      return invoice;
    });
    setInvoices(updatedInvoices);
  };

  const columns = [
    {
      name: "ID",
      // selector: 'invoice_id',
      sortable: true,
      center: true,
      width: "140px",
      cell: (row) => <span>{row.invoice_id}</span>,
    },
    {
      name: "Nummer",
      // selector: 'invoice_number',
      sortable: true,
      center: true,
      width: "140px",
      cell: (row) => <span>{row.invoice_number}</span>,
    },
    {
      name: "Unternehmen",
      // selector: 'hosp_name',
      sortable: true,
      cell: (row) => <span>{row.hosp_name}</span>,
    },
    {
      name: "Description",
      // selector: 'hosp_name',
      sortable: true,
      cell: (row) => <span>{row.description}</span>,
    },
    {
      name: "Gesamt",
      // selector: 'amount',
      sortable: true,
      center: true,
      width: "120px",
      cell: (row) => <span>{AmountWithtax(row.amount, 19)}</span>,
    },
    {
      name: "Fälligkeit",
      selector: (row) => formatDate(new Date(row.due_date)),
      sortable: true,
      center: true,
      width: "120px",
      // cell: (row) => <span>{formatDate(row.due_date)}</span>,
    },
    {
      name: "Datum",
      selector: (row) => row.created_at, // Use a function-based selector
      format: (row) => moment(row.created_at).format("DD/MM/YYYY HH:mm:ss"),
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "Status",
      // selector: 'status',
      sortable: true,
      center: true,
      width: "120px",
      cell: (row) => (
        <span> {row.status === "Paid" ? "Bezahlt" : "Offen"}</span>
      ),
    },
    {
      name: "Download",
      // selector: 'status',
      sortable: true,
      center: true,
      // cell: (row) => <a href={`/images/${row.invoice_number}.pdf`} download>Download</a>
      cell: (row) => <DownloadButton filename={row.invoice_number} />,
    },
    {
      name: "Action",
      // selector: 'action',
      center: true,
      width: "120px",
      cell: (row) => (
        <Button
          color="primary"
          size="sm"
          appearance={row.status === "Paid" ? "primary" : "ghost"}
          onClick={() => handleStatusUpdate(row.invoice_id,row.status === "Paid" ? "Paid" : "Unpaid")}>
          {row.status === "Paid" ? "Offen" : "Bezahlt"}
        </Button>
      ),
    },
    {
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      // cell: (row) => <a href={`/images/${row.invoice_number}.pdf`} download>Download</a>
      cell: (row) => <DeleteButton onClick={() => handleDelete(row)} />,
    },
  ];

  const DeleteButton = ({onClick}) => (
    <button onClick={onClick}>Löschen</button>
  );
  const handleDelete = (file) => {
    if (
      window.confirm(`Are you sure you want to delete ${file.invoice_number}?`)
    ) {
      // Perform the file deletion logic here
      deleteFileFromS3(file.invoice_id);
    }
  };

  const deleteFileFromS3 = async (id) => {
    try {
      axios
        .post("/api2/delete_invoice", {id: id})
        .then((res) => {
          console.log(res);
          GetInvoices();
          //setInvoices(res.data)
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error deleting file from S3:", error);
    }
  };

  const DownloadButton = (filename) => {
    const handleDownload = useCallback(async () => {
      try {
        const response = await axios.get(
          `/api2/download-invoice?filename=${filename.filename}`,
          {responseType: "blob"}
        );

        const blob = new Blob([response.data], {type: "application/pdf"});

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = filename.filename;
        link.click();

        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading invoice:", error);
      }
    }, [filename.filename]);

    return <button onClick={handleDownload}>Download</button>;
  };

  return (
    <div className="content">
      <DataTable
        title="Rechnungen"
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        persistTableHead
        highlightOnHover
        striped
        dense
        contextActions={contextActions}
        contextMessage={{
          singular: "Eintrag",
          plural: "Einträge",
          message: "ausgewählt",
        }}
        noDataComponent="Keine Rechnung gefunden"
        paginationComponentOptions={{
          rowsPerPageText: "Zeilen pro Seite",
          rangeSeparatorText: "von",
          selectAllRowsItem: true,
          selectAllRowsItemText: "Alle",
        }}
      />
    </div>
  );
};

export default InvoiceTable;
