import React from "react";
import classnames from "classnames";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  BrowserHistory,
  withRouter,
} from "react-router-dom";
import * as Cookies from "js-cookie";
import axios from "axios";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Badge,
  Row,
  Col,
} from "reactstrap";
//import MessageList from "building_block/message/components/MessageList";
import NotificationDropdown from "./NotificationDropdown";
import {NotificationProvider} from "./NotificationContext";

import {admin, messages} from "variables/general.jsx";
const messageToReder = 5;

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      msgLength: "",
      msgLengthJob: "",
      notificationJob: [],
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({user});
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  logOut = (e) => {
    // Call the signOut API
    axios
      .post("/api2/signOut", this.state)
      .then((response) => {
        // Optionally handle response if needed
        console.log("Logged out successfully");
      })
      .catch((error) => {
        console.log("Error during logout:", error);
      });
  
    // Clear all cookies dynamically
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
  
    // Clear all localStorage and sessionStorage data
    localStorage.clear();
    sessionStorage.clear();
  
    // Update state to redirect
    this.setState({ redirect: true });
  };
  
  // logOut = (e) => {
  //   axios
  //     .post("/api2/signOut", this.state)
  //     .then((response) => {
  //       // then print response status
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   localStorage.clear();
  //   Cookies.remove("user_email");
  //   Cookies.remove("user_id1");
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("user_id");
  //   localStorage.removeItem("role");
  //   Cookies.remove("refreshToken");
  //   this.setState({redirect: true});
  // };
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  render() {
    const {redirect} = this.state;
    if (redirect) {
      return <Redirect to="/auth/login" />;
    }

    return (
      <>
        <NotificationProvider>
          <Navbar
            className={classnames(
              "navbar-absolute fixed-top",
              this.state.color
            )}
            expand="lg">
            <Container fluid>
              <div className="navbar-wrapper">
                <div className="navbar-minimize">
                  <Button
                    className="btn-icon btn-round"
                    color="default"
                    id="minimizeSidebar"
                    onClick={this.props.handleMiniClick}>
                    <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                    <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                  </Button>
                </div>
                <div
                  className={classnames("navbar-toggle", {
                    toggled: this.state.sidebarOpen,
                  })}>
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleSidebar}>
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
              </div>
              <button
                aria-controls="navigation-index"
                aria-expanded={this.state.collapseOpen}
                aria-label="Toggle navigation"
                className="navbar-toggler"
                // data-target="#navigation"
                data-toggle="collapse"
                type="button"
                onClick={this.toggleCollapse}>
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </button>
              <Collapse
                className="justify-content-end"
                navbar
                isOpen={this.state.collapseOpen}>
                <Nav navbar>
                  <NotificationDropdown />
                  <UncontrolledDropdown className="btn-rotate" nav>
                    <DropdownToggle
                      aria-haspopup={true}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      id="navbarDropdownMenuLink"
                      nav>
                      <i className="nc-icon nc-settings-gear-65" />
                      <p>
                        <span className="d-lg-none d-md-block">
                          Enstellungen
                        </span>
                      </p>
                    </DropdownToggle>
                    <DropdownMenu
                      aria-labelledby="navbarDropdownMenuLink"
                      right>
                      <DropdownItem tag={Link} to="/admin/adduser">
                        Add User
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/admin/account">
                        Passwort ändern
                      </DropdownItem>
                      <DropdownItem onClick={this.logOut}>
                        Abmelden
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </NotificationProvider>
      </>
    );
  }
}

export default AdminNavbar;
