import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
import ReactPaginate from 'react-paginate';
import "./style1.css";
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Badge,
} from "reactstrap";
class HospitalProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [""],
      buttonToRender: 4,
      horizontalTabs: "details",
      hosp : [],
      errmsg:'',
      reserrmsg:'',
      resmsg:'',
      state1: false,
      state2: false,
      offset1: 0,
      perPage1: 10,
      currentPage1: 0
    };
  }
  componentWillMount(){
    const email = JSON.parse(localStorage.getItem('email'))
   this.setState({email})
  }
  componentDidMount(){
    console.log(this.props.match.params);
    const { hosp_id } = this.props.match.params;
    console.log(hosp_id);
    // const { username } = this.props.match.params;
    // console.log(username);
    this.setState({hosp_id})
    axios.post('/api2/hospitalsdetails1', {...this.state,hosp_id})
    .then(response => {
      console.log(response)
      const hosp = response.data;
      this.setState({ hosp });
      const check1 = response.data[0].mailoption1;
      this.setState({ check1 });
      console.log(check1);
      if (check1 === 1)
      {
      //  const msg1 = 'BENACHRICHTIGUNG ZU NEUEN KANDIDATEN'
      //  this.setState({ msg1 });
      //  console.log(msg1);
       this.setState({ state1:true });
      }
      else 
      {
        this.setState({ state1:false });
      }
      const check2 = response.data[0].mailoption2;
      this.setState({ check2 });
      console.log(check2);
      if (check2 === 1)
      {
      //  const msg2 = 'INFORMATION ZU NEUEN NACHRICHTEN'
      //  this.setState({ msg2 });
      // console.log(msg2);
      this.setState({ state2:true });
      }
      else 
      {
        this.setState({ state2:false });
      }

      localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      console.log(hosp);
    })
    .catch(error => {
      console.log(error.response);
    })
    //////////////////////////////////////////////
    axios.post("/api2/allhospitaljobs", {...this.state,hosp_id})
   .then(response => { // then print response status
    const msg = response.data.Message;
    this.setState({msg});
    console.log(response)
    const candidatelist = response.data;
     this.setState({candidatelist});
     const slice1 = candidatelist.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
     const postData1 = slice1.map(item => <React.Fragment>
                  <Card style = {{backgroundColor: '#f1f1f1', padding:'10px'}} >
                          
                          <Row>
                            <Col md = '2'>
                            <img src ={`https://unternehmen.hello-sister.de/uploads/${item.logo}`} alt='' height = '80'  width = 'auto'/>
                           </Col>
                            
                <Col md = '7'>
                <h7 style = {{color:'#4287f5', fontSize:'10px' }}> {item.company_name}</h7>
                 
                   <br></br>
                    <Link  to={`/admin/job-profile/${item.job_id}/${item.app_id}`}> 
                   {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}
                      </Link>
                     
                      <br></br>
                    
                     <i class="fas fa-map-marker-alt"> 
                   
                    </i> <h7 className="text-left" style = {{ fontSize:'13px' }}>{item.address} {item.haus_no},&nbsp;
                              {item.zip_code}  {item.city}, {item.state}</h7>
                            
                  </Col>
                {/* <Col md = '3' className="text-right">
                


<Link to={`/admin/candidatelist1/${item.job_id}/${item.hosp_id}`}> 
<Button
  
  color = 'primary'
  style={{fontSize: '12px'}}
 
>
 Bewerbungen
</Button>
 </Link> 

                </Col> */}
              </Row>
                <br></br> 
                <Row style = {{backgroundColor: '#ffffff',marginTop:'-10px'}}>
                   
                   <Col md="3">
                   <i class="far fa-clock"></i>
                   &nbsp;&nbsp;
                   <h7 style={{fontSize: '11px'}}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                    </Col>
                   <Col md="3">
                   <i class="fas fa-hourglass-half"></i>
&nbsp;&nbsp;
<h7 style={{fontSize: '11px'}}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} {item.job_ternure2 !== null ? ',' + item.job_ternure2 : null} </h7>  
                   </Col>
                   <Col md="3">
                   <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                   <h7 style={{fontSize: '11px'}}> Online seit: {item.created_date}</h7> 
                   </Col> 
                   <Col md="3">
                   <i class="far fa-calendar-alt"></i>

&nbsp;&nbsp;

&nbsp;&nbsp;
{item.job_status === 'online' ? <h7 style={{fontSize: '11px', color : '#28a745'}}>Online bis: {item.valid_till} </h7>  
:
item.job_status === 'offline' ? <h7 style={{fontSize: '11px', color : '#ff0800'}}>Offline seit: {item.valid_till} </h7>
:
item.job_status === 'deactive' ? <h7 style={{fontSize: '11px', color : '#ff0800'}}>Deactive seit: {item.valid_till} </h7>
:
null
} 


                    
                   </Col> 
               </Row> 
<div>
  </div>
                  
             </Card> 

 
     </React.Fragment>)
     localStorage.setItem('candidatelist', JSON.stringify(this.state.candidatelist));
     console.log(candidatelist);
     this.setState({
     state2:true
   })
     this.setState({
       pageCount1: Math.ceil(candidatelist.length / this.state.perPage1),
      
       postData1
   })
   this.setState({
     state2:false
   })
   })
 .catch(error => {
  console.log(error);
})
  }
  handleMoreClick = event => {
    this.setState({ 
      buttonToRender: (this.state.buttonToRender + 2)
    });
  };
  handlePageClick1 = (e) => {
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;
  
    this.setState({
      currentPage1: selectedPage1,
      offset1: offset1
  }, () => {
      this.componentDidMount()
  });
  
  };
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };

  logoSelect(hosplogo,username, e){
    //alert(id);
    console.log(hosplogo,this.state.hosp_id,username);
     //this.setState(});
     //console.log(this.state.hosp_logo);
     if(hosplogo === null){
       var errmsg = "no logo found";
       this.setState({errmsg})
     }else{
    axios
      .post('/api2/postlogo',{...this.state,hosplogo,username})
      .then(response =>{
        console.log(response)
        //this.setState({redirect : true})
        //window.location.reload(true);
        var resmsg = response.data.msg;
        this.setState({ resmsg });
      })
      .catch(error =>{
        console.log(error)
        var reserrmsg = error.response.data.msg;
        this.setState({ reserrmsg });
      })
  }
}
  render() {
    // const { redirect } = this.state;
    // const {isPasswordShown} = this.state;
    //  if (redirect) {
    //    return <Redirect to='/auth/Alle-Neue-Jobs-suchen'/>;
    //  }
    return (
      <div className="content">
         {this.state.hosp.map((user) => {
          return ( 
            <Row className="ml-auto mr-auto justify-content-center">
            <Col md="12">
            <Card className="card-user">
               
               {this.state.hosp.map(user => {
                     return (
                       <>
               <CardBody>
        
                     
                   
                 <Row>
        
         <Col md="12">
       
           <Row>
        <Col md="2"
        style={{
         padding: '8px',
         marginLeft: '29px',
         marginTop: '10px',
        }}
        >
        {user.hosp_logo === null ? null :   <span>  <img style={{width:'100px', height:'100px'}}
                     alt="..."
                     className="avatar border-gray"
                     src={`https://unternehmen.hello-sister.de/uploads/${user.hosp_logo}`}
                  
                     //src={require("assets/img/bg/5.jpg")}
                    />
                    </span>}
        </Col>
        <Col md="6"
      
        >
        <h5 cl assName="text-primary"><b> {user.hosp_name} </b> </h5>
       
        <p style={{fontSize:'14px'}}><i class="fas fa-map-marker-alt"></i>&nbsp;	{user.adress} {user.haus_number}, {user.zip_code} {user.city} 
        <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{user.state}, {user.country}</p>
       
        </Col>

 <Col md = '3' className="text-right" style = {{}}>
 {/* <Link to={`/admin/CreateJob/${this.state.hosp_id}`}>
                       <Button color="primary" 
                      // onClick={this.logoSelect.bind(this, user.hosp_logo,user.username)}
                      >
                      Job Anlegen
                      </Button> 
                     </Link> */}

                    </Col>
               
           </Row>
           <Badge color="warning">{this.state.errmsg}</Badge>
                      <Badge color="warning">{this.state.reserrmsg}</Badge>
                      <Badge color="success">{this.state.resmsg}</Badge>
  <Row>
             <Col md="12" style={{margin:'15px'}}>
               <h4>Account</h4>
               <hr></hr>
             
                      <h6 className="title"> <u>Benachrichtigungen Verwalten </u>  </h6>
                      
                 <br></br>
                 
                  {this.state.hosp.map(user => {
      return (
        <> 

                        <center>
                        {this.state.state1 ? <p>Allgemeine E-Mail Benachrichtigungen aktivieren</p>: null}  
                  
                          </center>
                         </>
      );
  })} 
                   
               
              
             </Col>
             </Row>
             <Row>
             <Col md="12" style={{margin:'15px'}}>
               <h4>Über Uns</h4>
               <hr></hr>
               <h6 className="title"> <u>Beschreibung</u>  </h6>
               <p className="p_wrap"> {user.hosp_disc}</p>
              
             </Col>
             </Row>
             <Row>
             <Col md="12" style={{margin:'15px'}}>
               <h4>Kontakt</h4>
               <hr></hr>
               <Card style = {{backgroundColor: '#f5f5f5', padding:'10px',margin:'13px'}} >
          <Row>
                            <Col md = '2'>
                            {user.user_profile === null ? null :  <img style={{width:'100px', height:'100px'}}
                       alt="..."
                       className="avatar border-gray"
                        src ={`https://unternehmen.hello-sister.de/uploads/${user.user_profile}`}
                    
                       
                      />}   
                           </Col>
                            
                     <Col md = '5'><h7 className="text-left"   >
                     
                    <b>{user.andre} {user.title === 'Kein' ? null : user.title} {user.f_name} {user.l_name}</b> 
                     
                     <br></br>
                     {user.telephone === null ? null :<><h7 className="text-left" style={{fontSize:'13px'}}><i class="fas fa-mobile-alt"></i> &nbsp;<a href={`tel:${user.telephone}`}>{user.telephone}</a></h7><br></br></>}
                     {user.telephone === null ? null :<><h7 className="text-left" style={{fontSize:'13px'}}><i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${user.telephone}`}target="_blank" rel="noopener noreferrer">{user.telephone}</a></h7><br></br></>}

       
                     <h7 className="text-left" style={{fontSize:'13px'}}><i class="fas fa-envelope-open-text"></i>&nbsp;{user.username}</h7><br></br>
                     <i class="fas fa-address-card"></i>&nbsp;
                     <h7 className="text-left" style = {{ fontSize:'13px' }}>{user.position}</h7>

                     </h7> 
                </Col>
           
                        
                </Row> 
         
             
             </Card> 
              
             </Col>
             </Row>

         
         </Col>



       </Row>

        </CardBody>
               </>
               );
             })}
             
{/* ////////// */}
<CardHeader>
             <h5><b>Jobs</b></h5></CardHeader>
             <CardBody>
             {this.state.postData1}
                    <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick1}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}/>
             </CardBody>
             </Card>

            </Col>
          </Row>
          
           
          ) })} 
      </div>
    );
  }
}

export default HospitalProfile;