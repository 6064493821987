/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// ##############################
// // // data for react-tables in ReactTables view
// #############################

const dataForReactTable = {
  headerRow: ["Name", "Position", "Office", "Age", "Actions"],
  footerRow: ["Name", "Position", "Office", "Age", "Actions"],
  dataRows: [
    ["Tiger Nixon", "System Architect", "Edinburgh", "61"],
    ["Garrett Winters", "Accountant", "Tokyo", "63"],
    ["Ashton Cox", "Junior Technical Author", "San Francisco", "66"],
    ["Cedric Kelly", "Senior Javascript Developer", "Edinburgh", "22"],
    ["Airi Satou", "Accountant", "Tokyo", "33"],
    ["Brielle Williamson", "Integration Specialist", "New York", "61"],
    ["Herrod Chandler", "Sales Assistant", "San Francisco", "59"],
    ["Rhona Davidson", "Integration Specialist", "Tokyo", "55"],
    ["Colleen Hurst", "Javascript Developer", "San Francisco", "39"],
    ["Sonya Frost", "Software Engineer", "Edinburgh", "23"],
    ["Jena Gaines", "Office Manager", "London", "30"],
    ["Quinn Flynn", "Support Lead", "Edinburgh", "22"],
    ["Charde Marshall", "Regional Director", "San Francisco", "36"],
    ["Haley Kennedy", "Senior Marketing Designer", "London", "43"],
    ["Tatyana Fitzpatrick", "Regional Director", "London", "19"],
    ["Michael Silva", "Marketing Designer", "London", "66"],
    ["Paul Byrd", "Chief Financial Officer (CFO)", "New York", "64"],
    ["Gloria Little", "Systems Administrator", "New York", "59"],
    ["Bradley Greer", "Software Engineer", "London", "41"],
    ["Dai Rios", "Personnel Lead", "Edinburgh", "35"],
    ["Jenette Caldwell", "Development Lead", "New York", "30"],
    ["Yuri Berry", "Chief Marketing Officer (CMO)", "New York", "40"],
    ["Caesar Vance", "Pre-Sales Support", "New York", "21"],
    ["Doris Wilder", "Sales Assistant", "Sidney", "23"],
    ["Angelica Ramos", "Chief Executive Officer (CEO)", "London", "47"],
    ["Gavin Joyce", "Developer", "Edinburgh", "42"],
    ["Jennifer Chang", "Regional Director", "Singapore", "28"],
    ["Brenden Wagner", "Software Engineer", "San Francisco", "28"],
    ["Fiona Green", "Chief Operating Officer (COO)", "San Francisco", "48"],
    ["Shou Itou", "Regional Marketing", "Tokyo", "20"],
    ["Michelle House", "Integration Specialist", "Sidney", "37"],
    ["Suki Burks", "Developer", "London", "53"],
    ["Prescott Bartlett", "Technical Author", "London", "27"],
    ["Gavin Cortez", "Team Leader", "San Francisco", "22"],
    ["Martena Mccray", "Post-Sales support", "Edinburgh", "46"],
    ["Unity Butler", "Marketing Designer", "San Francisco", "47"],
    ["Howard Hatfield", "Office Manager", "San Francisco", "51"],
    ["Hope Fuentes", "Secretary", "San Francisco", "41"],
    ["Vivian Harrell", "Financial Controller", "San Francisco", "62"],
    ["Timothy Mooney", "Office Manager", "London", "37"],
    ["Jackson Bradshaw", "Director", "New York", "65"],
    ["Olivia Liang", "Support Engineer", "Singapore", "64"]
  ]
};

// ##############################
// // // data for populating the calendar in Calendar view
// #############################

var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();

const events = [
  {
    title: "All Day Event",
    allDay: true,
    start: new Date(y, m, 1),
    end: new Date(y, m, 1)
  },
  {
    title: "Meeting",
    start: new Date(y, m, d - 1, 10, 30),
    end: new Date(y, m, d - 1, 11, 30),
    allDay: false,
    color: "green"
  },
  {
    title: "Lunch",
    start: new Date(y, m, d + 7, 12, 0),
    end: new Date(y, m, d + 7, 14, 0),
    allDay: false,
    color: "red"
  },
  {
    title: "PD-PRO-REACT Launch",
    start: new Date(y, m, d - 2),
    end: new Date(y, m, d - 2),
    allDay: true,
    color: "azure"
  },
  {
    title: "Birthday Party",
    start: new Date(y, m, d + 1, 19, 0),
    end: new Date(y, m, d + 1, 22, 30),
    allDay: false,
    color: "azure"
  },
  {
    title: "Click for Creative Tim",
    start: new Date(y, m, 21),
    end: new Date(y, m, 22),
    color: "orange"
  },
  {
    title: "Click for Google",
    start: new Date(y, m, 21),
    end: new Date(y, m, 22),
    color: "orange"
  }
];

// ##############################
// // // for vector map row in Dashboard view
// #############################

const us_flag = require("../assets/img/flags/US.png");
const de_flag = require("../assets/img/flags/DE.png");
const au_flag = require("../assets/img/flags/AU.png");
const gb_flag = require("../assets/img/flags/GB.png");
const ro_flag = require("../assets/img/flags/RO.png");
const br_flag = require("../assets/img/flags/BR.png");

const table_data = [
  { flag: us_flag, country: "USA", count: "2.920", percentage: "53.23%" },
  { flag: de_flag, country: "Germany", count: "1.300", percentage: "20.43%" },
  { flag: au_flag, country: "Australia", count: "760", percentage: "10.35%" },
  {
    flag: gb_flag,
    country: "United Kingdom",
    count: "690",
    percentage: "7.87%"
  },
  { flag: ro_flag, country: "Romania", count: "600", percentage: "5.94%" },
  { flag: br_flag, country: "Brasil", count: "550", percentage: "4.34%" }
];

// ##############################
// // // data for constatinopel
// #############################
const admin = [
  {
    //id: "1",
    //firstName: "Eridho",
    //lastName: "Rollian",
    //title: "",
    //salutation: "Herr",
  }
]

// ##############################
// // // data for messages
// #############################

const messages = [
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Eickhoff",
    lastMessage: "Mau apa?"
  },
  {
    name: "Bagus",
    lastMessage: "Tidak mau saya"
  },
  {
    name: "Admin",
    lastMessage: "Tolong ya"
  },
  {
    name: "Yilmaz",
    lastMessage: "Bitte"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
  {
    name: "Jane",
    lastMessage: "Good"
  },
]


// ##############################
// // // data for candidate profile
// #############################

const candidate = [
  {
    internalId: "894KA90",
    salutation: "Herr",
    jobType: "Arzt",
    title: "Dr",
    firstName: "Michelle",
    lastName: "Bachmann",
    email: "mbachmann@gmail.com",
    mobileNummer: "01768887678",
    salary: "200000",
    profileHighlight: "Ich habe in Deutschland studiert und ich bin so gut an der Arbeit",
    workPermit: "Ich bin berechtigt in die Europäische Union zu arbeiten und benötige kein Visum",
    dateBirth: "30.05.1988",
    dateRegistration: "31.03.2019",
    timeRegistration: "20:09",
    isBlocked: false,
    handleJob: 0,
    adresse: [
      {
          city: "Schmelz",
          region: "Saarland",
          country: "Deutschland",
          zipCode: "88798",
          street: "Test Str 46"
      }
    ],
    status: [
      {
        registration: "aktiv",
        status: "aktiv",
        jobWishNotification: "aktiv"
      }
    ],
    qualifications: [
         { 
           name: "Assistenzarzt",
           experience: "2-4"
          },
         { 
           name: "Facharzt",
           experience: "2-4"
          }
        ],
    specializations: [
         { 
           name: "Innere Medizin",
           experience: "6+"
          },
         { 
           name: "Geriatrie",
           experience: "0-1"
          }
        ],
    approbation: [
      {
        available: "Nein",
        dateCreated: "01.02.2005"
      }
    ],
    occupationPermit: [
      {
        available: "Ja",
        dateCreated: "01.01.2004",
        location: "Berlin"
      }
    ],
    languagesExamination: [
      {
        available: "Ja",
        dateCreated: "01.10.2003",
        location: "Baden Württemberg"
      }
    ],
    jobWish: [
      {
        jobType: [
          {
            name: "Arzt"
          },
          {
            name: "Krankenpflege"
          }
        ],
        qualifications: [
          {
            name: "Assistenzarzt"
          },
          {
            name: "Oberarzt"
          },
          {
            name: "Facharzt"
          }
        ],
        specializations: [
          {
            name: "HNO"
          },
          {
            name: "Innere Medizin"
          },
          {
            name: "Allgemeinemedizin"
          }
        ],
        workingTimes: [
          {
            name: "Vollzeit"
          }
        ],
        placeToWork: [
          {
            name: "Leipzig"
          },
          {
            name: "Berlin"
          },
          {
            name: "Koblenz"
          },
          {
            name: "Trier"
          },
          {
            name: "Mannheim"
          },
          {
            name: "Saarbrücken"
          }
        ],
        radius: "200",
        workingTernures:[
          {
            name: "Honorarvertretung"
          }
        ],
        sectorTypes: [
          {
            name: "MVZ"
          },
          {
            name: "Krankenhaus"
          }
      ],
      startDate: "01.01.2020"
      }
    ],
    resume: [
      {
        educations: [
          {
            schoolLevel: "Bachelor",
            institutionName: "Uni Mannheim",
            city: "Mannheim",
            country: "Deutschland",
            detail: "Innere Medizin",
            startMonth: "09",
            startYear: "2005",
            endMonth: "09",
            endYear: "2009"
          }
        ],
        experiences: [
          {
            companyName: "Klinikum Uni Mannheim",
            position: "Asisstenzarzt",
            skill: "Geriatrie",
            city: "Mannheim",
            country: "Deutschland",
            startMonth: "09",
            startYear: "2008",
            endMonth: "09",
            endYear: "2009"
          }
        ],
        languages: [
          {
            name: "Deutsch",
            level: "Muttersprache"
          }
        ]
      }
    ]
  }
]

// ##############################
// // // data for institution profile
// #############################
// const institutionProfile = [
//   {
//     value: "1",
//     id: "1",
//     label: "DRK Krankenhaus Nordlingen",
//     email: "kim.schucker@heartbeatlabs.com",
//     status: "aktiv",
//     type: "Praxis",
//     telefon: "+49 (0) 30 577 025 820",
//     website: "https://www.hystudio.de",
//     adresse: [
//       {
//           city: "Berlin",
//           region: "Berlin",
//           country: "Deutschland",
//           zipCode: "10178",
//           street: "Rosa-Luxemburg-Str. 2"
//       }
//     ],
//     jobs: [
//       {
//         qualification: "Assistenzarzt",
//         speciality: "Innere Medizin",
//         workingTernure: "Festanstellung",
//         workingTime: "Vollzeit",
//         institutionType: "Krankenhaus",
//         city: "Stuttgart",
//         nameInstitut: "KH Ingelheim",
//         dateCreated: "02.01.2019",
//         dateExpired: "02.01.2020",
//         status: "inaktiv"
//       },
//       {
//         qualification: "Oberarzt",
//         speciality: "Tropenmedizin",
//         workingTernure: "Festanstellung",
//         workingTime: "Vollzeit",
//         institutionType: "Krankenhaus",
//         city: "Stuttgart",
//         nameInstitut: "Mainz Institut",
//         dateCreated: "02.02.2019",
//         dateExpired: "02.02.2020",
//         status: "inaktiv"
//       },
//       {
//         qualification: "Chefarzt",
//         speciality: "HNO",
//         workingTernure: "Honorarvertretung",
//         workingTime: "Teilzeit",
//         institutionType: "MVZ",
//         city: "Stuttgart",
//         nameInstitut: "Krankenhaus Borkum",
//         dateCreated: "02.03.2019",
//         dateExpired: "02.03.2020",
//         status: "inaktiv"
//       },
//       {
//         qualification: "Oberazt",
//         speciality: "Chirurgie",
//         workingTernure: "Honorarvertretung",
//         workingTime: "Teilzeit",
//         institutionType: "Krankenhaus",
//         city: "Mannheim",
//         nameInstitut: "Uniklinikum Mannheim",
//         dateCreated: "02.04.2019",
//         dateExpired: "02.04.2020",
//         status: "inaktiv"
//       },
    
//       {
//         qualification: "Assistenzarzt",
//         speciality: "Geriatrie",
//         workingTernure: "Honorarvertretung",
//         workingTime: "Teilzeit",
//         institutionType: "Praxis",
//         city: "Mannheim",
//         nameInstitut: "Winterberg Saarland",
//         dateCreated: "02.05.2019",
//         dateExpired: "02.05.2020",
//         status: "inaktiv"
//       },
    
//       {
//         qualification: "Assistenzarzt",
//         speciality: "Geriatrie",
//         workingTernure: "Honorarvertretung",
//         workingTime: "Teilzeit",
//         institutionType: "Krankenhaus",
//         city: "Saarlouis",
//         nameInstitut: "Klinikum Saarland",
//         dateCreated: "02.06.2019",
//         dateExpired: "02.06.2020",
//         status: "inaktiv"
//       }
//     ]
//   }
// ]

// ##############################
// // // data for create select profile or edit
// #############################

const salutation = [
  {
    id: "0",
    label: "Bitte Auswahlen",
    value: ""
  },
  {
    id: "1",
    label: "Herr",
    value: "H"
  },
  {
    id: "2",
    label: "Frau",
    value: "F"
  }
]

const title = [
  {
    id: "0",
    label: "Bitte Auswahlen",
    value: ""
  },
  {
    id: "1",
    label: "Prof.",
    value: "P"
  },
  {
    id: "2",
    label: "Dr.",
    value: "D"
  },
  {
    id: "3",
    label: "Prof. Dr.",
    value: "PD"
  }
]

const countries = [
  {
      id: "1",
      value: "1",
      label: "Deutschland"
  },
  {
    id: "2",
      value: "2",
      label: "Schweiz"
  },
  {
    id: "3",
      value: "3",
      label: "Luxembourg"
  }
]

const cities = [
    {
        value: "1",
        label: "Saarland"
    },
    {
        value: "2",
        label: "Bayern"
    },
    {
        value: "3",
        label: "Berlin"
    }
]
const citiesLUX = [
    {
        value: "1",
        label: "Wiltz"
    },
    {
        value: "2",
        label: "Radingen"
    },
    {
        value: "3",
        label: "Capellen"
    }
]
const citiesSCH = [
    {
        value: "1",
        label: "Bern"
    },
    {
        value: "2",
        label: "Aargau"
    },
    {
        value: "3",
        label: "St. Gallen"
    }
]

const radius = [
    {
        value: "1",
        label: "50 km"
    },
    {
        value: "2",
        label: "100 km"
    },
    {
        value: "3",
        label: "200 km"
    },
    {
        value: "4",
        label: "500 km"
    }
]

const institutionType = [
  {
    value: "K",
    label: "Krankenhaus"
  },
  {
    value: "MVZ",
    label: "MVZ"
  },
  {
    value: "P",
    label: "Praxis"
  },
  {
    value: "AP",
    label: "Altenheim / Pflegeheim"
  },
]

const qualification = [
  {
    value: "1",
    label: "Assistenzarzt"
  },
  {
    value: "2",
    label: "Facharzt"
  },
  {
    value: "3",
    label: "Oberarzt"
  },
  {
    value: "4",
    label: "Chefarzt"
  }
]
const specialization = [
  {
    value: "1",
    label: "Geriatrie"
  },
  {
    value: "2",
    label: "Innere Medizin"
  }
]
const workingTime = [
  {
    value: "1",
    label: "Vollzeit"
  },
  {
    value: "2",
    label: "Teilzeit"
  }
]
const workingTernure = [
  {
    value: "1",
    label: "Festanstellung"
  }
]

const jobTypes = [
  {
    value: "1",
    label: "Arzt"
  },
  {
    value: "2",
    label: "Krankenpflege"
  },
  {
    value: "3",
    label: "Altenpflege"
  },
  {
    value: "4",
    label: "Erzieher"
  },
  {
    value: "5",
    label: "Ergotherapeut"
  }
]

const jobTypesSister = [
  {
    value: "1",
    label: "Krankenpflege"
  },
  {
    value: "2",
    label: "Altenpflege"
  }
]

const jobTypesTherapeut = [
  {
    value: "1",
    label: "Erzieher"
  },
  {
    value: "2",
    label: "Ergotherapeut"
  }
]

// ##############################
// // // data for showing profile candidates table
// #############################
const candidates_table = [
  {
      id: "1",
      dateRegistration: "22.02.2019",
      status: "aktiv",
      telefonNumber: "+419878808",
      jobType: "Arzt",
      qualification: "Assistenzarzt",
      qualificationExperienced: "2-4",
      title: "Herr",
      firstName: "Michelle",
      lastName: "Bachmann",
      totalAccepted: "3",
      totalRejected: "2",
      totalNotAnswer: "15",
      lastContacted: [
        {
          date: "02.06.2019",
          time: "09:58"
        }
      ],
      specialities: [
          {
              name: "Inner Medizin",
              specialityExperienced: "6+"
          },
          {
              name: "Geriatrie",
              specialityExperienced: "6+"
          }
      ],
      adresse: [
          {
              city: "Schmelz",
              region: "Saarland",
              country: "Deutschland",
              zipCode: "88798",
              street: "Test Str 46"
          }
      ],
      radiusFromInstitut: "45,6",
      workingTime: "Vollzeit",
      workingTernure: "Festanstellung",
      jobWish: [
        {
          salary: "198000"
        }
      ]
  }
  ,
  {
      id: "2",
      dateRegistration: "22.03.2019",
      status: "aktiv",
      telefonNumber: "+4998790808",
      jobType: "Arzt",
      qualification: "Chefarzt",
      qualificationExperienced: "4-6",
      title: "Frau",
      firstName: "Maike",
      lastName: "Kunsmann",
      totalAccepted: "4",
      totalRejected: "2",
      totalNotAnswer: "2",
      lastContacted: [
        {
          date: "02.06.2019",
          time: "09:45"
        }
      ],
      specialities: [
          {
              name: "Inner Medizin",
              specialityExperienced: "6+"
          },
          {
              name: "Geriatrie",
              specialityExperienced: "6+"
          }
      ],
      adresse: [
          {
              city: "Mannheim",
              region: "Saarland",
              country: "Deutschland",
              zipCode: "51477",
              street: "Test Str 46"
          }
      ],
      radiusFromInstitut: "45,6",
      workingTime: "Vollzeit",
      workingTernure: "Festanstellung",
      jobWish: [
        {
          salary: "190000"
        }
      ]
  }
  ,
  {
      id: "3",
      status: "inaktiv",
      telefonNumber: "+319878808",
      dateRegistration: "22.04.2019",
      jobType: "Arzt",
      qualification: "Assistenzarzt",
      qualificationExperienced: "0-1",
      title: "Frau",
      firstName: "Sarah",
      lastName: "Grohe",
      totalAccepted: "",
      totalRejected: "2",
      totalNotAnswer: "19",
      lastContacted: [""],
      specialities: [
          {
              name: "Inner Medizin",
              specialityExperienced: "6+"
          },
          {
              name: "Geriatrie",
              specialityExperienced: "6+"
          }
      ],
      adresse: [
          {
              city: "Stuttgart",
              region: "Saarland",
              country: "Deutschland",
              zipCode: "98777",
              street: "Test Str 46"
          }
      ],
      radiusFromInstitut: "45,6",
      workingTime: "Vollzeit",
      workingTernure: "Festanstellung",
      jobWish: [
        {
          salary: "200000"
        }
      ]
  }
  ,
  {
      id: "4",
      status: "aktiv",
      telefonNumber: "+4977878808",
      dateRegistration: "22.05.2019",
      jobType: "Arzt",
      qualification: "Assistenzarzt",
      qualificationExperienced: "6+",
      title: "Herr",
      firstName: "Jorg",
      lastName: "Dietrich",
      totalAccepted: "",
      totalRejected: "",
      totalNotAnswer: "",
      lastContacted: [""],
      specialities: [
          {
              name: "Inner Medizin",
              specialityExperienced: "6+"
          },
          {
              name: "Geriatrie",
              specialityExperienced: "6+"
          }
      ],
      adresse: [
          {
              city: "Hamburg",
              region: "Saarland",
              country: "Deutschland",
              zipCode: "77887",
              street: "Test Str 46"
          }
      ],
      radiusFromInstitut: "45,6",
      workingTime: "Vollzeit",
      workingTernure: "Festanstellung",
      jobWish: [
        {
          salary: ""
        }
      ]
  }
  ,
  {
      id: "5",
      status: "inaktiv",
      telefonNumber: "+419870008",
      dateRegistration: "22.06.2019",
      jobType: "Arzt",
      qualification: "Assistenzarzt",
      qualificationExperienced: "2-4",
      title: "Herr",
      firstName: "Daniel",
      lastName: "Chen",
      totalAccepted: "",
      totalRejected: "",
      totalNotAnswer: "15",
      lastContacted: [
        {
          date: "02.01.2019",
          time: "11:58"
        }
      ],
      specialities: [
          {
              name: "Inner Medizin",
              specialityExperienced: "6+"
          },
          {
              name: "Geriatrie",
              specialityExperienced: "6+"
          }
      ],
      adresse: [
          {
              city: "Saarbruecken",
              region: "Saarland",
              country: "Deutschland",
              zipCode: "66117",
              street: "Test Str 46"

          }
      ],
      radiusFromInstitut: "45,6",
      workingTime: "Vollzeit",
      workingTernure: "Festanstellung",
      jobWish: [
        {
          salary: "200000"
        }
      ]
  }
]

// ##############################
// // // data for showing contact person
// #############################
const contactPersons= [
  {
    id: "1",
    salutation: "Herr",
    title: "Prof",
    lastName: "Eickhoff",
    firstName: "Haji",
    activationEmail: true,
    handleJob: 2,
    isAktiv: true,
    email: "heick@nn.de",
    telefon: "09887878",
    position: "Personalleiter",
    status: "aktiv",
    institutions: [
      {
        label: "Mariannen-Hospital Werl gGmbH"
      },
      {
        label: "HY STUDIO"
      }
    ],
    institution: [
        {
          value: "1",
          id: "M-1",
          label: "DRK Krankenhaus Nordlingen",
          email: "kim.schucker@heartbeatlabs.com",
          status: "aktiv",
          type: "Praxis",
          telefon: "+49 (0) 30 577 025 820",
          website: "https://www.hystudio.de",
          dateRegistration: "29.08.2019",
          timeRegistration: "12:18",
          adresse: [
            {
                city: "Berlin",
                region: "Berlin",
                country: "Deutschland",
                zipCode: "10178",
                street: "Rosa-Luxemburg-Str. 2"
            }
          ],
          jobs: [
            {
              qualification: "Assistenzarzt",
              speciality: "Innere Medizin",
              workingTernure: "Festanstellung",
              workingTime: "Vollzeit",
              institutionType: "Krankenhaus",
              city: "Stuttgart",
              nameInstitut: "KH Ingelheim",
              dateCreated: "02.01.2019",
              dateExpired: "02.01.2020",
              status: "inaktiv"
            },
            {
              qualification: "Oberarzt",
              speciality: "Tropenmedizin",
              workingTernure: "Festanstellung",
              workingTime: "Vollzeit",
              institutionType: "Krankenhaus",
              city: "Stuttgart",
              nameInstitut: "Mainz Institut",
              dateCreated: "02.02.2019",
              dateExpired: "02.02.2020",
              status: "inaktiv"
            },
            {
              qualification: "Chefarzt",
              speciality: "HNO",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "MVZ",
              city: "Stuttgart",
              nameInstitut: "Krankenhaus Borkum",
              dateCreated: "02.03.2019",
              dateExpired: "02.03.2020",
              status: "inaktiv"
            },
            {
              qualification: "Oberazt",
              speciality: "Chirurgie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Krankenhaus",
              city: "Mannheim",
              nameInstitut: "Uniklinikum Mannheim",
              dateCreated: "02.04.2019",
              dateExpired: "02.04.2020",
              status: "inaktiv"
            },
          
            {
              qualification: "Assistenzarzt",
              speciality: "Geriatrie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Praxis",
              city: "Mannheim",
              nameInstitut: "Winterberg Saarland",
              dateCreated: "02.05.2019",
              dateExpired: "02.05.2020",
              status: "inaktiv"
            },
          
            {
              qualification: "Assistenzarzt",
              speciality: "Geriatrie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Krankenhaus",
              city: "Saarlouis",
              nameInstitut: "Klinikum Saarland",
              dateCreated: "02.06.2019",
              dateExpired: "02.06.2020",
              status: "inaktiv"
            }
          ]
        },
        {
          value: "2",
          id: "M-2",
          label: "KH Ingelheim",
          email: "pmueller@kkel.de",
          status: "aktiv",
          type: "Praxis",
          telefon: "(02043) 278-0",
          website: "",
          dateRegistration: "29.03.2019",
          timeRegistration: "20:18",
          adresse: [
            {
                city: "Gladbeck",
                region: "Nordrhein-Westfalen",
                country: "Deutschland",
                zipCode: "45964",
                street: "Barbarastraße 1"
            }
          ],
          jobs: [
            {
              qualification: "Chefarzt",
              speciality: "Innere Medizin",
              workingTernure: "Festanstellung",
              workingTime: "Vollzeit",
              institutionType: "Krankenhaus",
              city: "Stuttgart",
              nameInstitut: "KH Ingelheim",
              dateCreated: "02.01.2019",
              dateExpired: "02.01.2020",
              status: "inaktiv"
            },
            {
              qualification: "Ltd. Oberarzt",
              speciality: "Tropenmedizin",
              workingTernure: "Festanstellung",
              workingTime: "Vollzeit",
              institutionType: "Krankenhaus",
              city: "Stuttgart",
              nameInstitut: "Mainz Institut",
              dateCreated: "02.02.2019",
              dateExpired: "02.02.2020",
              status: "inaktiv"
            },
            {
              qualification: "Chefarzt",
              speciality: "HNO",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "MVZ",
              city: "Stuttgart",
              nameInstitut: "Krankenhaus Borkum",
              dateCreated: "02.03.2019",
              dateExpired: "02.03.2020",
              status: "inaktiv"
            },
            {
              qualification: "Chefarzt",
              speciality: "Chirurgie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Krankenhaus",
              city: "Mannheim",
              nameInstitut: "Uniklinikum Mannheim",
              dateCreated: "02.04.2019",
              dateExpired: "02.04.2020",
              status: "inaktiv"
            },
          
            {
              qualification: "Assistenzarzt",
              speciality: "Geriatrie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Praxis",
              city: "Mannheim",
              nameInstitut: "Winterberg Saarland",
              dateCreated: "02.05.2019",
              dateExpired: "02.05.2020",
              status: "inaktiv"
            },
          
            {
              qualification: "Assistenzarzt",
              speciality: "Geriatrie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Krankenhaus",
              city: "Saarlouis",
              nameInstitut: "Klinikum Saarland",
              dateCreated: "02.06.2019",
              dateExpired: "02.06.2020",
              status: "inaktiv"
            }
          ]
        },
    ]
  },
  {
    id: "2",
    salutation: "Frau",
    title: "",
    lastName: "Yilmaz",
    firstName: "Hajah",
    email: "hajY@nn.de",
    activationEmail: false,
    handleJob: 0,
    isAktiv: true,
    telefon: "098878978",
    position: "Personalleiter",
    status: "aktiv",
    institutions: [
      {
        label: "HY STUDIO"
      }
    ],
    institution: [
      {
        value: "1",
        id: "M-1",
        label: "DRK Krankenhaus Nordlingen",
        email: "kim.schucker@heartbeatlabs.com",
        status: "aktiv",
        type: "Praxis",
        telefon: "+49 (0) 30 577 025 820",
        website: "https://www.hystudio.de",
        dateRegistration: "29.08.2019",
        timeRegistration: "12:18",
        adresse: [
          {
              city: "Berlin",
              region: "Berlin",
              country: "Deutschland",
              zipCode: "10178",
              street: "Rosa-Luxemburg-Str. 2"
          }
        ],
        jobs: [
          {
            qualification: "Assistenzarzt",
            speciality: "Innere Medizin",
            workingTernure: "Festanstellung",
            workingTime: "Vollzeit",
            institutionType: "Krankenhaus",
            city: "Stuttgart",
            nameInstitut: "KH Ingelheim",
            dateCreated: "02.01.2019",
            dateExpired: "02.01.2020",
            status: "inaktiv"
          },
          {
            qualification: "Oberarzt",
            speciality: "Tropenmedizin",
            workingTernure: "Festanstellung",
            workingTime: "Vollzeit",
            institutionType: "Krankenhaus",
            city: "Stuttgart",
            nameInstitut: "Mainz Institut",
            dateCreated: "02.02.2019",
            dateExpired: "02.02.2020",
            status: "inaktiv"
          },
          {
            qualification: "Chefarzt",
            speciality: "HNO",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "MVZ",
            city: "Stuttgart",
            nameInstitut: "Krankenhaus Borkum",
            dateCreated: "02.03.2019",
            dateExpired: "02.03.2020",
            status: "inaktiv"
          },
          {
            qualification: "Oberazt",
            speciality: "Chirurgie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Krankenhaus",
            city: "Mannheim",
            nameInstitut: "Uniklinikum Mannheim",
            dateCreated: "02.04.2019",
            dateExpired: "02.04.2020",
            status: "inaktiv"
          },
        
          {
            qualification: "Assistenzarzt",
            speciality: "Geriatrie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Praxis",
            city: "Mannheim",
            nameInstitut: "Winterberg Saarland",
            dateCreated: "02.05.2019",
            dateExpired: "02.05.2020",
            status: "inaktiv"
          },
        
          {
            qualification: "Assistenzarzt",
            speciality: "Geriatrie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Krankenhaus",
            city: "Saarlouis",
            nameInstitut: "Klinikum Saarland",
            dateCreated: "02.06.2019",
            dateExpired: "02.06.2020",
            status: "inaktiv"
          }
        ]
      },
      {
        value: "2",
        id: "M-2",
        label: "KH Ingelheim",
        email: "pmueller@kkel.de",
        status: "aktiv",
        type: "Praxis",
        telefon: "(02043) 278-0",
        website: "",
        dateRegistration: "29.03.2019",
        timeRegistration: "20:18",
        adresse: [
          {
              city: "Gladbeck",
              region: "Nordrhein-Westfalen",
              country: "Deutschland",
              zipCode: "45964",
              street: "Barbarastraße 1"
          }
        ],
        jobs: [
          {
            qualification: "Chefarzt",
            speciality: "Innere Medizin",
            workingTernure: "Festanstellung",
            workingTime: "Vollzeit",
            institutionType: "Krankenhaus",
            city: "Stuttgart",
            nameInstitut: "KH Ingelheim",
            dateCreated: "02.01.2019",
            dateExpired: "02.01.2020",
            status: "inaktiv"
          },
          {
            qualification: "Ltd. Oberarzt",
            speciality: "Tropenmedizin",
            workingTernure: "Festanstellung",
            workingTime: "Vollzeit",
            institutionType: "Krankenhaus",
            city: "Stuttgart",
            nameInstitut: "Mainz Institut",
            dateCreated: "02.02.2019",
            dateExpired: "02.02.2020",
            status: "inaktiv"
          },
          {
            qualification: "Chefarzt",
            speciality: "HNO",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "MVZ",
            city: "Stuttgart",
            nameInstitut: "Krankenhaus Borkum",
            dateCreated: "02.03.2019",
            dateExpired: "02.03.2020",
            status: "inaktiv"
          },
          {
            qualification: "Chefarzt",
            speciality: "Chirurgie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Krankenhaus",
            city: "Mannheim",
            nameInstitut: "Uniklinikum Mannheim",
            dateCreated: "02.04.2019",
            dateExpired: "02.04.2020",
            status: "inaktiv"
          },
        
          {
            qualification: "Assistenzarzt",
            speciality: "Geriatrie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Praxis",
            city: "Mannheim",
            nameInstitut: "Winterberg Saarland",
            dateCreated: "02.05.2019",
            dateExpired: "02.05.2020",
            status: "inaktiv"
          },
        
          {
            qualification: "Assistenzarzt",
            speciality: "Geriatrie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Krankenhaus",
            city: "Saarlouis",
            nameInstitut: "Klinikum Saarland",
            dateCreated: "02.06.2019",
            dateExpired: "02.06.2020",
            status: "inaktiv"
          }
        ]
      },
      {
        value: "3",
        id: "M-3",
        label: "HELIOS Frankenwaldklinik Kronach",
        email: "pmueller@kkel.de",
        status: "aktiv",
        type: "Praxis",
        telefon: "(02043) 278-0",
        website: "",
        dateRegistration: "29.03.2019",
        timeRegistration: "20:18",
        adresse: [
          {
              city: "Gladbeck",
              region: "Nordrhein-Westfalen",
              country: "Deutschland",
              zipCode: "45964",
              street: "Barbarastraße 1"
          }
        ],
        jobs: [
          {
            qualification: "Chefarzt",
            speciality: "Innere Medizin",
            workingTernure: "Festanstellung",
            workingTime: "Vollzeit",
            institutionType: "Krankenhaus",
            city: "Stuttgart",
            nameInstitut: "KH Ingelheim",
            dateCreated: "02.01.2019",
            dateExpired: "02.01.2020",
            status: "inaktiv"
          },
          {
            qualification: "Ltd. Oberarzt",
            speciality: "Tropenmedizin",
            workingTernure: "Festanstellung",
            workingTime: "Vollzeit",
            institutionType: "Krankenhaus",
            city: "Stuttgart",
            nameInstitut: "Mainz Institut",
            dateCreated: "02.02.2019",
            dateExpired: "02.02.2020",
            status: "inaktiv"
          },
          {
            qualification: "Chefarzt",
            speciality: "HNO",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "MVZ",
            city: "Stuttgart",
            nameInstitut: "Krankenhaus Borkum",
            dateCreated: "02.03.2019",
            dateExpired: "02.03.2020",
            status: "inaktiv"
          },
          {
            qualification: "Chefarzt",
            speciality: "Chirurgie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Krankenhaus",
            city: "Mannheim",
            nameInstitut: "Uniklinikum Mannheim",
            dateCreated: "02.04.2019",
            dateExpired: "02.04.2020",
            status: "inaktiv"
          },
        
          {
            qualification: "Assistenzarzt",
            speciality: "Geriatrie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Praxis",
            city: "Mannheim",
            nameInstitut: "Winterberg Saarland",
            dateCreated: "02.05.2019",
            dateExpired: "02.05.2020",
            status: "inaktiv"
          },
        
          {
            qualification: "Assistenzarzt",
            speciality: "Geriatrie",
            workingTernure: "Honorarvertretung",
            workingTime: "Teilzeit",
            institutionType: "Krankenhaus",
            city: "Saarlouis",
            nameInstitut: "Klinikum Saarland",
            dateCreated: "02.06.2019",
            dateExpired: "02.06.2020",
            status: "inaktiv"
          }
        ]
      },
  ]
  },
  {
    id: "3",
    salutation: "Frau",
    title: "Dr",
    lastName: "Michael",
    firstName: "Hajah",
    activationEmail: true,
    handleJob: 2,
    isAktiv: false,
    email: "HajM@nn.de",
    telefon: "0988337878",
    position: "Personalleiter",
    status: "gesperrt",
    institutions: [
      {
        label: "Mariannen-Hospital Werl gGmbH"
      }
    ],
    institution: [
        {
          value: "1",
          id: "M-1",
          label: "DRK Krankenhaus Nordlingen",
          email: "kim.schucker@heartbeatlabs.com",
          status: "aktiv",
          type: "Praxis",
          telefon: "+49 (0) 30 577 025 820",
          website: "https://www.hystudio.de",
          dateRegistration: "29.08.2019",
          timeRegistration: "12:18",
          adresse: [
            {
                city: "Berlin",
                region: "Berlin",
                country: "Deutschland",
                zipCode: "10178",
                street: "Rosa-Luxemburg-Str. 2"
            }
          ],
          jobs: [
            {
              qualification: "Assistenzarzt",
              speciality: "Innere Medizin",
              workingTernure: "Festanstellung",
              workingTime: "Vollzeit",
              institutionType: "Krankenhaus",
              city: "Stuttgart",
              nameInstitut: "KH Ingelheim",
              dateCreated: "02.01.2019",
              dateExpired: "02.01.2020",
              status: "inaktiv"
            },
            {
              qualification: "Oberarzt",
              speciality: "Tropenmedizin",
              workingTernure: "Festanstellung",
              workingTime: "Vollzeit",
              institutionType: "Krankenhaus",
              city: "Stuttgart",
              nameInstitut: "Mainz Institut",
              dateCreated: "02.02.2019",
              dateExpired: "02.02.2020",
              status: "inaktiv"
            },
            {
              qualification: "Chefarzt",
              speciality: "HNO",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "MVZ",
              city: "Stuttgart",
              nameInstitut: "Krankenhaus Borkum",
              dateCreated: "02.03.2019",
              dateExpired: "02.03.2020",
              status: "inaktiv"
            },
            {
              qualification: "Oberazt",
              speciality: "Chirurgie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Krankenhaus",
              city: "Mannheim",
              nameInstitut: "Uniklinikum Mannheim",
              dateCreated: "02.04.2019",
              dateExpired: "02.04.2020",
              status: "inaktiv"
            },
          
            {
              qualification: "Assistenzarzt",
              speciality: "Geriatrie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Praxis",
              city: "Mannheim",
              nameInstitut: "Winterberg Saarland",
              dateCreated: "02.05.2019",
              dateExpired: "02.05.2020",
              status: "inaktiv"
            },
          
            {
              qualification: "Assistenzarzt",
              speciality: "Geriatrie",
              workingTernure: "Honorarvertretung",
              workingTime: "Teilzeit",
              institutionType: "Krankenhaus",
              city: "Saarlouis",
              nameInstitut: "Klinikum Saarland",
              dateCreated: "02.06.2019",
              dateExpired: "02.06.2020",
              status: "inaktiv"
            }
          ]
        }
      ]
  },
]

// ##############################
// // // data for showing date of book
// #############################

const dateLogoBooked = [
  {
    startDate: "01.01.2019",
    earliestDateEndContract: "01.01.2020",
    deadlineTermination: "01.10.2019"
  }
]

const dateFirstBookedInstitut = [
  {
    startDate: "01.01.2019",
    earliestDateEndContract: "01.01.2020",
    deadlineTermination: "01.10.2019"
  }
]

const showProfileBooked = [
  {
    startDate: "04.01.2019",
    earliestDateEndContract: "04.01.2020",
    deadlineTermination: "01.10.2019",
    institutionName: "KH Ingelheim"
  }
]

const jobsBooked = [
  {
    startDate: "01.01.2019",
    earliestDateEndContract: "01.01.2021",
    deadlineTermination: "01.10.2019",
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    status: "inaktiv"
  },
  {
    startDate: "03.01.2019",
    earliestDateEndContract: "03.01.2021",
    deadlineTermination: "01.10.2019",
    qualification: "Oberarzt",
    speciality: "Tropenmedizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "Mainz Institut",
    dateCreated: "02.02.2019",
    dateExpired: "02.02.2020",
    status: "inaktiv"
  }
]

const klinikBooked = [
  {
    startDate: "03.01.2019",
    earliestDateEndContract: "04.01.2021",
    deadlineTermination: "01.10.2019",
    name: "KH Ingelheim",

  },
  {
    startDate: "07.01.2019",
    earliestDateEndContract: "07.01.2021",
    deadlineTermination: "01.05.2021",
    name: "DRK Nordlingen",

  },
]


// ##############################
// // // data for showing data of institution
// #############################

const institutions = [
  {
    value: "1",
    id: "1",
    label: "DRK Krankenhaus Nordlingen"
  },
  {
    value: "2",
    id: "2",
    label: "KH Ingelheim"
  },

]

const jobs = [
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    status: "inaktiv",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!",
    qualificationExperienced: "2-4",
    specialityExperienced: "6+",
    adresse: [
      {
        city: "Berlin",
        region: "Berlin",
        country: "Deutschland",
        zipCode: "10178",
        street: "Rosa-Luxemburg-Str. 2"
      }
    ],
    dateStart: "17.09.2019",
    approbation: "Nein",
    occupationPermit: "Ja",
    salary: "90000"
  },

]

const jobsSample = [
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.09.2019",
    status: "aktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.11.2019",
    status: "inaktiv",
    workFlow: "rejected",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.02.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.03.2019",
    status: "inaktiv",
    workFlow: "notAnswer",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.05.2019",
    status: "inaktiv",
    workFlow: "rejected",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.04.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.08.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.01.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.12.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.01.2019",
    status: "inaktiv",
    workFlow: "rejected",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "09.02.2019",
    status: "inaktiv",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "29.09.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "22.09.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "20.09.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "18.09.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "10.09.2019",
    status: "inaktiv",
    workFlow: "rejected",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },
  {
    qualification: "Assistenzarzt",
    speciality: "Innere Medizin",
    workingTernure: "Festanstellung",
    workingTime: "Vollzeit",
    institutionType: "Krankenhaus",
    city: "Stuttgart",
    nameInstitut: "KH Ingelheim",
    dateCreated: "02.01.2019",
    dateExpired: "02.01.2020",
    lastDateAction: "12.09.2019",
    status: "inaktiv",
    workFlow: "accepted",
    jobDescription: "Du willst Dir neben Deinem Studium bei flexibler Zeiteinteilung etwas dazu verdienen und gleichzeitig Dein Business-Netzwerk aufbauen? Hello-Medics bietet Dir deutschlandweit attraktive und gut bezahlte Werkstudententätigkeiten bei namhaften Arbeitgebern an. Jetzt bewerben und wertvolle Einblicke in Deine Wunschbranche erhalten!"
  },

]

const institutionProfileInvoice = [ 
  {
  value: "1",
  id: "M-1",
  isVideo: false,
  isPhoto: true,
  label: "DRK Kkell GMBH",
  email: "kim.schucker@heartbeatlabs.com",
  status: "aktiv",
  type: "Praxis",
  telefon: "+49 (0) 30 577 025 820",
  website: "https://www.hystudio.de",
  dateRegistration: "29.08.2019",
  timeRegistration: "12:18",
  adresse: [
    {
        city: "Berlin",
        region: "Berlin",
        country: "Deutschland",
        zipCode: "10178",
        street: "Rosa-Luxemburg-Str. 2"
    }
  ],
  invoice: [
    {
      label: "Ultimate",
      startDate: "01.01.2018",
      earliestDateEndContract:"01.09.2018",
      deadlineTermination: "01.01.2019"
    }
  ]

},
  {
  value: "2",
  id: "M-3",
  isVideo: false,
  isPhoto: true,
  label: "MHK St. Josef Bendorf",
  email: "angela.zylla@marienhaus.de",
  status: "aktiv",
  type: "Krankenhaus",
  telefon: "02622 708-4474",
  website: "",
  dateRegistration: "23.09.2019",
  timeRegistration: "14:52",
  adresse: [
    {
        city: "Bendorf",
        region: "Rheinland-Pfalz",
        country: "Deutschland",
        zipCode: "56170",
        street: "Magaretha-Flesch-Platz 1"
    }
  ],
  invoice: [
    {
      label: "Flatrate",
      startDate: "01.01.2018",
      earliestDateEndContract:"01.09.2018",
      deadlineTermination: "01.01.2019"
    }
  ]

}
]

const jobsFromEachInstitution= [
  {
    value: "1",
    id: "M-1",
    isVideo: false,
    isPhoto: true,
    label: "DRK Krankenhaus Nordlingen",
    email: "kim.schucker@heartbeatlabs.com",
    status: "aktiv",
    type: "Praxis",
    telefon: "+49 (0) 30 577 025 820",
    website: "https://www.hystudio.de",
    dateRegistration: "29.08.2019",
    timeRegistration: "12:18",
    products: [
      {
        label: "Ultimate",
        logo: false,
        topJobs: false,
        adsProfile: true,
        quantityInstitution: 2
      }
    ],
    institutionDescription: "Nordlingen ist Europas führender privater Krankenhausbetreiber mit insgesamt rund 100.000 Mitarbeitern. Zum Unternehmen gehören unter dem Dach der Holding Helios Health die Helios Kliniken in Deutschland und Quirónsalud in Spanien. Rund 19 Millionen Patienten entscheiden sich jährlich für eine medizinische Behandlung bei Helios. 2018 erzielte das Unternehmen in beiden Ländern einen Gesamtumsatz von 9 Milliarden Euro.",
    contactPersons: [
      {
        id: "1",
        salutation: "Herr",
        title: "Prof",
        lastName: "Eickhoff",
        firstName: "Haji",
        email: "heick@nn.de",
        telefon: "09887878",
        position: "Personalleiter",
        status: "aktiv",
        activationEmail: true,
        handleJob: 2,
        isAktiv: false,
        institutions: [
          {
            label: "Mariannen-Hospital Werl gGmbH"
          },
          {
            label: "HY STUDIO"
          }
        ],
      },
      {
        id: "2",
        salutation: "Frau",
        title: "",
        lastName: "Yilmaz",
        firstName: "Hajah",
        email: "hajY@nn.de",
        telefon: "098878978",
        position: "Personalleiter",
        status: "aktiv",
        activationEmail: false,
        handleJob: 0,
        isAktiv: false,
        institutions: [
          {
            label: "Mariannen-Hospital Werl gGmbH"
          },
          {
            label: "HY STUDIO"
          }
        ],
      },
      {
        id: "3",
        salutation: "Frau",
        title: "Dr",
        lastName: "Michael",
        firstName: "Hajah",
        email: "HajM@nn.de",
        telefon: "0988337878",
        position: "Personalleiter",
        status: "gesperrt",
        activationEmail: false,
        handleJob: 2,
        isAktiv: true,
        institutions: [
          {
            label: "Mariannen-Hospital Werl gGmbH"
          },
          {
            label: "HY STUDIO"
          }
        ],
      },
    ],
    adresse: [
      {
          city: "Berlin",
          region: "Berlin",
          country: "Deutschland",
          zipCode: "10178",
          street: "Rosa-Luxemburg-Str. 2"
      }
    ],
    jobs: [
      {
        qualification: "Assistenzarzt",
        speciality: "Innere Medizin",
        workingTernure: "Festanstellung",
        workingTime: "Vollzeit",
        institutionType: "Krankenhaus",
        city: "Stuttgart",
        nameInstitut: "KH Ingelheim",
        dateCreated: "02.01.2019",
        dateExpired: "02.01.2020",
        status: "inaktiv"
      },
      {
        qualification: "Oberarzt",
        speciality: "Tropenmedizin",
        workingTernure: "Festanstellung",
        workingTime: "Vollzeit",
        institutionType: "Krankenhaus",
        city: "Stuttgart",
        nameInstitut: "Mainz Institut",
        dateCreated: "02.02.2019",
        dateExpired: "02.02.2020",
        status: "inaktiv"
      },
      {
        qualification: "Chefarzt",
        speciality: "HNO",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "MVZ",
        city: "Stuttgart",
        nameInstitut: "Krankenhaus Borkum",
        dateCreated: "02.03.2019",
        dateExpired: "02.03.2020",
        status: "inaktiv"
      },
      {
        qualification: "Oberazt",
        speciality: "Chirurgie",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "Krankenhaus",
        city: "Mannheim",
        nameInstitut: "Uniklinikum Mannheim",
        dateCreated: "02.04.2019",
        dateExpired: "02.04.2020",
        status: "inaktiv"
      },
    
      {
        qualification: "Assistenzarzt",
        speciality: "Geriatrie",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "Praxis",
        city: "Mannheim",
        nameInstitut: "Winterberg Saarland",
        dateCreated: "02.05.2019",
        dateExpired: "02.05.2020",
        status: "inaktiv"
      },
    
      {
        qualification: "Assistenzarzt",
        speciality: "Geriatrie",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "Krankenhaus",
        city: "Saarlouis",
        nameInstitut: "Klinikum Saarland",
        dateCreated: "02.06.2019",
        dateExpired: "02.06.2020",
        status: "inaktiv"
      }
    ]
  },
  {
    value: "2",
    id: "M-2",
    isVideo: true,
    isPhoto: true,
    label: "KH Ingelheim",
    email: "pmueller@kkel.de",
    status: "aktiv",
    type: "Praxis",
    telefon: "(02043) 278-0",
    website: "",
    dateRegistration: "29.03.2019",
    timeRegistration: "20:18",
    products: [
      {
        label: "Flatrate",
        logo: true,
        topJobs: true,
        adsProfile: false,
        quantityInstitution: 0
      }
    ],
    institutionDescription: "Ingelheim ist Europas führender privater Krankenhausbetreiber mit insgesamt rund 100.000 Mitarbeitern. Zum Unternehmen gehören unter dem Dach der Holding Helios Health die Helios Kliniken in Deutschland und Quirónsalud in Spanien. Rund 19 Millionen Patienten entscheiden sich jährlich für eine medizinische Behandlung bei Helios. 2018 erzielte das Unternehmen in beiden Ländern einen Gesamtumsatz von 9 Milliarden Euro.",
    contactPersons: [
      {
        id: "1",
        salutation: "Herr",
        title: "Prof",
        lastName: "Eickhoff",
        firstName: "Haji",
        email: "heick@nn.de",
        telefon: "09887878",
        position: "Personalleiter",
        status: "aktiv",
        institutions: [
          {
            label: "Mariannen-Hospital Werl gGmbH"
          },
          {
            label: "HY STUDIO"
          }
        ],
      },
      {
        id: "2",
        salutation: "Frau",
        title: "",
        lastName: "Yilmaz",
        firstName: "Hajah",
        email: "hajY@nn.de",
        telefon: "098878978",
        position: "Personalleiter",
        status: "aktiv",
        institutions: [
          {
            label: "Mariannen-Hospital Werl gGmbH"
          },
          {
            label: "HY STUDIO"
          }
        ],
      }
    ],
    adresse: [
      {
          city: "Gladbeck",
          region: "Nordrhein-Westfalen",
          country: "Deutschland",
          zipCode: "45964",
          street: "Barbarastraße 1",
          
      }
    ],
    jobs: [
      {
        qualification: "Chefarzt",
        speciality: "Innere Medizin",
        workingTernure: "Festanstellung",
        workingTime: "Vollzeit",
        institutionType: "Krankenhaus",
        city: "Stuttgart",
        nameInstitut: "KH Ingelheim",
        dateCreated: "02.01.2019",
        dateExpired: "02.01.2020",
        status: "inaktiv"
      },
      {
        qualification: "Ltd. Oberarzt",
        speciality: "Tropenmedizin",
        workingTernure: "Festanstellung",
        workingTime: "Vollzeit",
        institutionType: "Krankenhaus",
        city: "Stuttgart",
        nameInstitut: "Mainz Institut",
        dateCreated: "02.02.2019",
        dateExpired: "02.02.2020",
        status: "inaktiv"
      },
      {
        qualification: "Chefarzt",
        speciality: "HNO",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "MVZ",
        city: "Stuttgart",
        nameInstitut: "Krankenhaus Borkum",
        dateCreated: "02.03.2019",
        dateExpired: "02.03.2020",
        status: "inaktiv"
      },
      {
        qualification: "Chefarzt",
        speciality: "Chirurgie",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "Krankenhaus",
        city: "Mannheim",
        nameInstitut: "Uniklinikum Mannheim",
        dateCreated: "02.04.2019",
        dateExpired: "02.04.2020",
        status: "inaktiv"
      },
    
      {
        qualification: "Assistenzarzt",
        speciality: "Geriatrie",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "Praxis",
        city: "Mannheim",
        nameInstitut: "Winterberg Saarland",
        dateCreated: "02.05.2019",
        dateExpired: "02.05.2020",
        status: "inaktiv"
      },
    
      {
        qualification: "Assistenzarzt",
        speciality: "Geriatrie",
        workingTernure: "Honorarvertretung",
        workingTime: "Teilzeit",
        institutionType: "Krankenhaus",
        city: "Saarlouis",
        nameInstitut: "Klinikum Saarland",
        dateCreated: "02.06.2019",
        dateExpired: "02.06.2020",
        status: "inaktiv"
      }
    ]
  },
]

// ##############################
// // // data for showing data of invoice
// #############################
const mainInstitution = [
  {
    value: "0",
    id: "0",
    label: "Bitte auswählen"
  },
  {
    value: "1",
    id: "1",
    label: "DRK Krankenhaus Nordlingen",
    contactInformation: [
      {
        id: "M-1",
        name: "DRK Krankenhaus Nordlingen",
        institutionsType: "Krankenhaus",
        email: "info@nordlingen.de",
        telefon: "09898777",
        website: "www.nordlingen.de",
        dateRegistration: "29.08.2019",
        timeRegistration: "12:18",
        adresse: [
          {
          street: "test-1 str 40",
          city: "saarlouis",
          zipCode: "66117",
          region: "bayern",
          country: "deutschland"
        }
      ],
      positionNumber: "KN-01920",
      dateRegistrtaion: "29.06.2017"
      }
    ],
    dateFirstBookedInstitut: [
      {
        startDate: "01.01.2019",
        earliestDateEndContract: "10.01.2020",
        deadlineTermination: "01.10.2019"
      }
    ],
    dataInvoice: [
      {
        contractType: "Basic",
        customerNumber: "KJAVKSC90",
        invoiceFile: [
          {
            id: "1",
            invoiceDate: "15.10.2019",
            dueDate: "22.10.2019",
            total: "1089"
          },
          {
            id: "2",
            invoiceDate: "15.09.2019",
            dueDate: "22.09.2019",
            total: "1089"
          },
          {
            id: "3",
            invoiceDate: "15.08.2019",
            dueDate: "22.08.2019",
            total: "1089"
          },
          {
            id: "4",
            invoiceDate: "15.07.2019",
            dueDate: "22.07.2019",
            total: "1089"
          },
          {
            id: "5",
            invoiceDate: "15.06.2019",
            dueDate: "22.06.2019",
            total: "1089"
          },
          {
            id: "6",
            invoiceDate: "15.05.2019",
            dueDate: "22.05.2019",
            total: "1089"
          }
        ]
      }
    ]
  },
  {
    value: "2",
    id: "2",
    label: "Mariannen-Hospital Werl gGmbH",
    contactInformation: [
      {
        id: "M-2",
        name: "Mariannen-Hospital Werl gGmbH",
        institutionsType: "MVZ",
        email: "info@Werl.de",
        telefon: "09898797",
        website: "www.Werl.de",
        dateRegistration: "29.08.2019",
  timeRegistration: "12:18",
        adresse: [
          {
          street: "test-2 str 40",
          city: "saarland",
          zipCode: "66119",
          region: "heidelberg",
          country: "deutschland"
        }
      ],
        positionNumber: "HW-09271",
        dateRegistrtaion: "30.06.2017"
      }
    ],
    dateFirstBookedInstitut: [
      {
        startDate: "01.01.2019",
        earliestDateEndContract: "01.01.2020",
        deadlineTermination: "20.10.2019"
      }
    ],
    dataInvoice: [
      {
        contractType: "Flatrate",
        customerNumber: "JJAKIS80",
        invoiceFile: [
          {
            id: "1",
            invoiceDate: "20.10.2019",
            dueDate: "14.10.2019",
            total: "2999"
          },
          {
            id: "2",
            invoiceDate: "15.09.2019",
            dueDate: "22.09.2019",
            total: "2999"
          },
          {
            id: "3",
            invoiceDate: "15.08.2019",
            dueDate: "22.08.2019",
            total: "2999"
          },
          {
            id: "4",
            invoiceDate: "15.07.2019",
            dueDate: "22.07.2019",
            total: "2999"
          },
          {
            id: "5",
            invoiceDate: "15.06.2019",
            dueDate: "22.06.2019",
            total: "2999"
          },
          {
            id: "6",
            invoiceDate: "15.05.2019",
            dueDate: "22.05.2019",
            total: "2999"
          }
        ]
      }
    ]
  }
]


const mainProducts = [
  {
    value: "1",
    label: "Basic"
  },
  {
    value: "2",
    label: "Flatrate"
  },
  {
    value: "3",
    label: "Ultimate"
  },
  {
    value: "4",
    label: "Enterprise"
  }
]
const optionProducts = [
  {
    value: "1",
    label: "Logo"
  },
  {
    value: "2",
    label: "Top Jobs"
  },
  {
    value: "3",
    label: "Mehr Einrichtung"
  },
  {
    value: "4",
    label: "Anbieterprofil"
  }
]


const dataInvoice = [
  {
    contractType: "Basic",
    customerNumber: "KJAVKSC90",
    invoiceFile: [
      {
        id: "1",
        invoiceDate: "15.10.2019",
        dueDate: "22.10.2019",
        total: "1089"
      },
      {
        id: "2",
        invoiceDate: "15.09.2019",
        dueDate: "22.09.2019",
        total: "1089"
      },
      {
        id: "3",
        invoiceDate: "15.08.2019",
        dueDate: "22.08.2019",
        total: "1089"
      },
      {
        id: "4",
        invoiceDate: "15.07.2019",
        dueDate: "22.07.2019",
        total: "1089"
      },
      {
        id: "5",
        invoiceDate: "15.06.2019",
        dueDate: "22.06.2019",
        total: "1089"
      },
      {
        id: "6",
        invoiceDate: "15.05.2019",
        dueDate: "22.05.2019",
        total: "1089"
      }
    ]
  }
]

const tableMonthInvoice = [
  {
    actualDate: "Januar 2019",
    dataInvoice: [
      {
        nameInstitut: "KH Ingelheim",
        dueDate: "21.01.2019",
        total: "982"
      },
      {
        nameInstitut: "HY Studio",
        dueDate: "21.01.2019",
        total: "2999"
      },
      {
        nameInstitut: "Krankenhaus Berlin",
        dueDate: "21.01.2019",
        total: "3999"
      },
      {
        nameInstitut: "KH Stuttgart",
        dueDate: "21.01.2019",
        total: "8999"
      },
      {
        nameInstitut: "Praxis Frankfurt",
        dueDate: "21.01.2019",
        total: "888"
      },
      {
        nameInstitut: "MVZ Hamburg",
        dueDate: "21.01.2019",
        total: "7999"
      },
    ]
  },
  {
    actualDate: "Februar 2019",
    dataInvoice: [
      {
        nameInstitut: "KH Ingelheim",
        dueDate: "21.01.2019",
        total: "982"
      },
      {
        nameInstitut: "HY Studio",
        dueDate: "21.01.2019",
        total: "2999"
      },
      {
        nameInstitut: "Krankenhaus Berlin",
        dueDate: "21.01.2019",
        total: "3999"
      },
      {
        nameInstitut: "KH Stuttgart",
        dueDate: "21.01.2019",
        total: "8999"
      },
    ]
  }
]

// ##############################
// // // data for showing data of invoice
// #############################
const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
  "Juli", "August", "September", "Oktober", "November", "Dezember"
];

// ##############################
// // // data for showing data top dashboard
// #############################

const dataTopJobWish = [
  {
    place: "Stuttgart",
    jobType: "Arzt",
    qualification: "Oberarzt",
    specialzation: "Innere Medizin",
    total: "981",
    precentage: "47,8"
  },
  {
    place: "Schmelz",
    jobType: "Arzt",
    qualification: "Chefarzt",
    specialzation: "Innere Medizin",
    total: "232",
    precentage: "27,8"
  },
  {
    place: "Bonn",
    jobType: "Arzt",
    qualification: "Assistenzarzt",
    specialzation: "Innere Medizin",
    total: "52",
    precentage: "17,8"
  },
  {
    place: "Mannheim",
    jobType: "Arzt",
    qualification: "Oberarzt",
    specialzation: "HNO",
    total: "20",
    precentage: "7,8"
  },
  {
    place: "Leipzig",
    jobType: "Arzt",
    qualification: "Oberarzt",
    specialzation: "Innere Medizin",
    total: "981",
    precentage: "2,8"
  }
]

const dataTopJobs = [
  {
    place: "Berlin",
    jobType: "Arzt",
    qualification: "Chefarzt",
    specialzation: "Tropenmedizin",
    total: "52",
    precentage: "42,8"
  },
  {
    place: "Saarlouis",
    jobType: "Arzt",
    qualification: "Chefarzt",
    specialzation: "Innere Medizin",
    total: "22",
    precentage: "17,8"
  },
  {
    place: "Bochum",
    jobType: "Arzt",
    qualification: "Assistenzarzt",
    20: "Innere Medizin",
    total: "12",
    precentage: "7,8"
  },
  {
    place: "Frankfurt",
    jobType: "Arzt",
    qualification: "Oberarzt",
    specialzation: "HNO",
    total: "10",
    precentage: "2,8"
  },
  {
    place: "Leipzig",
    jobType: "Arzt",
    qualification: "Oberarzt",
    specialzation: "Innere Medizin",
    total: "5",
    precentage: "0,8"
  }
]

export { dataForReactTable, events, table_data, 
          // for admin
          admin,
          // for message
          messages,
          //for candidates
          candidate, 
          //for select
          salutation, title, countries, cities, citiesLUX, citiesSCH, radius, qualification, specialization,
          workingTime, workingTernure, jobTypesSister, jobTypesTherapeut, jobTypes, institutionType,
          //for table
          candidates_table,
          //for contact person 
          contactPersons,
          // for date booked
          dateLogoBooked, dateFirstBookedInstitut, showProfileBooked, jobsBooked, klinikBooked,
          // for institution
          institutions, jobs,
          // for Job List
          jobsFromEachInstitution,
          // for institution invoice
          institutionProfileInvoice,
          // for data invoice
          dataInvoice,
          // for month name
          monthNames,
          // jobs sample
          jobsSample,
          // top dashboard
          dataTopJobWish, dataTopJobs,
          // products
          mainProducts, optionProducts,
          //main institution
          mainInstitution,
          //table month invoice
          tableMonthInvoice
         };
