import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Notification Context
const NotificationContext = createContext();

export function useNotifications() {
    return useContext(NotificationContext);
}

export function NotificationProvider({ children }) {
    const [notifications, setNotifications] = useState([]);

    async function fetchNotifications() {
        try {
            const response = await axios.get('/api2/getupdates');
            setNotifications(response.data);
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }

    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <NotificationContext.Provider value={{ notifications, fetchNotifications }}>
            {children}
        </NotificationContext.Provider>
    );
}