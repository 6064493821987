  import React from 'react'

  //import { Link } from "react-router-dom";
  // reactstrap components
  import {
      Row,
      Col,
      Button,
      Card,
      CardBody,

      Container
    } from "reactstrap";

  // import Ternure from "@material-ui/icons/BusinessCenter";
  // import Time from "@material-ui/icons/Timer";

  const LogoBooked = ({item}) => {
      return (
          <Container>
          <Row>
            <Col md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="img"
                  src={require("assets/img/screenshot/laptop-basic.png")}
                  width="100px"
                />
                
              </div>
              <Card>
                <CardBody>
                  <Container>
                  <Row>
                    <Col md="7">
                      <b>
                    Vertragsbeginn:
                      </b>
                    </Col>
                    <Col md="5">
                    {item.startDate}
                    </Col>
                    <br/>
                    <Col md="7">
                      <b>
                    Frühestmögliches Vertragsende:
                      </b>
                    </Col>
                    <Col md="5">
                    {item.earliestDateEndContract}
                    </Col>
                    <br/>
                    <Col md="7">
                      <b>
                      Letztmöglicher Kündigungseingang bis:
                      </b>
                    </Col>
                    <Col md="5">
                    {item.deadlineTermination}
                    </Col>
                  </Row>
                  </Container>
                  
                  
                </CardBody>
              </Card>
              <Row>
                    <Col className="">
                    <Button href='src/assets/img/invoice-example.png' download color="primary">
                          Rechnung drucken
                        </Button>
                    </Col>
                  </Row>
            </Col>
          </Row>
        </Container>
      )
  }

  export default LogoBooked