import React from "react";
import axios from 'axios';
import {Redirect } from "react-router-dom";
// react plugin used to create DropdownMenu for selecting items
//import Select from "react-select";
import { Link } from "react-router-dom";
//import {jsPDF} from "jspdf";
import Ternure from "@material-ui/icons/BusinessCenter";
// import Time from "@material-ui/icons/Timer";
import TypeMedical from "@material-ui/icons/LocationCity";
import City from "@material-ui/icons/MapOutlined";
// import Ternure from "@material-ui/icons/BusinessCenter";
import Time from "@material-ui/icons/Timer";
import {
  Card,
  CardHeader,
  CardBody,
  //CardTitle,
  //CardFooter,
  //FormGroup,
  Row,
  Col,
  Table,
  Button,
  //UncontrolledTooltip,
  Badge
} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      horizontalTabs: "HelloMatch",
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: true,
      visible7: true,
      visible8: true,
      visible9: true,
      visible10: true,
      visible11: true,
      visible12: true,
      visible13: true,
      visible14: true,
      visible15: true,
      visible16: true,
      visible17: true,
      visible18: true,
      visible19: true,
      visible20: true,
      visible23: true,
      visible41: true,
      visible42: true,
      visible43: true,
      visible44: true,
      visible45: true,
      visible46: true,
      candidatelist : [],
      candidatelist1 : [],
      app_sign : null,
      app_sign_city : null,
      app_sign_date : null
    };
  }
  // componentWillMount(){
  //   const user_id = JSON.parse(localStorage.getItem('user_id'))
  // this.setState({user_id})
  // }
  componentDidMount(){
    console.log(this.props.match.params)
    const { hosp_id } = this.props.match.params;
   console.log(hosp_id)
   const { job_id } = this.props.match.params;
   console.log(job_id)
   this.setState({job_id})
    //console.log(this.state);
    axios.post("/api2/InterestedCandidates1", {...this.state,job_id,hosp_id})
   .then(response => { // then print response status
    // const msg = response.data.Message;
    // this.setState({msg});
    console.log(response)
    const candidatelist1 = response.data;
     localStorage.setItem('candidatelist1', JSON.stringify(this.state.candidatelist1));
      this.setState({candidatelist1});
      console.log(candidatelist1)
//      if(this.state.candidatelist.job_field === 'Altenpflege'||'Therapeut'||
//      'Medizintechnik'||'Rettungsdienst'||'Praxispersonal'||'Pharmazie')
//      {
//       this.setState({visible6:false});
//      }
//      else {
//       this.setState({visible6:true});
//      }
// /////////////
//      const jobField = candidatelist[0].job_field;
//      this.setState({jobField})
//      console.log({jobField})

 })
 .catch(error => {
  console.log(error);
})
////////////////////////////////////////////////////////////////////////
axios.post("/api2/jobcompany", {...this.state,job_id,hosp_id})
   .then(response => { // then print response status
    // const msg = response.data.Message;
    // this.setState({msg});
    console.log(response)
    const candidatelist = response.data;
    localStorage.setItem('candidatelist', JSON.stringify(this.state.candidatelist));
     this.setState({candidatelist});
 })
 .catch(error => {
  console.log(error);
})
  }
candidateprofileHandler (appid,candidateStatus, e){
    e.preventDefault();
    this.setState({appid})
    this.setState({candidateStatus})
console.log("app id",appid,this.state.job_id,candidateStatus)
    if(candidateStatus === "absage" || candidateStatus === "Einstellungszusage"){
      console.log("route 1 works")
      this.setState({redirect : true})
    }else if (candidateStatus === "Beworben"){
      console.log("route 2 works")
      this.setState({redirect1 : true})
    }else if (candidateStatus === "Bewerbungsprozess"){
      console.log("route 3 works")
      this.setState({redirect2 : true})
    }
    //const { company_name, person_name} = this.state
      // console.log("update email",userid)
      // axios
      // .post('/api2/sendupdatemail', {...this.state,userid})
      // .then(response => {
      //   console.log(response);
      //   const msg = "Sie lehnen diese Anfrage ab";
      //     this.setState({msg})
      //   window.location.reload();
      // })
      // .catch(error => {
      //   console.log(error.response);
      // })
   }
  render() {
    const { redirect } = this.state;
  
       if (redirect) {
         return <Redirect to={`/admin/Status-Kandidaten-Search/${this.state.appid}/${this.state.job_id}`}/>;
       }
       const { redirect1 } = this.state;
  
       if (redirect1) {
         return <Redirect to={`/admin/candidate-profile/${this.state.job_id}/${this.state.appid}`}/>;
       }
       const { redirect2 } = this.state;
       if (redirect2) {
         return <Redirect to={`/admin/candidate-profile-status/${this.state.job_id}/${this.state.appid}`}/>;
       }
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                <h4 className="title">job</h4>
               <br></br>
               {this.state.candidatelist.map((item) => {
                          return (
                            <> 
               <Card key = {item.job_id}>
                          <CardBody>
                           <Row>
                            <Col md = "1.5">
                            <img src ={`../uploads/${item.logo}`} alt='' height = '100' width = '150' />
                            </Col>
                            <Col>
                           
                            <h5 className = "title" style = {{fontSize: '25px'}} >
                        
                     {item.company_name}</h5>
                    <h5>
                          {item.address} {item.haus_no}, <br></br>
                          {item.zip_code} {item.city} </h5>
                     
                
                <h5 style = {{fontSize: '20px'}}>
                
                {item.job_function} (m/w/d) {item.profession === 'undefined' ? null : item.profession}
               
                </h5>
                </Col>
                  </Row>
              
                  <Row>
                  <Col md="3">
                    Status 
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    <Badge className="mr-1" color="success" pill>
                          {item.job_status}
                          </Badge> 
                          </h5>
                    

                    </Col>
                    <Col md="2">
                    <Ternure />
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    {item.job_ternure}
                    </h5>
                    
                    </Col>
                    
                    <Col md="2">
                    <Time />
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    {item.job_worktime} 
                    </h5>
                    

                    </Col>

                    <Col md="2">
                    <TypeMedical />
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    {item.city}
                    </h5>
                    

                    </Col>
                    
                    
                    <Col md="3">
                    <City />
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    Laufzeit bis: {item.valid_till} 
                    </h5>
                    
                    </Col>

                    
                </Row>
              
                  </CardBody>
             </Card> 
             </>
                      
                      )
                    })} 
                </CardHeader>
                <CardHeader>
                  <h4 className="title">Kandidaten für die Stellenanzeige</h4>
                  <hr></hr>
                </CardHeader>
                <Card>
                <CardHeader>
                  <Row>
                    <Col md = '10'>
                    <h3>
                    <Link to="/admin/hello-search-package" >{this.state.err1}</Link>
                  </h3>
                    </Col>
                    {/* <Col>
                    <Link to = {"/admin/save-search"}>
                    <b>
                    Suche Speichern
                    </b>
                    </Link>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">VOM</th>
                        <th className="text-left">KANDIDATEN</th>
                        {/* <th className="text-left">Job ID</th> */}
                        <th className="text-left">BERUF & KOMPETENZEN	</th>
                        <th className="text-left">STATUS</th>
                        {/* <th className="text-left">DATENSCHUTZ</th> */}
                        <th className="text-right">AKTIONEN</th>
                      </tr>
                    </thead>
                    <tbody>
                   
                    
                              {this.state.candidatelist1.map((contact) => {
                          return (
                            <> 
                              <tr>
                             <td style={{fontSize:'12px'}}>
                            <medium className="text-italic">
                            {contact.applied_date} 
                            </medium>
                            </td> 
                            <td>
                      
                          <div className="text-left" style={{fontSize: '12px'}}> 
                          {contact.title === 'Kein' ? null : contact.title} 
                          {/* {contact.salutation}  */}
                          {contact.f_name} {contact.l_name}
                            <br></br>
                        {contact.job_field}
                        </div>
                      
                        </td>
                        <td className="text-left"  style={{fontSize: '15px'}}>
                         <Badge className="mr-1" color="primary" pill> 
                        {contact.s_job_function} <br></br> {contact.s_profession} <br></br>{contact.s_profession1}<br></br> {contact.s_profession2}
                         </Badge> 
            <br/>
            <Badge className="mr-1" color="primary" pill>
                        {contact.name}
            </Badge>
                                    </td>
                                    <td className="text-left"  style={{fontSize: '15px'}}>
                        <Badge className="mr-1" color="success" pill>
                          {contact.hospital_status} AM {contact.action_date}
                          </Badge>
                          <br></br>
                      
                        </td>
                        {/* <td className="text-left"  style={{fontSize: '12px'}}>
                        <p>Bei einer Absage des Bewerber oder einer Beendigung des Bewerbungsverfahrens
aus anderen Gründen verpflichten Sie sich, sämtliche Kontaktdaten und
Unterlagen des Bewerbers unverzüglich und ordnungsgemäß zu löschen.</p>
                          <br></br>
                      
                        </td> */}
                        <td className="text-right">
                      
                         <Link to={`/admin/detail-kandidate/${contact.app_id}`}>
                          <Button
                           style={{fontSize: '12px'}} 
                            color="info"
                            type="button"
                            //onClick={this.candidateprofileHandler.bind(this, contact.app_id,contact.candidate_status)}
                          >  Kandidate profil
                          </Button>
                        </Link>
                        </td> 
                        
                        </tr>
                             </>
                      
                          )
                        })} 
                 
                    </tbody>
                  </Table>
                </CardBody>

                <hr />

              </Card>
                </Card>
            </Col>
          </Row> 
        </div>
      </>
    );
  }
}

export default Imprint;
